/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react"
import { getLookupProjectType } from "../../services/api/projectType"
import { getLookupProjectStatus } from "../../services/api/projectStatus"
import { getLookupDistrict, getLookupProvince } from "../../../oneportal/services/api/administrative"
import { exportFile, updateProjectStatus } from "../../services/api/projects"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { headerQLCT } from "../../units/constanst"
import { TBody } from "../../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../../oneportal/components/Table/TBodyRow"
import { THead } from "../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../oneportal/components/Table/THeadRow"
import { TWrapper } from "../../../oneportal/components/Table/TWrapper"
import LastPageIcon from "../../../../assets/image/last-page-arrow.png"
import FirstPageIcon from "../../../../assets/image/first-page-arrow.png"
import BackIcon from "../../../../assets/image/back.png"
import NextIcon from "../../../../assets/image/next.png"
import { getAllProject } from "../../services/api/projects"
import DialogConfirm from "../../components/DialogConfirm/DialogConfirm"
import ListRecordInProject from "../ListProject/ListRecordInProject"
import { CircularProgress } from "@mui/material"

export type ListProjectProps = {
  isEdit?: boolean
  getProjectId?: (projectId: number) => void
}

const ListProject = (props: ListProjectProps) => {
  const { getProjectId } = props
  const api = useApiClient()
  const alert = useAlert()
  const [listProjectType, setListProjectType] = useState([] as any)
  const [listProjectStatus, setListProjectStatus] = useState([] as any)
  const [listProvince, setListProvince] = useState([] as any)
  const [listDistrict, setListDistrict] = useState([] as any)
  const [listProject, setListProject] = useState([] as any)
  const [totalPage, setTotalPage] = useState(1)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [statusId, setStatus] = useState()
  const [openConfirrm, setOpenConfirm] = useState(false)
  const [isExportingPdf, setIsExportingPdf] = useState(false)
  const [isExportingExcel, setIsExportingExcel] = useState(false)
  const [projectId, setProjectId] = useState()
  const [filter, setFilter] = useState({
    keyword: "",
    projectTypeId: "",
    provinceId: "",
    districtId: "",
    projectStatusId: "",
  })

  useEffect(() => {
    getProjectType()
    getProjectStatus()
    getProvince()
    GetListAll("", "", "", "", "", "", pageIndex, pageSize)
  }, [])

  useEffect(() => {
    getDistrict(filter.provinceId)
  }, [filter.provinceId])

  const GetListAll = async (
    keyword,
    projectTypeId,
    provinceId,
    districtId,
    projectStatusId,
    sort,
    pageIndex,
    pageSize
  ) => {
    try {
      const results = await getAllProject(api, {
        keyword,
        projectTypeId,
        provinceId,
        districtId,
        projectStatusId,
        sort,
        pageIndex,
        pageSize,
      })
      setListProject(results?.content.items.map((item) => ({ ...item, open: false })))
      setTotalPage(results?.content.pageCount)
      setPageSize(results?.content.pageSize)
    } catch (error) {}
  }

  const getProjectType = async () => {
    try {
      const results = await getLookupProjectType(api)

      setListProjectType(results?.content)
    } catch (error) {}
  }

  const getProjectStatus = async () => {
    try {
      const results = await getLookupProjectStatus(api)
      setListProjectStatus(results?.content)
    } catch (error) {}
  }

  const getProvince = async () => {
    try {
      const results = await getLookupProvince(api)
      setListProvince(results?.content)
    } catch (error) {}
  }

  const getDistrict = async (id) => {
    try {
      const results = await getLookupDistrict(api, id as number)
      setListDistrict(results?.content)
    } catch (error) {}
  }

  const onChangePage = (e) => {
    //GetListAll("", "", "", "", "", "", "", "asc", pageIndex.toString(), e.target.value)
    setPageSize(e.target.value)
    GetListAll(
      filter.keyword,
      filter.projectTypeId,
      filter.provinceId,
      filter.districtId,
      filter.projectStatusId,
      "",
      pageIndex,
      e.target.value
    )
  }

  const gotoLastPage = (e) => {
    setPageIndex(totalPage)
    GetListAll(
      filter.keyword,
      filter.projectTypeId,
      filter.provinceId,
      filter.districtId,
      filter.projectStatusId,
      "",
      totalPage,
      pageSize
    )
  }
  const gotoFirstPage = (e) => {
    setPageIndex(1)
    GetListAll(
      filter.keyword,
      filter.projectTypeId,
      filter.provinceId,
      filter.districtId,
      filter.projectStatusId,
      "",
      1,
      pageSize
    )
  }

  const gotoNextPage = (e) => {
    const next = pageIndex + 1
    if (pageIndex < totalPage) {
      setPageIndex(next)
      GetListAll(
        filter.keyword,
        filter.projectTypeId,
        filter.provinceId,
        filter.districtId,
        filter.projectStatusId,
        "",
        next,
        pageSize
      )
    }
  }

  const gotoPrePage = (e) => {
    if (pageIndex > 1) {
      const pre = pageIndex - 1
      setPageIndex(pre)

      GetListAll(
        filter.keyword,
        filter.projectTypeId,
        filter.provinceId,
        filter.districtId,
        filter.projectStatusId,
        "",
        pre,
        pageSize
      )
    }
  }

  const onChangeFilter = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    })
  }

  const searchFilter = (e) => {
    GetListAll(
      filter.keyword,
      filter.projectTypeId,
      filter.provinceId,
      filter.districtId,
      filter.projectStatusId,
      "",
      pageIndex,
      pageSize
    )
  }

  const onClearFilter = (e) => {
    setFilter({
      keyword: "",
      projectTypeId: "",
      provinceId: "",
      districtId: "",
      projectStatusId: "",
    })
    GetListAll("", "", "", "", "", "", pageIndex, pageSize)
  }

  const onChangeStatusByRow = async (e, id) => {
    setOpenConfirm(true)
    setProjectId(id)
    setStatus(e.target.value)
  }

  const updateStatus = async (e, id) => {
    try {
      const results = await updateProjectStatus(api, {
        id,
        statusId: statusId,
      })
      if (results) {
        alert("Cập nhật thành công", { variant: "success" })
        setOpenConfirm(false)
        GetListAll("", "", "", "", "", "", pageIndex, pageSize)
      }
    } catch (error) {
      GetListAll("", "", "", "", "", "", pageIndex, pageSize)
    }
  }

  const onClickRow = (id) => {
    setListProject(
      listProject.map((item) => {
        if (item.id === id) {
          return { ...item, open: !item?.open }
        }
        return { ...item, open: false }
      })
    )
  }

  const handlerExportFile = async (type: string) => {
    const { keyword, projectTypeId, provinceId, districtId, projectStatusId } = filter
    type === "pdf" ? setIsExportingPdf(true) : setIsExportingExcel(true)
    const results = await exportFile(api, {
      fileExtention: type,
      keyword,
      ProjectTypeId: projectTypeId,
      ProvinceId: provinceId,
      DistrictId: districtId,
      pageIndex,
      ProjectStatusId: projectStatusId,
      pageSize,
    })
    if (results) {
      setIsExportingPdf(false)
      setIsExportingExcel(false)
      const fileType =
        type === "pdf"
          ? "application/pdf"
          : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const url = window.URL.createObjectURL(new Blob([results], { type: fileType }))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `Congtrinh.${type}`)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    }
  }

  return (
    <>
      <section className="page-content qlds-page">
        <div className="page__title">
          <span>Danh sách công trình</span>
        </div>
        <div className="wrap__content-page qlhs-form">
          <form action="">
            <div className="form-row">
              <div className="form-group col-12 col-lg-6">
                <input
                  id="input-searcht"
                  type="text"
                  className="form-control"
                  placeholder="Nhập tên công trình hoặc tên chủ đầu tư"
                  name="keyword"
                  value={filter.keyword}
                  onChange={onChangeFilter}
                />
              </div>
              <div className="form-group col-12 col-lg-6">
                <select
                  className="custom-select"
                  onChange={onChangeFilter}
                  name="projectTypeId"
                  value={filter.projectTypeId}
                >
                  <option selected value="">
                    Loại công trình
                  </option>
                  {Array.isArray(listProjectType) &&
                    listProjectType.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-12 col-lg-4">
                <select className="custom-select" name="provinceId" onChange={onChangeFilter} value={filter.provinceId}>
                  <option selected value="">
                    Tỉnh/Thành phố
                  </option>
                  {Array.isArray(listProvince) &&
                    listProvince.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-12 col-lg-4">
                <select className="custom-select" name="districtId" onChange={onChangeFilter} value={filter.districtId}>
                  <option selected value="">
                    Quận/Huyện
                  </option>
                  {Array.isArray(listDistrict) &&
                    listDistrict.map((item) => <option value={item.id}>{item.name}</option>)}
                </select>
              </div>
              <div className="form-group col-12 col-lg-4">
                <select
                  className="custom-select"
                  name="projectStatusId"
                  onChange={onChangeFilter}
                  value={filter.projectStatusId}
                >
                  <option selected value="">
                    Trạng thái công trình
                  </option>
                  {Array.isArray(listProjectStatus) &&
                    listProjectStatus.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="form__save pt-3 border-0 mt-0">
              <div className="btn btn-ct btn-default-ct loading" onClick={onClearFilter}>
                Xóa
              </div>
              <div className="btn btn-ct btn-primary-ct loading" onClick={searchFilter}>
                Tìm kiếm
              </div>
              <button
                className="btn btn-ct btn-danger-ct loading"
                type="button"
                onClick={() => handlerExportFile("pdf")}
              >
                {isExportingPdf && <CircularProgress size={20} color="inherit" />}
                Export to PDF
              </button>
              <button
                className="btn btn-ct btn-success-ct loading"
                type="button"
                onClick={() => handlerExportFile("xlsx")}
              >
                {isExportingExcel && <CircularProgress size={20} color="inherit" />}
                Export to Excel
              </button>
            </div>
          </form>
        </div>

        <TWrapper tableContainerClassName="danhsach-ctrinh-table">
          <THead>
            <THeadRow>
              {Array.isArray(headerQLCT) &&
                headerQLCT.map((item: any, index) => <THeadCell key={index}>{item.name}</THeadCell>)}
            </THeadRow>
          </THead>
          <TBody>
            {Array.isArray(listProject) && listProject.length > 0 ? (
              listProject.map((item, index) => (
                <>
                  <TBodyRow onClick={() => onClickRow(item.id)}>
                    <TBodyCell>{index + 1}</TBodyCell>
                    <TBodyCell>{item?.projectGroupName}</TBodyCell>
                    <TBodyCell>{item?.name}</TBodyCell>

                    <TBodyCell>{item?.investors?.name}</TBodyCell>
                    <TBodyCell>{item?.address}</TBodyCell>
                    <TBodyCell>{item?.projectTypeName}</TBodyCell>
                    <TBodyCell>
                      <select className="custom-select" onChange={(e) => onChangeStatusByRow(e, item.id)}>
                        {Array.isArray(listProjectStatus) &&
                          listProjectStatus.map((row, index) => {
                            return (
                              <option
                                key={index}
                                value={row.id}
                                selected={row?.id?.toString() === item?.projectStatusId?.toString() ? true : false}
                              >
                                {row.name}
                              </option>
                            )
                          })}
                      </select>
                      {getProjectId && <button onClick={() => getProjectId(item.id)}>Chon</button>}
                    </TBodyCell>
                  </TBodyRow>
                  {item.open && (
                    <TBodyRow>
                      <TBodyCell colSpan={100}>
                        <ListRecordInProject projectId={item.id} />
                      </TBodyCell>
                    </TBodyRow>
                  )}
                </>
              ))
            ) : (
              <TBodyRow>
                <TBodyCell colSpan={100}>
                  <p className="text-center"> Không có dữ liệu</p>
                </TBodyCell>
              </TBodyRow>
            )}
          </TBody>
        </TWrapper>
        <div className="pagination">
          <p className="page-number">{`Trang ${pageIndex}/${totalPage}`}</p>
          <button className="first-page" onClick={gotoFirstPage}>
            <img src={FirstPageIcon} alt="" />
          </button>
          <button className="pre-page" onClick={gotoPrePage}>
            <img src={BackIcon} alt="" />
          </button>
          <select className="custom-select" onChange={onChangePage}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <button className="next-page" onClick={gotoNextPage}>
            <img src={NextIcon} alt="" />
          </button>
          <button className="last-page" onClick={gotoLastPage}>
            <img src={LastPageIcon} alt="" />
          </button>
        </div>
      </section>
      {statusId && (
        <DialogConfirm
          open={openConfirrm}
          setOpen={setOpenConfirm}
          content={"Bạn có muốn cập nhật trạng thái của dự án này?"}
          rowId={projectId}
          getList={GetListAll}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handleAccept={updateStatus}
        />
      )}
    </>
  )
}

export default ListProject
