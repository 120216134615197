/* eslint-disable react-hooks/exhaustive-deps */
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { useAction } from "@corets/use-action"
import { useAsync } from "@corets/use-async"
import { useForm } from "@corets/use-form"
import { Add, AttachFile, Remove } from "@mui/icons-material"
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router"
import { links } from "../../../../config/links"
import { WideDialog } from "../../../oneportal/components/Dialogs/WideDialog"
import { Select } from "../../../oneportal/components/Select"
import { createMuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { useDebounce } from "../../../oneportal/hooks/useDebounce"
import { autoSuggest } from "../../services/api/map4d"
import { getListForm, getReflectDetail } from "../../services/api/reflectTheScene"
import { createCreateOrUpdateReflectForm } from "./createCreateOrUpdateReflectForm"
import { isImage, isVideo, isDocument } from "../../utils/file"

export type CreateOrUpdateReflectDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
  levelOptions: Array<any>
  fieldOptions: Array<any>
  statusOptions: Array<any>
  sourceOptions: Array<any>
  categoryOptions: Array<any>
  actionOptions: Array<any>
}

export const CreateOrUpdateReflectDialog = (props: CreateOrUpdateReflectDialogProps) => {
  const { open, onClose, onSuccess, levelOptions, fieldOptions, sourceOptions, categoryOptions } = props
  const api = useApiClient()
  const alert = useAlert()
  const history = useHistory()

  const { reflectId } = useParams<{
    reflectId: string
  }>()

  const reflectAction = useAction(async () => await getReflectDetail(api, reflectId as any))

  const [form] = useForm(createCreateOrUpdateReflectForm(api, reflectAction.getResult()?.content), [
    JSON.stringify(reflectAction.getResult()?.content),
  ])
  const bind = createMuiFormBinder(form)

  const location = useLocation()

  const firstRouter = location?.pathname?.split("/")?.[1]

  const formOptions = useAsync(async () => await getListForm(api, {}), [])?.getResult()?.content || []

  const [totalFile, setTotalFile] = useState(1)
  const [detectionPositionSearchTerm, setDetectionPositionSearchTerm] = useState("")
  const detectionPositionDebounce = useDebounce(detectionPositionSearchTerm, 2000)
  const autoSuggestPoistions = useAction(async () => await autoSuggest(api, detectionPositionDebounce))

  useEffect(() => {
    if (open && reflectId) {
      reflectAction.run()
    }
  }, [reflectId, open])

  useEffect(() => {
    if (detectionPositionDebounce) {
      autoSuggestPoistions.run()
    }
  }, [detectionPositionDebounce])

  const handleSubmit = async () => {
    const errors = await form.validate()
    console.log("errors :>> ", errors)
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Thêm phản ánh thành công", { variant: "success" })
      handleClose()
      onSuccess && onSuccess()
    } else {
      alert("Thêm phản ánh thất bại", { variant: "error" })
    }
  }

  const handleClose = () => {
    onClose()
  }

  const handleChangeFile = (files, index) => {
    console.log(index, files)
    const fileTypeExist = {
      image: 0,
      video: 0,
      document: 0,
    }
    const currentFiles = form.getAt("files") || []
    for (let file of currentFiles) {
      if (file instanceof File && file?.type?.includes("image/")) {
        fileTypeExist.image++
      }
      if (typeof file?.filePath === "string" && isImage(file?.filePath)) {
        fileTypeExist.image++
      }
      if (file instanceof File && file?.type?.includes("video/")) {
        fileTypeExist.video++
      }
      if (typeof file?.filePath === "string" && isVideo(file?.filePath)) {
        fileTypeExist.video++
      }
      if (file instanceof File && isDocument(file?.name)) {
        fileTypeExist.document++
      }
      if (typeof file?.filePath === "string" && isDocument(file?.filePath)) {
        fileTypeExist.document++
      }
    }
    for (let file of files) {
      if (file?.type?.includes("image/") && fileTypeExist?.image >= 3) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
      if (file?.type?.includes("video/") && fileTypeExist?.video >= 1) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
      if (isDocument(file?.name) && fileTypeExist?.document >= 1) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
    }
    form.setAt(`files[${index}]`, files?.[0])
  }

  if (!open) {
    return <></>
  }

  return (
    <WideDialog maxWidth="lg" title={reflectId ? "Chỉnh sửa phản ánh" : "Thêm phản ánh"} onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-6">
            <label>Cá nhân/tổ chức</label>
            <input {...bind.textField("OrganizationPersonalName")} placeholder="--Nhập cá nhân/tổ chức--" />
            <FormHelperText {...bind.helperText("OrganizationPersonalName")} />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>Điện thoại</label>
            <input {...bind.textField("PhoneNumber")} placeholder="--Nhập số điện thoại--" />
            <FormHelperText {...bind.helperText("PhoneNumber")} />
          </div>

          <div className="form-group col-12 col-md-12">
            <label>
              Tiêu đề <span>*</span>
            </label>
            <input {...bind.textField("Title")} placeholder="--Nhập tiêu đề--" />
            <FormHelperText {...bind.helperText("Title")} />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>
              Nội dung <span>*</span>
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={bind.textField("Content").value}
              onChange={(event, editor) => {
                const data = editor.getData()
                form.setAt("Content", data)
              }}
            />
            <FormHelperText {...bind.helperText("Content")} />
          </div>

          <div className="form-group col-12 col-md-12">
            <label>
              Vị trí phát hiện <span>*</span>
            </label>
            <Autocomplete
              disabled={false}
              options={autoSuggestPoistions?.getResult()?.result || []}
              getOptionLabel={(option: any) => option?.address || ""}
              id="controlled"
              noOptionsText="Không có lựa chọn"
              isOptionEqualToValue={(option, value) => option === value}
              loading={autoSuggestPoistions?.isRunning() || detectionPositionSearchTerm !== detectionPositionDebounce}
              onChange={(e, value) => {
                form.setAt("DetectionPosition", value?.address)
                form.setAt("Longitude", value?.location?.lng?.toString())
                form.setAt("Latitude", value?.location?.lat?.toString())
                setDetectionPositionSearchTerm(value?.address)
              }}
              renderInput={(params) => {
                return (
                  <div ref={params.InputProps.ref}>
                    <input
                      {...params.inputProps}
                      type="text"
                      placeholder={`--Nhập vị trí phát hiện--`}
                      className={clsx(
                        "form-control custom-select",
                        form.getErrorsAt("DetectionPosition") && "is-invalid"
                      )}
                      value={detectionPositionSearchTerm}
                      onChange={(e) => {
                        setDetectionPositionSearchTerm(e.target.value)
                      }}
                    />
                  </div>
                )
              }}
            />
            <FormHelperText {...bind.helperText("DetectionPosition")} />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>
              Kinh độ <span>*</span>
            </label>
            <input {...bind.textField("Longitude")} readOnly />
            <FormHelperText {...bind.helperText("Longitude")} />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>
              Vĩ độ <span>*</span>
            </label>
            <input {...bind.textField("Latitude")} readOnly />
            <FormHelperText {...bind.helperText("Latitude")} />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>
              Lĩnh vực <span>*</span>
            </label>
            <Select options={fieldOptions} bind={bind} field="ReflectionFieldId" labelDefaultOption="Chọn lĩnh vực" />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>
              Chuyên mục <span>*</span>
            </label>
            <Select options={categoryOptions} bind={bind} field="CategoriesId" labelDefaultOption="Chọn chuyên mục" />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>
              Hình thức <span>*</span>
            </label>
            <Select options={formOptions} bind={bind} field="ReflectionFormId" labelDefaultOption="Chọn hình thức" />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>
              Mức độ <span>*</span>
            </label>
            <Select options={levelOptions} bind={bind} field={"ReflectionLevelId"} labelDefaultOption="Chọn mức độ" />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>
              Nguồn phản ánh <span>*</span>
            </label>
            <Select
              options={sourceOptions}
              bind={bind}
              field={"SourceOfReflectionId"}
              labelDefaultOption="Chọn nguồn phản ánh"
            />
          </div>

          <div className="form-group col-12 col-md-12">
            <label>
              Chi tiết nguồn <span>*</span>
            </label>
            <input {...bind.textField("SourceDetails")} placeholder="--Nhập chi tiết nguồn--" />
            <FormHelperText {...bind.helperText("SourceDetails")} />
          </div>

          <div className="form-group col-12 col-md-12">
            <label>File đính kèm</label>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>STT</TableCell>
                  <TableCell>Tải file</TableCell>
                  <TableCell>Xóa</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...new Array(totalFile)]?.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>
                      <Button variant="contained" component="label" startIcon={<AttachFile />}>
                        Chọn file
                        <input
                          hidden
                          type="file"
                          onChange={(e) => handleChangeFile(e?.target?.files, i)}
                          accept="image/*,video/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                        />
                      </Button>{" "}
                      {form.getAt(`files[${i}]`)?.name || ""}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          if (form.getAt("files")?.[i]) {
                            const newReflectionFiles = form.getAt("files")
                            newReflectionFiles.splice(i, 1)
                            form.setAt("files", newReflectionFiles)
                          }
                          setTotalFile(Math.max(0, totalFile - 1))
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell sx={{ width: "50px" }}>
                    <IconButton onClick={() => setTotalFile(totalFile + 1)}>
                      <Add />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="form-group col-12 col-md-12">
            <FormControlLabel
              control={
                <Checkbox
                  value={form.getAt("IsPublic")}
                  onClick={(e: any) => {
                    form.setAt("IsPublic", e.target?.checked)
                  }}
                />
              }
              label="Công khai phản ánh này"
            />
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button type="button" onClick={handleClose} className="btn btn-ct btn-danger-ct loading">
              Đóng
            </button>
            <button
              disabled={form.isSubmitting()}
              type="button"
              onClick={() => {
                form.setAt("IsPosted", true)
                handleSubmit()
              }}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {reflectId ? "Lưu thay đổi" : "Thêm"}
            </button>
            {/* <button
              disabled={form.isSubmitting()}
              type="button"
              onClick={() => {
                form.setAt("IsPosted", false)
                handleSubmit()
              }}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {"Lưu bản nháp"}
            </button> */}
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
