import { Apps, BorderColor, Print, Search } from "@mui/icons-material"
import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Tooltip,
} from "@mui/material"
import { useRef, useState } from "react"

interface ActionButtonProps {
  isNew?: boolean
  onClickProcess?: () => void
  onClickSame?: () => void
  onCloseSame?: () => void
}

const ActionButton = ({ isNew, onClickProcess, onClickSame, onCloseSame }: ActionButtonProps) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }
  return (
    <>
      <Tooltip title="Thao tác" placement="top" arrow>
        <IconButton ref={anchorRef} color="primary" onClick={handleToggle}>
          <Apps />
        </IconButton>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  <MenuItem onClick={() => onClickSame && onClickSame()}>
                    <ListItemIcon>
                      <Search />
                    </ListItemIcon>
                    <Typography>Phản ánh tương tự</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {}}>
                    <ListItemIcon>
                      <Print />
                    </ListItemIcon>
                    <Typography>Phiếu phản ánh</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => onClickProcess && onClickProcess()}>
                    <ListItemIcon>
                      <BorderColor />
                    </ListItemIcon>
                    <Typography>Xử lý</Typography>
                  </MenuItem>
                  <Typography variant="subtitle2" sx={{ pl: 1 }}>
                    ({isNew ? "Mới" : "Đã đọc"})
                  </Typography>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ActionButton
