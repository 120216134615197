import { AxiosInstance } from "axios"

const defaultKey = "29350440eb729a1980a474cb3953ceea"

export const autoSuggest = async (api: AxiosInstance, text: string) => {
  try {
    const res = await api.get("https://api.map4d.vn/sdk/autosuggest", {
      params: {
        text: text,
        Key: process.env.REACT_APP_4D_KEY || defaultKey,
      },
    })
    return res.data
  } catch (err) {}
}
