import { ChangeEvent, RefObject } from "react"

export type UploadArrayFileProps = {
  inputRef?: RefObject<HTMLInputElement>
  onUploadFile: (data: File) => void
}
export const UploadFile = (props: UploadArrayFileProps) => {
  const { inputRef, onUploadFile } = props

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e?.target?.files?.length && e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        onUploadFile(file)
      }

      reader.readAsDataURL(file)
    }
  }

  return (
    <input
      className="fileInput"
      type="file"
      onChange={(e) => handleUploadFile(e)}
      ref={inputRef}
      hidden
      multiple={false}
    />
  )
}
