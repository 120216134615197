import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"

export type SearchRoleFormType = {
  name?: string
}

export const createSearchRoleForm = (
  data?: SearchRoleFormType
): ObservableForm<SearchRoleFormType, SearchRoleFormType> => {
  const form = createFormFromSchema<SearchRoleFormType>(
    object<SearchRoleFormType>({
      name: string()
        .optional()
        .toDefault(data?.name || ""),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
