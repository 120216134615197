import React, { useEffect, useState } from "react"
import { Menu, MenuItem, IconButton, FormControlLabel, Checkbox } from "@mui/material"
import { MoreVert } from "@mui/icons-material"
import { HEADER_TABLE } from "../../units/constanst"

type CheckAllStatus = "Check" | "NoCheck" | "Indeterminate"

interface IColumn {
  name: string
  align?: string
  checked?: boolean
  disableToggle?: boolean
}

interface ColumnSettingProps {
  onApply?: (columns) => void
  initColumns?: IColumn[]
}

const ColumnSetting = ({ onApply, initColumns = HEADER_TABLE }: ColumnSettingProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [columns, setColumns] = useState<IColumn[]>([])
  const [checkAllStatus, setCheckAllStatus] = useState<CheckAllStatus>("Check")

  const open = Boolean(anchorEl)

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickApply = () => {
    onApply && onApply(columns?.filter((item) => item?.checked))
  }

  const handleToggleColumn = (column: string) => {
    setColumns(
      columns?.map((item) => {
        if (item?.name === column) {
          item.checked = !item?.checked
        }
        return item
      })
    )
  }

  const handleClickAll = () => {
    let isChecked = checkAllStatus !== "Check"
    setColumns(columns?.map((item) => ({ ...item, checked: item?.disableToggle ? true : isChecked })))
  }

  useEffect(() => {
    const calcCheckAllStatus = () => {
      let check = 0
      let nocheck = 0
      for (let column of columns) {
        if (column?.disableToggle) {
          continue
        }
        if (column.checked) {
          check++
        } else {
          nocheck++
        }
      }
      if (check === 0) {
        setCheckAllStatus("NoCheck")
      } else if (nocheck === 0) {
        setCheckAllStatus("Check")
      } else {
        setCheckAllStatus("Indeterminate")
      }
    }
    calcCheckAllStatus()
  }, [columns])

  useEffect(() => {
    setColumns(
      (initColumns || [])?.map((item) => ({
        ...item,
        checked: typeof item?.checked === "boolean" ? item?.checked : true,
      }))
    )
  }, [initColumns])

  return (
    <>
      <IconButton size="small" onClick={handleClickOpen}>
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <FormControlLabel
            label="Tất cả"
            sx={{ m: 0, p: 0 }}
            control={
              <Checkbox
                sx={{ p: "2px" }}
                checked={checkAllStatus === "Check"}
                indeterminate={checkAllStatus === "Indeterminate"}
                onClick={handleClickAll}
              />
            }
          />
        </MenuItem>
        {columns?.map((item: any, i) => (
          <MenuItem key={i}>
            <FormControlLabel
              label={item?.name}
              sx={{ m: 0, p: 0 }}
              control={
                <Checkbox
                  sx={{ p: "2px" }}
                  disabled={item?.disableToggle}
                  checked={item?.checked}
                  onChange={() => handleToggleColumn(item?.name)}
                />
              }
            />
          </MenuItem>
        ))}
        <MenuItem onClick={handleClickApply}>Áp dụng</MenuItem>
      </Menu>
    </>
  )
}

export default ColumnSetting
