import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material"
import { useState } from "react"
import { listProcessAction, ProcessAction } from "../../../units/constanst"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import CancelTab from "./CancelTab"
import DiscussTab from "./DiscussTab"
import ReceiveTab from "./ReceiveTab"
import RemoveTab from "./RemoveTab"
import UpdateTab from "./UpdateTab"
import DistributeTab from "./DistributeTab"
import ReconfirmTab from "./ReconfirmTab"
import ReprocessTab from "./ReprocessTab"
import RedistributeTab from "./RedistributeTab"
import VerifyTab from "./VerifyTab"
import ProcessTab from "./ProcessTab"
import DistributeUnitTab from "./DistributeUnitTab"
import ReVerifyTab from "./ReVerify"
import CompleteConfirmationTab from "./CompleteConfirmation"

interface ReflectProcessTabProps {
  reflect: ReflectResponse
  onUpdateSuccess?: () => void
  fieldOptions: any[]
  levelOptions: any[]
  actionOptions: any[]
  formOptions: any[]
  categoryOptions: any[]
  statusOptions: any[]
  sourceOptions: any[]
  listManipulationReflect: any[]
}

const ProcessTabSelectAction = (props: ReflectProcessTabProps) => {
  const { reflect, onUpdateSuccess, listManipulationReflect = [] } = props
  const [activeTab, setActiveTab] = useState<ProcessAction>(ProcessAction.Select)
  const [reflectAction, setReflectAction] = useState<any>(reflect)

  const listManipulationReflectOptions = [
    {
      label: "[Chọn thao tác]",
      value: ProcessAction.Select,
      dayOfExpected: 0,
    },
    ...listManipulationReflect?.map((manipulation) => ({
      label: manipulation?.name,
      value: manipulation?.id,
      dayOfExpected: manipulation?.dayOfExpected || 0,
    })),
  ]

  const handleChangeProcessAction = (e) => {
    setActiveTab(e.target.value as ProcessAction)
    const processTab = listProcessAction?.find((action) => action?.id === e.target.value)
    setReflectAction({
      ...reflect,
      processingStepCurrentId: processTab?.step || reflect?.processingStepCurrentId || 1,
      reflectionStatusId: processTab?.status || reflect?.reflectionStatusId || 1,
    })
  }

  const dayOfExpected = listManipulationReflectOptions.find((item) => item?.value === activeTab)?.dayOfExpected || 0

  const updateReflect = {
    ...reflectAction,
  }

  return (
    <Stack direction="column" spacing={2}>
      <Table
        sx={{
          width: "100%",
          td: { border: "1px solid #eee", borderCollapse: "collapse", ":first-of-type": { fontWeight: 500 } },
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableBody>
          <TableRow>
            <TableCell>Nội dung</TableCell>
            <TableCell>
              {<div className="ck-editor" dangerouslySetInnerHTML={{ __html: reflect?.content as string }} />}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <FormControl fullWidth variant="standard">
        <InputLabel>Thao tác</InputLabel>
        <Select value={activeTab} onChange={handleChangeProcessAction} label="Thao tác" variant="standard">
          {listManipulationReflectOptions?.map((action) => (
            <MenuItem key={action.value} value={action?.value}>
              {action.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ mt: 2 }}>
        {activeTab === ProcessAction.Receive && (
          <ReceiveTab {...props} reflect={updateReflect} onSuccess={onUpdateSuccess} />
        )}
        {activeTab === ProcessAction.Distribute && (
          <DistributeTab
            {...props}
            dayOfExpected={dayOfExpected || 0}
            onSuccess={onUpdateSuccess}
            reflect={updateReflect}
          />
        )}
        {activeTab === ProcessAction.DistributeUnit && (
          <DistributeUnitTab
            {...props}
            dayOfExpected={dayOfExpected || 0}
            reflect={updateReflect}
            onSuccess={onUpdateSuccess}
          />
        )}
        {activeTab === ProcessAction.Process && <ProcessTab reflect={updateReflect} onSuccess={onUpdateSuccess} />}
        {activeTab === ProcessAction.Reconfirm && <ReconfirmTab reflect={updateReflect} onSuccess={onUpdateSuccess} />}
        {activeTab === ProcessAction.Verify && <VerifyTab reflect={updateReflect} onSuccess={onUpdateSuccess} />}
        {activeTab === ProcessAction.CompleteConfirmation && (
          <CompleteConfirmationTab reflect={updateReflect} onSuccess={onUpdateSuccess} />
        )}
        {activeTab === ProcessAction.Cancel && <CancelTab reflect={updateReflect} onSuccess={onUpdateSuccess} />}
        {activeTab === ProcessAction.Exchange && <DiscussTab reflect={updateReflect} onSuccess={onUpdateSuccess} />}
        {activeTab === ProcessAction.Remove && <RemoveTab reflect={updateReflect} onSuccess={onUpdateSuccess} />}
        {activeTab === ProcessAction.Update && <UpdateTab {...props} onSuccess={onUpdateSuccess} />}
        {activeTab === ProcessAction.Redistribute && (
          <RedistributeTab {...props} onSuccess={onUpdateSuccess} reflect={updateReflect} />
        )}
        {activeTab === ProcessAction.Reprocess && <ReprocessTab reflect={updateReflect} onSuccess={onUpdateSuccess} />}
        {activeTab === ProcessAction.ReVerify && <ReVerifyTab reflect={updateReflect} onSuccess={onUpdateSuccess} />}
      </Box>
    </Stack>
  )
}

export default ProcessTabSelectAction
