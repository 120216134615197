/* eslint-disable react-hooks/exhaustive-deps */
import { PageContent } from "../../components/PageContent"
import { useApiClient } from "../../hooks/useApiClient"
import { getUserAccountDetail } from "../../services/api/account"
import { useAsync } from "@corets/use-async"
import { useForm } from "@corets/use-form"
import { createMuiFormBinder } from "../../helpers/createMuiFormBinder"
import { createUpdateProfileForm } from "./createUpdateProfile"
import { FormHelperText } from "@material-ui/core"
import { genderOptions, roleOptions } from "../../units/constanst"
import ChangeAvatarIcon from "../../../../assets/image/change-avatar-blue.png"
import AvatarDefault from "../../../../assets/image/avatar.jpg"
import { useEffect, useRef, useState } from "react"
import { UploadImage } from "../../components/UploadImage"
import { readImageFile } from "../../helpers/readImageFile"
import { useAlert } from "../../hooks/useAlert"
import { useAuth } from "../../hooks/useAuth"
import { CircularProgress } from "@mui/material"
import { getLookupPC07 } from "../../../records/services/api/pc07"
import { Select } from "../../components/Select"
import "../../../../assets/style/userInfo.css"
import { getLookupReflectionProcessingUnit } from "../../services/api/administrative"

const Profile = () => {
  const api = useApiClient()
  const inputRef = useRef<HTMLInputElement>(null)
  const alert = useAlert()
  const auth = useAuth()

  const userInfo = useAsync(() => getUserAccountDetail(api), [])
  const pc07Options = useAsync(() => getLookupReflectionProcessingUnit(api), [])

  const [form] = useForm(createUpdateProfileForm(api, userInfo.getResult()?.content), [JSON.stringify(userInfo)])
  const bind = createMuiFormBinder(form)

  const [imagePreview, setImagePreview] = useState<string | undefined>(AvatarDefault)

  useEffect(() => {
    userInfo.getResult()?.content && setImagePreview(readImageFile(userInfo.getResult()?.content?.avatar as string))
  }, [JSON.stringify(userInfo)])

  const handleSubmitForm = async () => {
    const formValidate = await form.validate()
    if (formValidate) return

    const res = await form.submit()

    if (res) {
      alert("Thành công", { variant: "success" })
      auth.refresh()
      userInfo.run()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const handleResetForm = () => {
    form.clear()
    setImagePreview(readImageFile(userInfo.getResult()?.content?.avatar as string, AvatarDefault))
  }

  return (
    <PageContent pageTitle="Thông tin tài khoản" sectionClassName="user-page" wrapClassName="user__form">
      <form>
        <div className="form__top d-flex justify-content-lg-between">
          <div className="main__form">
            <div className="form-row">
              <div className="form-group col-12 col-md-6">
                <label>
                  Họ tên<span>*</span>
                </label>
                <input {...bind.textField("fullName")} placeholder="Nguyễn Văn Anh" />
                <FormHelperText {...bind.helperText("fullName")} />
              </div>
              <div className="form-group col-12 col-md-6">
                <label>Giới tính</label>
                <select {...bind.select("sex")}>
                  {genderOptions.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12 col-md-6">
                <label>
                  Số điện thoại<span>*</span>
                </label>
                <input {...bind.textFieldNumber("phoneNumber")} placeholder="0973381806" />
                <FormHelperText {...bind.helperText("phoneNumber")} />
              </div>
              <div className="form-group col-12 col-md-6">
                <label>
                  Email<span>*</span>
                </label>
                <input disabled {...bind.textField("email")} placeholder="anhnv@gmail.com" />
                <FormHelperText {...bind.helperText("email")} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12 col-md-6">
                <label>Đơn vị công tác</label>
                <Select
                  disabled
                  options={pc07Options.getResult()?.content}
                  bind={bind}
                  field={"processingUnitId"}
                  labelDefaultOption="Chọn đơn vị"
                />
              </div>
              <div className="form-group col-12 col-md-6">
                <label>Quyền truy cập</label>
                <select disabled className="custom-select">
                  {roleOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="user__avatar">
            <h5>Avatar</h5>
            <div className="user__img">
              <img src={imagePreview} alt="" />
              <button type="button" className="change__avatar" onClick={() => inputRef?.current?.click()}>
                <img src={ChangeAvatarIcon} alt="" />
              </button>
              <UploadImage inputRef={inputRef} form={form} setImagePreview={setImagePreview} field="file" />
            </div>
          </div>
        </div>
        <div className="form__save d-flex align-items-center justify-content-center">
          <button className="btn btn-ct btn-default-ct" type="reset" onClick={handleResetForm}>
            Hủy
          </button>
          <button
            disabled={!form.isChanged() || form.isSubmitting()}
            className="btn btn-ct btn-primary-ct loading"
            type="button"
            onClick={handleSubmitForm}
          >
            {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
            Lưu thay đổi
          </button>
        </div>
      </form>
    </PageContent>
  )
}

export default Profile
