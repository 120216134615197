import { AxiosInstance } from "axios"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { ChunkedLookupResponse } from "../../../oneportal/units/types/ChunkedLookupResponse"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"
import { SecurityMatrix } from "../../units/types/SecurityMatrix"
import { SecurityMatrixRequest } from "../../units/types/SecurityMatrixRequest"

export type CheckPerParamRequest = {
  roleName?: string
  actionName?: string
  screenName?: string
}

export const getLookupAction = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/Permission/GetActionLookup")
    return res.data
  } catch (err) {}
}

export const getLookupScreen = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/Permission/GetScreenLookup")
    return res.data
  } catch (err) {}
}

export const getSecurityMatrixDetail = async (
  api: AxiosInstance,
  roleId: string
): Promise<ChunkedResponse<SecurityMatrix[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<SecurityMatrix[]>>("/api/admin/Permission/GetSecurityMatrixDetail", {
      params: { roleId },
    })
    return res.data
  } catch (err) {}
}

export const updateSecurityMatrix = async (
  api: AxiosInstance,
  req: SecurityMatrixRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>(
      "/api/admin/Permission/UpdateSecurityMatrix",
      req
    )

    return res.data
  } catch (err) {}
}

export const checkPermission = async (
  api: AxiosInstance,
  req: CheckPerParamRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Permission/CheckPermission", {
      params: req,
    })

    return res.data
  } catch (err) {}
}
