import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { REGEX_PASSWORD } from "../../../../oneportal/units/constanst"
import { resetPassword } from "../../../services/api/user"
import { ResetPassword } from "../../../units/types/ResetPassword"

export const createResetPasswordForm = (
  api: AxiosInstance,
  userId?: string
): ObservableForm<ResetPassword, boolean> => {
  const form = createFormFromSchema<ResetPassword, boolean>(
    object<ResetPassword>({
      password: string()
        .required(true, "Bắt buộc")
        .matches(REGEX_PASSWORD, "Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ít nhất 8 ký tự"),
      userId: string().toDefault(userId || ""),
    })
  ).handler(async (form) => {
    const data = await form.get()
    const res = await resetPassword(api, data)

    if (res?.content.status) return true
    return false
  })

  return form
}
