/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { useState } from "react"
import { Route } from "react-router-dom"
import { links } from "../../../../config/links"
import { createMuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { FormMode } from "../../../oneportal/units/enums/FormMode"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { getProjectDetail } from "../../services/api/projects"
import { ProjectInfo } from "../../units/enums/ProjectInfo"
import { Project } from "../../units/types/Project"
import { ProjectListDialog } from "../ProjectListDialog"
import { createCreateOrUpdateRecordForm } from "./createCreateOrUpdateRecordForm"
import { AttachFileType, RecordForm } from "./RecordForm"

export const CreateRecord = () => {
  const api = useApiClient()
  const [projectForm, setProjectForm] = useState<ChunkedResponse<Project> | undefined>(undefined)
  const projectAction = useAction(async (projectId: number) => await getProjectDetail(api, projectId))

  const [form] = useForm(createCreateOrUpdateRecordForm(api, FormMode.Create, undefined, projectForm?.content), [
    JSON.stringify(projectForm),
  ])
  const bind = createMuiFormBinder(form)
  const alert = useAlert()
  const [proInfo, setProInfo] = useState(ProjectInfo.New)
  const [attachFile, setAttachFile] = useState<AttachFileType | undefined>(undefined)

  const handleChangeProjectInfo = () => {
    setProjectForm(undefined)
    form.clear()
    setProInfo(proInfo === ProjectInfo.New ? ProjectInfo.Old : ProjectInfo.New)
  }

  const handleSubmitForm = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()
    if (res) {
      setProjectForm(undefined)
      setAttachFile(undefined)
      form.clear()
      alert("Thành công", { variant: "success" })
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const handleGetProjectDetail = async (recordId: number) => {
    await projectAction.run(recordId)
    setProjectForm(projectAction.getResult())
  }

  const isDisableProjectInfoFields = proInfo === ProjectInfo.Old
  return (
    <>
      <RecordForm
        bind={bind}
        onChangeProjectInfo={handleChangeProjectInfo}
        proInfo={proInfo}
        isDisableProjectInfoFields={isDisableProjectInfoFields}
        form={form}
        onSubmit={handleSubmitForm}
        attachFile={attachFile}
        setAttachFile={setAttachFile}
        positions={projectAction.getResult()?.content.positions}
        communeIds={projectAction.getResult()?.content.projectHasCommuneId}
      />
      <Route path={links.records.recordsList()}>
        <ProjectListDialog getProjectDetail={handleGetProjectDetail} />
      </Route>
    </>
  )
}
