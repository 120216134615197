/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "@corets/use-form"
import { ChangeEvent, KeyboardEvent, useEffect } from "react"
import { SearchCategoryFormType, createSearchCategoryForm } from "./createSearchCategoryForm"
import { Select } from "../../../../oneportal/components/Select"
import { useAsync } from "@corets/use-async"
import { getLookupReflectionFields } from "../../../../oneportal/services/api/administrative"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useHistory } from "react-router-dom"
import { links } from "../../../../../config/links"
import { WPlus } from "../../../../../assets/images"

export type SearchCategoryFormProps = {
  onSubmit: (data: SearchCategoryFormType) => void
  defaultValue: SearchCategoryFormType
}
export const SearchCategoryForm = (props: SearchCategoryFormProps) => {
  const { onSubmit, defaultValue } = props
  const api = useApiClient()
  const history = useHistory()

  const [form] = useForm(createSearchCategoryForm(defaultValue), [defaultValue])
  const reflectionOptions = useAsync(() => getLookupReflectionFields(api), [])
  const bind = createMuiFormBinder(form)

  const handleSubmit = async () => {
    const data = await form.get()
    onSubmit(data)
  }

  const handleChangeFormValue = (event: ChangeEvent<HTMLInputElement>, field: string) => {
    const value = event.target.value
    form.setAt(field, value)
    if (!value) {
      handleSubmit()
    }
  }

  const handleKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit()
    }
  }

  useEffect(() => {
    if (form.getAt("reflectionFieldId")) {
      handleSubmit()
    }
  }, [form.getAt("reflectionFieldId")])

  return (
    <form>
      <div className="form-row flex-wrap">
        <div className="form-group mb-0 col-lg-6">
          <input
            value={form.getAt("keyword") || ""}
            onChange={(event) => handleChangeFormValue(event, "keyword")}
            className="form-control"
            placeholder="Tìm kiếm tên chuyên mục"
            onKeyDown={handleKeyEnter}
          />
        </div>
        <div className="form-group mb-0 col-lg-2">
          <Select
            options={reflectionOptions.getResult()?.content}
            bind={bind}
            field={"reflectionFieldId"}
            labelDefaultOption="Chọn lĩnh vực"
          />
        </div>
        <div className="form__save-ct">
          <div className="form__save border-top-0 pt-0 mt-0 justify-content-start">
            <button className="btn btn-ct btn-primary-ct" type="button" onClick={handleSubmit}>
              Tìm kiếm
            </button>
            <button
              className="btn  btn-ct  btn-add btn-danger-ct  d-flex align-items-center"
              type="button"
              onClick={() => history.push(links.category.createCategory())}
            >
              Thêm chuyên mục
              <img src={WPlus} alt="" />
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
