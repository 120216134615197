import { AxiosError, AxiosInstance } from "axios"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { createErrorType } from "../../../oneportal/helpers/createErrorType"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { ChunkedLookupResponse } from "../../../oneportal/units/types/ChunkedLookupResponse"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"
import { ErrorType } from "../../../oneportal/units/types/ErrorType"
import { Role } from "../../../oneportal/units/types/Role"
import { RoleRequest } from "../../units/types/RoleRequest"

export type RoleParams = ChunkedParams & {
  name?: string
  sortExpression?: string
  code?: string
}

export const getListRole = async (
  api: AxiosInstance,
  params?: RoleParams
): Promise<ChunkedPaginationResponse<Role> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<Role>>("/api/admin/Role/GetListRole", {
      params: req,
    })

    return res.data
  } catch (err) {}
}

export const createRole = async (
  api: AxiosInstance,
  req: RoleRequest,
  errorCallBack?: (error?: ErrorType) => void
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Role/CreateRole", req)

    return res.data
  } catch (err: unknown | AxiosError) {
    errorCallBack!(createErrorType((err as AxiosError).response))
  }
}

export const updateRole = async (
  api: AxiosInstance,
  req: RoleRequest,
  errorCallBack?: (error?: ErrorType) => void
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Role/UpdateRole", req)

    return res.data
  } catch (err: unknown | AxiosError) {
    errorCallBack!(createErrorType((err as AxiosError).response))
  }
}

export const getRoleDetail = async (api: AxiosInstance, roleId: string): Promise<ChunkedResponse<Role> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Role>>("/api/admin/Role/GetDetailRole", {
      params: { id: roleId },
    })
    return res.data
  } catch (err) {}
}

export const deleteRole = async (
  api: AxiosInstance,
  roleId: string
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Role/DeleteRole", null, {
      params: { id: roleId },
    })

    return res.data
  } catch (err: unknown | AxiosError) {}
}

export const getLookupRole = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/Role/GetRoleLookup")
    return res.data
  } catch (err) {}
}
