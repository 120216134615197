import styled from "styled-components"
import { ReactNode } from "react"
import { SortDirection } from "../../units/enums/SortDirection"
import { TableCell, TableCellProps, TableSortLabel } from "@mui/material"

export type THeadCellProps = {
  name?: string
  align?: TableCellProps["align"]
  onClick?: (name: string) => void
  active?: boolean
  direction?: SortDirection
  children?: ReactNode
  colSpan?: string | number | any
  rowSpan?: string | number | any
  className?: string
}

export const THeadCell = (props: THeadCellProps) => {
  const { name, align, onClick, children, active, direction, colSpan, rowSpan, className } = props

  return (
    <TableCell className={className} align={align} colSpan={colSpan} rowSpan={rowSpan}>
      {!!onClick && (
        <CustomSortLabel active={active} direction={direction} onClick={() => !!onClick && onClick(name ?? "")}>
          <CustomTextLabel color="inherit">{children}</CustomTextLabel>
        </CustomSortLabel>
      )}

      {!onClick && <CustomTextLabel>{children}</CustomTextLabel>}
    </TableCell>
  )
}

const CustomTextLabel = styled.label`
  color: var(--text-color);
  min-height: 1px;
  font-weight: 500;
  margin-bottom: 0;
`

const CustomSortLabel = styled(TableSortLabel)`
  & .MuiTableSortLabel-icon {
    color: var(--text-color) !important;
  }
`
