import { useAction } from "@corets/use-action"
import { useAsync } from "@corets/use-async"
import { LocationOn } from "@mui/icons-material"
import { Box, Button, Drawer, MenuItem, MenuList, Autocomplete, TextField, Paper, InputBase } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import Leaflet from "leaflet"
import { useEffect, useState } from "react"
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"
import { PageContent } from "../../../oneportal/components/PageContent"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { useDebounce } from "../../../oneportal/hooks/useDebounce"
import InfoDialog from "../../components/ReflectProcessDialog/InfoDialog"
import { autoSuggest } from "../../services/api/map4d"
import { getListCategories, getListReflect } from "../../services/api/reflectTheScene"

import locationIcon from "../../../../assets/image/location.png"
import { dateVNFormat } from "../../../oneportal/helpers/utils"

export const markIcon = new Leaflet.Icon({
  iconUrl: locationIcon,
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [25, 50],
})

const Map = () => {
  const theme = useTheme()
  const api = useApiClient()
  const alert = useAlert()

  const [open, setOpen] = useState(false)
  const [address, setAddress] = useState<any>(null)
  const [cateId, setCateId] = useState(0)
  const [searchTermInput, setSerchTermInput] = useState("")
  const searchTermInputDebounce = useDebounce(searchTermInput, 1500)
  const [openInfoReflectModal, setOpenInfoReflectModal] = useState(false)
  const [reflectProcessId, setReflectProcessId] = useState<any>(null)

  const autoSuggestPoistions = useAction(async () => await autoSuggest(api, searchTermInputDebounce))
  const categoryOptions = useAsync(async () => await getListCategories(api, {}), [])?.getResult()?.content || []

  const filterParams = {
    ReflectionStatusIds: 2,
    CategoriesId: cateId || undefined,
    IsDelete: false,
  }

  const reflectAction = useAction(async () => await getListReflect(api, filterParams))
  const reflectList = reflectAction.getResult()?.content.items || []

  let position: any = [
    +reflectList?.[0]?.latitude || 20.545056263611123,
    +reflectList?.[0]?.longitude || 105.93393770840272,
  ]
  if (address) {
    position[0] = +address?.location?.lat
    position[1] = +address?.location?.lng
  }

  console.log(position, cateId)

  const handleClickCategory = (id: number) => {
    setCateId(id)
    setAddress(null)
  }

  useEffect(() => {
    if (searchTermInputDebounce) {
      autoSuggestPoistions.run()
    }
  }, [searchTermInputDebounce])

  useEffect(() => {
    reflectAction.run()
  }, [cateId])

  useEffect(() => {
    const options = reflectList?.length ?? {}
    if (cateId && !reflectList?.length) {
      alert("Không có phản ánh nào", { variant: "error" })
    }
  }, [reflectList])

  function UpdateMapPosition() {
    const map = useMap()

    useEffect(() => {
      map.setView(position)
    }, [position])

    return null
  }

  const renderReflectInfo = (item) => (
    <Box sx={{ "& > p": { m: 0 }, maxHeight: 600, overflowY: "auto" }}>
      <p>
        <b>Tiêu đề:</b> {item?.title}
      </p>
      <p>
        <b>Vị trí:</b> {item?.detectionPosition}
      </p>
      <p>
        <b>Thời gian:</b> {dateVNFormat(item?.createdDate)}
      </p>
      <p
        className="map-view-detail"
        onClick={() => {
          setOpenInfoReflectModal(true)
          setReflectProcessId(item?.id)
        }}
      >
        Chi tiết
      </p>
    </Box>
  )

  return (
    <PageContent pageTitle="Bản đồ phản ánh hiện trường" sectionClassName="user-page">
      <Box sx={{ position: "relative", m: "-30px" }}>
        <Box
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: "absolute",
            display: "flex",
            paddingBottom: "16px",
            zIndex: 1,
            top: 7,
            left: 50,
            right: 20,
          }}
        >
          <Paper>
            <Autocomplete
              options={autoSuggestPoistions?.getResult()?.result || []}
              getOptionLabel={(option: any) => option?.address || ""}
              noOptionsText="No options"
              loadingText="Loading…"
              isOptionEqualToValue={(option, value) => option?.address === value}
              clearIcon
              clearOnBlur={false}
              loading={autoSuggestPoistions?.isRunning()}
              value={address?.address}
              onChange={(e, value: any) => {
                setAddress(value)
                setCateId(0)
              }}
              fullWidth
              sx={{ width: 400 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Nhập vị trí tìm kiếm"
                  onChange={(e) => {
                    setSerchTermInput(e.target.value)
                  }}
                  sx={{ fieldset: { border: "none" } }}
                />
              )}
            />
          </Paper>
          <Button onClick={() => setOpen(true)} sx={{ textTransform: "none" }}>
            Xem phản ánh theo lĩnh vực
          </Button>
        </Box>
        <MapContainer center={position} zoom={13} scrollWheelZoom={true} style={{ height: 700, zIndex: 0 }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <UpdateMapPosition />
          {cateId &&
            reflectList?.map((item, i) => (
              <Marker position={[item?.latitude, item?.longitude]} icon={markIcon} key={i}>
                <Popup>{renderReflectInfo(item)}</Popup>
                {/* <Tooltip direction="top" offset={[8, -50]}>
                  {renderReflectInfo(item)}
                </Tooltip> */}
              </Marker>
            ))}
          {address && <Marker position={position} icon={markIcon}></Marker>}
        </MapContainer>
      </Box>

      <InfoDialog
        open={openInfoReflectModal}
        handleClose={() => {
          setReflectProcessId(null)
          setOpenInfoReflectModal(false)
        }}
        reflectId={reflectProcessId}
      />

      <Drawer anchor="right" open={open} onClose={() => setOpen(false)} className="map-drawer">
        <Box sx={{ p: 1 }}>
          <h3>Lĩnh vực</h3>
          <MenuList>
            {categoryOptions?.map((category) => (
              <MenuItem
                key={category?.id}
                sx={{ color: category?.id === cateId ? theme.palette.primary.main : "black" }}
                onClick={() => handleClickCategory(+category?.id || 0)}
              >
                <div className="d-flex items-center">
                  <LocationOn />
                  <span>{category?.name}</span>
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Drawer>
    </PageContent>
  )
}

export default Map
