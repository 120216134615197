import { TableCell, TableCellProps } from "@mui/material"
import { ReactNode } from "react"

export type TBodyCellProps = {
  align?: TableCellProps["align"]
  className?: string
  colSpan?: number
  children?: ReactNode
  toolTip?: string
  onClick?: (e?: any) => any
}

export const TBodyCell = (props: TBodyCellProps) => {
  const { align, className, colSpan,toolTip, onClick = () => null, children } = props

  return (
    <TableCell className={className} align={align} onClick={onClick} colSpan={colSpan} title={toolTip}>
      {children}
    </TableCell>
  )
}
