import { useAction } from "@corets/use-action"
import CloseIcon from "@mui/icons-material/Close"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material"
import Slide from "@mui/material/Slide"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import React, { useEffect } from "react"
import Leaflet from "leaflet"
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getReflectDetail } from "../../../services/api/reflectTheScene"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import locationIcon from "../../../../../assets/image/location.png"

const markIcon = new Leaflet.Icon({
  iconUrl: locationIcon,
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [25, 50],
})

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface MapDialogProps {
  open: boolean
  handleClose: () => void
  reflect: ReflectResponse
}

export default function MapDialog(props: MapDialogProps) {
  const { open, handleClose, reflect } = props
  const theme = useTheme()
  const api = useApiClient()

  const lat = reflect?.latitude || 0
  const lng = reflect?.longitude || 0
  const position: any = [+lat || 20.545056263611123, +lng || 105.93393770840272]

  function UpdateMapPosition() {
    const map = useMap()

    useEffect(() => {
      map.setView(position);
    }, [position])

    return null
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, color: "#fff" }}>
        Bản đồ địa điểm phản ánh
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: 400 }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <UpdateMapPosition />
          <Marker position={position} icon={markIcon}>
            <Popup>{reflect?.detectionPosition}</Popup>
          </Marker>
        </MapContainer>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  )
}
