import { useAction } from "@corets/use-action"
import { useAsync } from "@corets/use-async"
import CloseIcon from "@mui/icons-material/Close"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tab } from "@mui/material"
import Slide from "@mui/material/Slide"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import React, { useEffect, useState } from "react"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { getListForm, getManipulationOfUser, getReflectDetail } from "../../services/api/reflectTheScene"
import { ReflectResponse } from "../../units/types/ReflectResponse"
import ReflectAccessLogTab from "./AccessLogTab"
import ReflectActionLogTab from "./ActionLogTab"
import ReflectInfoTab from "./InfoTab"
import ReflectProcessTab from "./ProcessTab"
import DiscussTab from "./DiscussTab"
import ProcessResultTab from "./ProcessResultTab"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface ProcessDialogProps {
  open: boolean
  handleClose: () => void
  reflectId: string | number
  fieldOptions: any[]
  levelOptions: any[]
  actionOptions: any[]
  categoryOptions: any[]
  statusOptions: any[]
  sourceOptions: any[]
  handleRefreshReflect?: () => void
}

export default function ProcessDialog(props: ProcessDialogProps) {
  const {
    open,
    handleClose,
    reflectId,
    fieldOptions,
    levelOptions,
    actionOptions,
    categoryOptions,
    statusOptions,
    sourceOptions,
    handleRefreshReflect,
  } = props
  const theme = useTheme()
  const api = useApiClient()
  const [tabActive, setTabActive] = useState("1")

  const reflectAction = useAction(async () => await getReflectDetail(api, reflectId?.toString()))
  const reflectItem: ReflectResponse = reflectAction?.getResult()?.content

  const formOptions = useAsync(async () => await getListForm(api, {}), [])?.getResult()?.content || []

  const manipulationAction = useAction(
    async () =>
      await getManipulationOfUser(api, {
        reflectTheSceneId: reflectId,
      })
  )

  const listManipulationReflect = manipulationAction?.getResult()?.content?.status || []

  const handleUpdateSuccess = () => {
    // reflectAction.run()
    console.log(reflectId, handleRefreshReflect)
    handleRefreshReflect && handleRefreshReflect()
    handleClose()
  }

  useEffect(() => {
    if (reflectId) {
      manipulationAction.run()
    }
  }, [reflectId])

  useEffect(() => {
    if (reflectId && open) {
      setTabActive("1")
      reflectAction.run()
    }
  }, [reflectId, open])

  const tabs = [
    {
      label: "Thông tin phản ánh",
      value: "1",
      content: <ReflectInfoTab reflect={reflectItem} />,
    },
    {
      label: "Xử lý",
      value: "2",
      content: (
        <ReflectProcessTab
          reflect={reflectItem}
          fieldOptions={fieldOptions}
          levelOptions={levelOptions}
          formOptions={formOptions}
          actionOptions={actionOptions}
          categoryOptions={categoryOptions}
          statusOptions={statusOptions}
          sourceOptions={sourceOptions}
          onUpdateSuccess={handleUpdateSuccess}
          listManipulationReflect={listManipulationReflect}
        />
      ),
    },
    {
      label: "Nhật ký thao tác",
      value: "3",
      content: <ReflectActionLogTab reflect={reflectItem} />,
    },
    {
      label: "Truy cập",
      value: "4",
      content: <ReflectAccessLogTab reflect={reflectItem} />,
    },
    {
      label: "Trao đổi",
      value: "5",
      content: <DiscussTab reflect={reflectItem} onSuccess={handleUpdateSuccess} />,
    },
  ]

  if (reflectItem?.reflectionHandledViewDto) {
    tabs.push({
      label: "Kết quả xử lý",
      value: "6",
      content: <ProcessResultTab reflect={reflectItem} />,
    })
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, color: "#fff" }}>
        Xem thông tin
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TabContext value={tabActive}>
          <TabList
            onChange={(e, value) => setTabActive(value)}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {tabs?.map((tab) => (
              <Tab label={tab?.label} key={tab?.label} value={tab?.value} />
            ))}
          </TabList>
          {tabs?.map((tab) => (
            <TabPanel key={tab?.label} value={tab?.value}>
              {tab?.content}
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  )
}
