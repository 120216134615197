import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { Button, IconButton } from "@mui/material"
import { Add, AttachFile, Remove, Close } from "@mui/icons-material"
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tab } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import Slide from "@mui/material/Slide"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import React, { useEffect, useState } from "react"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { createCreateOrUpdateCommentForm } from "./CreateOrUpdateCommentForm"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface ConfirmDialogProps {
  comment?: any
  open: boolean
  handleClose: () => void
  onSuccess?: () => void
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const { comment, open, handleClose, onSuccess } = props
  const theme = useTheme()
  const api = useApiClient()
  const alert = useAlert()

  const [form] = useForm(createCreateOrUpdateCommentForm(api, comment), [JSON.stringify(comment)])

  const actionName = comment?.isActive ? "Ẩn" : "Hiện"

  const handleSubmit = async () => {
    const errors = await form.validate()
    console.log("comment show hide", form.get(), errors)
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert(actionName + " phải hồi thành công", { variant: "success" })
      onSuccess && onSuccess()
    } else {
      alert(actionName + " phải hồi thất bại", { variant: "error" })
    }
  }

  useEffect(() => {
    if (open) {
      form.setAt("IsActive", !form.getAt("IsActive"))
    }
  }, [comment])

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, color: "#fff" }}>
        Xác nhận {actionName?.toLowerCase()} phản hồi
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>Bạn có muốn {actionName?.toLowerCase()} phản hồi này không?</DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          Đóng
        </Button>
        <LoadingButton loading={form.isSubmitting()} variant="contained" onClick={handleSubmit}>
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
