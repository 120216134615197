import { AxiosInstance } from "axios"
import { ReflectHistoryParams } from "../../units/types/ReflectHistoryParams"
import { ReflectHistoryRequest } from "../../units/types/ReflectHistoryRequest"

export const getListReflectHistory = async (api: AxiosInstance, params?: ReflectHistoryParams): Promise<any> => {
  try {
    const res: any = await api.get("/api/admin/ReflectTheSceneHistory/GetAll", {
      params: params,
    })
    return res?.data
  } catch (error) {}
}

export const createReflectHistory = async (api: AxiosInstance, body?: ReflectHistoryRequest): Promise<any> => {
  try {
    const res: any = await api.post("/api/admin/ReflectTheSceneHistory/Create", body)
    return res?.data
  } catch (error) {}
}

export const updateReflectHistory = async (api: AxiosInstance, body?: ReflectHistoryRequest): Promise<any> => {
  try {
    const res: any = await api.put("/api/admin/ReflectTheSceneHistory/Update", body)
    return res?.data
  } catch (error) {}
}

export const deleteReflectHistory = async (api: AxiosInstance, id: number): Promise<any> => {
  try {
    const res: any = await api.put("/api/admin/ReflectTheSceneHistory/Delete", {}, { params: { id } })
    return res?.data
  } catch (error) {}
}
