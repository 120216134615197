import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { createReflectionField, updateReflectionField } from "../../../services/api/reflection"
import { DistrictRequest } from "../../../units/types/districtRequest"
import { Reflection } from "../../../units/types/reflection"

export const createCreateOrUpdateReflectionFieldForm = (
  api: AxiosInstance,
  data?: Reflection
): ObservableForm<DistrictRequest, boolean> => {
  const form = createFormFromSchema<DistrictRequest, boolean>(
    object<DistrictRequest>({
      id: string()
        .optional()
        .toDefault(data?.id?.toString() || ""),
      name: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.name || ""),
    })
  ).handler(async (form) => {
    const datas = await form.get()

    if (data) {
      const res = await updateReflectionField(api, datas)
      if (res?.content?.status) return true
    } else {
      delete datas.id
      const res = await createReflectionField(api, datas)
      if (res?.content?.status) return true
    }
    return false
  })

  return form
}
