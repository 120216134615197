/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "@corets/use-form"
import { CircularProgress } from "@mui/material"
import { InputDatePicker } from "../../../oneportal/components/DatePicker"
import { WideDialog } from "../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { changeStatusDoneForm } from "./changeStatusDoneForm"
import { Select } from "../../../oneportal/components/Select"

import { changeStatusForm } from "./changeStatusForm"

export type ChangeStatusProps = {
  onClose: () => void
  onSuccess?: (data) => void
  statusId: string
}

export const ChangeStatus = (props: ChangeStatusProps) => {
  const { onClose, onSuccess = () => null, statusId } = props
  const resultOptions = [
    {
      label: "Đạt",
      value: "true",
    },
    {
      label: "Không đạt",
      value: "false",
    },
  ]

  const [form] = useForm(statusId === "4" ? changeStatusForm() : changeStatusDoneForm(), [JSON.stringify({})])
  const bind = createMuiFormBinder(form)
  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const data = await form.get()

    if (data) {
      onClose()
      onSuccess(data)
    }
  }

  return (
    <WideDialog title="Chuyển trạng thái hồ sơ" onClose={onClose}>
      <form>
        <div className="form-row">
          {statusId === "4" && (
            <div className="form-group col-12 col-md-12">
              <label>Lý do hủy</label>
              <input {...bind.textField("cancelReason")} />
            </div>
          )}
          <div className="form-group col-12 col-md-12">
            <label>
              Ngày trả lời <span>*</span>
            </label>
            <InputDatePicker {...bind.datePicker("dateRealReturn")} bind={bind} field="dateRealReturn" />
          </div>

          {statusId === "3" && (
            <div className="form-group col-12 col-md-12">
              <label>
                Kết quả <span>*</span>
              </label>

              <Select
                options={resultOptions.map((item) => {
                  return {
                    id: item.value,
                    name: item.label,
                  }
                })}
                bind={bind}
                field={"result"}
                labelDefaultOption="Chọn kết quả"
              />
            </div>
          )}

          <div className="col-12 d-flex justify-content-center">
            <button
              disabled={form.isSubmitting() || !form.isChanged()}
              type="button"
              onClick={handleSubmit}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              Lưu thay đổi
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
