import { useForm } from "@corets/use-form"
import { ChangeEvent, KeyboardEvent } from "react"
import { useHistory } from "react-router"
import { WPlus } from "../../../../../assets/images"
import { links } from "../../../../../config/links"
import {
  createSearchReflectionProcessingForm,
  SearchReflectionProcessingFormType,
} from "./createSearchReflectionProcessingForm"

export type SearchReflectionProcessingFormProps = {
  onSubmit: (data: SearchReflectionProcessingFormType) => void
  defaultValue: SearchReflectionProcessingFormType
}
export const SearchReflectionProcessingForm = (props: SearchReflectionProcessingFormProps) => {
  const { onSubmit, defaultValue } = props
  const history = useHistory()

  const [form] = useForm(createSearchReflectionProcessingForm(defaultValue), [defaultValue])

  const handleSubmit = async () => {
    const data = await form.get()
    onSubmit(data)
  }

  const handleChangeFormValue = (event: ChangeEvent<HTMLInputElement>, field: string) => {
    const value = event.target.value
    form.setAt(field, value)
    if (!value) {
      handleSubmit()
    }
  }

  const handleKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit()
    }
  }

  return (
    <form>
      <div className="form-row flex-wrap">
        <div className="form-group mb-0 col-lg-4">
          <input
            value={form.getAt("keyword") || ""}
            onChange={(event) => handleChangeFormValue(event, "keyword")}
            className="form-control"
            placeholder="Tìm theo tên"
            onKeyDown={handleKeyEnter}
          />
        </div>
        <div className="form-group mb-0 col-lg-2">
          <input
            value={form.getAt("address") || ""}
            onChange={(event) => handleChangeFormValue(event, "address")}
            className="form-control"
            placeholder="Tìm theo địa chỉ"
            onKeyDown={handleKeyEnter}
          />
        </div>
        <div className="form-group mb-0 col-lg-2">
          <input
            value={form.getAt("phoneNumber") || ""}
            onChange={(event) => handleChangeFormValue(event, "phoneNumber")}
            className="form-control"
            placeholder="Tìm theo số điện thoại"
            onKeyDown={handleKeyEnter}
          />
        </div>
        <div className="form__save-ct">
          <div className="form__save border-top-0 pt-0 mt-0 justify-content-start">
            <button className="btn btn-ct btn-primary-ct" type="button" onClick={handleSubmit}>
              Tìm kiếm
            </button>
            <button
              className="btn  btn-ct  btn-add btn-danger-ct  d-flex align-items-center"
              type="button"
              onClick={() => history.push(links.category.createReflectionProcessingUnit())}
            >
              Thêm đơn vị xử lý
              <img src={WPlus} alt="" />
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
