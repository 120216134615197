import { useForm } from "@corets/use-form"
import { useAction } from "@corets/use-action"
import { useAsync } from "@corets/use-async"
import { Delete, Edit } from "@mui/icons-material"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableRow,
} from "@mui/material"
import { useEffect, useState } from "react"
import { PagePagination } from "../../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../../oneportal/components/PagePagination/usePagePagination"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { TBody } from "../../../../oneportal/components/Table/TBody"
import { TBodyRow } from "../../../../oneportal/components/Table/TBodyRow"
import { TBodyCell } from "../../../../oneportal/components/Table/TBodyCell"
import { TBodySkeleton } from "../../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../../../oneportal/components/Table/TWrapper"
import { dateVNFormat } from "../../../../oneportal/helpers/utils"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getUserAccountDetail } from "../../../../oneportal/services/api/account"
import {
  getListReflectHistory,
  createReflectHistory,
  updateReflectHistory,
  deleteReflectHistory,
} from "../../../services/api/reflectTheSceneHistory"
import { updateReflect } from "../../../services/api/reflectTheScene"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import { ReflectHistoryResponse } from "../../../units/types/ReflectHistoryResponse"
import { updateStatusReflectForm } from "../../CreateOrUpdateReflectDialog/updateStatusReflectForm"

interface ExchangeTabProps {
  reflect: ReflectResponse
  onSuccess?: () => void
}

const ExchangeTab = ({ reflect }: ExchangeTabProps) => {
  const api = useApiClient()
  const alert = useAlert()

  const [content, setContent] = useState("")
  const [idToUpdate, setIdToUpdate] = useState<any>(null)
  const [idToDelete, setIdToDelete] = useState<any>(null)

  const user: any = useAsync(() => getUserAccountDetail(api), [])?.getResult()?.content

  const [form] = useForm(updateStatusReflectForm(api, reflect), [JSON.stringify(reflect)])

  const reflectHistoryAction = useAction(
    async () =>
      await getListReflectHistory(api, {
        ReflectTheSceneId: reflect?.id,
        IsDelete: false,
        IsDiscuss: true,
        // ProcessingStepNewId: 10,
        PageIndex: pagination.query.pageIndex || 0,
        PageSize: pagination.query.pageSize || 10,
      })
  )
  const listReflectHistory = reflectHistoryAction?.getResult()?.content?.items || []

  const pagination = usePagePagination({
    pageCount: reflectHistoryAction.getResult()?.content.pageCount,
    useQueryString: false,
  })

  // const createDiscussAction = useAction(
  //   async () =>
  //     await createReflectHistory(api, {
  //       reason: content,
  //       isDiscuss: true,
  //       reflectTheSceneId: reflect?.id,
  //       processingStepNewId: 10,
  //       userId: user?.id,
  //     })
  // )

  const updateDiscussAction = useAction(
    async () =>
      await updateReflectHistory(api, {
        // content: content,
        reason: content,
        isDiscuss: true,
        reflectTheSceneId: reflect?.id,
        // processingStepPreviousId: reflect.processingStepCurrentId,
        // processingStepNewId: 10,
        // userId: user?.id,
        id: idToUpdate,
      })
  )

  const deleteDiscussAction = useAction(async () => await deleteReflectHistory(api, idToDelete || 0))

  const handleCreateOrUpdateDiscuss = async () => {
    if (!content) {
      alert("Nội dung không được để trống", { variant: "error" })
      return
    }
    if (!idToUpdate) {
      try {
        // await createDiscussAction.run()
        await form.submit()
        reflectHistoryAction.run()
        setContent("")
        alert("Trao đổi đã được thêm", { variant: "success" })
      } catch (error) {
        alert("Thêm trao đổi thất bại, thử lại", { variant: "error" })
      }
    } else {
      // create discuss
      try {
        await updateDiscussAction.run()
        reflectHistoryAction.run()
        setContent("")
        alert("Trao đổi đã được sửa", { variant: "success" })
      } catch (error) {
        alert("Sửa trao đổi thất bại, thử lại", { variant: "error" })
      }
    }
  }

  const handleDeleteDiscuss = async () => {
    try {
      await deleteDiscussAction.run()
      reflectHistoryAction.run()
      setIdToDelete(null)
      alert("Trao đổi đã được xóa", { variant: "success" })
    } catch (error) {
      alert("Xóa trao đổi thất bại, thử lại", { variant: "error" })
    }
  }

  const handleCancelUpdateDiscuss = () => {
    setIdToUpdate(null)
    setContent("")
  }

  useEffect(() => {
    if (reflect?.id) {
      reflectHistoryAction.run()
    }
  }, [reflect?.id, pagination.query.pageIndex, pagination.query.pageSize])

  useEffect(() => {
    form.setAt("IsDiscuss", true)
    form.setAt("Reason", content)
  }, [content])

  return (
    <>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Stack direction="column" spacing={2}>
          <FormControl>
            <TextField
              size="small"
              variant="standard"
              label="Nội dung trao đổi"
              minRows={4}
              multiline
              value={content}
              onChange={(e: any) => setContent(e.target.value)}
              fullWidth
            />
            <FormHelperText error={!content} sx={{ ml: 0 }}>
              Vui lòng nhập nội dung trao đổi
            </FormHelperText>
          </FormControl>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              // disabled={createDiscussAction?.isRunning() || updateDiscussAction.isRunning()}
              disabled={form?.isSubmitting()}
              onClick={handleCreateOrUpdateDiscuss}
            >
              {idToUpdate ? "Cập nhật" : "Thêm mới"}
            </Button>
            {idToUpdate && (
              <Button variant="contained" onClick={handleCancelUpdateDiscuss}>
                Hủy
              </Button>
            )}
          </Stack>
        </Stack>
      </Paper>
      <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
        <THead>
          <THeadRow>
            <THeadCell align="center">STT</THeadCell>
            <THeadCell align="center">Thời gian</THeadCell>
            <THeadCell>Nội dung</THeadCell>
            <THeadCell>Người thực hiện</THeadCell>
            <THeadCell>Cập nhật</THeadCell>
            <THeadCell>Xóa</THeadCell>
          </THeadRow>
        </THead>
        <TBody>
          {!reflectHistoryAction.isRunning() &&
            listReflectHistory?.map((item: ReflectHistoryResponse, i) => (
              <TableRow
                key={i}
                sx={
                  item?.id === idToUpdate
                    ? {
                        backgroundColor: "#359ae1 !important",
                        color: "#fff !important",
                      }
                    : {}
                }
              >
                <TBodyCell align="center">{i + 1}</TBodyCell>
                <TBodyCell align="center">{dateVNFormat(item?.createdDate)}</TBodyCell>
                <TBodyCell>{item?.reason}</TBodyCell>
                <TBodyCell>{item?.userName}</TBodyCell>
                <TBodyCell>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setIdToUpdate(item?.id || null)
                      setContent(item?.reason || "")
                    }}
                  >
                    <Edit />
                  </IconButton>
                </TBodyCell>
                <TBodyCell>
                  <IconButton color="error" onClick={() => setIdToDelete(item?.id)}>
                    <Delete />
                  </IconButton>
                </TBodyCell>
              </TableRow>
            ))}
        </TBody>

        {reflectHistoryAction.isRunning() && <TBodySkeleton count={10} colSpan={6} />}
      </TWrapper>

      {!reflectHistoryAction.isRunning() && !listReflectHistory.length && <TPlaceholder title="Không có dữ liệu" />}

      {listReflectHistory.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageIndex={pagination.query.pageIndex}
            pageCount={reflectHistoryAction.getResult()?.content.pageCount}
            {...pagination.bind()}
          />
        </Box>
      )}
      <Dialog open={!!idToDelete} onClose={() => setIdToDelete(null)} fullWidth maxWidth="sm">
        <DialogTitle>Xác nhận xóa</DialogTitle>
        <DialogContent>
          <DialogContentText>Bạn có chắc muốn xóa trao đổi? .</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIdToDelete(null)}>Hủy</Button>
          <Button
            variant="contained"
            color="error"
            disabled={deleteDiscussAction?.isRunning()}
            onClick={handleDeleteDiscuss}
            autoFocus
          >
            Xóa
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExchangeTab
