import { Back, FirstPageArrow1, LastPageArrow, Next } from "../../../../assets/images"
import clsx from "clsx"

export type PagePaginationProps = {
  pageIndex?: number
  pageCount?: number
  pageSize?: number
  totalItemCount?: number
  onGoFirstPage?: () => void
  onGoToPrevPage?: () => void
  onGoToNextPage?: () => void
  onGoToLastPage?: () => void
  onChangePageSize: (pageSize: number) => void
}

export const PagePagination = (props: PagePaginationProps) => {
  const {
    pageCount,
    pageSize,
    pageIndex,
    onGoFirstPage,
    onGoToPrevPage,
    onGoToNextPage,
    onGoToLastPage,
    onChangePageSize,
  } = props

  return (
    <div className="pagination">
      <p className="page-number">
        Trang {pageIndex || 1}/{pageCount}
      </p>
      <button className={clsx("first-page", !pageIndex && "disabled")} onClick={onGoFirstPage}>
        <img src={FirstPageArrow1} alt="" />
      </button>
      <button className={clsx("pre-page", !pageIndex && "disabled")} onClick={onGoToPrevPage}>
        <img src={Back} alt="" />
      </button>
      <select
        className="custom-select"
        onChange={(event) => onChangePageSize(parseInt(event.target.value))}
        value={pageSize || 10}
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={30}>30</option>
      </select>
      <button
        className={clsx("next-page", (pageIndex || 1).toString() === pageCount?.toString() && "disabled")}
        onClick={onGoToNextPage}
      >
        <img src={Next} alt="" />
      </button>
      <button
        className={clsx("last-page", (pageIndex || 1).toString() === pageCount?.toString() && "disabled")}
        onClick={onGoToLastPage}
      >
        <img src={LastPageArrow} alt="" />
      </button>
    </div>
  )
}
