import { useAction } from "@corets/use-action"
import { ExpandMore } from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { useEffect } from "react"
import { PagePagination } from "../../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../../oneportal/components/PagePagination/usePagePagination"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { TBody } from "../../../../oneportal/components/Table/TBody"
import { TBodySkeleton } from "../../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../../../oneportal/components/Table/TWrapper"
import { dateVNFormat,datetimeVNFormat, stripHtml } from "../../../../oneportal/helpers/utils"
import { getListAccessLog } from "../../../services/api/accessHistory"
import { ReflectResponse } from "../../../units/types/ReflectResponse"

const TypographyTruncate = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  display: "-webkit-box",
  maxWidth: "200px",
  "-webkitLineClamp": "3",
  "-webkitBoxOrient": "vertical",
  overflow: "hidden",
}))

interface ReflectActionLogTabProps {
  reflect: ReflectResponse
}

const ActionLogTab = ({ reflect }: ReflectActionLogTabProps) => {
  const api = useApiClient()

  const accessHistoryLog = useAction(
    async () =>
      await getListAccessLog(api, {
        ReflectionId: reflect?.id,
        PageIndex: pagination.query.pageIndex || 0,
        PageSize: pagination.query.pageSize || 10,
      })
  )
  const listAccessHistoryLog = accessHistoryLog?.getResult()?.content?.reflectionLogHistorys?.items || []

  const pagination = usePagePagination({
    pageCount: accessHistoryLog.getResult()?.content?.reflectionLogHistorys?.pageCount,
    useQueryString: false,
  })

  useEffect(() => {
    if (reflect?.id) {
      accessHistoryLog.run()
    }
    if (!reflect?.id) {
      pagination.bind().onGoFirstPage()
    }
  }, [reflect?.id, pagination.query.pageIndex, pagination.query.pageSize])

  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>Thông tin phản ánh</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={"15%"}>Tiêu đề</TableCell>
                <TableCell>{accessHistoryLog?.getResult()?.content?.title}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell width={"15%"}>Nội dung</TableCell>
                <TableCell>
                  <div
                    className="ck-editor"
                    dangerouslySetInnerHTML={{
                      __html: accessHistoryLog?.getResult()?.content?.content as string,
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
        <THead>
          <THeadRow>
            <THeadCell align="center">STT</THeadCell>
            <THeadCell align="center">Thời gian</THeadCell>
            <THeadCell>Cán bộ </THeadCell>
            <THeadCell>Nội dung (nhật ký xử lý)</THeadCell>
            {/* <THeadCell>Nội dung (nhật ký thao tác) </THeadCell> */}
            {/* <THeadCell>Trạng thái</THeadCell> */}
            <THeadCell>Thao tác</THeadCell>
            {/* <THeadCell>Cập nhật</THeadCell> */}
          </THeadRow>
        </THead>
        <TBody>
          {!accessHistoryLog.isRunning() &&
            listAccessHistoryLog?.length > 0 &&
            listAccessHistoryLog?.map((item, i) => (
              <THeadRow key={i}>
                <THeadCell align="center">{i + 1}</THeadCell>
                <THeadCell align="center">{datetimeVNFormat(item?.time)}</THeadCell>
                <THeadCell>{item?.userName || item?.processingUnitName}</THeadCell>
                <THeadCell>
                  <Tooltip
                    title={<div className="ck-editor" dangerouslySetInnerHTML={{ __html: item.contentOperationLog }} />}
                    placement="top"
                    arrow
                  >
                    <TypographyTruncate>{stripHtml(item?.contentOperationLog)}</TypographyTruncate>
                  </Tooltip>
                </THeadCell>
                {/* <THeadCell>
                  <Tooltip
                    title={<div className="ck-editor" dangerouslySetInnerHTML={{ __html: item.content }} />}
                    placement="top"
                    arrow
                  >
                    <TypographyTruncate>{stripHtml(item?.content)}</TypographyTruncate>
                  </Tooltip>
                </THeadCell> */}
                {/* <THeadCell>{item?.workedStatus}</THeadCell> */}
                <THeadCell>{item?.manipulation}</THeadCell>
                {/* <THeadCell>{item?.update}</THeadCell> */}
              </THeadRow>
            ))}
        </TBody>

        {accessHistoryLog.isRunning() && <TBodySkeleton count={10} colSpan={11} />}
      </TWrapper>

      {!accessHistoryLog.isRunning() && !listAccessHistoryLog.length && <TPlaceholder title="Không có dữ liệu" />}

      {listAccessHistoryLog.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageIndex={pagination.query.pageIndex}
            pageCount={accessHistoryLog.getResult()?.content?.reflectionLogHistorys?.pageCount}
            {...pagination.bind()}
          />
        </Box>
      )}
    </Box>
  )
}

export default ActionLogTab
