import { AxiosResponse } from "axios"
import { ErrorType } from "../units/types/ErrorType"

export const createErrorType = (errorResponse?: AxiosResponse): ErrorType | undefined => {
  if (!errorResponse) return
  return {
    errorMessage: errorResponse.data.errorMessage,
    errorType: errorResponse.data.errorType,
  }
}
