import React, { useState } from "react"
import { Drawer, IconButton, Typography, Button, Box } from "@mui/material"
import { Info } from "@mui/icons-material"

const GuideDrawer = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <Info color="primary" />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 1, width: "90vw", maxWidth: "500px" }}>
          <Typography component="h3">Hướng dẫn sử dụng</Typography>
          <p>Chưa có dữ liệu</p>
          <Button variant="contained" onClick={() => setOpen(false)}>
            Quay lại
          </Button>
        </Box>
      </Drawer>
    </>
  )
}

export default GuideDrawer
