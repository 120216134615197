import { AxiosInstance } from "axios"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ProcessingUnit, ReflectionField } from "../../unit/types/ProcessingUnit"

export type ReportParams = ChunkedParams & {
  RecordTypeId?: string
  ProjectTypeId?: string
  fromDate?: string
  toDate?: string
  GroupProjectId?: string
  fileExtention?: string
  categoryId?: string
}

export const exportFile = async (uri: string, api: AxiosInstance, req: ReportParams): Promise<any> => {
  try {
    const res = await api.get(uri, {
      params: { ...req },
      responseType: "blob",
    })

    return res.data
  } catch (error) {}
}

export const getProcessingUnit = async (
  api: AxiosInstance,
  params?: ReportParams
): Promise<ChunkedPaginationResponse<ProcessingUnit> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<ProcessingUnit>>("api/admin/Statistics/GetUnitStatistics", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getFieldsStatistics = async (
  api: AxiosInstance,
  params?: ReportParams
): Promise<ChunkedPaginationResponse<ReflectionField> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<ReflectionField>>("/api/admin/Statistics/GetFieldsStatistics", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getCategoryStatistics = async (
  api: AxiosInstance,
  params?: ReportParams
): Promise<ChunkedPaginationResponse<ReflectionField> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<ReflectionField>>(
      "/api/admin/Statistics/GetCategoryStatistics",
      {
        params: req,
      }
    )
    return res.data
  } catch (err) {}
}

export const getStatisticsByEvaluate = async (
  api: AxiosInstance,
  params?: ReportParams
): Promise<ChunkedPaginationResponse<ProcessingUnit> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<ProcessingUnit>>(
      "api/admin/Statistics/GetStatisticsByEvaluate",
      {
        params: req,
      }
    )
    return res.data
  } catch (err) {}
}

export const getStatisticsBySource = async (
  api: AxiosInstance,
  params?: ReportParams
): Promise<ChunkedPaginationResponse<ReflectionField> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<ReflectionField>>("/api/admin/Statistics/GetSourceStatistics", {
      params: req,
    })
    return res.data
  } catch (err) {}
}
