import { MainLayout } from "./layouts/MainLayout"
import { Switch, Route } from "react-router-dom"
import { Login } from "./pages/Login/Login"
import { Suspense } from "react"
import { links } from "../../config/links"
import { routes } from "../../config/routes"
import { JWTProvider } from "./contexts/JWTProvider"

export const Oneportal = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path={links.oneportal.login()} component={Login} />
        <JWTProvider>
          <MainLayout>
            {routes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </MainLayout>
        </JWTProvider>
      </Switch>
    </Suspense>
  )
}
