import { AxiosInstance } from "axios"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"
import { Commune } from "../../units/types/commune"
import { CommuneRequest } from "../../units/types/communeRequest"

export type communeParams = ChunkedParams & {
  sortExpression?: string
  name?: string
  provinceId?: string
  districtId?: string
}

export const getAllCommune = async (
  api: AxiosInstance,
  params?: communeParams
): Promise<ChunkedPaginationResponse<Commune> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<Commune>>("/api/admin/Commune/GetAll", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const deleteCommune = async (
  api: AxiosInstance,
  CommuneId: number
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.put<ChunkedResponse<ChunkedStatusResponse>>(`/api/admin/Commune/Delete?id=${CommuneId}`)

    return res.data
  } catch (err) {}
}

export const createCommune = async (
  api: AxiosInstance,
  req: CommuneRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Commune/Create", req)
    return res.data
  } catch (err) {}
}

export const updateCommune = async (
  api: AxiosInstance,
  req: CommuneRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.put<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Commune/Update", req)
    return res.data
  } catch (err) {}
}

export const getCommuneDetail = async (
  api: AxiosInstance,
  CommuneId: string
): Promise<ChunkedResponse<Commune> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Commune>>(`/api/admin/Commune/GetDetail?id=${CommuneId}`)
    return res.data
  } catch (err) {}
}
