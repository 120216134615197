import styled from "styled-components"
import { TBodyCell } from "./TBodyCell"
import { TBodyRow } from "./TBodyRow"
import { TBody } from "./TBody"
import { Skeleton } from "@mui/material"

export type TBodySkeletonProps = {
  colSpan: number
  count: number
}

export const TBodySkeleton = (props: TBodySkeletonProps) => {
  const { colSpan, count } = props

  return (
    <TBody>
      {new Array(count).fill(null).map((_, index) => (
        <TBodyRow key={index}>
          <TBodyCell colSpan={colSpan}>
            <CustomSkeleton width="100%" variant="rect" />
          </TBodyCell>
        </TBodyRow>
      ))}
    </TBody>
  )
}

const CustomSkeleton = styled(Skeleton)`
  height: 25px;
`
