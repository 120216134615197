import { AxiosInstance } from "axios"
import { ChunkedResponse } from "../../units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../units/types/ChunkedStatusResponse"
import { UploadFileStream } from "../../units/types/UploadFileStream"

export const uploadFileStream = async (
  api: AxiosInstance,
  data: FormData
): Promise<ChunkedResponse<UploadFileStream> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<UploadFileStream>>("/api/admin/DocumentUpload/UploadFileStream", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res.data
  } catch (err) {}
}

export const deleteDocument = async (
  api: AxiosInstance,
  fileId: number
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/DocumentUpload/DeleteDocument", {
      params: [{ id: fileId }],
    })
    return res.data
  } catch (err) {}
}
