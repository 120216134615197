import { FormHelperText } from "@mui/material"
import { MuiFormBinder } from "../helpers/createMuiFormBinder"

type optionsType = {
  id: string | number
  name: string
}
export type SelectProps = {
  bind: MuiFormBinder
  field: string
  disabled?: boolean
  options?: optionsType[]
  onClickButton?: () => void
  labelButton?: string
  hiddenButton?: boolean
  labelDefaultOption?: string
}
export const Select = (props: SelectProps) => {
  const {
    bind,
    field,
    disabled = false,
    options = [],
    onClickButton,
    labelButton,
    hiddenButton,
    labelDefaultOption,
  } = props

  return (
    <>
      <div className="d-flex">
        {/* <Autocomplete
          disabled={disabled}
          disablePortal
          id="combo-box-demo"
          options={options}
          getOptionLabel={(option) => option.name}
          sx={{ width: "100%" }}
          // {...bind.select(field)}
          renderInput={(params) => <TextField {...params} {...bind.textField(field)} label={labelDefaultOption}   inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}/>}
        /> */}
        <select disabled={disabled} {...bind.select(field)}>
          {labelDefaultOption && <option value="">--{labelDefaultOption}--</option>}
          {options.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        {onClickButton && !hiddenButton && (
          <button disabled={disabled} type="button" className="btn btn-ct btn-primary-ct" onClick={onClickButton}>
            {labelButton}
          </button>
        )}
      </div>
      <FormHelperText {...bind.helperText(field)} />
    </>
  )
}
