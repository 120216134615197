import { SnackbarProvider } from "notistack"
import { HelmetProvider } from "react-helmet-async"
import { Router, BrowserRouter } from "react-router-dom"
import { DependencyInjectionProvider } from "./modules/oneportal/contexts/DependencyInjectionProvider"
import { Oneportal } from "./modules/oneportal/Oneportal"
import { createBrowserHistory } from "history"

export const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Router history={createBrowserHistory()}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            <DependencyInjectionProvider>
              <Oneportal />
            </DependencyInjectionProvider>
          </SnackbarProvider>
        </Router>
      </BrowserRouter>
    </HelmetProvider>
  )
}
