/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../../config/links"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getReflectionFieldDetail } from "../../../services/api/reflectionProcessing"
import { createCreateOrUpdateReflectionProcessingForm } from "./createCreateOrUpdateReflectionProcessingForm"

export type CreateOrUpdateReflectionProcessingDialogProps = {
  onSuccess: () => void
}

export const CreateOrUpdateReflectionProcessingDialog = (props: CreateOrUpdateReflectionProcessingDialogProps) => {
  const { onSuccess } = props
  const api = useApiClient()
  const alert = useAlert()
  const history = useHistory()

  const { reflectionProcessingUnitId } = useParams<{
    reflectionProcessingUnitId: string
  }>()

  const reflectionProcessingDetail = useAction(
    async () => await getReflectionFieldDetail(api, reflectionProcessingUnitId)
  )

  const [form] = useForm(
    createCreateOrUpdateReflectionProcessingForm(api, reflectionProcessingDetail.getResult()?.content),
    [JSON.stringify(reflectionProcessingDetail.getResult()?.content)]
  )

  const bind = createMuiFormBinder(form)

  console.log(reflectionProcessingDetail.getResult()?.content)

  useEffect(() => {
    reflectionProcessingUnitId && reflectionProcessingDetail.run()
  }, [reflectionProcessingUnitId])

  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Thành công", { variant: "success" })
      handleClose()
      onSuccess()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const handleClose = () => history.push(links.category.reflectionProcessingUnit())

  return (
    <WideDialog
      title={reflectionProcessingUnitId ? "Chỉnh sửa đơn vị xử lý" : "Thêm đơn vị xử lý"}
      onClose={handleClose}
    >
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-12">
            <label>
              Tên đơn vị xử lý <span>*</span>
            </label>
            <input {...bind.textField("name")} />
            <FormHelperText {...bind.helperText("name")} />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>
              Địa chỉ <span>*</span>
            </label>
            <input {...bind.textField("address")} />
            <FormHelperText {...bind.helperText("address")} />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>
              Số điện thoại <span>*</span>
            </label>
            <input {...bind.textField("phoneNumber")} />
            <FormHelperText {...bind.helperText("phoneNumber")} />
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button
              disabled={form.isSubmitting() || !form.isChanged()}
              type="button"
              onClick={handleSubmit}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {reflectionProcessingUnitId ? "Lưu thay đổi" : "Thêm"}
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
