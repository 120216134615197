import { createFormFromSchema } from "@corets/form"
import { object, string } from "@corets/schema"
import { ISODate } from "../../../oneportal/units/types/ISODate"

export type FilterReportFormType = {
  RecordTypeId?: string
  ProjectTypeId?: string
  FromDate?: ISODate
  ToDate?: ISODate
  GroupProjectId?: string
}

export const createFilterReportForm = () => {
  const form = createFormFromSchema<FilterReportFormType>(
    object<FilterReportFormType>({
      RecordTypeId: string().optional(),
      ProjectTypeId: string().optional(),
      FromDate: string().date("Ngày không đúng").optional(),
      ToDate: string().date("Ngày không đúng").optional(),
      GroupProjectId: string().optional(),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
