import { useState, useEffect } from "react"
import { useQuery } from "../../hooks/useQuery"

export type PagePaginationQuery = {
  pageIndex?: number
  pageSize?: number
}

export type UsePagePaginationProps = {
  pageCount?: number
  useQueryString?: boolean
}

export const usePagePagination = (props: UsePagePaginationProps) => {
  const { pageCount, useQueryString = true } = props
  const [queryState, setQueryState] = useState<PagePaginationQuery>({ pageIndex: 1, pageSize: 10 })
  const [queryString, , patchQueryString] = useQuery<PagePaginationQuery>({})

  const query = useQueryString ? queryString : queryState

  const patchQuery = (options: PagePaginationQuery) => {
    if (useQueryString) {
      patchQueryString(options)
    } else {
      setQueryState((state) => ({ ...state, ...options }))
    }
  }

  const handleGoFirstPage = () => {
    patchQuery({ pageIndex: undefined })
  }

  const handleGoToPrevPage = () => {
    query.pageIndex && patchQuery({ pageIndex: query.pageIndex.toString() === "2" ? undefined : query.pageIndex - 1 })
  }

  const handleGoToNextPage = () => {
    if (!query.pageIndex) {
      patchQuery({
        pageIndex: 2,
      })
      return
    }
    query.pageIndex.toString() !== pageCount?.toString() &&
      patchQuery({
        pageIndex: parseInt(query.pageIndex.toString()) + 1,
      })
  }

  const handleGoToLastPage = () => {
    patchQuery({ pageIndex: pageCount })
  }

  const handleChangePageSize = (pageSize: number) => {
    patchQuery({ pageSize: pageSize === 10 ? undefined : pageSize, pageIndex: 0 })
  }

  const bind = () => ({
    onGoFirstPage: handleGoFirstPage,
    onGoToPrevPage: handleGoToPrevPage,
    onGoToNextPage: handleGoToNextPage,
    onGoToLastPage: handleGoToLastPage,
    onChangePageSize: handleChangePageSize,
  })

  return {
    query,
    bind,
  }
}
