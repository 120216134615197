import { useAction } from "@corets/use-action"
import CloseIcon from "@mui/icons-material/Close"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tab } from "@mui/material"
import Slide from "@mui/material/Slide"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import React, { useState, useEffect } from "react"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { getReflectDetail } from "../../services/api/reflectTheScene"
import { ReflectResponse } from "../../units/types/ReflectResponse"
import DiscussTab from "./DiscussTab"
import ReflectInfoTab from "./InfoTab"
import ProcessResultTab from "./ProcessResultTab"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface ProcessDialogProps {
  open: boolean
  handleClose: () => void
  reflectId: string | number
}

export default function ProcessDialog(props: ProcessDialogProps) {
  const { open, handleClose, reflectId } = props
  const [tabActive, setTabActive] = useState("1")
  const theme = useTheme()
  const api = useApiClient()

  const reflectAction = useAction(async () => await getReflectDetail(api, reflectId?.toString()))
  const reflectItem: ReflectResponse = reflectAction?.getResult()?.content

  const tabs = [
    {
      label: "Thông tin phản ánh",
      value: "1",
      content: <ReflectInfoTab reflect={reflectItem} />,
    },
    {
      label: "Trao đổi",
      value: "2",
      content: <DiscussTab reflect={reflectItem} />,
    },
  ]

  if (reflectItem?.reflectionHandledViewDto) {
    tabs.push({
      label: "Kết quả xử lý",
      value: "3",
      content: <ProcessResultTab reflect={reflectItem} />,
    })
  }

  useEffect(() => {
    if (reflectId && open) {
      reflectAction.run()
    }
  }, [reflectId, open])

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, color: "#fff" }}>
        Xem thông tin
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TabContext value={tabActive}>
          <TabList
            onChange={(e, value) => setTabActive(value)}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {tabs?.map((tab) => (
              <Tab label={tab?.label} key={tab?.label} value={tab?.value} />
            ))}
          </TabList>
          {tabs?.map((tab) => (
            <TabPanel key={tab?.label} value={tab?.value}>
              {tab?.content}
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  )
}
