import { THeadCell } from "../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../oneportal/components/Table/THeadRow"

interface RowReflectHeaderProps {
  columnsToShow: any
}

const RowReflectHeader = ({ columnsToShow }: RowReflectHeaderProps) => {
  return (
    <THeadRow>
      {columnsToShow.map((item: any) => (
        <THeadCell align={item?.align || "left"}>{item.name}</THeadCell>
      ))}
    </THeadRow>
  )
}

export default RowReflectHeader
