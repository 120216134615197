/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useEffect, useState } from "react"
import "../../../../assets/style/danhmuc.css"

import { SearchProcessingUnitForm } from "../../components/SearchProcessingUnitForm/SearchProcessingUnitForm"
import { SearchProcessingUniFormType } from "../../components/SearchProcessingUnitForm/createSearchProcessingUnitForm"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { useTableSorter } from "../../../oneportal/components/Table/useTableSorter"
import { PageContentWithSearchBar } from "../../../oneportal/components/PageContentWithSearchbar"
import { TWrapper } from "../../../oneportal/components/Table/TWrapper"
import { TBodySkeleton } from "../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../oneportal/components/Table/THead"
import { THeadRow } from "../../../oneportal/components/Table/THeadRow"
import { THeadCell } from "../../../oneportal/components/Table/THeadCell"
import { TBody } from "../../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../../oneportal/components/Table/TBodyRow"
import { TPlaceholder } from "../../../oneportal/components/Table/TPlaceholder"
import { PagePagination } from "../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../oneportal/components/PagePagination/usePagePagination"
import { useQuery } from "../../../oneportal/hooks/useQuery"
import { exportFile, getCategoryStatistics, ReportParams } from "../../service/api/report"

const Category = () => {
  const api = useApiClient()
  const sorter = useTableSorter()
  const [isExportExcel, setIsExportExcel] = useState(false)
  const [isExportPdf, setIsExportPdf] = useState(false)

  const categoryAction = useAction(async () => await getCategoryStatistics(api, categoryParams))

  const pagination = usePagePagination({
    pageCount: categoryAction.getResult()?.content.pageCount,
  })
  const [query, , pathQuery] = useQuery<ReportParams>({})

  const categoryParams: ReportParams = {
    sorting: sorter.query.sort && `${sorter.query.sort} ${sorter.query.direction}`,
    pageIndex: pagination.query.pageIndex,
    pageSize: pagination.query.pageSize || 30,
    fromDate: query?.fromDate,
    toDate: query?.toDate,
  }

  useEffect(() => {
    categoryAction.run()
  }, [JSON.stringify(categoryParams)])

  const handleSearchCategory = (data: SearchProcessingUniFormType) =>
    pathQuery({ ...data, pageSize: undefined, pageIndex: undefined })

  const handleExport = async (type: "excel" | "pdf") => {
    const isExportPdf = type === "pdf"
    const params = { ...categoryParams, isExportPdf }
    isExportPdf ? setIsExportPdf(true) : setIsExportExcel(true)

    const results = await exportFile("/api/admin/Statistics/ExportCategoryStatistics", api, params)
    if (results) {
      setIsExportPdf(false)
      setIsExportExcel(false)
      const fileType = isExportPdf
        ? "application/pdf"
        : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const url = window.URL.createObjectURL(new Blob([results], { type: fileType }))

      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `Thongketheochuyenmuc.${isExportPdf ? "pdf" : "xlsx"}`)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    }
  }

  return (
    <PageContentWithSearchBar
      pageTitle="Thống kê theo chuyên mục"
      sectionClassName="qlds-page"
      searchbarClassName="list-user-form"
      searchBar={
        (!!categoryAction.getResult()?.content.items.length || !!Object.keys(query).length) && (
          <SearchProcessingUnitForm
            onSubmit={handleSearchCategory}
            defaultValue={query}
            onExport={handleExport}
            isExportExcel={isExportExcel}
            isExportPdf={isExportPdf}
          />
        )
      }
    >
      <>
        <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
          {categoryAction.isRunning() && <TBodySkeleton count={10} colSpan={8} />}
          {!!categoryAction.getResult()?.content.items.length && (
            <>
              <THead>
                <THeadRow>
                  <THeadCell rowSpan={3}>STT</THeadCell>
                  <THeadCell {...sorter.bind("name")} rowSpan={3}>
                    Chuyên mục
                  </THeadCell>
                  <THeadCell {...sorter.bind("total")} rowSpan={3}>
                    Tổng số phản ánh
                  </THeadCell>
                  <THeadCell className="header-cell text-center" colSpan={6}>
                    Đã xử lý
                  </THeadCell>
                  <THeadCell className="header-cell text-center" colSpan={6}>
                    Đang xử lý
                  </THeadCell>
                </THeadRow>

                <THeadRow>
                  <THeadCell className="header-cell text-center" colSpan={2}>
                    Tổng
                  </THeadCell>
                  <THeadCell className="header-cell text-center" colSpan={2}>
                    Trong hạn
                  </THeadCell>
                  <THeadCell className="header-cell text-center" colSpan={2}>
                    Quá hạn
                  </THeadCell>
                  <THeadCell className="header-cell text-center" colSpan={2}>
                    Tổng
                  </THeadCell>
                  <THeadCell className="header-cell text-center" colSpan={2}>
                    Trong hạn
                  </THeadCell>
                  <THeadCell className="header-cell text-center" colSpan={2}>
                    Quá hạn
                  </THeadCell>
                </THeadRow>
                <THeadRow>
                  <THeadCell className="header-cell">Số lượng</THeadCell>
                  <THeadCell className="header-cell">Tỷ lệ</THeadCell>
                  <THeadCell className="header-cell">Số lượng</THeadCell>
                  <THeadCell className="header-cell">Tỷ lệ</THeadCell>
                  <THeadCell className="header-cell">Số lượng</THeadCell>
                  <THeadCell className="header-cell">Tỷ lệ</THeadCell>
                  <THeadCell className="header-cell">Số lượng</THeadCell>
                  <THeadCell className="header-cell">Tỷ lệ</THeadCell>
                  <THeadCell className="header-cell">Số lượng</THeadCell>
                  <THeadCell className="header-cell">Tỷ lệ</THeadCell>
                  <THeadCell className="header-cell">Số lượng</THeadCell>
                  <THeadCell className="header-cell">Tỷ lệ</THeadCell>
                </THeadRow>
              </THead>
              <TBody>
                {!categoryAction.isRunning() &&
                  categoryAction.getResult()?.content.items.map((item, index) => (
                    <TBodyRow key={item.id}>
                      <TBodyCell>
                        {(pagination.query.pageSize || 10) *
                          (pagination.query.pageIndex ? pagination.query.pageIndex - 1 : 0) +
                          index +
                          1}
                      </TBodyCell>
                      <TBodyCell>{item?.name}</TBodyCell>
                      <TBodyCell>{item.total}</TBodyCell>
                      <TBodyCell>{item.processedNumber.total.number}</TBodyCell>
                      <TBodyCell>{item.processedNumber.total.percentString}</TBodyCell>
                      <TBodyCell>{item.processedNumber.inDueDate.number}</TBodyCell>
                      <TBodyCell>{item.processedNumber.inDueDate.percentString}</TBodyCell>
                      <TBodyCell>{item.processedNumber.outOfDate.number}</TBodyCell>
                      <TBodyCell>{item.processedNumber.outOfDate.percentString}</TBodyCell>
                      <TBodyCell>{item.processingNumber.total.number}</TBodyCell>
                      <TBodyCell>{item.processingNumber.total.percentString}</TBodyCell>
                      <TBodyCell>{item.processingNumber.inDueDate.number}</TBodyCell>
                      <TBodyCell>{item.processingNumber.inDueDate.percentString}</TBodyCell>
                      <TBodyCell>{item.processingNumber.outOfDate.number}</TBodyCell>
                      <TBodyCell>{item.processingNumber.outOfDate.percentString}</TBodyCell>
                    </TBodyRow>
                  ))}
              </TBody>
            </>
          )}
        </TWrapper>
        {!categoryAction.isRunning() &&
          !categoryAction.getResult()?.content.items.length &&
          !Object.keys(query).length && <TPlaceholder title="Không có dữ liệu"></TPlaceholder>}
        {!categoryAction.isRunning() &&
          !categoryAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
        {!!categoryAction.getResult()?.content.items.length && (
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageCount={categoryAction.getResult()?.content.pageCount}
            {...pagination.bind()}
            pageIndex={pagination.query.pageIndex}
          />
        )}
      </>
    </PageContentWithSearchBar>
  )
}

export default Category
