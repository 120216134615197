/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "@corets/use-form"
import { useEffect, useState } from "react"
import { useAsync } from "@corets/use-async"
import { createSearchProcessingUniForm, SearchProcessingUniFormType } from "./createSearchProcessingUnitForm"
import { Select } from "../../../oneportal/components/Select"
import { createMuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { getLookupCategory } from "../../../oneportal/services/api/administrative"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DesktopDatePicker from "@mui/lab/DesktopDatePicker"

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import Box from "@mui/material/Box"
import { dateTimeVNFormatType2 } from "../../../oneportal/helpers/utils"
import { excelExport, pdfExport } from "../../../../assets/images"
import { CircularProgress } from "@mui/material"

export type SearchProcessingUniProps = {
  onSubmit: (data: SearchProcessingUniFormType) => void
  defaultValue: SearchProcessingUniFormType
  showCategory?: boolean
  onExport?: (type: "excel" | "pdf") => void
  isExportExcel?: boolean
  isExportPdf?: boolean
}
export const SearchProcessingUnitForm = (props: SearchProcessingUniProps) => {
  const { onSubmit, defaultValue, showCategory, onExport, isExportExcel, isExportPdf } = props
  const api = useApiClient()

  const [form] = useForm(createSearchProcessingUniForm(defaultValue), [defaultValue])
  const categoryOptions = useAsync(() => getLookupCategory(api), [])
  const bind = createMuiFormBinder(form)
  const [filter, setFilter] = useState({ fromDate: "", toDate: "" })

  const handleSubmit = async () => {
    const data = await form.get()

    const date = {
      fromDate: dateTimeVNFormatType2(filter?.fromDate),
      toDate: dateTimeVNFormatType2(filter?.toDate),
    }

    const submitData = { ...data, ...date }

    onSubmit(submitData)
  }

  useEffect(() => {
    if (form.getAt("categoryId")) {
      handleSubmit()
    }
  }, [form.getAt("categoryId")])

  const onChangeFromDate = (value) => {
    setFilter({
      ...filter,
      fromDate: value,
    })
  }
  const onChangeToDate = (value) => {
    setFilter({
      ...filter,
      toDate: value,
    })
  }

  useEffect(() => {
    if (form.getAt("districtId")) {
      handleSubmit()
    }
  }, [form.getAt("districtId")])

  return (
    <form>
      <div className="form-row flex-wrap">
        <div className="form-group mb-0 col-12 col-lg-6">
          <div className="form-row">
            <div className="form-group col-lg-6">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={filter.fromDate}
                  onChange={onChangeFromDate}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <input ref={inputRef} className="form-control" {...inputProps} placeholder="--/--/----" />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                />
              </LocalizationProvider>
            </div>
            <span className="line">-</span>
            <div className="form-group col-lg-6">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={filter.toDate}
                  onChange={onChangeToDate}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <input ref={inputRef} className="form-control" {...inputProps} placeholder="--/--/----" />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        {showCategory && (
          <div className="form-group mb-0 col-lg-2">
            <Select
              options={categoryOptions.getResult()?.content}
              bind={bind}
              field={"categoryId"}
              labelDefaultOption="Chọn chuyên mục"
            />
          </div>
        )}

        <div className="form__save-ct">
          <div className="form__save border-top-0 pt-0 mt-0 justify-content-start">
            <button className="btn btn-ct btn-primary-ct" type="button" onClick={handleSubmit}>
              Tìm kiếm
            </button>
            <button className="btn btn-ct btn-success-ct loading" type="button" onClick={() => onExport?.("excel")}>
              {isExportExcel && <CircularProgress size={20} color="inherit" />}
              <img src={excelExport} alt="" />
              Xuất excel
            </button>
            <button className="btn btn-ct btn-default-ct loading" type="button" onClick={() => onExport?.("pdf")}>
              {isExportPdf && <CircularProgress size={20} color="inherit" />}
              <img src={pdfExport} alt="" />
              Xuất pdf
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
