/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent } from "react"
import { useAsync } from "@corets/use-async"
import { useForm } from "@corets/use-form"
import { CircularProgress, FormGroup, FormControlLabel, Checkbox, IconButton } from "@mui/material"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../config/links"
import { WideDialog } from "../../../oneportal/components/Dialogs/WideDialog"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { getLookupAction, getLookupScreen, getSecurityMatrixDetail } from "../../../category/services/api/permission"
import { createPermissionForm } from "./createPermissionForm"
import styled from "styled-components"
import { DeleteIcon } from "../../../../assets/icons"
import { ScreenType } from "../../../category/units/types/SecurityMatrixRequest"
import { PermissionType } from "../../units/enums/PermissionType"

const screenItem: ScreenType = {
  actions: [
    { actionId: PermissionType.AllAction },
    { actionId: PermissionType.Create },
    { actionId: PermissionType.Delete },
    { actionId: PermissionType.Update },
    { actionId: PermissionType.Watch },
  ],
  screenId: 0,
}

export const PermissionDialog = () => {
  const api = useApiClient()
  const alert = useAlert()
  const history = useHistory()

  const { roleId } = useParams<{
    roleId: string
  }>()

  const securityMatrixDetail = useAsync(async () => await getSecurityMatrixDetail(api, roleId))

  const pageLookup = useAsync(async () => {
    const [actions, screens] = await Promise.all([getLookupAction(api), getLookupScreen(api)])

    return {
      actions,
      screens,
    }
  }, [])

  const [form] = useForm(createPermissionForm(api, roleId, securityMatrixDetail.getResult()?.content), [
    JSON.stringify(securityMatrixDetail.getResult()?.content),
  ])

  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()
    if (res) {
      alert("Thành công", { variant: "success" })
      handleClose()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const handleClose = () => history.push(links.system.listRole())

  const handleAddScreen = () => {
    const screenIds = pageLookup.getResult()?.screens?.content.map((item) => item.id)
    const formScreenIds = form.getAt("screens").map((sc) => sc.screenId)
    const screenId = screenIds?.find((item) => !formScreenIds.includes(item))

    form.setAt("screens", [...form.getAt("screens"), { ...screenItem, screenId }])
  }

  const handleRemoveScreen = (screenIndex: number) => {
    form.setAt(
      "screens",
      form.getAt("screens").filter((_, index) => screenIndex !== index)
    )
  }

  const handleChangeScreen = (event: ChangeEvent<HTMLSelectElement>, screenIndex: number) => {
    const { value } = event.target
    form.setAt(`screens.${screenIndex}.screenId`, parseInt(value))
  }

  const handleActionChecked = (itemScreen: ScreenType, value: number) => {
    return itemScreen.actions.map((item) => item.actionId).includes(value)
  }

  const handleChangeAction = (indexScreen: number, value: number) => {
    if (value === PermissionType.AllAction) {
      form.setAt(`screens.${indexScreen}.actions`, screenItem.actions)
    } else {
      const actionValue = form.getAt(`screens.${indexScreen}.actions`).find((item) => item.actionId === value)

      actionValue
        ? form.getAt(`screens.${indexScreen}.actions`).length > 1 &&
          form.setAt(
            `screens.${indexScreen}.actions`,
            form
              .getAt(`screens.${indexScreen}.actions`)
              .filter(
                (item: { actionId: number }) => item.actionId !== value && item.actionId !== PermissionType.AllAction
              )
          )
        : form.getAt(`screens.${indexScreen}.actions`).length ===
          (pageLookup.getResult()?.actions?.content.length as number) - 2
        ? form.setAt(`screens.${indexScreen}.actions`, screenItem.actions)
        : form.setAt(`screens.${indexScreen}.actions`, [
            ...form
              .getAt(`screens.${indexScreen}.actions`)
              .filter((item) => item.actionId !== PermissionType.AllAction),
            { actionId: value },
          ])
    }
  }

  return (
    <WideDialog title="Phân quyền màn hình" onClose={handleClose} maxWidth="md">
      <form>
        {form.getAt("screens") && form.getAt("screens").length ? (
          form.getAt("screens").map((itemScreen: ScreenType, indexScreen) => (
            <div key={indexScreen} className="form-row form-section">
              <IconButton className="action-form-section" onClick={() => handleRemoveScreen(indexScreen)}>
                <DeleteIcon />
              </IconButton>
              <div className="form-group col-12 col-md-4">
                <label>
                  Màn hình <span>*</span>
                </label>
                <select
                  className="custom-select"
                  value={itemScreen.screenId}
                  onChange={(event) => handleChangeScreen(event, indexScreen)}
                >
                  {pageLookup.getResult()?.screens?.content.map((item, index) => (
                    <option
                      disabled={
                        !!form
                          .getAt("screens")
                          .map((sc) => sc.screenId)
                          .includes(item.id)
                      }
                      key={index}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-12 col-md-8">
                <label>
                  Quyền <span>*</span>
                </label>

                <CustomFormGroup>
                  {pageLookup.getResult()?.actions?.content.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="primary"
                          value={item.id}
                          checked={handleActionChecked(itemScreen, item.id)}
                          onChange={() => handleChangeAction(indexScreen, item.id)}
                        />
                      }
                      label={item.name}
                    />
                  ))}
                </CustomFormGroup>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center p-3">Chưa có màn hình được phân quyền</p>
        )}
        <div className="col-12 d-flex justify-content-center">
          <button
            className="btn btn-ct btn-add btn-info d-flex align-items-center"
            type="button"
            onClick={handleAddScreen}
            disabled={
              form.getAt("screens")?.map((sc) => sc.screenId).length === pageLookup.getResult()?.screens?.content.length
            }
          >
            Thêm màn hình
          </button>
          <button
            disabled={form.isSubmitting() || !form.isChanged()}
            type="button"
            onClick={handleSubmit}
            className="btn btn-ct btn-primary-ct loading"
          >
            {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
            Lưu thay đổi
          </button>
        </div>
      </form>
    </WideDialog>
  )
}

const CustomFormGroup = styled(FormGroup)`
  flex-direction: row !important;

  .MuiTypography-root {
    font-size: 13px;
  }
`
