/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { Route, useHistory, useLocation } from "react-router-dom"
import { EditUserIcon, LockIcon } from "../../../assets/icons"
import { AvatarDefault, WPlus } from "../../../assets/images"
import { links } from "../../../config/links"
import { PageContentWithSearchBar } from "../../oneportal/components/PageContentWithSearchbar"
import { PagePagination } from "../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../oneportal/components/Table/TBodyRow"
import { TBodySkeleton } from "../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../oneportal/components/Table/THead"
import { THeadCell } from "../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../oneportal/components/Table/TWrapper"
import { useTableSorter } from "../../oneportal/components/Table/useTableSorter"
import { readImageFileFromCore,readImageFile } from "../../oneportal/helpers/readImageFile"
import { useAlert } from "../../oneportal/hooks/useAlert"
import { useApiClient } from "../../oneportal/hooks/useApiClient"
import { useDebounce } from "../../oneportal/hooks/useDebounce"
import { useQuery } from "../../oneportal/hooks/useQuery"
import { CreateOrUpdateUserDialog } from "../components/User/CreateOrUpdateUserDialog/CreateOrUpdateUserDialog"
import { ResetPasswordDialog } from "../components/User/ResetPasswordDialog/ResetPasswordDialog"
import { SearchUserFormType } from "../components/User/SearchUserForm/createSearchUserForm"
import { SearchUserForm } from "../components/User/SearchUserForm/SearchUserForm"
import { activeUser, deActiveUser, getAllUserManagement, UserParams } from "../services/api/user"

const ListUser = () => {
  const api = useApiClient()
  const alert = useAlert()
  const userManagement = useAction(() => getAllUserManagement(api, userParams))
  const history = useHistory()
  const [isShowModalCreate, setShowModalCreate] = useState(false)
  const [selectedId, setSelectedId] = useState("")

  const sorter = useTableSorter()
  const pagination = usePagePagination({
    pageCount: userManagement.getResult()?.content.pageCount,
  })
  const [query, , pathQuery] = useQuery<UserParams>({})

  const userParams: UserParams = {
    sortExpression: sorter.query.sort && `${sorter.query.sort} ${sorter.query.direction}`,
    pageIndex: pagination.query.pageIndex,
    pageSize: pagination.query.pageSize,
    email: query.email,
    processingUnitId: query.processingUnitId,
  }

  useEffect(() => {
    userManagement.run()
  }, [JSON.stringify(userParams)])

  const handleClickChangeStatus = useDebounce(async (userId: string, status: boolean) => {
    const res = status ? await deActiveUser(api, userId) : await activeUser(api, userId)
    if (res?.content.status) {
      alert("Thành công", { variant: "success" })
      userManagement.run()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }, 500)

  const handleSearchUser = (data: SearchUserFormType) =>
    pathQuery({ ...data, pageSize: undefined, pageIndex: undefined })

  const handleClickResetPassword = (userId: string) => history.push(links.system.resetPassword(userId))

  return (
    <PageContentWithSearchBar
      pageTitle="Danh sách user"
      sectionClassName="qlds-page"
      searchbarClassName="list-user-form"
      searchBar={
        (!!userManagement.getResult()?.content.items.length || !!Object.keys(query).length) && (
          <SearchUserForm onSubmit={handleSearchUser} defaultValue={query} setShowModalCreate={setShowModalCreate} />
        )
      }
    >
      <>
        <TWrapper
          // widths={["5%", "10%", "15%", "10%", "10%", "10%", "10%", "30%"]}
          tableClassName="table__user"
          tableContainerClassName="wrap-table__user"
        >
          {userManagement.isRunning() && <TBodySkeleton count={10} colSpan={8} />}
          {!!userManagement.getResult()?.content.items.length && (
            <>
              <THead>
                <THeadRow>
                  <THeadCell>STT</THeadCell>
                  <THeadCell>Avartar</THeadCell>
                  <THeadCell {...sorter.bind("fullName")}>Tên</THeadCell>
                  <THeadCell {...sorter.bind("email")}>Email</THeadCell>
                  <THeadCell {...sorter.bind("reflectionUnitName")}>Đơn vị</THeadCell>
                  <THeadCell>Hành động</THeadCell>
                </THeadRow>
              </THead>
              <TBody>
                {!userManagement.isRunning() &&
                  userManagement.getResult()?.content.items.map((item, index) => (
                    <TBodyRow key={item.id}>
                      <TBodyCell>
                        {(pagination.query.pageSize || 10) *
                          (pagination.query.pageIndex ? pagination.query.pageIndex - 1 : 0) +
                          index +
                          1}
                      </TBodyCell>
                      <TBodyCell>
                        <div className="table__avatar">
                          <img src={readImageFileFromCore(item.avatar, AvatarDefault)} alt="" />
                        </div>
                      </TBodyCell>
                      <TBodyCell>{item.fullName}</TBodyCell>
                      <TBodyCell>{item.email}</TBodyCell>
                      <TBodyCell>{item.reflectionUnitName}</TBodyCell>
                      <TBodyCell>
                        <div className="action__user d-flex align-items-center">
                          <button
                            className={clsx("btn btn-action mr-3", item.status && "is__active")}
                            onClick={() => handleClickChangeStatus(item.id, item.status)}
                          >
                            {item.status ? "Active" : "Deactive"}
                          </button>
                          <button
                            type="button"
                            className="border-0 loading mr-3"
                            onClick={() => handleClickResetPassword(item.id)}
                          >
                            <LockIcon />
                            Reset password
                          </button>
                          <button
                            type="button"
                            className="border-0 loading"
                            onClick={() => {
                              setSelectedId(item.id)
                              setShowModalCreate(true)
                            }}
                          >
                            <EditUserIcon />
                            Edit
                          </button>
                        </div>
                      </TBodyCell>
                    </TBodyRow>
                  ))}
              </TBody>
            </>
          )}
        </TWrapper>
        {!userManagement.isRunning() &&
          !userManagement.getResult()?.content.items.length &&
          !Object.keys(query).length && (
            <TPlaceholder title="Không có dữ liệu">
              <button
                className="btn  btn-ct  btn-add btn-danger-ct  d-flex align-items-center"
                type="button"
                onClick={() => history.push(links.system.createUser())}
              >
                Thêm user
                <img src={WPlus} alt="" />
              </button>
            </TPlaceholder>
          )}
        {!userManagement.isRunning() &&
          !userManagement.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
        {!!userManagement.getResult()?.content.items.length && (
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageCount={userManagement.getResult()?.content.pageCount}
            {...pagination.bind()}
            pageIndex={pagination.query.pageIndex}
          />
        )}
      </>
      <Route path={links.system.resetPassword()}>
        <ResetPasswordDialog />
      </Route>
      {isShowModalCreate && (
        <CreateOrUpdateUserDialog
          onSuccess={() => userManagement.run()}
          setShowModalCreate={setShowModalCreate}
          selectedId={selectedId}
        />
      )}
    </PageContentWithSearchBar>
  )
}

export default ListUser
