/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, FormHelperText } from "@mui/material"
import { PrevIcon } from "../../../../assets/icons"
import { InputDatePicker } from "../../../oneportal/components/DatePicker"
import { Select } from "../../../oneportal/components/Select"
import { MuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { projectInfoOptions, resultOptions } from "../../units/constanst"
import { ProjectInfo } from "../../units/enums/ProjectInfo"
import { AttachFileTypeByLookupRecordsType } from "../../units/types/AttachFileTypeByLookupRecordsType"
import { useEffect, useState } from "react"
import { ObservableForm } from "@corets/form"
import { links } from "../../../../config/links"
import { useHistory } from "react-router-dom"
import { useAsync } from "@corets/use-async"
import {
  getLookupProvince,
  getLookupDistrictMulti,
  getLookupCommuneMulti,
} from "../../../oneportal/services/api/administrative"
import { getLookupDocumentAnsType } from "../../services/api/documentAnsType"
import { getLookupPC07 } from "../../services/api/pc07"
import { getLookupProjectGroup } from "../../services/api/projectGroup"
import { getLookupProjectType } from "../../services/api/projectType"
import { getLookupReceiveType } from "../../services/api/receiveType"
import { getLookupRecordsType } from "../../services/api/recordTypes"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { Route } from "react-router-dom"
import { getLookupInvestor } from "../../../category/services/api/investor"
import { Investor } from "../../../category/units/types/Investor"
import { useAction } from "@corets/use-action"
import { getLookupAttachFileType } from "../../services/api/attachFileType"
import { getLookupRecordsStatus } from "../../services/api/recordsStatus"
import { AttachFile } from "./AttachFile"
import { Records } from "../../units/types/Records"
import { InvestorSelectAsync } from "../../../oneportal/components/InvestorSelectAsync"
import { SelectAsyncValueType } from "../../../oneportal/units/types/SelectAsyncValueType"
import { MUIAutocomplete } from "../../../oneportal/components/Autocomplete/MUIAutocomplete"
import { MUIAutocompleteMulti } from "../../../oneportal/components/Autocomplete/MUIAutocompleteMulti"
import { isEmpty } from "lodash-es"
import { Positions } from "../../units/types/Positions"

export type RecordFormProps = {
  bind: MuiFormBinder
  onChangeProjectInfo?: () => void
  proInfo?: ProjectInfo
  isDisableProjectInfoFields?: boolean
  form: ObservableForm
  onSubmit: () => void
  districtId?: number
  communeIds?: number[]
  record?: Records
  attachFile: AttachFileType | undefined
  setAttachFile: (value: AttachFileType | undefined) => void
  positions?: Positions[]
}

export type AttachFileType = ChunkedArrayResponse<AttachFileTypeByLookupRecordsType>

export const RecordForm = (props: RecordFormProps) => {
  const history = useHistory()
  const api = useApiClient()

  const {
    bind,
    proInfo = ProjectInfo.New,
    isDisableProjectInfoFields = false,
    form,
    onChangeProjectInfo,
    onSubmit,
    record,
    attachFile,
    setAttachFile,
    communeIds,
    positions,
  } = props

  const [showRequiredTextForAttachFile, setShowRequiredTextForAttachFile] = useState(false)
  const [provinceValues, setProvinceValues] = useState<Array<string>>([])
  const [districtValues, setDistrictValues] = useState<Array<string>>([])
  const [communeValues, setCommuneValues] = useState<Array<string>>([])
  const [isSubmitted, setIsSubmitted] = useState(false)

  const districtMulti = useAction(async (provinceIds: number[]) => await getLookupDistrictMulti(api, provinceIds))
  const communeMulti = useAction(async (districtIds: number[]) => await getLookupCommuneMulti(api, districtIds))
  const investor = useAsync(async () => getLookupInvestor(api), [])
  const recordsStatus = useAsync(async () => getLookupRecordsStatus(api), [])

  const pageOptions = useAsync(async () => {
    const [recordTypes, projectGroup, projectType, province, pc07, receiveType, documentAnsType] = await Promise.all([
      getLookupRecordsType(api),
      getLookupProjectGroup(api),
      getLookupProjectType(api),
      getLookupProvince(api),
      getLookupPC07(api),
      getLookupReceiveType(api),
      getLookupDocumentAnsType(api),
    ])

    return {
      recordTypes,
      projectGroup,
      projectType,
      province,
      pc07,
      receiveType,
      documentAnsType,
    }
  }, [])

  useEffect(() => {
    let listProvince: string[]
    let listDistrict: string[]
    listProvince = []
    listDistrict = []
    positions?.map((item) => {
      if (!listProvince.includes(item?.province.id.toString())) {
        listProvince?.push(item?.province.id.toString())
      }
      if (!listDistrict.includes(item?.district.id.toString())) {
        listDistrict?.push(item?.district.id.toString())
      }
    })
    setProvinceValues(listProvince)
    setDistrictValues(listDistrict)
  }, [positions])

  useEffect(() => {
    setProvinceValues(["28"])
  }, [])

  useEffect(() => {
    if (communeIds) {
      setCommuneValues(communeIds.map((item) => item.toString()))
    }
  }, [communeIds])

  useEffect(() => {
    districtMulti.run(provinceValues.map((i) => Number(i)))
  }, [provinceValues])

  useEffect(() => {
    communeMulti.run(districtValues.map((i) => Number(i)))
  }, [districtValues])

  useEffect(() => {
    if (form.getAt("recordsTypeId")) {
      handleGetAttachFile(form.getAt("recordsTypeId"))
      form.setAt(`recordFile`, undefined)
      setAttachFile(undefined)
      setShowRequiredTextForAttachFile(false)
    }
  }, [form.getAt("recordsTypeId")])

  useEffect(() => {
    if (form.getAt("pC07ReceiveId") && !form.getAt("pC07ManageId")) {
      form.setAt("pC07ManageId", form.getAt("pC07ReceiveId"))
    }
  }, [form.getAt("pC07ReceiveId")])

  const handleGetAttachFile = async (recordsTypeId: number) => {
    const res = await getLookupAttachFileType(api, recordsTypeId)
    if (res) {
      if (record && record.recordsFiles.length && record.recordsTypeId.toString() === form.getAt("recordsTypeId")) {
        const newAtt = res.content.map((resItem) => {
          const file = record.recordsFiles.find(
            (recordsFileItem) => resItem.id === recordsFileItem.fileTypeAttachmentId
          )
          if (file) {
            return {
              ...resItem,
              fileId: file.documentUploadId,
              fileName: file.fileTypeAttachmentName,
              path: file.path,
            }
          }
          return { ...resItem }
        })
        form.setAt(
          "recordFile",
          newAtt
            .filter((item) => item.fileId)
            .map((result) => {
              return { fileId: result.fileId, fileTypeAttachmentId: result.id }
            })
        )
        setAttachFile({ content: newAtt, err: res.err })
      } else {
        setAttachFile(res)
      }
    }
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    if (!attachFile?.content.length) {
      onSubmit()
    } else {
      const checkRequiredFile = attachFile?.content.every((value) => {
        if (value.isRequired) {
          return !!value.fileId
        }
        return true
      })

      if (checkRequiredFile) {
        onSubmit()
        setShowRequiredTextForAttachFile(false)
      } else {
        setShowRequiredTextForAttachFile(true)
      }
    }
  }

  const handleChangeProjecrInfo = () => {
    onChangeProjectInfo!()
    setAttachFile(undefined)
  }

  const getValueOfInvestor = (): SelectAsyncValueType | undefined => {
    const find = investor
      ?.getResult()
      ?.content.find((item) => item.id.toString() === form.getAt("project.investorId").toString())
    if (find) {
      return {
        label: find.name,
        value: find.id,
      }
    } else {
      return
    }
  }

  const handleChangeProvince = (event, value) => {
    setProvinceValues(value)
  }

  const handleChangeDistrict = (event, value) => {
    setDistrictValues(value)
  }

  const handleChangeCommune = (event, value) => {
    setCommuneValues(value)
    form.setAt("project.communeIds", value)
  }

  return (
    <>
      <Route path={links.records.addinvestor()}></Route>

      <form>
        <section className="info__project">
          <h4>Thông tin công trình, dự án</h4>
          <div className="form-row">
            <div className="form-group col-12">
              <label>
                Tên dự án, công trình, phương tiện <span>*</span>
              </label>
              <div className="form-row">
                {onChangeProjectInfo && (
                  <div className="col-2 pr-0">
                    <select
                      className="custom-select border-right-0 select-ct-radius"
                      onChange={handleChangeProjecrInfo}
                      value={proInfo}
                    >
                      {projectInfoOptions.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div
                  className={`${
                    onChangeProjectInfo ? (isDisableProjectInfoFields ? "col-9 pl-0" : "col-10 pl-0") : "col-12"
                  }`}
                >
                  <input
                    placeholder="Tên dự án, công trình, phương tiện"
                    disabled={isDisableProjectInfoFields}
                    {...bind.textField("project.name", "input-ct-border")}
                  />
                  <FormHelperText {...bind.helperText("project.name")} />
                </div>

                {isDisableProjectInfoFields && (
                  <div className="col-1 pl-0">
                    <button
                      type="button"
                      className="btn btn-custom-ct btn-primary-ct"
                      onClick={() => history.push(links.records.recordsList())}
                    >
                      Chọn
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group col-10 col-lg-6">
              <label>
                Chủ đầu tư <span>*</span>
              </label>
              <InvestorSelectAsync
                form={form}
                field="project.investorId"
                bind={bind}
                onClickButton={() => history.push(links.records.addinvestor())}
                hiddenButton={onChangeProjectInfo ? false : true}
                labelButton="Thêm mới"
                disabled={isDisableProjectInfoFields}
                data={getValueOfInvestor()}
              />
            </div>
            <div className="form-group col-12 col-lg-6">
              <label>
                Địa điểm xây dựng <span>*</span>
              </label>
              <MUIAutocompleteMulti
                {...bind.autocomplete("province")}
                options={pageOptions.getResult()?.province?.content.map((item) => item.id.toString()) || []}
                placeholder="Chọn tỉnh thành"
                noOptionsText="Không có lựa chọn"
                isError={isSubmitted && isEmpty(provinceValues) ? ["Bắt buộc"] : undefined}
                disabled={isDisableProjectInfoFields}
                getOptionLabel={(option) =>
                  pageOptions.getResult()?.province?.content.find((item) => item.id.toString() === option)?.name || ""
                }
                onChange={handleChangeProvince}
                value={provinceValues}
              />
            </div>
            <div className="form-group col-12 col-lg-4">
              <label>Quận/Huyện</label>
              <MUIAutocompleteMulti
                {...bind.autocomplete("district")}
                options={districtMulti?.getResult()?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  districtMulti?.getResult()?.content.find((item) => item.id.toString() === option)?.name || ""
                }
                placeholder="Chọn Quận/Huyện"
                noOptionsText="Không có lựa chọn"
                isError={isSubmitted && isEmpty(districtValues) ? ["Bắt buộc"] : undefined}
                disabled={isDisableProjectInfoFields}
                onChange={handleChangeDistrict}
                value={districtValues}
              />
            </div>
            <div className="form-group col-12 col-lg-4">
              <label>Phường/Xã</label>
              <MUIAutocompleteMulti
                {...bind.autocomplete("project.communeIds")}
                options={communeMulti?.getResult()?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  communeMulti?.getResult()?.content.find((item) => item.id.toString() === option)?.name || ""
                }
                placeholder="Chọn Phường/Xã"
                noOptionsText="Không có lựa chọn"
                isError={isSubmitted && isEmpty(communeValues) ? ["Bắt buộc"] : undefined}
                disabled={isDisableProjectInfoFields}
                onChange={handleChangeCommune}
                value={communeValues}
              />
            </div>
            <div className="form-group col-12 col-lg-4">
              <label>Địa chỉ</label>
              <input
                {...bind.textField("project.address")}
                disabled={isDisableProjectInfoFields}
                placeholder="Nhập địa chỉ"
              />
            </div>
            <div className="form-group col-12 col-lg-4">
              <label>
                Nhóm dự án<span>*</span>
              </label>
              <MUIAutocomplete
                {...bind.autocomplete("project.projectGroupId")}
                options={pageOptions.getResult()?.projectGroup?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  pageOptions.getResult()?.projectGroup?.content.find((item) => item.id.toString() === option)?.name ||
                  ""
                }
                placeholder="Chọn nhóm dự án"
                noOptionsText="Không có lựa chọn"
                isError={form.getErrorsAt("project.projectGroupId")}
                disabled={isDisableProjectInfoFields}
              />
            </div>
            <div className="form-group col-12 col-lg-4">
              <label>
                Loại công trình<span>*</span>
              </label>
              <MUIAutocomplete
                {...bind.autocomplete("project.projectTypeId")}
                options={pageOptions.getResult()?.projectType?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  pageOptions.getResult()?.projectType?.content.find((item) => item.id.toString() === option)?.name ||
                  ""
                }
                placeholder="Chọn loại công trình"
                noOptionsText="Không có lựa chọn"
                isError={form.getErrorsAt("project.projectTypeId")}
                disabled={isDisableProjectInfoFields}
              />
            </div>
            <div className="form-group col-12 col-lg-4">
              <label>
                Loại hồ sơ<span>*</span>
              </label>
              <MUIAutocomplete
                {...bind.autocomplete("recordsTypeId")}
                options={pageOptions.getResult()?.recordTypes?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  pageOptions.getResult()?.recordTypes?.content.find((item) => item.id.toString() === option)?.name ||
                  ""
                }
                placeholder="Chọn loại hồ so"
                noOptionsText="Không có lựa chọn"
                isError={form.getErrorsAt("recordsTypeId")}
              />
            </div>
          </div>
        </section>
        <section className="info__project">
          <h4>Thông tin tiếp nhận hồ sơ</h4>
          <div className="form-row">
            <div className="form-group col-lg-3">
              <label>
                Số phiếu tiếp nhận<span>*</span>
              </label>
              <input {...bind.textField("recordNum")} />
              <FormHelperText {...bind.helperText("recordNum")} />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Hình thức tiếp nhận<span>*</span>
              </label>
              <MUIAutocomplete
                {...bind.autocomplete("receiveTypeId")}
                options={pageOptions.getResult()?.receiveType?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  pageOptions.getResult()?.receiveType?.content.find((item) => item.id.toString() === option)?.name ||
                  ""
                }
                placeholder="Chọn hình thức tiếp nhận"
                noOptionsText="Không có lựa chọn"
                isError={form.getErrorsAt("receiveTypeId")}
              />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Thời gian tiếp nhận<span>*</span>
              </label>
              <InputDatePicker {...bind.datePicker("receiveDate")} bind={bind} field="receiveDate" />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Cán bộ tiếp nhận<span>*</span>
              </label>
              <input {...bind.textField("receiveUserName")} placeholder="Nhập họ tên" />
              <FormHelperText {...bind.helperText("receiveUserName")} />
            </div>
            <div className="form-group col-lg-3">
              <label>Số văn bản trả lời</label>
              <input {...bind.textField("returnDocNumb")} />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Ngày hẹn trả kết quả<span>*</span>
              </label>
              <InputDatePicker {...bind.datePicker("datePredictReturn")} bind={bind} field="datePredictReturn" />
            </div>
            <div className="form-group col-lg-3">
              <label>Ngày trả kết quả</label>
              <InputDatePicker {...bind.datePicker("dateRealReturn")} bind={bind} field="dateRealReturn" />
              {form.getAt("recordsStatusId") === "4" && !form.getAt("dateRealReturn") && (
                <p className="MuiFormHelperText-root Mui-error css-1d1r5q-MuiFormHelperText-root">
                  Phải nhập ngày trả kết quả
                </p>
              )}
            </div>
            <div className="form-group col-lg-3">
              <label>Kết quả</label>
              <Select
                options={resultOptions.map((item) => {
                  return {
                    id: item.value,
                    name: item.label,
                  }
                })}
                bind={bind}
                field={"result"}
              />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Đơn vị xử lý hồ sơ <span>*</span>
              </label>
              <MUIAutocomplete
                {...bind.autocomplete("pC07ReceiveId")}
                options={pageOptions.getResult()?.pc07?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  pageOptions.getResult()?.pc07?.content.find((item) => item.id.toString() === option)?.name || ""
                }
                placeholder="Chọn Đơn vị xử lý hồ sơ"
                noOptionsText="Không có lựa chọn"
                isError={form.getErrorsAt("pC07ReceiveId")}
              />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Đơn vị quản lý hồ sơ <span>*</span>
              </label>
              <MUIAutocomplete
                {...bind.autocomplete("pC07ManageId")}
                options={pageOptions.getResult()?.pc07?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  pageOptions.getResult()?.pc07?.content.find((item) => item.id.toString() === option)?.name || ""
                }
                placeholder="Chọn đơn vị quản lý hồ sơ"
                noOptionsText="Không có lựa chọn"
                isError={form.getErrorsAt("pC07ManageId")}
              />
            </div>
            <div className="form-group col-lg-3">
              <label>Thời gian dự kiến tổ chức kiểm tra</label>
              <InputDatePicker {...bind.datePicker("datePredictCheck")} bind={bind} field="datePredictCheck" />
            </div>
            <div className="form-group col-lg-3">
              <label>Trạng thái hồ sơ</label>
              <MUIAutocomplete
                {...bind.autocomplete("recordsStatusId")}
                options={recordsStatus.getResult()?.content.map((item) => item.id.toString()) || []}
                getOptionLabel={(option) =>
                  recordsStatus.getResult()?.content.find((item) => item.id.toString() === option)?.name || ""
                }
                noOptionsText="Không có lựa chọn"
                isError={form.getErrorsAt("recordsStatusId")}
                disabled={isDisableProjectInfoFields}
              />
            </div>
            {form.getAt("recordsStatusId") === "4" && (
              <div className="col-12">
                <label>Lý do hủy</label>
                <input {...bind.textField("cancelReason")} />
              </div>
            )}
          </div>
        </section>
        <AttachFile
          form={form}
          showDownloadButton={!onChangeProjectInfo}
          attachFile={attachFile}
          setAttachFile={setAttachFile}
          showRequiredTextForAttachFile={showRequiredTextForAttachFile}
        />
        <div className="form__save pb-3 border-0">
          <button className="btn btn-ct btn-back-ct btn-default-ct" type="button" onClick={() => history.goBack()}>
            <PrevIcon />
            Quay lại
          </button>
          <button
            className="btn btn-ct btn-primary-ct loading"
            type="button"
            onClick={handleSubmit}
            disabled={form.isSubmitting() || !form.isChanged()}
          >
            {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
            {!!onChangeProjectInfo ? "Thêm hồ sơ" : "Chỉnh sửa hồ sơ"}
          </button>
        </div>
      </form>
    </>
  )
}
