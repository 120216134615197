import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { updateUserAccount } from "../../services/api/account"
import { CurrentUser } from "../../units/types/CurrentUser"
import { ISODate } from "../../units/types/ISODate"

export type UpdateProfileFormType = {
  email: string
  dateOfBirth: ISODate
  fullName: string
  phoneNumber: string
  roleName: string
  sex: boolean
  processingUnitId: number
  file?: File
}

export const createUpdateProfileForm = (
  api: AxiosInstance,
  data?: CurrentUser
): ObservableForm<UpdateProfileFormType, boolean> => {
  const form = createFormFromSchema<UpdateProfileFormType, boolean>(
    object<UpdateProfileFormType>({
      email: string()
        .toDefault(data?.email || "")
        .required(true, "Bắt buộc")
        .email(),
      dateOfBirth: string().toDefault(data?.dateOfBirth).optional(),
      fullName: string()
        .toDefault(data?.fullName || "")
        .required(true, "Bắt buộc"),
      phoneNumber: string()
        .toDefault(data?.phoneNumber || "")
        .required(true, "Bắt buộc"),
      roleName: string().toDefault(data?.roleName || ""),
      sex: string().toDefault(data?.sex ? "true" : "false"),
      processingUnitId: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.processingUnitId?.toString() || ""),
      file: object().optional().allowUnknownKeys(),
    })
  ).handler(async (form) => {
    const datas = await form.get()
    const formData = new FormData()

    Object.keys(datas).forEach((item) => datas[item] && formData.append(item, datas[item]))
    const res = await updateUserAccount(api, formData)

    if (res?.content.status) return true
    return false
  })

  return form
}
