import { AxiosInstance } from "axios"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { Project } from "../../units/types/Project"

export type ProjectParams = ChunkedParams & {
  ProvinceId?: string
  DistrictId?: string
  fileExtention: string
  ProjectStatusId?: string
  ProjectTypeId?: string
}

export const getAllProject = async (api: AxiosInstance, req: any): Promise<any> => {
  try {
    const res = await api.get(`api/admin/Project/GetAll`, {
      params: req,
    })
    return res.data
  } catch (error) {}
}

export const getProjectDetail = async (
  api: AxiosInstance,
  projectId: number
): Promise<ChunkedResponse<Project> | undefined> => {
  try {
    const res = await api.get(`/api/admin/Project/GetDetail/${projectId}`)
    return res.data
  } catch (error) {}
}

export const updateProjectStatus = async (api: AxiosInstance, req: any): Promise<any> => {
  try {
    const res = await api.put(`api/admin/Project/UpdateStatus`, req)
    return res.data
  } catch (error) {}
}

export const exportFile = async (api: AxiosInstance, req: ProjectParams): Promise<any> => {
  try {
    const res = await api.post("/api/admin/Project/ExportFile", null, {
      params: { ...req },
      responseType: "blob",
    })

    return res.data
  } catch (error) {}
}
