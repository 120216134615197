import { AxiosInstance } from "axios"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { AttachFileTypeByLookupRecordsType } from "../../units/types/AttachFileTypeByLookupRecordsType"

export const getLookupAttachFileType = async (
  api: AxiosInstance,
  recordsTypeId: number
): Promise<ChunkedArrayResponse<AttachFileTypeByLookupRecordsType> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<AttachFileTypeByLookupRecordsType>>(
      "/api/admin/AttachFileType/GetLookupRecordsType",
      { params: { recordsTypeId } }
    )
    return res.data
  } catch (err) {}
}
