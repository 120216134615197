import { ChunkedParams } from "./../../../oneportal/units/types/ChunkedParams"
import { AxiosInstance } from "axios"
import { GetlistRecordRequest } from "../../units/types/GetlListRequest"

export type RecordParams = ChunkedParams & {
  ProvinceId?: string
  fileExtention: string
  ProjectId?: string
  FromDate?: string
  ToDate?: string
  RecordsTypeId?: string
  RecordsStatusId?: string
}

export const getListRecord = async (api: AxiosInstance, req: GetlistRecordRequest): Promise<any> => {
  try {
    const res = await api.get("/api/admin/Records/GetAll", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const deleteRecord = async (api: AxiosInstance, id: number): Promise<any> => {
  try {
    const res = await api.delete(`api/admin/Records/Delete/${id}`)
    return res.data
  } catch (err) {}
}

export const getLookupRecordStatus = async (api: AxiosInstance): Promise<any> => {
  try {
    const res = await api.get(`api/admin/RecordsStatus/GetLookup`)
    return res.data
  } catch (error) {}
}

export const updateRecordStatus = async (api: AxiosInstance, req: any): Promise<any> => {
  try {
    const res = await api.post(`api/admin/Records/UpdateStatus`, req)
    return res.data
  } catch (error) {}
}

export const exportFile = async (api: AxiosInstance, req: RecordParams): Promise<any> => {
  try {
    const res = await api.post("/api/admin/Records/ExportFile", null, {
      params: { ...req },
      responseType: "blob",
    })

    return res.data
  } catch (error) {}
}
