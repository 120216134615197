import { useAction } from "@corets/use-action"
import { Box, TableRow } from "@mui/material"
import { useEffect } from "react"
import { PagePagination } from "../../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../../../oneportal/components/Table/TBodyCell"
import { TBodySkeleton } from "../../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../../../oneportal/components/Table/TWrapper"
import { dateVNFormat } from "../../../../oneportal/helpers/utils"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getListReflectHistory } from "../../../services/api/reflectTheSceneHistory"
import { ReflectHistoryResponse } from "../../../units/types/ReflectHistoryResponse"
import { ReflectResponse } from "../../../units/types/ReflectResponse"

interface ExchangeTabProps {
  reflect: ReflectResponse
  onSuccess?: () => void
}

const DiscussTab = ({ reflect }: ExchangeTabProps) => {
  const api = useApiClient()

  const reflectHistoryAction = useAction(
    async () =>
      await getListReflectHistory(api, {
        ReflectTheSceneId: reflect?.id,
        IsDelete: false,
        IsDiscuss: true,
        PageIndex: pagination.query.pageIndex || 0,
        PageSize: pagination.query.pageSize || 10,
      })
  )
  const listReflectHistory = reflectHistoryAction?.getResult()?.content?.items || []

  const pagination = usePagePagination({
    pageCount: reflectHistoryAction.getResult()?.content.pageCount,
    useQueryString: false,
  })

  useEffect(() => {
    if (reflect?.id) {
      reflectHistoryAction.run()
    }
  }, [reflect?.id, pagination.query.pageIndex, pagination.query.pageSize])

  return (
    <>
      <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
        <THead>
          <THeadRow>
            <THeadCell align="center">STT</THeadCell>
            <THeadCell align="center">Thời gian</THeadCell>
            <THeadCell>Nội dung</THeadCell>
            <THeadCell>Người thực hiện</THeadCell>
          </THeadRow>
        </THead>
        <TBody>
          {!reflectHistoryAction.isRunning() &&
            listReflectHistory?.map((item: ReflectHistoryResponse, i) => (
              <TableRow key={i}>
                <TBodyCell align="center">{i + 1}</TBodyCell>
                <TBodyCell align="center">{dateVNFormat(item?.createdDate)}</TBodyCell>
                <TBodyCell>{item?.reason}</TBodyCell>
                <TBodyCell>{item?.userName}</TBodyCell>
              </TableRow>
            ))}
        </TBody>

        {reflectHistoryAction.isRunning() && <TBodySkeleton count={10} colSpan={6} />}
      </TWrapper>

      {!reflectHistoryAction.isRunning() && !listReflectHistory.length && <TPlaceholder title="Không có dữ liệu" />}

      {listReflectHistory.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageIndex={pagination.query.pageIndex}
            pageCount={reflectHistoryAction.getResult()?.content.pageCount}
            {...pagination.bind()}
          />
        </Box>
      )}
    </>
  )
}

export default DiscussTab
