import { Dialog, DialogContent } from "@mui/material"
import { ReactNode } from "react"
import { CloseModalIcon } from "../../../../assets/icons"

export type WideDialogProps = {
  onClose: () => void
  onExited?: () => void
  children?: ReactNode | ReactNode[]
  closeable?: boolean
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
  title?: string
}

export const WideDialog = (props: WideDialogProps) => {
  const { children, onClose, onExited, title, closeable = false, maxWidth = "sm" } = props

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={maxWidth}
      disableEscapeKeyDown
      TransitionProps={{
        onExited: onExited,
      }}
      onClose={(_, reason) => {
        if (reason !== "backdropClick" || closeable) {
          onClose()
        }
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
          <CloseModalIcon />
        </button>
      </div>

      <DialogContent>
        <div className="modal-body pb-4">{children}</div>
      </DialogContent>
    </Dialog>
  )
}
