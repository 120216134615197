export const DATE_EXPIRES = new Date().setTime(new Date().getTime() + 720 * 60 * 60 * 1000)
export const REFRESH_TOKEN_INTERVAL = 500
export const TIME_TO_IDLE = 1000
export const REGEX_PASSWORD = /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
export const genderOptions = [
  {
    value: "false",
    label: "Nữ",
  },
  {
    value: "true",
    label: "Nam",
  },
]

export const roleOptions = [
  {
    value: "ADMIN",
    label: "Admin",
  },
]
