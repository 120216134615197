import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"

export type SearchUserFormType = {
  email?: string
  processingUnitId?: string
}

export const createSearchUserForm = (
  data?: SearchUserFormType
): ObservableForm<SearchUserFormType, SearchUserFormType> => {
  const form = createFormFromSchema<SearchUserFormType>(
    object<SearchUserFormType>({
      email: string()
        .optional()
        .toDefault(data?.email || ""),
      processingUnitId: string()
        .optional()
        .toDefault(data?.processingUnitId || ""),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
