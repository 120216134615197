import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { REVIEWER_OPTIONS } from "../../units/constanst"
import { ReflectHasInteractiveParams } from "../../units/types/ReflectFilterParams"

const ALL_OPTIONS = { name: "Tất cả", id: 0 }

interface ISearchForm {
  params?: ReflectHasInteractiveParams
  onSetParams: (params: any) => void
  fieldOptions: Array<any>
  statusOptions: Array<any>
  categoryOptions: Array<any>
}

const HaveCommentForm = ({ params, onSetParams, fieldOptions, statusOptions, categoryOptions }: ISearchForm) => {
  const api = useApiClient()

  const handleSelectChange = (field: string) => (e: any) => {
    onSetParams({
      ...params,
      [field]: e.target.value,
      PageIndex: 0,
    })
  }

  return (
    <form>
      <Grid container sx={{ py: "16px" }} spacing={2}>
        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Lĩnh vực</InputLabel>
            <Select fullWidth value={params?.CategoriesId} onChange={handleSelectChange("CategoriesId")}>
              {[ALL_OPTIONS, ...categoryOptions]?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Chuyên mục</InputLabel>
            <Select
              fullWidth
              value={params?.ReflectionFieldId}
              onChange={handleSelectChange("ReflectionFieldId")}
              variant="standard"
            >
              {[ALL_OPTIONS, ...fieldOptions]?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Trạng thái</InputLabel>
            <Select
              value={params?.ReflectionStatusId}
              onChange={handleSelectChange("ReflectionStatusId")}
              label="Trạng thái"
              variant="standard"
            >
              {[ALL_OPTIONS, ...statusOptions]?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Tình trạng</InputLabel>
            <Select
              value={params?.IsAnswered}
              onChange={handleSelectChange("IsAnswered")}
              label="Tình trạng"
              variant="standard"
            >
              {REVIEWER_OPTIONS?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={12}>
          <TextField
            label="Tìm kiếm dữ liệu: mã phản ánh, tiêu đề, địa điểm"
            variant="standard"
            fullWidth
            onChange={handleSelectChange("Searching")}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default HaveCommentForm
