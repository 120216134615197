import { AxiosInstance } from "axios"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { ChunkedLookupResponse } from "../../../oneportal/units/types/ChunkedLookupResponse"

export const getLookupRecordsStatus = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/RecordsStatus/GetLookup")
    return res.data
  } catch (err) {}
}
