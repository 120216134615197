import { AxiosInstance } from "axios"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"
import { District } from "../../units/types/district"
import { DistrictRequest } from "../../units/types/districtRequest"

export type districtParams = ChunkedParams & {
  sortExpression?: string
  name?: string
}

export const getAllDistrict = async (
  api: AxiosInstance,
  params?: districtParams
): Promise<ChunkedPaginationResponse<District> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<District>>("/api/admin/District/GetAll", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const deleteDistrict = async (
  api: AxiosInstance,
  districtId: number
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.put<ChunkedResponse<ChunkedStatusResponse>>(
      `/api/admin/District/Delete?id=${districtId}`
    )

    return res.data
  } catch (err) {}
}

export const createDistrict = async (
  api: AxiosInstance,
  req: DistrictRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/District/Create", req)
    return res.data
  } catch (err) {}
}

export const updateDistrict = async (
  api: AxiosInstance,
  req: DistrictRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.put<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/District/Update", req)
    return res.data
  } catch (err) {}
}

export const getDistrictDetail = async (
  api: AxiosInstance,
  districtId: string
): Promise<ChunkedResponse<District> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<District>>(`/api/admin/District/GetDetail?id=${districtId}`)
    return res.data
  } catch (err) {}
}
