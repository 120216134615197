import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"

export type SearchProcessingUniFormType = {
  categoryId?: string
}

export const createSearchProcessingUniForm = (
  data?: SearchProcessingUniFormType
): ObservableForm<SearchProcessingUniFormType, SearchProcessingUniFormType> => {
  const form = createFormFromSchema<SearchProcessingUniFormType>(
    object<SearchProcessingUniFormType>({
      categoryId: string().optional().toDefault(data?.categoryId),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
