import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { createCategory, updateCategory } from "../../../services/api/category"
import { Categories } from "../../../units/types/categories"
import { CategoryRequest } from "../../../units/types/categoryRequest"

export const createCreateOrUpdateCategoryForm = (
  api: AxiosInstance,
  data?: Categories
): ObservableForm<CategoryRequest, boolean> => {
  const form = createFormFromSchema<CategoryRequest, boolean>(
    object<CategoryRequest>({
      id: string()
        .optional()
        .toDefault(data?.id?.toString() || ""),
      name: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.name || ""),
      reflectionFieldId: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.reflectionFieldId?.toString() || ""),
      file: object().optional().allowUnknownKeys(),
    })
  ).handler(async (form) => {
    const datas = await form.get()
    const formData = new FormData()

    Object.keys(datas).forEach((item) => datas[item] && formData.append(item, datas[item]))

    if (data) {
      const res = await updateCategory(api, formData)
      if (res?.content?.status) return true
    } else {
      delete datas.id
      const res = await createCategory(api, formData)
      if (res?.content?.status) return true
    }
    return false
  })

  return form
}
