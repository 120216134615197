import { useAction } from "@corets/use-action"
import { Box } from "@mui/material"
import { useEffect } from "react"
import { PagePagination } from "../../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../../../oneportal/components/Table/TBody"
import { TBodySkeleton } from "../../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../../../oneportal/components/Table/TWrapper"
import { dateVNFormat,datetimeVNFormat } from "../../../../oneportal/helpers/utils"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getListAccessHistory } from "../../../services/api/accessHistory"
import { ReflectResponse } from "../../../units/types/ReflectResponse"

interface ReflectAccessLogTabProps {
  reflect: ReflectResponse
}

const AccessLogTab = ({ reflect }: ReflectAccessLogTabProps) => {
  const api = useApiClient()

  const accessHistoryAction = useAction(
    async () =>
      await getListAccessHistory(api, {
        ReflectTheScenceId: reflect?.id,
        PageIndex: pagination.query.pageIndex || 0,
        PageSize: pagination.query.pageSize || 10,
      })
  )

  const listAccessHistory = accessHistoryAction?.getResult()?.content?.items || []

  const pagination = usePagePagination({
    pageCount: accessHistoryAction.getResult()?.content.pageCount,
    useQueryString: false,
  })

  useEffect(() => {
    if (reflect?.id) {
      accessHistoryAction.run()
    }
    if (!reflect?.id) {
      pagination.bind().onGoFirstPage()
    }
  }, [reflect?.id, pagination.query.pageIndex, pagination.query.pageSize])

  return (
    <Box>
      <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
        <THead>
          <THeadRow>
            <THeadCell>STT</THeadCell>
            <THeadCell>Thời gian</THeadCell>
            <THeadCell>Họ và tên</THeadCell>
            <THeadCell>Email</THeadCell>
          </THeadRow>
        </THead>
        <TBody>
          {!accessHistoryAction.isRunning() &&
            listAccessHistory?.map((item, i) => (
              <THeadRow key={i}>
                <THeadCell>{i + 1}</THeadCell>
                <THeadCell>{datetimeVNFormat(item?.createdDate)}</THeadCell>
                <THeadCell>{item?.userName}</THeadCell>
                <THeadCell>{item?.createdBy}</THeadCell>
              </THeadRow>
            ))}
        </TBody>

        {accessHistoryAction.isRunning() && <TBodySkeleton count={10} colSpan={11} />}
      </TWrapper>

      {!accessHistoryAction.isRunning() && !listAccessHistory.length && <TPlaceholder title="Không có dữ liệu" />}

      {listAccessHistory.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageIndex={pagination.query.pageIndex}
            pageCount={accessHistoryAction.getResult()?.content.pageCount}
            {...pagination.bind()}
          />
        </Box>
      )}
    </Box>
  )
}

export default AccessLogTab
