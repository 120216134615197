import { Apps, Delete, Edit, Reply, Visibility, VisibilityOff } from "@mui/icons-material"
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  ListItemIcon,
  Typography,
} from "@mui/material"
import React, { useRef, useState } from "react"

interface ActionButtonProps {
  onEditClick?: () => void
  onDeleteClick?: () => void
  onVisibilityClick?: () => void
  onReplyClick?: () => void
  item: any
}

const ActionButton = ({ item, onEditClick, onDeleteClick, onVisibilityClick, onReplyClick }: ActionButtonProps) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <IconButton ref={anchorRef} color="primary" onClick={handleToggle}>
        <Apps />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        style={{ zIndex: 999999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  {onEditClick && (
                    <MenuItem
                      onClick={() => {
                        onEditClick()
                        setOpen(false)
                      }}
                    >
                      <ListItemIcon>
                        <Edit color="primary" />
                      </ListItemIcon>
                      <Typography>Chỉnh sửa phản hồi</Typography>
                    </MenuItem>
                  )}
                  {onDeleteClick && (
                    <MenuItem
                      onClick={() => {
                        onDeleteClick()
                        setOpen(false)
                      }}
                    >
                      <ListItemIcon>
                        <Delete color="error" />
                      </ListItemIcon>
                      <Typography>Xóa phản hồi</Typography>
                    </MenuItem>
                  )}
                  {onVisibilityClick && (
                    <MenuItem
                      onClick={() => {
                        onVisibilityClick()
                        setOpen(false)
                      }}
                    >
                      <ListItemIcon>
                        {item?.isActive ? <VisibilityOff color="error" /> : <Visibility color="primary" />}
                      </ListItemIcon>
                      <Typography>{item?.isActive ? "Ẩn" : "Hiện"} phản hồi</Typography>
                    </MenuItem>
                  )}
                  {onReplyClick && (
                    <MenuItem
                      onClick={() => {
                        onReplyClick()
                        setOpen(false)
                      }}
                    >
                      <ListItemIcon>
                        <Reply color="primary" />
                      </ListItemIcon>
                      <Typography>Trả lời</Typography>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ActionButton
