import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

export type AlertDialogProps = {
  open: boolean
  title: string
  textContent: string
  closeable?: boolean
  onClose: () => void
  onExited?: () => void
  onAgree: () => void
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
}

export const AlertDialog = (props: AlertDialogProps) => {
  const { open, title, textContent, onClose, onExited = () => null, closeable = false, onAgree, maxWidth } = props

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
        maxWidth={maxWidth}
        TransitionProps={{
          onExited: onExited,
        }}
        onClose={(_, reason) => {
          if (reason !== "backdropClick" || closeable) {
            onClose()
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{textContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} className="btn btn-ct btn-back-ct btn-default-ct">
            Hủy
          </Button>

          <button className="btn btn-custom-ct btn-primary-ct" type="button" onClick={onAgree} autoFocus>
            Đồng ý
          </button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
