import { AxiosError, AxiosInstance } from "axios"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"
import { Log } from "../../units/types/Log"
import { LogFilterParams } from "../../units/types/LogRequest"

export type LogParams = ChunkedParams & {
    message?: string
  sortExpression?: string
  code?: string
}


export const getListLog = async (api: AxiosInstance, params: LogFilterParams) => {
    try {
      const res = await api.get("/api/admin/Log/GetListAll", {
        params: params,
      })
      return res.data
    } catch (err) {}
  }

export const getLogDetail = async (api: AxiosInstance, logId: string): Promise<ChunkedResponse<Log> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Log>>("/api/admin/Log/GetById", {
      params: { id: logId },
    })
    return res.data
  } catch (err) {}
}

export const deleteLog = async (
  api: AxiosInstance,
  logId: string[]
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const arr = logId.map(x => "input="+ x);
    var params = "";
    if(arr.length > 0)
    {
        params = ""+ arr.toString().replaceAll(",","&")
    }
    const res = await api.get<ChunkedResponse<ChunkedStatusResponse>>(`/api/admin/Log/Delete?${params}` )
    return res.data
  } catch (err: unknown | AxiosError) {}
}

