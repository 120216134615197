import { AxiosInstance } from "axios"
import { ChunkedResponse } from "../../oneportal/units/types/ChunkedResponse"
import { Reflection } from "../units/types/Reflection"

export const getDashBoardStatisticNewReflection = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection>>("api/DashBoard/GetDashBoardStatisticNewReflection")
    return res.data
  } catch (err) {}
}

export const getDashboardByReflectionStatus = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection>>("api/DashBoard/GetDashboardByReflectionStatus")
    return res.data
  } catch (err) {}
}

export const getDashBoardReflectionOntimeOrLate = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection>>("api/DashBoard/GetDashBoardReflectionOntimeOrLate")
    return res.data
  } catch (err) {}
}

export const getDashBoardStatisticStatusByField = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>("api/DashBoard/GetDashBoardStatisticStatusByField")
    return res.data
  } catch (err) {}
}

export const getDashBoardOntimeOrLateField = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>("api/DashBoard/GetDashBoardOntimeOrLateField")
    return res.data
  } catch (err) {}
}

export const getDashBoardStatisticByProcessingUnit = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>("api/DashBoard/GetDashBoardStatisticByProcessingUnit")
    return res.data
  } catch (err) {}
}

export const getDashBoardStatisticOntimeOrLateByProcessingUnit = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>(
      "api/DashBoard/GetDashBoardStatisticOntimeOrLateByProcessingUnit"
    )
    return res.data
  } catch (err) {}
}

export const getDashBoardSatisfactionLevelOverall = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection>>("api/DashBoard/GetDashBoardSatisfactionLevelOverall")
    return res.data
  } catch (err) {}
}

export const getDashBoardSatisfactionLevelByFields = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>("api/DashBoard/GetDashBoardSatisfactionLevelByFields")
    return res.data
  } catch (err) {}
}

export const getDashBoardSatisfactionLevelByProcessingUnit = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>(
      "api/DashBoard/GetDashBoardSatisfactionLevelByProcessingUnit"
    )
    return res.data
  } catch (err) {}
}

export const getSatisfactionLevelByCommuneDistrictProvince = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>(
      "api/DashBoard/GetSatisfactionLevelByCommuneDistricProvince"
    )
    return res.data
  } catch (err) {}
}

export const getDashBoardStatisticByDetectedPositionPeriod = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>(
      "api/DashBoard/GetDashBoardStatisticByDetectedPositionPeriod"
    )
    return res.data
  } catch (err) {}
}

export const getDashBoardStatisticOntimeOrLateByDetectedPosition = async (
  api: AxiosInstance
): Promise<ChunkedResponse<Reflection[]> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection[]>>(
      "api/DashBoard/GetDashBoardStatisticOntimeOrLateByDetectedPosition"
    )
    return res.data
  } catch (err) {}
}
