import { useHistory } from "react-router-dom"

import { ArrowRight, TableReport } from "../../assets/images"
import { links } from "../../config/links"
import { PageTitle } from "../oneportal/components/PageTitle"

const Report = () => {
  const history = useHistory()

  return (
    <section className="page-content report-page">
      <PageTitle title="Báo cáo" />
      <div
        className="report__row d-flex align-items-center justify-content-between"
        onClick={() => history.push(links.report.monitoring())}
      >
        <div className="report__title d-flex align-items-center">
          <img src={TableReport} alt="" />
          <h4>SỔ THEO DÕI TIẾP NHẬN VÀ TRẢ KẾT QUẢ</h4>
        </div>
        <div className="report__img-right">
          <img src={ArrowRight} alt="" />
        </div>
      </div>
      <div
        className="report__row d-flex align-items-center justify-content-between"
        onClick={() => history.push(links.report.project())}
      >
        <div className="report__title d-flex align-items-center">
          <img src={TableReport} alt="" />
          <h4>DANH SÁCH DỰ ÁN, CÔNG TRÌNH ĐƯỢC THẨM DUYỆT THIẾT KẾ, NGHIỆM THU VỀ PCCC</h4>
        </div>
        <div className="report__img-right">
          <img src={ArrowRight} alt="" />
        </div>
      </div>
      <div
        className="report__row d-flex align-items-center justify-content-between"
        onClick={() => history.push(links.report.statistics())}
      >
        <div className="report__title d-flex align-items-center">
          <img src={TableReport} alt="" />
          <h4>THỐNG KÊ SỐ LIỆU KẾT QUẢ THỰC HIỆN CÔNG TÁC THẨM DUYỆT VỀ PCCC</h4>
        </div>
        <div className="report__img-right">
          <img src={ArrowRight} alt="" />
        </div>
      </div>
    </section>
  )
}
export default Report
