import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { formatISODate } from "../../../oneportal/helpers/formatISODate"

export type changeStatusFormType = {
  cancelReason?: string
  dateRealReturn?: string
}

export const changeStatusForm = (
  data?: changeStatusFormType
): ObservableForm<changeStatusFormType, changeStatusFormType> => {
  const form = createFormFromSchema<changeStatusFormType>(
    object<changeStatusFormType>({
      cancelReason: string()
        .optional()
        .toDefault(data?.cancelReason || ""),
      dateRealReturn: string()
        .date("Ngày không đúng")
        .toDefault(formatISODate(data?.dateRealReturn) || "")
        .required(true, "Bắt buộc"),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
