import { AxiosInstance } from "axios"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedLookupResponse } from "../../../oneportal/units/types/ChunkedLookupResponse"
import { Investor } from "../../units/types/Investor"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { InvestorRequest } from "../../units/types/InvestorRequest"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"

export type InvestorParams = ChunkedParams & {
  sortExpression?: string
  provinceId?: number
  districtId?: number
  communeId?: number
  sorting?: string
}

export const getAllInvestor = async (
  api: AxiosInstance,
  params?: InvestorParams
): Promise<ChunkedPaginationResponse<Investor> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<Investor>>("/api/admin/Investor/GetAll", { params: req })
    return res.data
  } catch (err) {}
}

export const getLookupInvestor = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/Investor/GetLookup")
    return res.data
  } catch (err) {}
}

export const createReturnInvestor = async (
  api: AxiosInstance,
  req: InvestorRequest
): Promise<ChunkedResponse<Investor> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<Investor>>("/api/admin/Investor/CreateReturnInvestor", req)
    return res.data
  } catch (err) {}
}

export const createInvestor = async (
  api: AxiosInstance,
  req: InvestorRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Investor/Create", req)
    return res.data
  } catch (err) {}
}

export const updateInvestor = async (
  api: AxiosInstance,
  req: InvestorRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Investor/Update", req)
    return res.data
  } catch (err) {}
}

export const deleteInvestor = async (
  api: AxiosInstance,
  investorId: number
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.delete<ChunkedResponse<ChunkedStatusResponse>>(
      `/api/admin/Investor/DeletePermanent/${investorId}`
    )

    return res.data
  } catch (err) {}
}

export const getInvestorDetail = async (
  api: AxiosInstance,
  investorId: string
): Promise<ChunkedResponse<Investor> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Investor>>(`/api/admin/Investor/GetDetail/${investorId}`)
    return res.data
  } catch (err) {}
}
