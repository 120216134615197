import { createFormFromSchema, ObservableForm } from "@corets/form"
import { array, object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { UserDetail } from "../../../units/types/UerDetail"
import { UserRequest } from "../../../units/types/UserRequest"

export const createCreateOrUpdateUserForm = (
  api: AxiosInstance,
  data?: UserDetail
): ObservableForm<UserRequest, boolean> => {
  const form = createFormFromSchema<UserRequest, boolean>(
    object<UserRequest>({
      id: string()
        .optional()
        .toDefault(data?.id || ""),
      fullName: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.fullName || ""),
      email: string()
        .required(true, "Bắt buộc")
        .email("Email không đúng")
        .toDefault(data?.email || ""),
      roles: array().toDefault(data?.roles || []),
      sex: string().toDefault(data?.sex ? "true" : "false"),
      dateOfBirth: string()
        .optional()
        .toDefault(data?.dateOfBirth || ""),
      address: string()
        .optional()
        .toDefault(data?.address || ""),
      phoneNumber: string()
        .required(true, "Bắt buộc")
        .matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/, "Số điện thoại không đúng định dạng")
        .toDefault(data?.phoneNumber || ""),
      reflectionUnitId: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.reflectionUnitId.toString() || ""),
      file: object().optional().allowUnknownKeys(),
    })
  )
  return form
}
