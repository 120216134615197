export const convertObjectToFormData = (object: Object, form?: FormData, namespace?: string): FormData => {
  const formData = form || new FormData()
  for (let property in object) {
    if (!object.hasOwnProperty(property) || !object[property]) {
      continue
    }
    const formKey = namespace ? `${namespace}[${property}]` : property
    if (object[property] instanceof Date) {
      formData.append(formKey, object[property].toISOString())
    } else if (typeof object[property] === "object" && !(object[property] instanceof File)) {
      convertObjectToFormData(object[property], formData, formKey)
    } else {
      formData.append(formKey, object[property])
    }
  }
  return formData
}
