import { useState, useEffect } from "react"
import {
  Box,
  Grid,
  IconButton,
  Checkbox,
  Button,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material"
import { green } from "@mui/material/colors"
import { Delete, Edit, Visibility, VisibilityOff, Reply } from "@mui/icons-material"
import { useAction } from "@corets/use-action"
import { PagePagination } from "../../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../../../oneportal/components/Table/TBody"
import { TBodySkeleton } from "../../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../../../oneportal/components/Table/TWrapper"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getListAccessHistory } from "../../../services/api/accessHistory"
import { getListComment } from "../../../services/api/comment"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import CreateOrUpdateCommentDialog from "./CreateOrUpdateCommentDialog"
import ConfirmShowOrHideDialog from "./ConfirmShowOrHideDialog"
import ConfirmDeleteDialog from "./ConfirmDeleteDialog"
import { getListCommentOfReflectInteractive } from "../../../services/api/reflectTheScene"
import { ExpandMore, KeyboardArrowUp } from "@mui/icons-material"
import ActionButton from "../ActionButton"
import { TypographyTruncate } from "../../Typography"
import { isImage, isVideo, isDocument } from "../../../utils/file"
import ZoomableImage from "../../MediaView/ZoomableImage"
import ZoomableVideo from "../../MediaView/ZoomableVideo"

const BASE_API = process.env.REACT_APP_BASE_URL_API

interface ReflectInfoTabProps {
  reflect: ReflectResponse
  onSuccess?: () => void
}

const ReflectInfoTab = ({ reflect, onSuccess }: ReflectInfoTabProps) => {
  const [isOpenCreateDialog, setOpenCreateDialog] = useState(false)
  const [dialogAction, setDialogAction] = useState<any>(null)
  const [isOpenConfirmChangeActiveDialog, setOpenConfirmChangeActiveDialog] = useState<boolean>(false)
  const [isOpenConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false)
  const [commentSelected, setCommentSelected] = useState<any>(null)
  const api = useApiClient()
  const [commentId, setCommentId] = useState(0)
  const [openChildrenComment, setChildrenComment] = useState(false)

  const reflectInteractiveAction = useAction(
    async () =>
      await getListCommentOfReflectInteractive(api, {
        ReflectionId: reflect?.id?.toString(),
        IsDelete: false,
        PageIndex: pagination.query.pageIndex || 0,
        PageSize: pagination.query.pageSize || 10,
      })
  )

  const listReflectInteractive = reflectInteractiveAction?.getResult()?.content?.items || []

  const pagination = usePagePagination({
    pageCount: reflectInteractiveAction.getResult()?.content.pageCount,
    useQueryString: false,
  })

  useEffect(() => {
    if (reflect?.id) {
      reflectInteractiveAction.run()
    }
    if (!reflect?.id) {
      pagination.bind().onGoFirstPage()
    }
  }, [reflect?.id, pagination.query.pageIndex, pagination.query.pageSize])

  const handleToggleReflect = (id: number) => () => {
    setChildrenComment(true)
    setCommentId(id)
  }

  const handleToggleOff = () => {
    setChildrenComment(false)
    setCommentId(0)
  }

  const handleReplyAll = () => {}

  return (
    <Box>
      <Box justifyContent="flex-end" alignItems="center" sx={{ display: "flex", paddingBottom: "16px" }}>
        <Button
          variant="contained"
          onClick={() => {
            setDialogAction("create")
            setCommentSelected(null)
          }}
        >
          Phản hồi chung
        </Button>
      </Box>
      <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
        <THead>
          <THeadRow>
            <THeadCell>Nội dung tương tác</THeadCell>
            <THeadCell>Tương tác của công dân </THeadCell>
            <THeadCell>Tên cán bộ hoặc công dân</THeadCell>
            <THeadCell>Nội dung phản hồi</THeadCell>
            <THeadCell>Hình ảnh</THeadCell>
            <THeadCell>Thao tác</THeadCell>
          </THeadRow>
        </THead>
        <TBody>
          {!reflectInteractiveAction.isRunning() &&
            listReflectInteractive?.length > 0 &&
            listReflectInteractive?.map((item, i) => {
              const isExpand = openChildrenComment && item?.id === commentId
              return (
                <>
                  <TableRow key={i}>
                    <TableCell>
                      <div className="flex item-centers">
                        {item?.childrenComments.length > 0 && (
                          <div onClick={isExpand ? handleToggleOff : handleToggleReflect(item?.id)}>
                            {!isExpand ? (
                              <ExpandMore className="cursor-pointer" />
                            ) : (
                              <KeyboardArrowUp className="cursor-pointer" />
                            )}
                          </div>
                        )}
                        {/* <span>{item?.content}</span> */}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Checkbox disabled defaultChecked={item?.isCitizenInteraction} />
                    </TableCell>
                    <TableCell>{item?.userComment}</TableCell>
                    <TableCell>
                      <Tooltip title={item?.content} placement="top" arrow>
                        <TypographyTruncate>{item?.content}</TypographyTruncate>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {item?.commentFilesViewDtos
                        ?.filter((item) => !isDocument(item?.fileName))
                        ?.map((item, i) => (
                          <Box key={i} sx={{ width: 120, mb: 2 }}>
                            {isImage(item?.fileName) && (
                              <ZoomableImage src={BASE_API + item.filePath} style={{ width: "100%" }} />
                            )}
                            {isVideo(item?.fileName) && (
                              <ZoomableVideo
                                src={BASE_API + item.filePath + "#t=0.1"}
                                controls
                                style={{ width: "100%" }}
                              />
                            )}
                          </Box>
                        ))}
                      {reflect?.commentFilesViewDtos
                        ?.filter((item) => isDocument(item?.fileName))
                        ?.map((item, i) => (
                          <Box key={i} sx={{ mt: 1 }}>
                            <p>
                              Tài liệu:{" "}
                              <a href={BASE_API + item.filePath} target="_blank">
                                {item?.fileName}
                              </a>
                            </p>
                          </Box>
                        ))}
                    </TableCell>
                    <TableCell>
                      <ActionButton
                        onEditClick={() => {
                          setDialogAction("update")
                          setCommentSelected(item)
                        }}
                        onDeleteClick={() => {
                          setOpenConfirmDeleteDialog(true)
                          setCommentSelected(item)
                        }}
                        onVisibilityClick={() => {
                          setCommentSelected(item)
                          setOpenConfirmChangeActiveDialog(true)
                        }}
                        onReplyClick={() => {
                          setDialogAction("reply")
                          setCommentSelected(item)
                        }}
                        item={item}
                      />
                    </TableCell>
                  </TableRow>
                  {isExpand &&
                    item?.childrenComments.length > 0 &&
                    item?.childrenComments.map((comment) => (
                      <TableRow key={i} className="p-0">
                        <TableCell className="pl-50px">
                          <Tooltip title={comment?.content} placement="top" arrow>
                            <TypographyTruncate>{comment?.content}</TypographyTruncate>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Checkbox disabled defaultChecked={comment?.isCitizenInteraction} />
                        </TableCell>
                        <TableCell>{comment?.userComment}</TableCell>
                        <TableCell>
                          <Tooltip title={comment?.content} placement="top" arrow>
                            <TypographyTruncate>{comment?.content}</TypographyTruncate>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <ActionButton
                            onEditClick={() => {
                              setDialogAction("update")
                              setCommentSelected(comment)
                            }}
                            onDeleteClick={() => {
                              setOpenConfirmDeleteDialog(true)
                              setCommentSelected(comment)
                            }}
                            onVisibilityClick={() => {
                              setCommentSelected(comment)
                              setOpenConfirmChangeActiveDialog(true)
                            }}
                            item={comment}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )
            })}
        </TBody>

        {reflectInteractiveAction.isRunning() && <TBodySkeleton count={10} colSpan={11} />}
      </TWrapper>

      {!reflectInteractiveAction.isRunning() && !listReflectInteractive.length && (
        <TPlaceholder title="Không có dữ liệu" />
      )}

      {listReflectInteractive.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageIndex={pagination.query.pageIndex}
            pageCount={reflectInteractiveAction.getResult()?.content.pageCount}
            {...pagination.bind()}
          />
        </Box>
      )}

      <CreateOrUpdateCommentDialog
        action={dialogAction || "create"}
        reflect={reflect}
        comment={commentSelected}
        open={!!dialogAction}
        handleClose={() => setDialogAction(null)}
        onSuccess={() => {
          reflectInteractiveAction.run()
          onSuccess && onSuccess()
          setDialogAction(null)
        }}
      />

      <ConfirmShowOrHideDialog
        open={isOpenConfirmChangeActiveDialog}
        handleClose={() => setOpenConfirmChangeActiveDialog(false)}
        comment={commentSelected}
        onSuccess={() => {
          reflectInteractiveAction.run()
          onSuccess && onSuccess()
          setOpenConfirmChangeActiveDialog(false)
        }}
      />

      <ConfirmDeleteDialog
        open={isOpenConfirmDeleteDialog}
        handleClose={() => setOpenConfirmDeleteDialog(false)}
        commentId={commentSelected?.id}
        onSuccess={() => {
          reflectInteractiveAction.run()
          onSuccess && onSuccess()
          setOpenConfirmDeleteDialog(false)
        }}
      />
    </Box>
  )
}

export default ReflectInfoTab
