import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { createCommune, updateCommune } from "../../../services/api/commune"
import { Commune } from "../../../units/types/commune"
import { CommuneRequest } from "../../../units/types/communeRequest"

export const createCreateOrUpdateCommuneForm = (
  api: AxiosInstance,
  data?: Commune
): ObservableForm<CommuneRequest, boolean> => {
  const form = createFormFromSchema<CommuneRequest, boolean>(
    object<CommuneRequest>({
      id: string()
        .optional()
        .toDefault(data?.id?.toString() || ""),
      name: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.name || ""),
      provinceId: string().required(true, "Bắt buộc").toDefault(data?.provinceId?.toString()),
      districtId: string().required(true, "Bắt buộc").toDefault(data?.districtId?.toString()),
    })
  ).handler(async (form) => {
    const datas = await form.get()

    if (data) {
      const res = await updateCommune(api, datas)
      if (res?.content?.status) return true
    } else {
      delete datas.id
      const res = await createCommune(api, datas)
      if (res?.content?.status) return true
    }
    return false
  })

  return form
}
