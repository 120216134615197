/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "@corets/use-form"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect } from "react"
import { useHistory } from "react-router"
import { links } from "../../../../config/links"
import { Header } from "../../components/Header/Header"
import { PageTitle } from "../../components/PageTitle"
import { getCookie, removeCookie } from "../../helpers/cookies"
import { createMuiFormBinder } from "../../helpers/createMuiFormBinder"
import { setJWTCookies } from "../../helpers/setJWTCookies"
import { useAlert } from "../../hooks/useAlert"
import { useApiClient } from "../../hooks/useApiClient"
import { CookieKeys } from "../../units/enums/CookieKeys"
import { createLoginForm } from "./createLoginForm"

export const Login = () => {
  const history = useHistory()
  const api = useApiClient()

  const [form] = useForm(createLoginForm(api))
  const bind = createMuiFormBinder(form)
  const alert = useAlert()

  useEffect(() => {
    const accessToken = getCookie(CookieKeys.AccessToken)
    if (accessToken) history.push(links.home.dashboard())
  }, [])

  const handleLogin = async () => {
    const validationErrors = await form.validate()
    if (validationErrors) return

    const res = await form.submit()

    if (res) {
      const { refreshToken, returnUrl, token } = res.content
      setJWTCookies(refreshToken, returnUrl, token)
      alert("Đăng nhập thành công", { variant: "success" })
      if (returnUrl) {
        // Redirect to returnUrl
        removeCookie(CookieKeys.ReturnUrl)
        history.push(returnUrl)
        return
      }
      history.push(links.home.dashboard())
    } else {
      alert("Email hoặc mật khẩu không đúng", { variant: "error" })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin()
    }
  }

  return (
    <>
      <PageTitle title="Đăng nhập" />
      <Header showAvatar={false} />
      <section className="login-page">
        <div className="login-box">
          <h3>Đăng nhập</h3>
          <form {...bind.form()} onKeyDown={handleKeyDown}>
            <div className="form-group m-w-335">
              <label>
                Tên đăng nhập<span>*</span>
              </label>
              <input {...bind.textField("email")} placeholder="Tên đăng nhập" />
              <FormHelperText {...bind.helperText("email")} />
            </div>
            <div className="form-group m-w-335">
              <label>
                Mật khẩu<span>*</span>
              </label>
              <input {...bind.textField("password")} type="password" placeholder="Mật khẩu" />
              <FormHelperText {...bind.helperText("password")} />
            </div>
            <button disabled={form.isSubmitting()} className="btn loading" type="button" onClick={handleLogin}>
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              Đăng Nhập
            </button>
          </form>
        </div>
      </section>
    </>
  )
}
