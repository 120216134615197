import * as React from "react"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

export default function CustomizedDialogs(props) {
  const { open, setOpen, content, rowId, handleAccept } = props
  const handleClose = () => {
    setOpen(false)
  }

  // const handleAccept = () => {
  //   setOpen(false);
  //   deleteRow(rowId);
  //   getList('', '', '', '', '', '', '', '', pageIndex.toString(), pageSize.toString());
  // };

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogContent dividers>
        <Typography gutterBottom>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Thoát</Button>
        <Button onClick={(e) => handleAccept(e, rowId)}>Đồng ý</Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
