import { ReactNode, useEffect, useState } from "react"
import { CategoryIcon, HomeIcon, InformationIcon, RecordIcon, ReportIcon, SystemIcon } from "../../../assets/icons"
import { Header } from "../components/Header/Header"
import { Sidebar } from "../components/Sidebar/Sidebar"
import UserIcon from "../../../assets/image/user-icon.png"
import ChangePasswordIcon from "../../../assets/image/change-password-icon.png"
import { links } from "../../../config/links"
import { useApiClient } from "../hooks/useApiClient"
import { getMenuData } from "../services/api/menuSideBar"
import { getUserAccountDetail } from "../services/api/account"
import { useAsync } from "@corets/use-async"
import { Drawer, IconButton, Typography, Button, Box, useMediaQuery } from "@mui/material"

export type MainLayoutProps = {
  children: ReactNode
}

const mapMenuArray = (arr: []) => {
  if (Array.isArray(arr)) {
    const newArr = arr.map((item: any) => {
      if (item?.code === "DOCUMENT") {
        return {
          text: item?.title,
          icon: <RecordIcon />,
          key: "record",
          childrens: item?.childrent.map((sub: any) => {
            switch (sub?.code) {
              case "RECORDS":
                return {
                  text: sub?.title,
                  link: links.records.records(),
                }
              case "PROJECT":
                return {
                  text: sub?.title,
                  link: links.records.projects(),
                }
              case "CREATERECORDS":
                return {
                  text: sub?.title,
                  link: links.records.addRecord(),
                }
              default:
                return {}
            }
          }),
        }
      }

      if (item?.code === "REPORT") {
        return {
          text: item?.title,
          key: "report",
          icon: <ReportIcon />,
          childrens: item?.childrent?.map((sub: any) => {
            switch (sub?.code) {
              case "REFLECTION_REPORT_PROCESSING_UNIT":
                return {
                  text: sub?.name,
                  link: links.report.reflectionReportProcessingUnit(),
                }
              case "REFLECTION_REPORT_VOTE":
                return {
                  text: sub?.name,
                  link: links.report.reflectionReportVote(),
                }
              case "REFLECTION_REPORT_REFLECTION_FIELD":
                return {
                  text: sub?.name,
                  link: links.report.reflectionReportReflectionField(),
                }
              case "REFLECTION_REPORT_CATEGORY":
                return {
                  text: sub?.name,
                  link: links.report.reflectionReportCategory(),
                }
              case "REFLECTION_REPORT_SOURCE":
                return {
                  text: sub?.name,
                  link: links.report.reflectionReportSource(),
                }
              case "REFLECTION_REPORT_PROCESSING_DATE":
                return {
                  text: sub?.name,
                  link: links.report.reflectionReportProcessingDate(),
                }
              default:
                return {}
            }
          }),
        }
      }
      if (item?.code === "MESSAGE") {
        return {
          text: item?.title,
          key: "infomation",
          icon: <InformationIcon />,
          childrens: item?.childrent.map((sub: any) => {
            switch (sub?.code) {
              case "MESSAGEHASSEND":
                return {
                  text: sub?.title,
                  link: links.information.messageSent(),
                }
              case "MESSAGEHASRECEIVE":
                return {
                  text: sub?.title,
                  link: links.information.messageReceived(),
                }
              case "SENDMESSAGE":
                return {
                  text: sub?.title,
                  link: links.information.sendMessage(),
                }
              default:
                return {}
            }
          }),
        }
      }
      if (item?.code === "COMMON") {
        return {
          text: item?.title,
          key: "category",
          icon: <CategoryIcon />,
          childrens: item?.childrent.map((sub: any) => {
            switch (sub?.code) {
              case "DISTRICT_MANAGEMENT":
                return {
                  text: sub?.title,
                  link: links.category.distrists(),
                }
              case "COMMUNE_MANAGEMENT":
                return {
                  text: sub?.title,
                  link: links.category.communes(),
                }

              case "CATEGORIES_MANAGEMENT":
                return {
                  text: sub?.title,
                  link: links.category.categoryManagement(),
                }
              case "REFLECTIONFIELDS_MANAGEMENT":
                return {
                  text: sub?.title,
                  link: links.category.reflectionFieldsManagement(),
                }
              case "REFLECTION_PROCESSING_UNIT_MANAGEMENT":
                return {
                  text: sub?.title,
                  link: links.category.reflectionProcessingUnit(),
                }
              case "REFLECTION_SOURCE_MANAGEMENT":
                return {
                  text: sub?.title,
                  link: links.category.reflectionSource(),
                }
              default:
                return {}
            }
          }),
        }
      }

      if (item?.code === "REFLECTION_WAITING_RECEIVE") {
        return {
          text: item?.title,
          icon: <ReportIcon />,
          link: links.reflect.reflectionWaitingReceive(),
        }
      }
      if (item?.code === "REFLECTION_WAITING_PROCESS") {
        return {
          text: item?.title,
          icon: <ReportIcon />,
          link: links.reflect.reflectionWaitingProcess(),
        }
      }
      if (item?.code === "REFLECTION_HAVE_COMMENT") {
        return {
          text: item?.title,
          icon: <ReportIcon />,
          link: links.reflect.reflectionHaveComment(),
        }
      }
      if (item?.code === "REFLECTION_HAVE_DISCUSS") {
        return {
          text: item?.title,
          icon: <ReportIcon />,
          link: links.reflect.reflectionHaveDiscuss(),
        }
      }

      if (item?.code === "REFLECTION_WAITING_APPROVE") {
        return {
          text: item?.title,
          icon: <ReportIcon />,
          link: links.reflect.reflectionWaitingApprove(),
        }
      }
      if (item?.code === "REFLECTION_HAVE_PROCESSED") {
        return {
          text: item?.title,
          icon: <ReportIcon />,
          link: links.reflect.reflectionHaveProcessed(),
        }
      }
      if (item?.code === "REFLECTION_COMPLETE") {
        return {
          text: item?.title,
          icon: <ReportIcon />,
          link: links.reflect.reflectionComplete(),
        }
      }
      if (item?.code === "REFLECTION_MAP") {
        return {
          text: item?.title,
          icon: <ReportIcon />,
          link: links.reflect.reflectionMap(),
        }
      }
      return {}
    })
    return newArr
  }
  return []
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props
  const api = useApiClient()
  const [menu, setMenu] = useState([] as any)
  const [isOpenSidebar, setOpenSidebar] = useState(false)
  const isMobile = useMediaQuery("(max-width:992px)")

  const menuData = useAsync(() => getMenuData(api), []).getResult()?.content
  const userInfo = useAsync(() => getUserAccountDetail(api), []).getResult()?.content

  useEffect(() => {
    if (menuData) {
      const menu = [
        {
          text: "Trang chủ",
          link: links.home.dashboard(),
          icon: <HomeIcon />,
        },
        ...mapMenuArray(menuData),
      ]
      setMenu(menu)
    }
  }, [menuData])

  return (
    <>
      <Header
        items={[
          {
            text: "Thông tin tài khoản",
            link: links.oneportal.profile(),
            icon: UserIcon,
          },
          {
            text: "Đặt lại mật khẩu",
            link: links.oneportal.changePassword(),
            icon: ChangePasswordIcon,
          },
        ]}
        OnClickMenuIcon={() => setOpenSidebar(!isOpenSidebar)}
      />
      <div></div>
      <main>
        <div className="wrap-page d-flex">
          <div className="aside__wrapper--desktop">
            <Sidebar items={menu} />
          </div>
          <div className="wrap-page__content">{children}</div>
        </div>
      </main>
      <Drawer anchor="left" open={isMobile && isOpenSidebar} onClose={() => setOpenSidebar(false)}>
        <div className="aside__wrapper--mobile">
          <Sidebar items={menu} />
        </div>
      </Drawer>
    </>
  )
}
