import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { Add, AttachFile, Remove, Close } from "@mui/icons-material"
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tab } from "@mui/material"
import Slide from "@mui/material/Slide"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import React, { useEffect, useState } from "react"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { createCreateOrUpdateCommentForm } from "./CreateOrUpdateCommentForm"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ActionText = {
  create: "Thêm",
  update: "Sửa",
  reply: "Trả lời",
}

interface CreateCommentDialogProps {
  action: "create" | "update" | "reply"
  reflect: any
  comment?: any
  open: boolean
  handleClose: () => void
  onSuccess?: () => void
}

export default function CreateCommentDialog(props: CreateCommentDialogProps) {
  const { action, reflect, comment, open, handleClose, onSuccess } = props
  const theme = useTheme()
  const api = useApiClient()
  const alert = useAlert()

  const [form] = useForm(createCreateOrUpdateCommentForm(api, comment), [JSON.stringify(comment)])
  const bind = createMuiFormBinder(form)
  const [totalFile, setTotalFile] = useState(1)

  const handleSubmit = async () => {
    const errors = await form.validate()
    console.log("comment " + action, form.get(), errors)
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert(ActionText[action] + " phải hồi thành công", { variant: "success" })
      onSuccess && onSuccess()
    } else {
      alert(ActionText[action] + " phải hồi thất bại", { variant: "error" })
    }
  }

  useEffect(() => {
    if (open) {
      console.log(comment?.commentFilesViewDtos, reflect.id)
      switch (action) {
        case "create":
          // form.setAt("Id", "")
          form.setAt("ReflectionId", reflect.id?.toString())
          setTotalFile(1)
          break
        case "update":
          setTotalFile((comment?.commentFilesViewDtos?.length || 0) + 1)
          break
        case "reply":
          form.setAt("Id", "")
          form.setAt("ReflectionId", reflect.id?.toString())
          form.setAt("Content", "")
          form.setAt("ParentId", comment?.parentId ? comment?.parentId?.toString() : comment?.id?.toString())
          form.setAt("IsReply", true)
          form.setAt("files", [])
          setTotalFile(1)
          break

        default:
          break
      } 
    }
    else {
      form.clear()
    }
  }, [open, reflect, action, comment])

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, color: "#fff" }}>
        {action === "update" ? "Chỉnh sửa phản hồi tương tác" : "Phản hồi tương tác"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            size="small"
            variant="standard"
            label="Nội dung"
            value={form.getAt("Content")}
            onChange={(e) => form.setAt("Content", e.target.value)}
            fullWidth
            multiline
            minRows={2}
          />
          <FormHelperText {...bind.helperText("Content")} sx={{ ml: 0 }} />
        </FormControl>
        <Table sx={{ td: { border: "1px solid #eee", borderCollapse: "collapse" } }}>
          <TableHead>
            <TableRow>
              <TableCell>STT</TableCell>
              <TableCell>Tải file</TableCell>
              <TableCell>Xóa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...new Array(totalFile)]?.map((item, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                  <Button variant="contained" component="label" startIcon={<AttachFile />}>
                    Chọn file
                    <input
                      hidden
                      type="file"
                      onChange={(e) => {
                        form.setAt(`files[${i}]`, e?.target?.files?.[0])
                      }}
                    />
                  </Button>{" "}
                  {form.getAt(`files[${i}]`)?.name || form.getAt(`files[${i}]`)?.fileName || ""}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      if (form.getAt("files")?.[i]) {
                        const newCommentFiles = form.getAt("files")
                        let deletedFiles = form.getAt("deletedFiles") || []
                        if (form.getAt("files")?.[i]?.fileId) {
                          deletedFiles = [...deletedFiles, form.getAt("files")?.[i]]
                        }
                        newCommentFiles.splice(i, 1)
                        form.setAt("files", newCommentFiles)
                        form.setAt("deletedFiles", deletedFiles)
                      }
                      setTotalFile(Math.max(0, totalFile - 1))
                    }}
                  >
                    <Remove />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell sx={{ width: "50px" }}>
                <IconButton onClick={() => setTotalFile(totalFile + 1)}>
                  <Add />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          Đóng
        </Button>
        <LoadingButton loading={form.isSubmitting()} variant="contained" onClick={handleSubmit}>
          {ActionText[action]}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
