import { ChunkedParams } from "./../../../oneportal/units/types/ChunkedParams"
import { AxiosInstance } from "axios"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"
import { RecordInfoAtLocal } from "../../units/types/RecordInfoAtLocal"
import { Records } from "../../units/types/Records"
import { ReportRecordChart } from "../../../home/units/types/Reflection"

export type RecordParams = ChunkedParams & {
  sortExpression?: string
}

export const createRecord = async (
  api: AxiosInstance,
  data: FormData
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Records/Create", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res.data
  } catch (err) {}
}

export const updateRecord = async (
  api: AxiosInstance,
  data: FormData
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Records/Update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res.data
  } catch (err) {}
}

export const getRecordDetail = async (
  api: AxiosInstance,
  recordId: number
): Promise<ChunkedResponse<Records> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Records>>(`/api/admin/Records/GetDetail/${recordId}`)
    return res.data
  } catch (err) {}
}

export const getRecordInfoAtLocal = async (
  api: AxiosInstance
): Promise<ChunkedResponse<{ status: RecordInfoAtLocal[] }> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<{ status: RecordInfoAtLocal[] }>>(`/api/admin/Records/RecordInfoAtLocal`)
    return res.data
  } catch (err) {}
}

export type ReportRecordChartParams = {
  recordTypeId?: number
  year: number
}
export const reportRecordChart = async (
  api: AxiosInstance,
  req?: ReportRecordChartParams
): Promise<ChunkedResponse<ReportRecordChart> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ReportRecordChart>>("/api/admin/Records/ReportRecordChart", null, {
      params: { ...req },
    })

    return res.data
  } catch (error) {}
}
