import { useForm } from "@corets/use-form"
import { useAsync } from "@corets/use-async"
import { useAction } from "@corets/use-action"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Paper,
  Stack,
  TextField,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { useState, useEffect } from "react"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import { updateStatusReflectForm } from "../../CreateOrUpdateReflectDialog/updateStatusReflectForm"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { getListProcessingUnit } from "../../../services/api/reflectTheScene"
import { getUsersByProcessingUnitId } from "../../../services/api/user"

interface DistributeUnitTabProps {
  reflect: ReflectResponse
  dayOfExpected?: number
  onSuccess?: () => void
}

const DEFAULT_TEXT = "Kính gửi các đơn vị phối hợp kiểm tra xử lý."

const DistributeUnitTab = ({ reflect, dayOfExpected = 0, onSuccess }: DistributeUnitTabProps) => {
  const api = useApiClient()
  const alert = useAlert()

  const processingUnits = useAsync(async () => await getListProcessingUnit(api, {}), [])?.getResult()?.content || []
  const userAction = useAction(async () => await getUsersByProcessingUnitId(api, form.getAt("ReflectionUnitId")))
  const userList = userAction?.getResult()?.content?.status || []

  const [form] = useForm(updateStatusReflectForm(api, reflect), [JSON.stringify(reflect)])
  const bind = createMuiFormBinder(form)

  const handleSubmit = async () => {
    if (!form.getAt("Reason")) {
      alert("Nội dung không được để trống", { variant: "error" })
      return
    }
    if (!form.getAt("ReflectionUnitId")) {
      alert("Đơn vị không được để trống", { variant: "error" })
      return
    }
    if (!form.getAt("UserHandlerId")) {
      return alert("Cá nhân không được để trống", { variant: "error" })
    }
    const errors = await form.validate()
    console.log(errors)
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Phân phối đơn vị thành công", { variant: "success" })
      onSuccess && onSuccess()
    } else {
      alert("Phân phối đơn vị thất bại", { variant: "error" })
      onSuccess && onSuccess()
    }
  }

  useEffect(() => {
    form.setAt("ProcessingStepCurrentId", reflect?.processingStepCurrentId)
    form.setAt("ReflectionStatusId", reflect?.reflectionStatusId)
    form.setAt("Reason", DEFAULT_TEXT)
    if (!form.getAt("ExpectedEndDateOfStep")) {
      const endDate = new Date()
      endDate.setDate(endDate.getDate() + 2)
      form.setAt("ExpectedEndDateOfStep", endDate.toISOString())
    }
  }, [reflect?.id])

  useEffect(() => {
    if (form.getAt("ReflectionUnitId")) {
      userAction.run()
    }
  }, [form.getAt("ReflectionUnitId")])

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="column" spacing={2}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                size="small"
                variant="standard"
                label="Tiêu đề"
                value={reflect?.title}
                disabled
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel>Đơn vị xử lý</InputLabel>
              <Select
                value={form.getAt("ReflectionUnitId")}
                onChange={(e) => form.setAt("ReflectionUnitId", e.target.value)}
                label="Lĩnh vực"
                variant="standard"
                fullWidth
              >
                {processingUnits?.map((action) => (
                  <MenuItem key={action.id} value={action?.id?.toString()}>
                    {action.name}
                  </MenuItem>
                ))}
                <FormHelperText />
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel>Cá nhân xử lý</InputLabel>
              <Select
                value={form.getAt("UserHandlerId")}
                onChange={(e) => form.setAt("UserHandlerId", e.target.value)}
                variant="standard"
                fullWidth
              >
                {userList?.map((item) => (
                  <MenuItem key={item.id} value={item?.id?.toString()}>
                    {item.fullName}
                  </MenuItem>
                ))}
                <FormHelperText />
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth variant="standard">
              <TextField
                size="small"
                variant="standard"
                label="Nội dung"
                minRows={4}
                multiline
                value={form.getAt("Reason")}
                onChange={(e: any) => form.setAt("Reason", e.target.value)}
                fullWidth
              />
              <FormHelperText error={!!form.getErrorsAt("Reason")} sx={{ ml: 0 }}>
                Vui lòng nhập nội dung
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                label="Hạn xử lý"
                value={form.getAt("ExpectedEndDateOfStep")}
                onChange={(value) => form.setAt("ExpectedEndDateOfStep", new Date(value).toISOString())}
                renderInput={(params) => <TextField {...params} size="small" variant="standard" fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={form.getAt("IsUrgentHandling")}
                  checked={form.getAt("IsUrgentHandling")}
                  onClick={(e: any) => {
                    form.setAt("IsUrgentHandling", e.target?.checked)
                  }}
                />
              }
              label="Xử lý khẩn cấp"
            />
          </Grid>
        </Grid>
        <Box textAlign="center">
          <Button variant="contained" disabled={form.isSubmitting()} onClick={handleSubmit}>
            Thực hiện
          </Button>
        </Box>
      </Stack>
    </Paper>
  )
}

export default DistributeUnitTab
