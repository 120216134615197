/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useEffect, useState } from "react"
import "../../../assets/style/danhmuc.css"
import { PageContentWithSearchBar } from "../../oneportal/components/PageContentWithSearchbar"
import { PagePagination } from "../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../oneportal/components/Table/TBodyRow"
import { TBodySkeleton } from "../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../oneportal/components/Table/THead"
import { THeadCell } from "../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../oneportal/components/Table/TWrapper"
import { useTableSorter } from "../../oneportal/components/Table/useTableSorter"
import { useApiClient } from "../../oneportal/hooks/useApiClient"
import { useQuery } from "../../oneportal/hooks/useQuery"
import { SearchCategoryForm } from "../components/Category/SearchProvinceForm/SearchCategoryForm"
import { getAllCategories, categoryParams, deleteCategory } from "../services/api/category"
import { SearchCategoryFormType } from "../components/Category/SearchProvinceForm/createSearchCategoryForm"
import { readImageFile } from "../../oneportal/helpers/readImageFile"
import { Checkbox } from "@mui/material"
import { Route, useHistory } from "react-router-dom"
import { DeleteIcon, EditIcon } from "../../../assets/icons"
import { links } from "../../../config/links"
import { AlertDialog } from "../../oneportal/components/Dialogs/AlertDialog"
import { useAlert } from "../../oneportal/hooks/useAlert"
import { CreateOrUpdateCategoryDialog } from "../components/Category/CreateOrUpdateCategoryDialog/CreateOrUpdateCategoryDialog"

const ListCategory = () => {
  const api = useApiClient()
  const sorter = useTableSorter()
  const history = useHistory()
  const alert = useAlert()

  const categoryAction = useAction(async () => await getAllCategories(api, categoryParams))

  const pagination = usePagePagination({
    pageCount: categoryAction.getResult()?.content.pageCount,
  })
  const [query, , pathQuery] = useQuery<categoryParams>({})
  const [categoryId, setCategoryId] = useState<number | undefined>(undefined)

  const categoryParams: categoryParams = {
    sortExpression: sorter.query.sort && `${sorter.query.sort} ${sorter.query.direction}`,
    pageIndex: pagination.query.pageIndex,
    pageSize: pagination.query.pageSize,
    name: query.keyword,
    reflectionFieldId: query.reflectionFieldId,
  }

  useEffect(() => {
    categoryAction.run()
  }, [JSON.stringify(categoryParams)])

  const handleSearchCategory = (data: SearchCategoryFormType) =>
    pathQuery({ ...data, pageSize: undefined, pageIndex: undefined })

  const handleDeleteCategory = async () => {
    const res = await deleteCategory(api, categoryId as number)
    setCategoryId(undefined)
    if (res?.content.status) {
      alert("Thành công", { variant: "success" })
      categoryAction.run()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  return (
    <PageContentWithSearchBar
      pageTitle="Danh sách chuyên mục"
      sectionClassName="qlds-page"
      searchbarClassName="list-user-form"
      searchBar={
        (!!categoryAction.getResult()?.content.items.length || !!Object.keys(query).length) && (
          <SearchCategoryForm onSubmit={handleSearchCategory} defaultValue={query} />
        )
      }
    >
      <>
        <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
          {categoryAction.isRunning() && <TBodySkeleton count={10} colSpan={8} />}
          {!!categoryAction.getResult()?.content.items.length && (
            <>
              <THead>
                <THeadRow>
                  <THeadCell>STT</THeadCell>
                  <THeadCell {...sorter.bind("name")}>Lĩnh vực</THeadCell>
                  <THeadCell {...sorter.bind("name")}>Tên chuyên mục</THeadCell>
                  <THeadCell>Ảnh đại diện</THeadCell>
                  <THeadCell>Hoạt động</THeadCell>
                  <THeadCell>Hành động</THeadCell>
                </THeadRow>
              </THead>
              <TBody>
                {!categoryAction.isRunning() &&
                  categoryAction.getResult()?.content.items.map((item, index) => (
                    <TBodyRow key={item.id}>
                      <TBodyCell>
                        {(pagination.query.pageSize || 10) *
                          (pagination.query.pageIndex ? pagination.query.pageIndex - 1 : 0) +
                          index +
                          1}
                      </TBodyCell>
                      <TBodyCell>{item.reflectionFieldName}</TBodyCell>
                      <TBodyCell>{item.name}</TBodyCell>
                      <TBodyCell>
                        <img className="icon-small" src={readImageFile(item?.categoryFilePath)} alt="" />
                      </TBodyCell>
                      <TBodyCell>
                        <Checkbox color="primary" checked />
                      </TBodyCell>
                      <TBodyCell>
                        <div className="action__user table-action d-flex align-items-center">
                          <button
                            type="button"
                            className="border-0 mr-3"
                            onClick={() => history.push(links.category.updateCategory(item.id.toString()))}
                          >
                            <EditIcon />
                          </button>
                          <button type="button" className="border-0" onClick={() => setCategoryId(item.id)}>
                            <DeleteIcon className="icon" />
                          </button>
                        </div>
                      </TBodyCell>
                    </TBodyRow>
                  ))}
              </TBody>
            </>
          )}
        </TWrapper>
        {!categoryAction.isRunning() &&
          !categoryAction.getResult()?.content.items.length &&
          !Object.keys(query).length && <TPlaceholder title="Không có dữ liệu"></TPlaceholder>}
        {!categoryAction.isRunning() &&
          !categoryAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
        {!!categoryAction.getResult()?.content.items.length && (
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageCount={categoryAction.getResult()?.content.pageCount}
            {...pagination.bind()}
            pageIndex={pagination.query.pageIndex}
          />
        )}
      </>
      <Route path={links.category.createCategory()} exact>
        <CreateOrUpdateCategoryDialog onSuccess={() => categoryAction.run()} />
      </Route>
      <Route path={links.category.updateCategory()} exact>
        <CreateOrUpdateCategoryDialog onSuccess={() => categoryAction.run()} />
      </Route>

      <AlertDialog
        title="Xóa chuyên mục"
        textContent="Bạn có muốn xoá dòng dữ liệu này?"
        open={!!categoryId}
        onClose={() => setCategoryId(undefined)}
        onAgree={handleDeleteCategory}
      />
    </PageContentWithSearchBar>
  )
}

export default ListCategory
