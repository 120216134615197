import { PageTitle } from "../../../oneportal/components/PageTitle"
import { FilterReportForm } from "../../components/FilterReportForm/FilterReportForm"

const Approve = () => {
  const uri = "/api/admin/Report/ProjectsApprovedPCCCReport"

  return (
    <section className="page-content report-page">
      <PageTitle title="Danh sách dự án, công trinhg được thẩm duyệt thiết kế, nghiệm thu về PCCC" />
      <div className="report-page__inner">
        <h5>DANH SÁCH DỰ ÁN, CÔNG TRÌNH ĐƯỢC THẨM DUYỆT THIẾT KẾ, NGHIỆM THU VỀ PCCC</h5>
        <div className="form__report-detail">
          <FilterReportForm uri={uri} />
        </div>
      </div>
    </section>
  )
}

export default Approve
