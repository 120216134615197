import { AxiosError, AxiosInstance } from "axios"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { createErrorType } from "../../../oneportal/helpers/createErrorType"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"
import { ErrorType } from "../../../oneportal/units/types/ErrorType"
import { ResetPassword } from "../../units/types/ResetPassword"
import { UserDetail } from "../../units/types/UerDetail"
import { UserManagement } from "../../units/types/UserManagement"

export type UserParams = ChunkedParams & {
  email?: string
  processingUnitId?: string
  sortExpression?: string
  isAdmin?: boolean
}

export const getAllUserManagement = async (
  api: AxiosInstance,
  params?: UserParams
): Promise<ChunkedPaginationResponse<UserManagement> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<UserManagement>>("/api/admin/User/GetUserManagement", {
      params: req,
    })

    return res.data
  } catch (err) {}
}

export const activeUser = async (
  api: AxiosInstance,
  userId: string
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/User/ActiveUser", null, {
      params: { id: userId },
    })
    return res.data
  } catch (err) {}
}

export const deActiveUser = async (
  api: AxiosInstance,
  userId: string
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/User/DeActiveUser", null, {
      params: { id: userId },
    })
    return res.data
  } catch (err) {}
}

export const resetPassword = async (
  api: AxiosInstance,
  req: ResetPassword
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/User/ResetPassword", null, {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const createUser = async (
  api: AxiosInstance,
  data: FormData,
  errorCallBack?: (error?: ErrorType) => void
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/User/CreateUser", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res.data
  } catch (err: unknown | AxiosError) {
    errorCallBack!(createErrorType((err as AxiosError).response))
  }
}

export const updateUser = async (
  api: AxiosInstance,
  data: FormData,
  errorCallBack?: (error?: ErrorType) => void
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/User/UpdateUser", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res.data
  } catch (err: unknown | AxiosError) {
    errorCallBack!(createErrorType((err as AxiosError).response))
  }
}

export const getUserDetail = async (
  api: AxiosInstance,
  userId: string
): Promise<ChunkedResponse<UserDetail> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<UserDetail>>("/api/admin/User/GetUserDetail", {
      params: { id: userId },
    })
    return res.data
  } catch (err) {}
}
