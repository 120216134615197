import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { Add, AttachFile, Remove } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import { useEffect, useState } from "react"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { useDebounce } from "../../../../oneportal/hooks/useDebounce"
import { autoSuggest } from "../../../services/api/map4d"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import { createCreateOrUpdateReflectForm } from "../../CreateOrUpdateReflectDialog/createCreateOrUpdateReflectForm"
import { isImage, isVideo, isDocument } from "../../../utils/file"
import ZoomableImage from "../../MediaView/ZoomableImage"
import ZoomableVideo from "../../MediaView/ZoomableVideo"

const BASE_API = process.env.REACT_APP_BASE_URL_API

interface UpdateTabProps {
  reflect: ReflectResponse
  onSuccess?: () => void
  fieldOptions: any[]
  levelOptions: any[]
  formOptions: any[]
  actionOptions: any[]
  categoryOptions: any[]
  statusOptions: any[]
  sourceOptions: any[]
}

const UpdateTab = (props: UpdateTabProps) => {
  const { reflect, fieldOptions, levelOptions, formOptions, categoryOptions, sourceOptions, onSuccess } = props
  const api = useApiClient()
  const alert = useAlert()

  const [form] = useForm(createCreateOrUpdateReflectForm(api, reflect), [JSON.stringify(reflect)])
  const bind = createMuiFormBinder(form)
  const [totalFile, setTotalFile] = useState(1)
  const [detectionPositionSearchTerm, setDetectionPositionSearchTerm] = useState(reflect?.detectionPosition || "")
  const detectionPositionDebounce = useDebounce(detectionPositionSearchTerm, 2000)
  const autoSuggestPoistions = useAction(async () => await autoSuggest(api, detectionPositionDebounce))

  const handleSubmit = async () => {
    const errors = await form.validate()
    console.log("errors receive:>> ", errors)

    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Tiếp nhận phản ánh thành công", { variant: "success" })
      onSuccess && onSuccess()
    } else {
      alert("Tiếp nhận phản ánh thất bại", { variant: "error" })
    }
  }

  const handleChangeFile = (files, index) => {
    console.log(files, index, form.getAt("files"))
    const fileTypeExist = {
      image: 0,
      video: 0,
      document: 0,
    }
    const currentFiles = form.getAt("files") || []
    for (let file of currentFiles) {
      if (file instanceof File && file?.type?.includes("image/")) {
        fileTypeExist.image++
      }
      if (typeof file?.filePath === "string" && isImage(file?.filePath)) {
        fileTypeExist.image++
      }
      if (file instanceof File && file?.type?.includes("video/")) {
        fileTypeExist.video++
      }
      if (typeof file?.filePath === "string" && isVideo(file?.filePath)) {
        fileTypeExist.video++
      }
      if (file instanceof File && isDocument(file?.name)) {
        fileTypeExist.document++
      }
      if (typeof file?.filePath === "string" && isDocument(file?.filePath)) {
        fileTypeExist.document++
      }
    }
    for (let file of files) {
      if (file?.type?.includes("image/") && fileTypeExist?.image >= 3) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
      if (file?.type?.includes("video/") && fileTypeExist?.video >= 1) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
      if (isDocument(file?.name) && fileTypeExist?.document >= 1) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
    }
    form.setAt(`files[${index}]`, files?.[0])
  }

  useEffect(() => {
    form.setAt("ProcessingStepCurrentId", reflect?.processingStepCurrentId)
    form.setAt("ReflectionStatusId", reflect?.reflectionStatusId)
    setTotalFile((reflect?.reflectionFilesViewDtos?.length || 0) + 1)
  }, [reflect?.id])

  useEffect(() => {
    if (detectionPositionDebounce && detectionPositionDebounce !== reflect?.detectionPosition) {
      autoSuggestPoistions.run()
    }
  }, [detectionPositionDebounce])

  useEffect(() => {
    setDetectionPositionSearchTerm(reflect?.detectionPosition || "")
  }, [reflect?.detectionPosition])

  console.log(form.getAt("files"))

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControl fullWidth>
            <TextField
              size="small"
              variant="standard"
              label="Tiêu đề"
              value={form.getAt("Title")}
              onChange={(e) => form.setAt("Title", e.target.value)}
              fullWidth
            />
            <FormHelperText {...bind.helperText("Title")} sx={{ ml: 0 }} />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            <label>
              Nội dung <span>*</span>
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={form.getAt("Content")}
              onChange={(event, editor) => {
                const data = editor.getData()
                form.setAt("Content", data)
              }}
            />
            <FormHelperText {...bind.helperText("Content")} sx={{ ml: 0 }} />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Autocomplete
            disabled={false}
            options={
              autoSuggestPoistions?.getResult()?.result || [
                {
                  address: form.getAt("DetectionPosition"),
                  location: {
                    lng: form.getAt("Longitude"),
                    lat: form.getAt("Latitude"),
                  },
                },
              ]
            }
            getOptionLabel={(option: any) => option?.address || ""}
            id="controlled"
            noOptionsText="Không có lựa chọn"
            isOptionEqualToValue={(option, value) => option === value}
            clearIcon
            loading={autoSuggestPoistions?.isRunning() || detectionPositionSearchTerm !== detectionPositionDebounce}
            value={{ address: detectionPositionSearchTerm }}
            onChange={(e, value: any) => {
              form.setAt("DetectionPosition", value?.address)
              form.setAt("Longitude", value?.location?.lng?.toString())
              form.setAt("Latitude", value?.location?.lat?.toString())
              setDetectionPositionSearchTerm(value?.address)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vị trí phát hiện"
                onChange={(e) => {
                  setDetectionPositionSearchTerm(e.target.value)
                }}
              />
            )}
          />
          <FormHelperText {...bind.helperText("DetectionPosition")} sx={{ ml: 0 }} />
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth>
            <TextField
              size="small"
              variant="standard"
              label="Kinh độ"
              disabled
              value={form.getAt("Longitude")}
              fullWidth
            />
            <FormHelperText {...bind.helperText("Longitude")} sx={{ ml: 0 }} />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth>
            <TextField
              size="small"
              variant="standard"
              label="Vĩ độ"
              disabled
              value={form.getAt("Latitude")}
              fullWidth
            />
            <FormHelperText {...bind.helperText("Latitude")} sx={{ ml: 0 }} />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Lĩnh vực</InputLabel>
            <Select
              value={form.getAt("ReflectionFieldId")}
              onChange={(e) => form.setAt("ReflectionFieldId", e.target.value)}
              label="Lĩnh vực"
              variant="standard"
              fullWidth
            >
              {fieldOptions?.map((action) => (
                <MenuItem key={action.id} value={action?.id?.toString()}>
                  {action.name}
                </MenuItem>
              ))}
              <FormHelperText />
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Chuyên mục</InputLabel>
            <Select
              value={form.getAt("CategoriesId")}
              onChange={(e) => form.setAt("CategoriesId", e.target.value)}
              label="Chuyên mục"
              variant="standard"
              fullWidth
            >
              {categoryOptions?.map((action) => (
                <MenuItem key={action.id} value={action?.id?.toString()}>
                  {action.name}
                </MenuItem>
              ))}
              <FormHelperText />
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Hình thức</InputLabel>
            <Select
              value={form.getAt("ReflectionFormId")}
              onChange={(e) => form.setAt("ReflectionFormId", e.target.value?.toString())}
              label="Hình thức"
              variant="standard"
              fullWidth
            >
              {formOptions?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
              <FormHelperText />
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Mức độ</InputLabel>
            <Select
              value={form.getAt("ReflectionLevelId")}
              onChange={(e) => form.setAt("ReflectionLevelId", e.target.value?.toString())}
              label="Mức độ"
              variant="standard"
              fullWidth
            >
              {levelOptions?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
              <FormHelperText />
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Nguồn phản ánh</InputLabel>
            <Select
              value={form.getAt("SourceOfReflectionId")}
              onChange={(e) => form.setAt("SourceOfReflectionId", e.target.value?.toString())}
              label="Nguồn phản ánh"
              variant="standard"
              fullWidth
            >
              {sourceOptions?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
              <FormHelperText />
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            <TextField
              size="small"
              variant="standard"
              label="Chi tiết nguồn"
              value={form.getAt("SourceDetails")}
              onChange={(e) => form.setAt("SourceDetails", e.target.value)}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Box className="mt-4">
            <Grid container spacing={2}>
              {reflect?.reflectionFilesViewDtos
                ?.filter((item) => !isDocument(item?.fileName))
                ?.map((item, i) => (
                  <Grid md={4} key={i} item>
                    {isImage(item?.fileName) && <ZoomableImage src={BASE_API + item.filePath} />}
                    {isVideo(item?.fileName) && (
                      <ZoomableVideo src={BASE_API + item.filePath + "#t=0.1"} style={{ width: "100%" }} />
                    )}
                  </Grid>
                ))}
            </Grid>
            {reflect?.reflectionFilesViewDtos
              ?.filter((item) => isDocument(item?.fileName))
              ?.map((item, i) => (
                <Box key={i} sx={{ mt: 1 }}>
                  <p>
                    Tài liệu:{" "}
                    <a href={BASE_API + item.filePath} target="_blank">
                      {item?.fileName}
                    </a>
                  </p>
                </Box>
              ))}
          </Box>
        </Grid>
        <Grid item md={12}>
          <InputLabel>File đính kèm</InputLabel>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>STT</TableCell>
                <TableCell>Tải file</TableCell>
                <TableCell>Xóa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...new Array(totalFile)]?.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    <Button variant="contained" component="label" startIcon={<AttachFile />}>
                      Chọn file
                      <input hidden type="file" onChange={(e) => handleChangeFile(e?.target?.files, i)} />
                    </Button>{" "}
                    {form.getAt(`files[${i}]`)?.name || form.getAt(`files[${i}]`)?.fileName || ""}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        if (form.getAt("files")?.[i]) {
                          const newReflectionFiles = form.getAt("files")
                          let deletedFiles = form.getAt("deletedFiles") || []
                          if (form.getAt("files")?.[i]?.fileId) {
                            deletedFiles = [...deletedFiles, form.getAt("files")?.[i]]
                          }
                          newReflectionFiles.splice(i, 1)
                          form.setAt("files", newReflectionFiles)
                          form.setAt("deletedFiles", deletedFiles)
                        }
                        setTotalFile(Math.max(0, totalFile - 1))
                      }}
                    >
                      <Remove />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}></TableCell>
                <TableCell sx={{ width: "50px" }}>
                  <IconButton onClick={() => setTotalFile(totalFile + 1)}>
                    <Add />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item md={12}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={form.getAt("IsPublic")}
                checked={form.getAt("IsPublic")}
                onClick={(e: any) => {
                  form.setAt("IsPublic", e.target?.checked)
                }}
              />
            }
            label="Công khai phản ánh này"
          />
        </Grid>
      </Grid>
      <Box textAlign="center">
        <Button variant="contained" disabled={form.isSubmitting()} onClick={handleSubmit}>
          Cập nhật
        </Button>
      </Box>
    </Paper>
  )
}

export default UpdateTab
