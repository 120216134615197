import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"

export type SearchReflectionFieldFormType = {
  keyword?: string
}

export const createSearchReflectionFieldForm = (
  data?: SearchReflectionFieldFormType
): ObservableForm<SearchReflectionFieldFormType, SearchReflectionFieldFormType> => {
  const form = createFormFromSchema<SearchReflectionFieldFormType>(
    object<SearchReflectionFieldFormType>({
      keyword: string()
        .optional()
        .toDefault(data?.keyword || ""),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
