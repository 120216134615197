import { PageTitle } from "../../../oneportal/components/PageTitle"
import { FilterReportForm } from "../../components/FilterReportForm/FilterReportForm"

const Monitoring = () => {
  const uri = "/api/admin/Report/MonitoringAndResultsReport"

  return (
    <section className="page-content report-page">
      <PageTitle title="Sổ theo dõi tiếp nhận và trả kết quả" />
      <div className="report-page__inner">
        <h5>SỔ THEO DÕI TIẾP NHẬN VÀ TRẢ KẾT QUẢ</h5>
        <div className="form__report-detail">
          <FilterReportForm uri={uri} />
        </div>
      </div>
    </section>
  )
}

export default Monitoring
