export const links = {
  oneportal: {
    logout: () => "/logout",
    login: () => "/login",
    //login: () => "https://admin.tracuuquyhoachsonla.vn/dang-nhap",
    //login: () => "https://admin.sonladev.cgis.asia/dang-nhap",
    changePassword: () => "/change-password",
    profile: () => "/profile",
  },
  home: {
    dashboard: () => "/",
  },
  records: {
    records: () => "/records",
    projects: () => "/projects",
    addRecord: () => "/add-record",
    addinvestor: () => "/add-record/add-investor",
    recordsList: () => "/add-record/records-list",
    editRecord: (recordId: string = ":recordId") => `/record/${recordId}`,
    changeStatus: () => "/records/change-status",
  },
  report: {
    report: () => "/report",
    monitoring: () => "/monitoring",
    project: () => "/project-approval",
    statistics: () => "/statistics-approval",
    reflectionReportProcessingUnit: () => "/thong-ke-theo-don-vi",
    reflectionReportVote: () => "/thong-ke-theo-danh-gia",
    reflectionReportReflectionField: () => "/thong-ke-theo-linh-vuc",
    reflectionReportCategory: () => "/thong-ke-theo-chuyen-muc",
    reflectionReportSource: () => "/thong-ke-theo-nguon-phan-anh",
    reflectionReportProcessingDate: () => "/thong-ke-theo-han-xu-ly",
  },
  information: {
    messageReceived: () => "/messages-received",
    messageSent: () => "/messages-sent",
    sendMessage: () => "/send-message",
    messageDetail: (messageId: string = ":messageId") => `/message/${messageId}`,
  },
  category: {
    distrists: () => "/quan-ly-quan-huyen",
    communes: () => "/quan-ly-phuong-xa",
    projectStatus: () => "/project-status",
    projectTypes: () => "/project-types",
    groupProjects: () => "/group-projects",
    recordStatus: () => "/record-status",
    createDistrict: () => "/quan-ly-quan-huyen/tao-moi",
    updateDistrict: (districtId: string = ":districtId") => `/quan-ly-quan-huyen/cap-nhat/${districtId}`,
    createCommune: () => "/quan-ly-phuong-xa/tao-moi",
    updateCommune: (communeId: string = ":communeId") => `/quan-ly-phuong-xa/cap-nhat/${communeId}`,
    categoryManagement: () => "/quan-ly-chuyen-muc",
    createCategory: () => "/quan-ly-chuyen-muc/tao-moi",
    updateCategory: (categoryId: string = ":categoryId") => `/quan-ly-chuyen-muc/cap-nhat/${categoryId}`,
    reflectionFieldsManagement: () => "/quan-ly-linh-vuc",
    createReflectionField: () => "/quan-ly-linh-vuc/tao-moi",
    updateReflectionField: (reflectionFieldId: string = ":reflectionFieldId") =>
      `/quan-ly-linh-vuc/cap-nhat/${reflectionFieldId}`,
    reflectionProcessingUnit: () => "/quan-ly-don-vi-xu-ly",
    createReflectionProcessingUnit: () => "/quan-ly-don-vi-xu-ly/tao-moi",
    updateReflectionProcessingUnit: (reflectionProcessingUnitId: string = ":reflectionProcessingUnitId") =>
      `/quan-ly-don-vi-xu-ly/cap-nhat/${reflectionProcessingUnitId}`,
    reflectionSource: () => "/quan-ly-nguon-phan-anh",
    createReflectionSource: () => "/quan-ly-nguon-phan-anh/tao-moi",
    updateReflectionSource: (reflectionSourceId: string = ":reflectionSourceId") =>
      `/quan-ly-nguon-phan-anh/cap-nhat/${reflectionSourceId}`,
  },
  system: {
    listUser: () => "/users",
    resetPassword: (userId: string = ":userId") => `/users/reset-password/${userId}`,
    createUser: () => "/users/create",
    updateUser: (userId: string = ":userId") => `/users/update/${userId}`,
    listRole: () => "/roles",
    listLog: () => "/logs",
    getDetailLog: (logId: string = ":logId") => `/logs/get/${logId}`,
    rolePermission: (roleId: string = ":roleId") => `/roles/permissions/${roleId}`,
    createRole: () => "/roles/create",
    updateRole: (roleId: string = ":roleId") => `/roles/update/${roleId}`,
  },
  reflect: {
    reflectionWaitingReceive: () => "/phan-anh-cho-tiep-nhan",
    reflectionWaitingProcess: () => "/phan-anh-cho-xu-ly",
    reflectionHaveComment: () => "/phan-anh-co-tuong-tac",
    reflectionHaveDiscuss: () => "/phan-anh-co-trao-doi",
    reflectionWaitingApprove: () => "/phan-anh-cho-xac-nhan-hoan-thanh",
    reflectionHaveProcessed: () => "/phan-anh-tung-tham-gia",
    reflectionComplete: () => "/phan-anh-da-hoan-thanh-xy-ly",
    reflectionMap: () => "/ban-do-phan-anh-hien-truong",
    createReflect: () => "/phan-anh-cho-xu-ly/tao-moi",
    updateReflect: (reflectId: string = ":reflectId") => "/phan-anh-cho-xu-ly/cap-nhat/" + reflectId,
  },
}
