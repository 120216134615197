import { useQuery } from "../../hooks/useQuery"
import { SortDirection } from "../../units/enums/SortDirection"

export type TableSorterQuery = {
  sort?: string
  direction?: SortDirection
}

export const useTableSorter = () => {
  const [query, , patchQuery] = useQuery<TableSorterQuery>({})

  const handleSort = (sort: string) => {
    if (query.sort === sort && query.direction === SortDirection.Ascending) {
      patchQuery({ direction: undefined, sort: undefined })
    } else {
      const direction =
        sort !== query.sort
          ? SortDirection.Descending
          : query.direction === SortDirection.Descending
          ? SortDirection.Ascending
          : SortDirection.Descending

      patchQuery({ sort, direction })
    }
  }

  const bind = (name: string) => ({
    name: name,
    active: query?.sort === name,
    direction: query?.sort === name ? query?.direction : SortDirection.Descending,
    onClick: () => handleSort(name),
  })

  return {
    query,
    bind,
  }
}
