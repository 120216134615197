import { Search } from "@mui/icons-material"
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { useState } from "react"
import { URGENT_OPTIONS } from "../../units/constanst"
import { ReflectFilterParams } from "../../units/types/ReflectFilterParams"

const ALL_OPTIONS = { name: "Tất cả", id: 0 }

const stepOptions = [
  { id: 2, name: "Chờ phân phối" },
  { id: 3, name: "Chờ xử lý" },
  { id: 5, name: "Chờ phê duyệt" },
]

interface ISearchForm {
  params?: ReflectFilterParams
  onSetParams: (params: any) => void
  levelOptions: Array<any>
  fieldOptions: Array<any>
  statusOptions: Array<any>
  sourceOptions: Array<any>
  categoryOptions: Array<any>
  actionOptions: Array<any>
  hideStepField?: boolean
  hideStatusField?: boolean
  onGoFirstPage?: () => void
}

const SearchForm = ({
  params,
  onSetParams,
  levelOptions,
  fieldOptions,
  statusOptions,
  sourceOptions,
  categoryOptions,
  actionOptions,
  hideStepField = false,
  hideStatusField = false,
  onGoFirstPage,
}: ISearchForm) => {
  const [searchTerm, setSearchTerm] = useState(params?.Search || "")

  const handleUpdateParam = (field: string, value: any) => {
    // onGoFirstPage && onGoFirstPage()
    onSetParams({
      ...params,
      [field]: value,
      pageIndex: 0,
    })
  }

  const handleSelectChange = (field: string) => (e: any) => {
    // onGoFirstPage && onGoFirstPage()
    onSetParams({
      ...params,
      [field]: e.target.value,
      pageIndex: 0,
    })
  }

  return (
    <form>
      <Grid container sx={{ py: "16px" }} spacing={2}>
        {!hideStepField && (
          <Grid item md={3}>
            <FormControl fullWidth variant="standard">
              <InputLabel>Thao tác</InputLabel>
              <Select
                value={params?.ProcessingStepCurrentId}
                onChange={handleSelectChange("ProcessingStepCurrentId")}
                label="Thao tác"
              >
                {[ALL_OPTIONS, ...actionOptions]?.map((action) => (
                  <MenuItem key={action.id} value={action?.id}>
                    {action.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item md={6}>
          <TextField
            label="Tìm kiếm mã phản ánh, tiều đề, tên cá nhân/tổ chức"
            variant="standard"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleUpdateParam("Search", searchTerm)
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleUpdateParam("Search", searchTerm)}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {!hideStatusField && (
          <Grid item md={3}>
            <FormControl fullWidth variant="standard">
              <InputLabel>Trạng thái</InputLabel>
              <Select
                value={params?.ProcessingStepCurrentId}
                onChange={handleSelectChange("ProcessingStepCurrentId")}
                label="Thao tác"
              >
                {[ALL_OPTIONS, ...stepOptions]?.map((action) => (
                  <MenuItem key={action.id} value={action?.id}>
                    {action.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item md={3}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Xử lý khẩn</InputLabel>
            <Select
              fullWidth
              value={params?.IsUrgentHandling}
              onChange={handleSelectChange("IsUrgentHandling")}
              variant="standard"
            >
              {URGENT_OPTIONS?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Lĩnh vực</InputLabel>
            <Select fullWidth value={params?.CategoriesId} onChange={handleSelectChange("CategoriesId")}>
              {[ALL_OPTIONS, ...categoryOptions]?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
              <MobileDatePicker
                label="Từ ngày"
                closeOnSelect
                inputFormat="DD/MM/YYYY"
                value={params?.FromDate || null}
                onChange={(value) => value && handleUpdateParam("FromDate", new Date(value).toISOString())}
                renderInput={(params) => <TextField {...params} size="small" variant="standard" fullWidth />}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="Đến ngày"
              closeOnSelect
              inputFormat="DD/MM/YYYY"
              value={params?.ToDate || null}
              onChange={(value) => value &&  handleUpdateParam("ToDate", new Date(value).toISOString())}
              renderInput={(params) => <TextField {...params} size="small" variant="standard" fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        {/* <Grid item md={3}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Mức độ</InputLabel>
            <Select
              fullWidth
              value={params?.ReflectionLevelId}
              onChange={handleSelectChange("ReflectionLevelId")}
              variant="standard"
            >
              {[ALL_OPTIONS, ...levelOptions]?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item md={3}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Chuyên mục</InputLabel>
            <Select
              fullWidth
              value={params?.ReflectionFieldId}
              onChange={handleSelectChange("ReflectionFieldId")}
              variant="standard"
            >
              {[ALL_OPTIONS, ...fieldOptions]?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Nguồn phản ánh</InputLabel>
            <Select
              fullWidth
              value={params?.SourceOfReflectionId}
              onChange={handleSelectChange("SourceOfReflectionId")}
              variant="standard"
            >
              {[ALL_OPTIONS, ...sourceOptions]?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  )
}

export default SearchForm
