import moment from "moment"

moment.locale("vi")

export const datetimeVNFormat = (date: string) => {
  if (date) return moment(date).format("DD/MM/YYYY kk:mm")
  return ""
}

export const getTimeFormat = (time: string) => {
  if (time) return moment(time).format("kk:mm")
  return ""
}

export const birthdayFormat = (date: string) => {
  if (!date) return ""
  if (date.length < 5) return date
  return moment(date).format("DD/MM/YYYY")
}

export const dateVNFormat = (date?: string) => {
  if (date) return moment(date).format("DD/MM/YYYY")
  return ""
}

export const dateVNFormatType2 = (date: string) => {
  if (date) return moment(date).format("DD-MM-YYYY")
  return ""
}

export const dateTimeVNFormatType2 = (date: string) => {
  if (date) return moment(date).format("YYYY-MM-DD kk:mm")
  return ""
}

export const formatDateToSubmit = (date: string) => moment(date).format("YYYY-MM-DD")

export const stripHtml = (html: string | any) => {
  let tmp = document.createElement("DIV")
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ""
}
