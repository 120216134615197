export const HEADER_TABLE = [
  { name: "STT", align: "center", disableToggle: true },
  { name: "Mã", align: "center" },
  { name: "Thời gian", align: "left" },
  { name: "Cá nhân/tổ chức", align: "left" },
  { name: "Điện thoại", align: "left" },
  { name: "Tiêu đề", align: "left" },
  { name: "Nội dung", align: "left" },
  { name: "Mức độ khẩn", align: "left" },
  { name: "Địa điểm", align: "left" },
  { name: "Trạng thái", align: "center" },
  { name: "Thao tác", align: "right", disableToggle: true },
]

export const HEADER_HAVE_DISCUSS_TABLE = [
  { name: "STT", align: "center", checked: true, disableToggle: true },
  { name: "Mã", align: "center", checked: true },
  { name: "Thời gian", align: "left", checked: true },
  { name: "Tiêu đề", align: "left", checked: true },
  { name: "Cá nhân/tổ chức", align: "left", checked: false },
  { name: "Điện thoại", align: "left", checked: false },
  { name: "Nội dung", align: "left", checked: false },
  { name: "Địa điểm", align: "left", checked: false },
  { name: "Đơn vị xử lý", align: "left", checked: true },
  { name: "Thao tác", align: "right", checked: true, disableToggle: true },
]

export const HEADER_HAVE_COMMENT_TABLE = [
  { name: "STT", align: "center", disableToggle: true },
  { name: "Mã", align: "center" },
  { name: "Tiêu đề", align: "left" },
  { name: "Số lượng tương tác (Đã duyệt/Chưa duyệt)", align: "left" },
  { name: "Địa điểm", align: "left" },
  { name: "Ngày phản ảnh", align: "center" },
  { name: "Thao tác", align: "center", disableToggle: true },
]

export const URGENT_OPTIONS = [
  {
    label: "Tất cả",
    id: 0,
    key: null,
  },
  {
    label: "Khẩn cấp",
    id: 1,
    key: true,
  },
  {
    label: "Bình thường",
    id: 2,
    key: false,
  },
]

export const REVIEWER_OPTIONS = [
  {
    label: "Tất cả",
    id: 0,
    key: undefined,
  },
  {
    label: "Đã trả lời",
    id: 1,
    key: true,
  },
  {
    label: "Chưa trả lời",
    id: 2,
    key: false,
  },
]

export const NEW_OPTIONS = [
  {
    label: "Tất cả",
    id: 0,
    key: 0,
  },
  {
    label: "Đã xem",
    id: 1,
    key: true,
  },
  {
    label: "Chưa xem",
    id: 2,
    key: false,
  },
]

export const ALL_OPTIONS = { name: "Tất cả", id: 0 }

export enum ProcessAction {
  Select = "ProcessAction.Select",
  Receive = 2,
  Distribute = 3,
  DistributeUnit = 4,
  Process = 5,
  Reconfirm = 6,
  Verify = 7,
  CompleteConfirmation = 8,
  Cancel = 9,
  Exchange = 10,
  Remove = 11,
  Update = 12,
  Redistribute = 13,
  Reprocess = 14,
  ReVerify = 15,
}

export const listProcessAction = [
  {
    id: ProcessAction.Receive,
    status: 2,
    step: 2,
  },
  {
    id: ProcessAction.Distribute,
    status: 2,
    step: 3,
  },
  {
    id: ProcessAction.DistributeUnit,
    status: 2,
    step: 3,
  },
  {
    id: ProcessAction.Process,
    status: 2,
    step: 5,
  },
  {
    id: ProcessAction.Reconfirm,
    status: null,
    step: null,
  },
  {
    id: ProcessAction.Verify,
    status: 4,
    step: 8,
  },
  {
    id: ProcessAction.CompleteConfirmation,
    status: 4,
    step: 8,
  },
  {
    id: ProcessAction.Cancel,
    status: 4,
    step: 9,
  },
  {
    id: ProcessAction.Exchange,
    status: null,
    step: null,
  },
  {
    id: ProcessAction.Remove,
    status: null,
    step: null,
  },
  {
    id: ProcessAction.Update,
    status: null,
    step: null,
  },
  {
    id: ProcessAction.Redistribute,
    status: 2,
    step: 2,
  },
  {
    id: ProcessAction.Reprocess,
    status: 2,
    step: 3,
  },
  {
    id: ProcessAction.ReVerify,
    status: 2,
    step: 5,
  },
]
