import { AxiosInstance } from "axios"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedLookupResponse } from "../../../oneportal/units/types/ChunkedLookupResponse"
import { ProjectGroup } from "../../units/types/ProjectGroup"

export type ProjectGroupParams = ChunkedParams & {
  nanme?: number
}

export const getAllProjectGroup = async (
  api: AxiosInstance,
  params?: ProjectGroupParams
): Promise<ChunkedPaginationResponse<ProjectGroup> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<ProjectGroup>>("/api/admin/ProjectGroup/GetAll", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getLookupProjectGroup = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/ProjectGroup/GetLookup")
    return res.data
  } catch (err) {}
}
