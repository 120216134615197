/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../../config/links"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { createGetDetailLogForm } from "./createGetDetailLogForm"
import { Select } from "../../../../oneportal/components/Select"
import { getLogDetail } from "../../../services/api/log"
import { TextField } from "@mui/material"

export type GetDetailDialog = {
  onSuccess: () => void
}

export const CreateOrUpdateDistrictDialog = (props: GetDetailDialog) => {
  const api = useApiClient()
  const history = useHistory()

  const { logId } = useParams<{
    logId: string
  }>()
  const levelOptions = [{ name: "Information", id: "Information" },{ name: "Error", id: "Error" },{ name: "Warning", id: "Warning" }]
  const logDetail = useAction(async () => await getLogDetail(api, logId))

  const [form] = useForm(createGetDetailLogForm(api, logDetail.getResult()?.content), [
    JSON.stringify(logDetail.getResult()?.content),
  ])
  const bind = createMuiFormBinder(form)
  useEffect(() => {
    logId && logDetail.run()
  }, [logId])


  const handleClose = () => history.push(links.system.listLog())

  return (
    <WideDialog maxWidth="lg" title={"Chi tiết Log"} onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-12">
            <label>
              Message
            </label>
            <TextField
            disabled
            size="small"
            variant="outlined"
            value={form.getAt("message")}
            onChange={(e) => form.setAt("message", e.target.value)}
            fullWidth
            multiline
            minRows={1}
            maxRows={6}
          />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>
              Exception
            </label>
            <TextField
            disabled
            size="small"
            variant="outlined"
            value={form.getAt("exception")}
            onChange={(e) => form.setAt("exception", e.target.value)}
            fullWidth
            multiline
            minRows={1}
            maxRows={15}
          />
          </div>
          <div className="form-group col-12 col-md-6">
            <label>Source Context</label>
            <input disabled {...bind.textField("sourceContext")}  />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>Request Path</label>
            <input disabled {...bind.textField("requestPath")}  />
          </div>

          <div className="form-group col-12 col-md-6">
            <label>
              Level 
            </label>
            <Select disabled
              options={levelOptions}
              bind={bind}
              field={"level"}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label>
            Action Name
            </label>
            <input disabled {...bind.textField("actionName")} />
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
