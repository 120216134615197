import { Chip, Tooltip, Typography } from "@mui/material"
import { cyan, green, orange, red } from "@mui/material/colors"
import { useState } from "react"
import { TBodyCell } from "../../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../../oneportal/components/Table/TBodyRow"
import { dateVNFormat, stripHtml } from "../../../oneportal/helpers/utils"
import { ReflectResponse } from "../../units/types/ReflectResponse"
import { TypographyTruncate } from "../Typography"

interface RowReflectItemProps {
  reflect: ReflectResponse
  index: number
  columnsToShow: any
  renderAction?: any
  onClickId?: any
  hideShowInfoIcon?: boolean
}

const RowReflectItem = ({ reflect, index, columnsToShow, renderAction, onClickId }: RowReflectItemProps) => {
  const [isShowInfo, setShowInfo] = useState(true)
  const renderTableBody = (item: any, index: number) => {
    const tableBody: JSX.Element[] = []

    for (let column of columnsToShow) {
      switch (column.name) {
        case "STT":
          tableBody.push(<TBodyCell align="center">{index + 1}</TBodyCell>)
          break
        case "Mã":
          tableBody.push(
            <TBodyCell align="center">
              <Tooltip title="Xem chi tiết" placement="top" arrow>
                <Typography
                  variant="body2"
                  sx={{ color: orange[700], fontWeight: 600, cursor: "pointer" }}
                  onClick={onClickId}
                >
                  {item.reflectionCode}
                </Typography>
              </Tooltip>
            </TBodyCell>
          )
          break
        case "Thời gian":
          tableBody.push(<TBodyCell align="left">{dateVNFormat(item.modifiedDate)}</TBodyCell>)
          break
        case "Cá nhân/tổ chức":
          tableBody.push(<TBodyCell align="left">{isShowInfo ? item.organizationPersonalName : "***"}</TBodyCell>)
          break
        case "Điện thoại":
          tableBody.push(<TBodyCell align="left">{isShowInfo ? item.phoneNumber : "***"}</TBodyCell>)
          break
        case "Tiêu đề":
          tableBody.push(
            <TBodyCell align="left">
              <Tooltip title="Xem chi tiết" placement="top-start" arrow>
                <Typography
                  variant="body2"
                  sx={{ color: orange[700], fontWeight: 600, cursor: "pointer" }}
                  onClick={onClickId}
                >
                  <TypographyTruncate fontWeight={600}>{stripHtml(item.title)}</TypographyTruncate>
                </Typography>
              </Tooltip>
            </TBodyCell>
          )
          break
        case "Nội dung":
          tableBody.push(
            <TBodyCell align="left">
              <Tooltip
                title={<div className="ck-editor" dangerouslySetInnerHTML={{ __html: item.content }} />}
                placement="top"
                arrow
              >
                <TypographyTruncate>{stripHtml(item.content)}</TypographyTruncate>
              </Tooltip>
            </TBodyCell>
          )
          break
        case "Mức độ khẩn":
          tableBody.push(
            <TBodyCell align="left">
              <Typography
                variant="body2"
                sx={{ color: item.reflectionLevelName === "Khẩn cấp" ? red[500] : green[500] }}
              >
                {item.reflectionLevelName}
              </Typography>
            </TBodyCell>
          )
          break
        case "Địa điểm":
          tableBody.push(
            <TBodyCell align="left">
              <Tooltip title={item.detectionPosition} placement="top" arrow>
                <TypographyTruncate>{item.detectionPosition}</TypographyTruncate>
              </Tooltip>
            </TBodyCell>
          )
          break
        case "Số lượng tương tác (Đã duyệt/Chưa duyệt)":
          tableBody.push(
            <TBodyCell align="left">
              <Typography
                variant="body2"
                sx={{ color: item?.notApprovedInteraction ? red[500] : cyan[500], fontWeight: 600 }}
              >
                {item?.approvedInteraction}/{item?.notApprovedInteraction}
              </Typography>
            </TBodyCell>
          )
          break
        case "Ngày phản ảnh":
          tableBody.push(<TBodyCell align="center">{dateVNFormat(item?.createdDate)}</TBodyCell>)
          break
        case "Đơn vị xử lý":
          tableBody.push(<TBodyCell align="left">{item.reflectionProcessingUnitName}</TBodyCell>)
          break
        case "Trạng thái":
          const statusName = {
            "1": "Mới",
            "2": "Chờ phân phối",
            "3": "Chờ xử lý",
            "5": "Chờ phê duyệt",
          }
          tableBody.push(
            <TBodyCell align="center">
              {statusName[item.processingStepCurrentId] && (
                <Chip size="small" label={statusName[item.processingStepCurrentId]} color="success" />
              )}
            </TBodyCell>
          )
          break
        case "Thao tác":
          tableBody.push(
            <TBodyCell align="right" className="reflection-table-action">
              {renderAction}
            </TBodyCell>
          )
          break

        default:
          break
      }
    }
    return tableBody
  }

  return <TBodyRow>{renderTableBody(reflect, index)}</TBodyRow>
}

export default RowReflectItem
