import { AxiosInstance } from "axios"
import { ReflectHasInteractiveParams, ReflectFilterParams } from "../../units/types/ReflectFilterParams"
import { createFormFromSchema, ObservableForm } from "@corets/form"
import { CommuneRequest } from "../../../category/units/types/communeRequest"
import { object } from "@corets/schema"

export const getListReflectProcessing = async (api: AxiosInstance, params: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/admin/ReflectTheScene/GetTheReflectTheSceneProcessing", {
      params: params,
    })
    return res.data
  } catch (err) {}
}

export const getListReflect = async (api: AxiosInstance, params: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/admin/ReflectTheScene/GetAll", {
      params: params,
    })
    return res.data
  } catch (err) {}
}

export const getListReflectHasInteractive = async (api: AxiosInstance, params: ReflectHasInteractiveParams) => {
  try {
    const res = await api.get("/api/admin/ReflectTheScene/GetReflectionHasInteractive", {
      params: params,
    })
    return res.data
  } catch (err) {}
}

export const getListCommentOfReflectInteractive = async (api: AxiosInstance, params: any) => {
  try {
    const res = await api.get("api/admin/Comment/GetAll", {
      params: params,
    })
    return res.data
  } catch (err) {}
}

export const getListAction = async (api: AxiosInstance, req?: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/cms/ReflectionProcessingSteps/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getListStatus = async (api: AxiosInstance, req?: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/admin/ReflectionStatus/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getListLevel = async (api: AxiosInstance, req?: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/admin/ReflectionLevel/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getListCategories = async (api: AxiosInstance, req?: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/cms/Categories/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getListFields = async (api: AxiosInstance, req: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/cms/ReflectionFields/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getListForm = async (api: AxiosInstance, req: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/admin/ReflectionForm/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getListSource = async (api: AxiosInstance, req: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/admin/ReflectionSource/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getListProcessingUnit = async (api: AxiosInstance, req: ReflectFilterParams) => {
  try {
    const res = await api.get("/api/admin/ReflectionProcessingUnit/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getListReflectRequest = (api: AxiosInstance, data?: any): ObservableForm<any, boolean> => {
  const form = createFormFromSchema<CommuneRequest, boolean>(object<CommuneRequest>({})).handler(async (form) => {
    const params = await form.get()

    const res: any = await api.get("/api/admin/ReflectTheScene/GetAll", {
      params: params,
    })
    if (res?.content) {
      return true
    }
    return false
  })

  return form
}

export const getListReflectByType = async (api: AxiosInstance, req?: ReflectFilterParams) => {
  console.log("req :>> ", req)
  try {
    const res = await api.get("/api/admin/ReflectTheScene/GetTheReflectTheSceneByType", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getReflectDetail = async (api: AxiosInstance, id: string): Promise<any> => {
  try {
    const res = await api.get<any>("/api/admin/ReflectTheScene/GetDetail", { params: { id } })
    return res.data
  } catch (err) {}
}

export const createReflect = async (api: AxiosInstance, formData: FormData): Promise<any> => {
  try {
    const res = await api.post<any>("/api/admin/ReflectTheScene/Create", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    return res.data
  } catch (err) {}
}

export const updateReflect = async (api: AxiosInstance, formData: FormData): Promise<any> => {
  try {
    const res = await api.put<any>("/api/admin/ReflectTheScene/Update", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    return res.data
  } catch (err) {}
}

export const reProcessReflect = async (api: AxiosInstance, formData: FormData): Promise<any> => {
  try {
    const res = await api.put<any>("/api/admin/ReflectTheScene/ReProcess", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    return res.data
  } catch (err) {}
}

export const deleteReflect = async (api: AxiosInstance, id: number): Promise<any> => {
  try {
    const res = await api.delete<any>("/api/admin/ReflectTheScene/DeletePermanent", {
      params: {
        id,
      },
    })
    return res.data
  } catch (err) {}
}

export const getSimilarReflection = async (api: AxiosInstance, params: any): Promise<any> => {
  try {
    const res = await api.get<any>("/api/admin/ReflectTheScene/GetSimilarReflection", {
      params,
    })
    return res.data
  } catch (err) {}
}

export const getManipulationOfUser = async (api: AxiosInstance, params: any): Promise<any> => {
  try {
    const res = await api.get<any>("/api/admin/User/GetManipulationOfUser", {
      params,
    })
    return res.data
  } catch (err) {}
}
