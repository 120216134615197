import { useAction } from "@corets/use-action"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Slide, Tab } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import React, { useState, useEffect } from "react"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { getReflectDetail } from "../../services/api/reflectTheScene"
import { ReflectResponse } from "../../units/types/ReflectResponse"
// import InfoTab from "./InfoTab"
import InfoTab from "../ReflectProcessDialog/InfoTab"
import ListCommentTab from "./ListCommentTab"

interface ReflectCommentDialogProps {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
  reflectId: number | string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ReflectCommentDialog = ({ open, onClose, onSuccess, reflectId }: ReflectCommentDialogProps) => {
  const theme = useTheme()
  const api = useApiClient()
  const [tabActive, setTabActive] = useState("2")

  const reflectAction = useAction(async () => await getReflectDetail(api, reflectId?.toString()))
  const reflectItem: ReflectResponse = reflectAction?.getResult()?.content

  useEffect(() => {
    if (reflectId && open) {
      setTabActive("1")
      reflectAction.run()
    }
  }, [reflectId, open])

  const tabs = [
    {
      label: "Thông tin phản ánh",
      value: "1",
      content: <InfoTab reflect={reflectItem} />,
    },
    {
      label: "Danh sách tương tác",
      value: "2",
      content: <ListCommentTab reflect={reflectItem} onSuccess={onSuccess} />,
    },
  ]

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, color: "#fff" }}>
        Thông tin phản ánh và danh sách tương tác người dân
      </DialogTitle>

      <DialogContent>
        <TabContext value={tabActive}>
          <TabList
            onChange={(e, value) => setTabActive(value)}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {tabs?.map((tab) => (
              <Tab label={tab?.label} key={tab?.label} value={tab?.value} />
            ))}
          </TabList>
          {tabs?.map((tab) => (
            <TabPanel key={tab?.label} value={tab?.value}>
              {tab?.content}
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={onClose}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReflectCommentDialog
