/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { TBody } from "../../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../../oneportal/components/Table/TBodyRow"
import { THead } from "../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../oneportal/components/Table/THeadRow"
import { TWrapper } from "../../../oneportal/components/Table/TWrapper"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { getProjectDetail } from "../../services/api/projects"
import { dateVNFormat } from "../../../oneportal/helpers/utils"
import { getLookupRecordStatus, updateRecordStatus } from "../../services/api/record"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import DialogConfirm from "../../components/DialogConfirm/DialogConfirm"

const ListRecordInProject = (props) => {
  const { projectId } = props
  const [records, setRecords] = useState([] as any)
  const [openConfirrm, setOpenConfirm] = useState(false)
  const [recordId, setRecordId] = useState()
  const [statusId, setStatus] = useState()
  const [listRecordStatus, setListRecordStatus] = useState([] as any)
  const api = useApiClient()
  const alert = useAlert()
  useEffect(() => {
    getRecordStatus()
  }, [])
  useEffect(() => {
    getSingleProject(projectId)
  }, [projectId])

  const getSingleProject = async (id) => {
    try {
      const result = await getProjectDetail(api, id)
      if (result) {
        setRecords(result?.content?.records)
      }
    } catch (error) {}
  }

  const getRecordStatus = async () => {
    try {
      const results = await getLookupRecordStatus(api)

      if (results) setListRecordStatus(results.content)
    } catch (error) {}
  }

  const onChangeStatusByRow = async (e, id) => {
    setOpenConfirm(true)
    setRecordId(id)
    setStatus(e.target.value)
  }

  const updateStatus = async (e, id) => {
    try {
      const results = await updateRecordStatus(api, {
        id,
        statusId: statusId,
      })
      if (results) {
        alert("Cập nhật thành công", { variant: "success" })
        setOpenConfirm(false)
        getSingleProject(projectId)
      }
    } catch (error) {
      getSingleProject(projectId)
    }
  }

  return (
    records && (
      <>
        <TWrapper>
          <THead>
            <THeadRow>
              <THeadCell>Loại hồ sơ</THeadCell>
              <THeadCell align="center">Hình thức tiếp nhận</THeadCell>
              <THeadCell align="center">Thời gian tiếp nhận</THeadCell>
              <THeadCell align="center">Ngày hẹn trả kết quả</THeadCell>
              <THeadCell align="center">Loại văn bản trả lời</THeadCell>
              <THeadCell align="center">Số văn bản trả lời</THeadCell>
              <THeadCell align="center">Ngày văn bản trả lời</THeadCell>
              <THeadCell align="center">Trạng thái hồ sơ</THeadCell>
            </THeadRow>
          </THead>
          <TBody>
            {Array.isArray(records) &&
              records.map((item) => (
                <TBodyRow>
                  <TBodyCell align="center">{item.recordsTypeName}</TBodyCell>
                  <TBodyCell align="center">{item.receiveTypeName}</TBodyCell>
                  <TBodyCell align="center">{dateVNFormat(item.receiveDate)}</TBodyCell>
                  <TBodyCell align="center">{dateVNFormat(item.datePredictReturn)}</TBodyCell>
                  <TBodyCell align="center">{item.documentAnsTypeName}</TBodyCell>
                  <TBodyCell align="center">{item.documentAnsTypeId}</TBodyCell>
                  <TBodyCell align="center">{dateVNFormat(item.dateRealReturn)}</TBodyCell>
                  <TBodyCell align="center">
                    <select className="custom-select" onChange={(e) => onChangeStatusByRow(e, item.id)}>
                      {Array.isArray(listRecordStatus) &&
                        listRecordStatus.map((row) => {
                          if (item?.recordsStatusId?.toString() === row?.id?.toString())
                            return (
                              <option value={row.id} selected>
                                {row.name}
                              </option>
                            )
                          return <option value={row.id}>{row.name}</option>
                        })}
                    </select>
                  </TBodyCell>
                </TBodyRow>
              ))}
          </TBody>
        </TWrapper>
        {statusId && (
          <DialogConfirm
            open={openConfirrm}
            setOpen={setOpenConfirm}
            content={"Bạn có muốn cập nhật trạng thái của hồ sơ này?"}
            rowId={recordId}
            handleAccept={updateStatus}
          />
        )}
      </>
    )
  )
}

export default ListRecordInProject
