import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"

export type SearchCommuneFormType = {
  keyword?: string
  provinceId?: string
  districtId?: string
}

export const createSearchCommuneForm = (
  data?: SearchCommuneFormType
): ObservableForm<SearchCommuneFormType, SearchCommuneFormType> => {
  const form = createFormFromSchema<SearchCommuneFormType>(
    object<SearchCommuneFormType>({
      keyword: string()
        .optional()
        .toDefault(data?.keyword || ""),
      provinceId: string()
        .optional()
        .toDefault(data?.provinceId || ""),
      districtId: string()
        .optional()
        .toDefault(data?.districtId || ""),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
