import { useAction } from "@corets/use-action"
import { useAsync } from "@corets/use-async"
import { Visibility } from "@mui/icons-material"
import { Box, Chip, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { links } from "../../../../config/links"
import { PageContent } from "../../../oneportal/components/PageContent"
import { PagePagination } from "../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../../oneportal/components/Table/TBody"
import { TBodySkeleton } from "../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../oneportal/components/Table/THead"
import { TPlaceholder } from "../../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../../oneportal/components/Table/TWrapper"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { useQuery } from "../../../oneportal/hooks/useQuery"
import ActionButton from "../../components/ActionButton"
import ColumnSetting from "../../components/ColumnSetting"
import { CreateOrUpdateReflectDialog } from "../../components/CreateOrUpdateReflectDialog/CreateOrUpdateReflectDialog"
import GuideDrawer from "../../components/GuideDrawer"
import RowReflectHeader from "../../components/ListTable/RowReflectHeader"
import RowReflectItem from "../../components/ListTable/RowReflectItem"
import InfoDialog from "../../components/ReflectProcessDialog/InfoDialog"
import ReflectProcessDialog from "../../components/ReflectProcessDialog/ProcessDialog"
import SearchForm from "../../components/SearchForm"
import SimilarReflectDialog from "../../components/SemilarReflectDialog"
import {
  deleteReflect,
  getListAction,
  getListCategories,
  getListFields,
  getListLevel,
  getListReflectByType,
  getListSource,
  getListStatus,
} from "../../services/api/reflectTheScene"
import { HEADER_HAVE_DISCUSS_TABLE, URGENT_OPTIONS } from "../../units/constanst"
import { ReflectFilterParams } from "../../units/types/ReflectFilterParams"

const WaitingProcess = () => {
  const api = useApiClient()
  const history = useHistory()
  const alert = useAlert()

  const [isOpenCreatePopup, setOpenCreatePopup] = useState(false)
  const [columnsToShow, setColumnsToShow] = useState(HEADER_HAVE_DISCUSS_TABLE?.filter((item) => item?.checked))
  const [reflectToDeleteId, setReflectToDeleteId] = useState<any>(null)
  const [reflectProcessId, setReflectProcessId] = useState<any>(null)
  const [openSameReflectModal, setOpenSameReflectModal] = useState(false)
  const [openProcessReflectModal, setOpenProcessReflectModal] = useState(false)
  const [openInfoReflectModal, setOpenInfoReflectModal] = useState(false)

  const reflectAction = useAction(async () => await getListReflectByType(api, getFilterParams()))
  const reflectList = reflectAction.getResult()?.content.items || []

  const pagination = usePagePagination({
    pageCount: reflectAction.getResult()?.content.pageCount,
  })

  const [query, , pathQuery] = useQuery<ReflectFilterParams>({
    Search: "",
    Type: 2,
    // ProcessingStepCurrentId: 0,
    ReflectionStatusId: 0,
    SourceOfReflectionId: 0,
    CategoriesId: 0,
    ReflectionFieldId: 0,
    ReflectionLevelId: 0,
    IsUrgentHandling: 0,
    FromDate: "",
    ToDate: "",
    Loading: false,
  })

  const levelOptions = useAsync(async () => await getListLevel(api, {}), [])?.getResult()?.content || []
  const fieldOptions = useAsync(async () => await getListFields(api, {}), [])?.getResult()?.content || []
  const statusOptions = useAsync(async () => await getListStatus(api, {}), [])?.getResult()?.content || []
  const sourceOptions = useAsync(async () => await getListSource(api, {}), [])?.getResult()?.content?.result || []
  const categoryOptions = useAsync(async () => await getListCategories(api, {}), [])?.getResult()?.content || []
  const actionOptions = useAsync(async () => await getListAction(api, {}), [])?.getResult()?.content || []

  const getFilterParams = () => {
    const IsUrgentHandling = URGENT_OPTIONS.find((urgent) => urgent?.id === parseInt(query?.IsUrgentHandling))
    const newParams = {
      ...query,
      ProcessingStepCurrentId: query?.ProcessingStepCurrentId || undefined,
      ReflectionStatusId: query?.ReflectionStatusId || undefined,
      SourceOfReflectionId: query?.SourceOfReflectionId || undefined,
      CategoriesId: query?.CategoriesId || undefined,
      ReflectionFieldId: query?.ReflectionFieldId || undefined,
      ReflectionLevelId: query?.ReflectionLevelId || undefined,
      IsUrgentHandling: IsUrgentHandling?.key ?? undefined,
      PageIndex: pagination.query.pageIndex || 0,
      PageSize: pagination.query.pageSize || 10,
      IsDelete: false,
      IsReflectTheScenceReviewer: true,
    }
    return newParams
  }

  const refreshReflect = () => {
    pathQuery({
      ...query,
      PageIndex: 0,
      Loading: !query?.Loading,
    })
  }

  const handleDeleteReflect = async () => {
    const res = await deleteReflect(api, reflectToDeleteId as number)
    setReflectToDeleteId(null)
    if (res?.content.status) {
      alert("Thành công", { variant: "success" })
      reflectAction.run()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  useEffect(() => {
    reflectAction.run()
  }, [JSON.stringify(getFilterParams())])

  return (
    <PageContent pageTitle="Phản ánh từng tham gia" sectionClassName="user-page" wrapClassName="user__form">
      <Box justifyContent="space-between" alignItems="center" sx={{ display: "flex", paddingBottom: "16px" }}>
        <Typography>Danh sách phản ánh</Typography>
        <Stack direction="row" spacing={2}>
          <ColumnSetting onApply={(columns) => setColumnsToShow(columns)} initColumns={HEADER_HAVE_DISCUSS_TABLE} />
          <GuideDrawer />
        </Stack>
      </Box>

      <Divider light />

      <SearchForm
        params={query}
        onSetParams={pathQuery}
        fieldOptions={fieldOptions}
        levelOptions={levelOptions}
        actionOptions={actionOptions}
        categoryOptions={categoryOptions}
        statusOptions={statusOptions}
        sourceOptions={sourceOptions}
        hideStepField
        hideStatusField
      />

      <Typography variant="body2" sx={{ fontWeight: 600, pt: 1 }}>
        Tổng số <Chip size="small" label={reflectAction.getResult()?.content?.totalItemCount || 0} color="warning" />
      </Typography>

      <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
        {reflectAction.isRunning() && <TBodySkeleton count={10} colSpan={11} />}
        {!!reflectAction.getResult()?.content?.items?.length && (
          <>
            <THead>
              <RowReflectHeader columnsToShow={columnsToShow} />
            </THead>

            <TBody>
              {!reflectAction.isRunning() &&
                reflectList.map((item: any, index) => (
                  <RowReflectItem
                    reflect={item}
                    index={index}
                    columnsToShow={columnsToShow}
                    renderAction={
                      <>
                        <Tooltip title="Xem chi tiết" placement="top" arrow>
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setOpenInfoReflectModal(true)
                              setReflectProcessId(item?.id)
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                        <ActionButton
                          onClickProcess={() => {
                            setOpenProcessReflectModal(true)
                            setReflectProcessId(item?.id)
                          }}
                          onClickSame={() => {
                            setReflectProcessId(item?.id)
                            setOpenSameReflectModal(true)
                          }}
                          onCloseSame={() => setOpenSameReflectModal(false)}
                          isNew={item?.processingStepCurrentId === 1}
                        />
                      </>
                    }
                    onClickId={() => {
                      setOpenInfoReflectModal(true)
                      setReflectProcessId(item?.id)
                    }}
                  />
                ))}
            </TBody>
          </>
        )}
        {!reflectAction.isRunning() &&
          !reflectAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
      </TWrapper>

      {!!reflectAction.getResult()?.content.items.length && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageIndex={pagination.query.pageIndex}
            pageCount={reflectAction.getResult()?.content.pageCount}
            {...pagination.bind()}
          />
        </Box>
      )}

      <CreateOrUpdateReflectDialog
        open={isOpenCreatePopup}
        onClose={() => setOpenCreatePopup(false)}
        onSuccess={() => reflectAction.run()}
        fieldOptions={fieldOptions}
        levelOptions={levelOptions}
        actionOptions={actionOptions}
        categoryOptions={categoryOptions}
        statusOptions={statusOptions}
        sourceOptions={sourceOptions}
      />

      <InfoDialog
        open={openInfoReflectModal}
        handleClose={() => {
          setReflectProcessId(null)
          setOpenInfoReflectModal(false)
        }}
        reflectId={reflectProcessId}
      />

      <ReflectProcessDialog
        open={openProcessReflectModal}
        handleClose={() => {
          setReflectProcessId(null)
          setOpenProcessReflectModal(false)
        }}
        reflectId={reflectProcessId}
        fieldOptions={fieldOptions}
        levelOptions={levelOptions}
        actionOptions={actionOptions}
        categoryOptions={categoryOptions}
        statusOptions={statusOptions}
        sourceOptions={sourceOptions}
        handleRefreshReflect={() => reflectAction.run()}
      />

      <SimilarReflectDialog
        onClose={() => {
          setReflectProcessId(null)
          setOpenSameReflectModal(false)
        }}
        open={openSameReflectModal}
        reflectId={reflectProcessId}
        onOpenProcessDialog={(id: number | string) => {
          setReflectProcessId(id)
          setOpenProcessReflectModal(true)
          setOpenSameReflectModal(false)
        }}
      />
    </PageContent>
  )
}

export default WaitingProcess
