import { useState } from "react"
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography, IconButton } from "@mui/material"
import { dateVNFormat } from "../../../../oneportal/helpers/utils"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import { Map } from "@mui/icons-material"
import { isImage, isVideo, isDocument } from '../../../utils/file'
import MapDialog from "./MapDialog"
import ZoomableImage from "../../MediaView/ZoomableImage"
import ZoomableVideo from "../../MediaView/ZoomableVideo"

const BASE_API = process.env.REACT_APP_BASE_URL_API

interface ReflectInfoTabProps {
  reflect: ReflectResponse
}

const ReflectInfoTab = ({ reflect }: ReflectInfoTabProps) => {
  const [isOpenMapDialog, setOpenMapDialog] = useState(false)

  return (
    <Box>
      <Typography>Thông tin:</Typography>
      <Table
        sx={{
          width: "100%",
          td: { border: "1px solid #eee", borderCollapse: "collapse" },
          marginBottom: 1,
          'td:first-child': { maxWidth: 250, fontWeight: 500 },
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableBody>
          <TableRow>
            <TableCell>Mã phản ánh</TableCell>
            <TableCell>{reflect?.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cá nhân, tổ chức</TableCell>
            <TableCell>{reflect?.organizationPersonalName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Điện thoại</TableCell>
            <TableCell>{reflect?.phoneNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tiêu đề</TableCell>
            <TableCell>{reflect?.title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nội dung</TableCell>
            <TableCell>
              {<div className="ck-editor" dangerouslySetInnerHTML={{ __html: reflect?.content as string }} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ngày phản ánh</TableCell>
            <TableCell>{dateVNFormat(reflect?.createdDate)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Địa điểm sự kiện 
              <IconButton size="small" color="primary" onClick={() => setOpenMapDialog(true)}>
                <Map />
              </IconButton>
            </TableCell>
            <TableCell>{reflect?.detectionPosition}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Chuyên mục</TableCell>
            <TableCell>{reflect?.reflectionFieldName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Lĩnh vực</TableCell>
            <TableCell>{reflect?.categoriesName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hình thức</TableCell>
            <TableCell>{reflect?.reflectionFormName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nguồn</TableCell>
            <TableCell>{reflect?.sourceOfReflectionName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Các đơn vị tham gia xử lý</TableCell>
            <TableCell>{reflect?.reflectionProcessingUnitName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Công khai</TableCell>
            <TableCell>{reflect?.isPublic ? "Công khai" : "Không công khai"}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography>Tài liệu (hình ảnh) đính kèm:</Typography>
      <Box className="mt-4">
        <Grid container spacing={2}>
          {reflect?.reflectionFilesViewDtos?.filter((item) => !isDocument(item?.fileName) && item?.processingStepId === 1)?.map((item, i) => (
            <Grid md={4} key={i} item>
              {isImage(item?.fileName) && <ZoomableImage src={BASE_API + item.filePath} alt="" />}
              {isVideo(item?.fileName) && <ZoomableVideo src={BASE_API + item.filePath + '#t=0.1'} style={{ width: '100%' }} />}
            </Grid>
          ))}
        </Grid>
        {reflect?.reflectionFilesViewDtos?.filter((item) => isDocument(item?.fileName) && item?.processingStepId === 1)?.map((item, i) => (
          <Box key={i} sx={{ mt: 1 }}>
            <p>
              Tài liệu:{' '}
              <a href={BASE_API + item.filePath} target="_blank">{item?.fileName}</a>
            </p>
          </Box>
        ))}
      </Box>
      <MapDialog
        open={isOpenMapDialog}
        handleClose={() => setOpenMapDialog(false)}
        reflect={reflect}
      />
    </Box>
  )
}

export default ReflectInfoTab
