import clsx from "clsx"
import { ReactNode } from "react"
import { PageTitle } from "./PageTitle"

export type PageContentProps = {
  children: ReactNode
  searchBar?: ReactNode
  searchbarClassName?: string
  pageTitle: string
  sectionClassName?: string
}

export const PageContentWithSearchBar = (props: PageContentProps) => {
  const { children, sectionClassName, pageTitle, searchBar, searchbarClassName } = props
  return (
    <section className={clsx("page-content", sectionClassName)}>
      <PageTitle title={pageTitle} />
      <div className="page__title">
        <span>{pageTitle}</span>
      </div>
      {searchBar && <div className={clsx("wrap__content-page", searchbarClassName)}>{searchBar}</div>}
      {children}
    </section>
  )
}
