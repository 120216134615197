export const HEADER_TABLE = [
  { name: "STT", align: "center", disableToggle: true ,  checked: true },
  { name: "Message", align: "left",  checked: true  },
  { name: "Raise Date", align: "left",  checked: true  },
  { name: "Level", align: "center" ,  checked: true },
  { name: "Action Name", align: "left" , checked: false},
  { name: "Request Path", align: "left", checked: false},
  { name: "Source Context", align: "left", checked: false},
  { name: "Chọn", align: "center", disableToggle: true,  checked: true },
]
