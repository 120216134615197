import { PageTitle } from "../../../oneportal/components/PageTitle"
import { FilterReportForm } from "../../components/FilterReportForm/FilterReportForm"

const Statistics = () => {
  const uri = "/api/admin/Report/StatisticsAndApprovalReport"

  return (
    <section className="page-content report-page">
      <PageTitle title="Thống kê số liệu kết quả thực hiện công tác thẩm duyệt về PCCC" />
      <div className="report-page__inner">
        <h5>THỐNG KÊ SỐ LIỆU KẾT QUẢ THỰC HIỆN CÔNG TÁC THẨM DUYỆT VỀ PCCC</h5>
        <div className="form__report-detail">
          <FilterReportForm uri={uri} />
        </div>
      </div>
    </section>
  )
}

export default Statistics
