import { ReactNode } from "react"
import styled from "styled-components"
import { InboxIcon } from "../../../../assets/icons"

export type TPlaceholderProps = {
  title?: ReactNode
  icon?: ReactNode
  children?: ReactNode
  className?: string
}

export const TPlaceholder = (props: TPlaceholderProps) => {
  const { title, children, icon = <InboxIcon />, className } = props
  return (
    <Root className={className}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {title && <CustomHeading>{title}</CustomHeading>}
      <ContentWrapper>{children}</ContentWrapper>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #9a9c9c;
  font-size: 15px;
  text-align: center;
  height: 400px;
`

const IconWrapper = styled.div`
  margin-bottom: 10px;
`

const CustomHeading = styled.label`
  margin-bottom: 10px;
  align-self: center;
`
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`
