import { useForm } from "@corets/use-form"
import { Box, Button, FormControl, FormHelperText, Paper, Stack, TextField } from "@mui/material"
import { useEffect } from "react"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import { updateStatusReflectForm } from "../../CreateOrUpdateReflectDialog/updateStatusReflectForm"

interface RemoveTabProps {
  reflect: ReflectResponse
  onSuccess?: () => void
}

const RemoveTab = ({ reflect, onSuccess }: RemoveTabProps) => {
  const api = useApiClient()
  const alert = useAlert()

  const [form] = useForm(updateStatusReflectForm(api, reflect), [JSON.stringify({ ...reflect })])

  const handleSubmit = async () => {
    if (!form.getAt("Reason")) {
      alert("Nội dung không được để trống", { variant: "error" })
      return
    }
    const errors = await form.validate()
    console.log("errors remove:>> ", errors)

    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Xóa phản ánh thành công", { variant: "success" })
      onSuccess && onSuccess()
    } else {
      alert("Xóa phản ánh thất bại", { variant: "error" })
      onSuccess && onSuccess()
    }
  }

  useEffect(() => {
    form.setAt("Reason", "")
    form.setAt("IsDelete", true)
  }, [reflect?.id])

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="column" spacing={2}>
        <FormControl>
          <TextField
            size="small"
            variant="standard"
            label="Nội dung xóa"
            minRows={4}
            multiline
            value={form.getAt("Reason")}
            onChange={(e: any) => form.setAt("Reason", e.target.value)}
            fullWidth
          />
          <FormHelperText error={!form.getAt("Reason")} sx={{ ml: 0 }}>
            Vui lòng nhập nội dung xóa
          </FormHelperText>
        </FormControl>
        <Box textAlign="center">
          <Button variant="contained" disabled={form.isSubmitting()} onClick={handleSubmit}>
            Thực hiện
          </Button>
        </Box>
      </Stack>
    </Paper>
  )
}

export default RemoveTab
