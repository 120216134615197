import { AxiosInstance } from "axios"
import { CommentParams } from "../../units/types/CommentParams"
import { CommentRequest } from "../../units/types/CommentRequest"

export const getListComment = async (api: AxiosInstance, params?: CommentParams): Promise<any> => {
  try {
    const res: any = await api.get("/api/admin/Comment/GetAll", {
      params: params,
    })
    return res?.data
  } catch (error) {}
}

export const getCommentDetail = async (api: AxiosInstance, id: string): Promise<any> => {
  try {
    const res = await api.get<any>("/api/admin/Comment/GetDetail", { params: { id } })
    return res.data
  } catch (err) {}
}

export const createComment = async (api: AxiosInstance, formData: FormData): Promise<any> => {
  try {
    const res = await api.post<any>("/api/admin/Comment/Create", formData)
    return res.data
  } catch (err) {}
}

export const updateComment = async (api: AxiosInstance, formData: FormData): Promise<any> => {
  try {
    const res = await api.put<any>("/api/admin/Comment/Update", formData)
    return res.data
  } catch (err) {}
}

export const deleteComment = async (api: AxiosInstance, id: number): Promise<any> => {
  try {
    const res = await api.put<any>(
      "/api/admin/Comment/Delete",
      {},
      {
        params: {
          id,
        },
      }
    )
    return res.data
  } catch (err) {
    return Promise.reject()
  }
}
