import { AxiosInstance } from "axios"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"
import { Reflection } from "../../units/types/reflection"
import { ReflectionRequest } from "../../units/types/reflectionFieldRequest"

export type reflectionParams = ChunkedParams & {
  sortExpression?: string
  name?: string
  address?: string
  phoneNumber?: string
}

export const getAllReflection = async (
  api: AxiosInstance,
  params?: reflectionParams
): Promise<ChunkedPaginationResponse<Reflection> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<Reflection>>("/api/admin/ReflectionProcessingUnit/GetAll", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const deleteReflection = async (
  api: AxiosInstance,
  reflectionFieldId: number
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.put<ChunkedResponse<ChunkedStatusResponse>>(
      `/api/admin/ReflectionProcessingUnit/Delete?id=${reflectionFieldId}`
    )

    return res.data
  } catch (err) {}
}

export const getReflectionFieldDetail = async (
  api: AxiosInstance,
  reflectionFieldId: string
): Promise<ChunkedResponse<Reflection> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Reflection>>(
      `/api/admin/ReflectionProcessingUnit/GetDetail?id=${reflectionFieldId}`
    )
    return res.data
  } catch (err) {}
}

export const createReflection = async (
  api: AxiosInstance,
  req: ReflectionRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>(
      "/api/admin/ReflectionProcessingUnit/Create",
      req
    )
    return res.data
  } catch (err) {}
}

export const updateReflection = async (
  api: AxiosInstance,
  req: ReflectionRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.put<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/ReflectionProcessingUnit/Update", req)
    return res.data
  } catch (err) {}
}
