import { AxiosInstance } from "axios"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedLookupResponse } from "../../../oneportal/units/types/ChunkedLookupResponse"
import { ProjectType } from "../../units/types/ProjectType"

export type ProjectTypeParams = ChunkedParams & {
  nanme?: number
}

export const getAllProjectType = async (
  api: AxiosInstance,
  params?: ProjectTypeParams
): Promise<ChunkedPaginationResponse<ProjectType> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<ProjectType>>("/api/admin/ProjectType/GetAll", { params: req })
    return res.data
  } catch (err) {}
}

export const getLookupProjectType = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/ProjectType/GetLookup")
    return res.data
  } catch (err) {}
}
