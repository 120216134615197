import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { Add, AttachFile, Remove } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import { useEffect, useState } from "react"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { useDebounce } from "../../../../oneportal/hooks/useDebounce"
import { autoSuggest } from "../../../services/api/map4d"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import { updateStatusReflectForm } from "../../CreateOrUpdateReflectDialog/updateStatusReflectForm"
import { isImage, isVideo, isDocument } from "../../../utils/file"
import ZoomableImage from "../../MediaView/ZoomableImage"
import ZoomableVideo from "../../MediaView/ZoomableVideo"

const BASE_API = process.env.REACT_APP_BASE_URL_API

interface ProcessTabProps {
  reflect: ReflectResponse
  onSuccess?: () => void
}

const ProcessTab = (props: ProcessTabProps) => {
  const { reflect, onSuccess } = props
  const api = useApiClient()
  const alert = useAlert()

  const [form] = useForm(updateStatusReflectForm(api, reflect), [JSON.stringify(reflect)])
  const bind = createMuiFormBinder(form)
  const [totalFile, setTotalFile] = useState(1)
  const [detectionPositionSearchTerm, setDetectionPositionSearchTerm] = useState(reflect?.detectionPosition || "")
  const detectionPositionDebounce = useDebounce(detectionPositionSearchTerm, 2000)
  const autoSuggestPoistions = useAction(async () => await autoSuggest(api, detectionPositionDebounce))

  const handleSubmit = async () => {
    const errors = await form.validate()
    console.log("errors process:>> ", errors)

    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Xử lý phản ánh thành công", { variant: "success" })
      onSuccess && onSuccess()
    } else {
      alert("Xử lý phản ánh thất bại", { variant: "error" })
    }
  }

  const handleChangeFile = (files, index) => {
    const fileTypeExist = {
      image: 0,
      video: 0,
      document: 0,
    }
    const currentFiles = form.getAt("files") || []
    for (let file of currentFiles) {
      if (file instanceof File && file?.type?.includes("image/")) {
        fileTypeExist.image++
      }
      if (typeof file?.filePath === "string" && isImage(file?.filePath)) {
        fileTypeExist.image++
      }
      if (file instanceof File && file?.type?.includes("video/")) {
        fileTypeExist.video++
      }
      if (typeof file?.filePath === "string" && isVideo(file?.filePath)) {
        fileTypeExist.video++
      }
      if (file instanceof File && isDocument(file?.name)) {
        fileTypeExist.document++
      }
      if (typeof file?.filePath === "string" && isDocument(file?.filePath)) {
        fileTypeExist.document++
      }
    }
    for (let file of files) {
      if (file?.type?.includes("image/") && fileTypeExist?.image >= 3) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
      if (file?.type?.includes("video/") && fileTypeExist?.video >= 1) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
      if (isDocument(file?.name) && fileTypeExist?.document >= 1) {
        alert("Chỉ được đính kèm tối đa 3 ảnh, 1 video và 1 tài liệu", { variant: "error" })
        return
      }
    }
    form.setAt(`files[${index}]`, files?.[0])
  }

  useEffect(() => {
    form.setAt("ProcessingStepCurrentId", reflect?.processingStepCurrentId)
    form.setAt("ReflectionStatusId", reflect?.reflectionStatusId)
    form.setAt("files", [])
    form.setAt("Reason", "")
    setTotalFile(1)
  }, [reflect])

  useEffect(() => {
    if (detectionPositionDebounce && detectionPositionDebounce !== reflect?.detectionPosition) {
      autoSuggestPoistions.run()
    }
  }, [detectionPositionDebounce])

  useEffect(() => {
    setDetectionPositionSearchTerm(reflect?.detectionPosition || "")
  }, [reflect?.detectionPosition])

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControl fullWidth>
            <label>
              Nội dung xử lý <span>*</span>
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={""}
              onChange={(event, editor) => {
                const data = editor.getData()
                form.setAt("Reason", data)
              }}
            />
            <FormHelperText error={!!form.getErrorsAt("Reason")} sx={{ ml: 0 }}>
              Nội dung bắt buộc nhập
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Box sx={{ border: "3px solid #80cf13", p: 2 }}>
            Tài liệu (hình ảnh) công dân đính kèm
            <Grid container spacing={2}>
              {reflect?.reflectionFilesViewDtos
                ?.filter((item) => !isDocument(item?.fileName))
                ?.map((item, i) => (
                  <Grid md={4} key={i} item>
                    {isImage(item?.fileName) && <ZoomableImage src={BASE_API + item.filePath} />}
                    {isVideo(item?.fileName) && (
                      <ZoomableVideo src={BASE_API + item.filePath + "#t=0.1"} style={{ width: "100%" }} />
                    )}
                  </Grid>
                ))}
            </Grid>
            {reflect?.reflectionFilesViewDtos
              ?.filter((item) => isDocument(item?.fileName))
              ?.map((item, i) => (
                <Box key={i} sx={{ mt: 1 }}>
                  <p>
                    Tài liệu:{" "}
                    <a href={BASE_API + item.filePath} target="_blank">
                      {item?.fileName}
                    </a>
                  </p>
                </Box>
              ))}
          </Box>
        </Grid>
        <Grid item md={12}>
          <InputLabel>File đính kèm</InputLabel>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>STT</TableCell>
                <TableCell>Tải file</TableCell>
                <TableCell>Xóa</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...new Array(totalFile)]?.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    <Button variant="contained" component="label" startIcon={<AttachFile />}>
                      Chọn file
                      <input
                        hidden
                        type="file"
                        onChange={(e) => handleChangeFile(e?.target?.files, i)}
                      />
                    </Button>{" "}
                    {form.getAt(`files[${i}]`)?.name || form.getAt(`files[${i}]`)?.fileName || ""}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        if (form.getAt("files")?.[i]) {
                          const newReflectionFiles = form.getAt("files")
                          let deletedFiles = form.getAt("deletedFiles") || []
                          if (form.getAt("files")?.[i]?.fileId) {
                            deletedFiles = [...deletedFiles, form.getAt("files")?.[i]]
                          }
                          newReflectionFiles.splice(i, 1)
                          form.setAt("files", newReflectionFiles)
                          form.setAt("deletedFiles", deletedFiles)
                        }
                        setTotalFile(Math.max(0, totalFile - 1))
                      }}
                    >
                      <Remove />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}></TableCell>
                <TableCell sx={{ width: "50px" }}>
                  <IconButton onClick={() => setTotalFile(totalFile + 1)}>
                    <Add />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Box textAlign="center" sx={{ mt: 1 }}>
        <Button variant="contained" disabled={form.isSubmitting()} onClick={handleSubmit}>
          Xử lý
        </Button>
      </Box>
    </Paper>
  )
}

export default ProcessTab
