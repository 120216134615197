import { useAction } from "@corets/use-action"
import { Box, Button, Chip, Divider, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { links } from "../../../config/links"
import { Route, useHistory } from "react-router-dom"
import { PageContent } from "../../../modules/oneportal/components/PageContent"
import { PagePagination } from "../../../modules/oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../modules/oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../../modules/oneportal/components/Table/TBody"
import { TBodyCell } from "../../../modules/oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../../modules/oneportal/components/Table/TBodyRow"
import { TBodySkeleton } from "../../../modules/oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../modules//oneportal/components/Table/THead"
import { THeadCell } from "../../../modules//oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../modules//oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../../modules//oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../../modules//oneportal/components/Table/TWrapper"
import { useAlert } from "../../../modules/oneportal/hooks/useAlert"
import { useApiClient } from "../../../modules/oneportal/hooks/useApiClient"
import { useQuery } from "../../../modules/oneportal/hooks/useQuery"
import ColumnSetting from "../../../modules/reflect/components/ColumnSetting"
import GuideDrawer from "../../../modules/reflect/components/GuideDrawer"
import SearchForm from "../../../modules/system/components/Log/SearchLogForm/SearchLogForm"
import {
    getListLog,
    deleteLog
} from "../../../modules/system/services/api/log"
import { HEADER_TABLE } from "../../../modules/system/units/constanst"
import { LogFilterParams } from "../../../modules/system/units/types/LogRequest"
import { AlertDialog } from "../../oneportal/components/Dialogs/AlertDialog"
import { CreateOrUpdateDistrictDialog } from "../components/Log/GetDetailDialog/GetDetailDialog"
const WaitingProcess = () => {
  const api = useApiClient()
  const history = useHistory()
  const alert = useAlert()
  const [columnsToShow, setColumnsToShow] = useState(HEADER_TABLE.filter((item) => item?.checked))
  const [logId, setLogId] = useState<string[]>([])
  const [isOpenDialog, setOpenDialog] = useState<boolean>(false)
  const logAction = useAction(async () => await getListLog(api, getFilterParams())) 
  const pagination = usePagePagination({
    pageCount: logAction.getResult()?.content.pageCount,
  })
  const onclickDelete =function(){
    if(logId.length > 0 )
      setOpenDialog(true)
    else
      alert("Bạn chưa chọn dòng dữ liệu nào", { variant: "warning" })
  }
  const [query, , pathQuery] = useQuery<LogFilterParams>({
    Keyword: "",
    Level: "",
    startDate: "",
    endDate: ""
  })

  const levelOptions = [{ name: "Information", id: "Information" },{ name: "Error", id: "Error" },{ name: "Warning", id: "Warning" }]

  const getFilterParams = () => {
    const newParams = {
      ...query,
      PageIndex: pagination.query.pageIndex || 0,
      PageSize: pagination.query.pageSize || 10,
    }
    return newParams
  }
  const handleDeleteLog = async () => {
    const res = await deleteLog(api, logId as string[])
    if (res?.content) {
      alert("Thành công", { variant: "success" })
      logAction.run()
      setLogId([])
      unCheckAll()
    } else {
      alert("Thất bại", { variant: "error" })
    }
    setOpenDialog(false)
  }
  const refreshLog = () => {
    logAction.run()
  }

  const handleCheckbox = (e) => {
    let isChecked = e.target.checked;
    for(let i = 0; i < logAction.getResult()?.content.items.length; i++ )
    {
      let ele = document.getElementById(logAction.getResult()?.content.items[i].id) as HTMLInputElement;
      ele.checked = isChecked;
    }
    isChecked ? setLogId(logAction.getResult()?.content.items.map(item=> item.id)) : setLogId([])
  }
  const unCheckAll =() => {   
    let ele = document.getElementById("cbAll") as HTMLInputElement;
    ele.checked = false;
  }
  useEffect(() => {
    logAction.run()
  }, [JSON.stringify(getFilterParams())])

  
  const renderTableBody = (item: any, index: number) => {
    const tableBody: JSX.Element[] = []

    for (let column of columnsToShow) {
      switch (column.name) {
        case "STT":
          tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))} align="center">{index + 1}</TBodyCell>)
          break
        case "Message":
          tableBody.push(
            <TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))} align="left">
              <Typography 
              variant="body2" 
              >
                {item.message.length > 100 ? item.message.substring(0,100) : item.message}
              </Typography>
            </TBodyCell>
          )
          break
        case "Raise Date":
          tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))} 
          align="center">{item.raiseDate}</TBodyCell>)
          break
        case "Level":
          if(item.level == "Warning")
            tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))} align="center"><Chip size="small" label={item.level} color="warning" /></TBodyCell>)
          else if (item.level == "Error")
            tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))} 
            align="center"><Chip size="small" label={item.level} color="error" /></TBodyCell>)
          else if (item.level == "Information")
            tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))}
            align="center"><Chip size="small" label={item.level} color="primary" /></TBodyCell>)
          else 
            tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))}
            align="center"><Chip size="small" label={item.level} color="success" /></TBodyCell>)
          break
        case "Action Name":
          tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))}
          align="left">{item.actionName}</TBodyCell>)
          break
        case "Request Path":
            tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))}
            align="left">{item.requestPath}</TBodyCell>)
            break
        case "Source Context":
            tableBody.push(<TBodyCell onClick={() => history.push(links.system.getDetailLog(item.id.toString()))}
            align="left">{item.actionName}</TBodyCell>)
                break
        case "Chọn":
          tableBody.push(
            <TBodyCell align="center" className="reflection-table-action">
                      <input
                      id={item.id} 
                      type="checkbox"  
                      checked={item.isCheck}
                      onChange={(e) => {
                        // add to list
                        if (e.target.checked) {
                          setLogId([...logId,item.id]);
                        } else {
                          // remove from list
                          setLogId(logId.filter(x=>x!=item.id));
                        }
                      }}
                             />
            </TBodyCell>
          )
          break

        default:
          break
      }
    }
    return tableBody
  }
  const renderTHeadRow = ()=> {
    const tableTHeadRow: JSX.Element[] = []
    for (let item of columnsToShow)
    {
      if(item.name!="Chọn")
      {
        tableTHeadRow.push(<THeadCell align={"center"}>{item.name}</THeadCell>)
      }
      else
      {
        tableTHeadRow.push(<THeadCell align={"center"}><input id={"cbAll"} type={"checkbox"} onChange={handleCheckbox} ></input></THeadCell>)
      }
    }
    return tableTHeadRow
  }
  return (
    <PageContent pageTitle="Danh sách log" sectionClassName="user-page" wrapClassName="user__form">
      <Box justifyContent="space-between" alignItems="center" sx={{ display: "flex", paddingBottom: "16px" }}>
        <Typography>Danh sách log</Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" size="small" onClick={refreshLog}>
            Tải lại danh sách
          </Button>
          <Button variant="contained" size="small" color="warning" onClick={onclickDelete}>
            Xóa
          </Button>
          <ColumnSetting onApply={(columns) => setColumnsToShow(columns)} initColumns={HEADER_TABLE} />
          <GuideDrawer />
        </Stack>
      </Box>

      <Divider light />

      <SearchForm
        params={query}
        onSetParams={pathQuery}
        levelOptions = {levelOptions}
      />
      <Typography variant="body2" sx={{ fontWeight: 600, pt: 1 }}>
        Tổng số <Chip size="small" label={logAction.getResult()?.content?.totalItemCount || 0} color="warning" />
      </Typography>

      <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
        {logAction.isRunning() && <TBodySkeleton count={10} colSpan={11} />}
        {!!logAction.getResult()?.content?.items?.length && (
          <>
            <THead>
              <THeadRow>
              {renderTHeadRow()}

              </THeadRow>
            </THead>

            <TBody>
              {!logAction.isRunning() &&
                logAction
                  .getResult()
                  ?.content.items.map((item, index) => <TBodyRow >
                    {renderTableBody(item, index)}
                  </TBodyRow>)}
            </TBody>
          </>
        )}
        {!logAction.isRunning() &&
          !logAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
      </TWrapper>

      {!!logAction.getResult()?.content.items.length && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageIndex={pagination.query.pageIndex}
            pageCount={logAction.getResult()?.content.pageCount}
            {...pagination.bind()}
          />
        </Box>
      )}

    <AlertDialog
        title="Xóa log"
        textContent="Bạn có muốn xoá dòng dữ liệu này?"
        open={isOpenDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={handleDeleteLog}
      />
      <Route path={links.system.getDetailLog()} exact>
        <CreateOrUpdateDistrictDialog onSuccess={() => logAction.run()} />
      </Route>
    </PageContent>
  )
}

export default WaitingProcess
