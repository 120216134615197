/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { EditIcon, DeleteIcon } from "../../../assets/icons"
import { WPlus } from "../../../assets/images"
import { links } from "../../../config/links"
import { AlertDialog } from "../../oneportal/components/Dialogs/AlertDialog"
import { PageContentWithSearchBar } from "../../oneportal/components/PageContentWithSearchbar"
import { PagePagination } from "../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../oneportal/components/Table/TBodyRow"
import { TBodySkeleton } from "../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../oneportal/components/Table/THead"
import { THeadCell } from "../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../oneportal/components/Table/TWrapper"
import { useTableSorter } from "../../oneportal/components/Table/useTableSorter"
import { useAlert } from "../../oneportal/hooks/useAlert"
import { useApiClient } from "../../oneportal/hooks/useApiClient"
import { useQuery } from "../../oneportal/hooks/useQuery"
import "../../../assets/style/danhmuc.css"

import { dateVNFormat } from "../../oneportal/helpers/utils"
import { deleteReflectionField, getAllReflection, reflectionParams } from "../services/api/reflection"
import { SearchReflectionFieldForm } from "../components/ReflectionField/SearchReflectionFieldForm/SearchReflectionFieldForm"
import { CreateOrUpdateReflectionFieldDialog } from "../components/ReflectionField/CreateOrUpdateDistrictDialog/CreateOrUpdateReflectionFieldDialog"
import { SearchReflectionFieldFormType } from "../components/ReflectionField/SearchReflectionFieldForm/createSearchReflectionForm"

const ListReflectionField = () => {
  const api = useApiClient()
  const history = useHistory()
  const sorter = useTableSorter()
  const alert = useAlert()

  const reflectionAction = useAction(async () => await getAllReflection(api, reflectionParams))
  const [reflectionFieldId, setReflectionFieldId] = useState<number | undefined>(undefined)

  const pagination = usePagePagination({
    pageCount: reflectionAction.getResult()?.content.pageCount,
  })
  const [query, , pathQuery] = useQuery<reflectionParams>({})

  const reflectionParams: reflectionParams = {
    sortExpression: sorter.query.sort && `${sorter.query.sort} ${sorter.query.direction}`,
    pageIndex: pagination.query.pageIndex,
    pageSize: pagination.query.pageSize,
    name: query.keyword,
  }

  useEffect(() => {
    reflectionAction.run()
  }, [JSON.stringify(reflectionParams)])

  const handleSearchReflectionField = (data: SearchReflectionFieldFormType) =>
    pathQuery({ ...data, pageSize: undefined, pageIndex: undefined })

  const handleDeleteReflectionField = async () => {
    const res = await deleteReflectionField(api, reflectionFieldId as number)
    setReflectionFieldId(undefined)
    if (res?.content.status) {
      alert("Thành công", { variant: "success" })
      reflectionAction.run()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  return (
    <PageContentWithSearchBar
      pageTitle="Danh sách lĩnh vực"
      sectionClassName="qlds-page"
      searchbarClassName="list-user-form"
      searchBar={
        (!!reflectionAction.getResult()?.content.items.length || !!Object.keys(query).length) && (
          <SearchReflectionFieldForm onSubmit={handleSearchReflectionField} defaultValue={query} />
        )
      }
    >
      <>
        <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
          {reflectionAction.isRunning() && <TBodySkeleton count={10} colSpan={8} />}
          {!!reflectionAction.getResult()?.content.items.length && (
            <>
              <THead>
                <THeadRow>
                  <THeadCell>STT</THeadCell>
                  <THeadCell {...sorter.bind("name")}>Tên</THeadCell>
                  <THeadCell {...sorter.bind("modifiedDate")}>Ngày sửa</THeadCell>
                  <THeadCell {...sorter.bind("modifiedBy")}>Người sửa</THeadCell>
                  <THeadCell>Hành động</THeadCell>
                </THeadRow>
              </THead>
              <TBody>
                {!reflectionAction.isRunning() &&
                  reflectionAction.getResult()?.content.items.map((item, index) => (
                    <TBodyRow key={item.id}>
                      <TBodyCell>
                        {(pagination.query.pageSize || 10) *
                          (pagination.query.pageIndex ? pagination.query.pageIndex - 1 : 0) +
                          index +
                          1}
                      </TBodyCell>
                      <TBodyCell>{item.name}</TBodyCell>
                      <TBodyCell>{dateVNFormat(item.modifiedDate)}</TBodyCell>
                      <TBodyCell>{item.modifiedBy}</TBodyCell>
                      <TBodyCell>
                        <div className="action__user table-action d-flex align-items-center">
                          <button
                            type="button"
                            className="border-0 mr-3"
                            onClick={() => history.push(links.category.updateReflectionField(item.id.toString()))}
                          >
                            <EditIcon />
                          </button>
                          <button type="button" className="border-0" onClick={() => setReflectionFieldId(item.id)}>
                            <DeleteIcon className="icon" />
                          </button>
                        </div>
                      </TBodyCell>
                    </TBodyRow>
                  ))}
              </TBody>
            </>
          )}
        </TWrapper>
        {!reflectionAction.isRunning() &&
          !reflectionAction.getResult()?.content.items.length &&
          !Object.keys(query).length && (
            <TPlaceholder title="Không có dữ liệu">
              <button
                className="btn  btn-ct  btn-add btn-danger-ct  d-flex align-items-center"
                type="button"
                onClick={() => history.push(links.category.createReflectionField())}
              >
                Thêm lĩnh vực
                <img src={WPlus} alt="" />
              </button>
            </TPlaceholder>
          )}
        {!reflectionAction.isRunning() &&
          !reflectionAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
        {!!reflectionAction.getResult()?.content.items.length && (
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageCount={reflectionAction.getResult()?.content.pageCount}
            {...pagination.bind()}
            pageIndex={pagination.query.pageIndex}
          />
        )}
      </>
      <Route path={links.category.createReflectionField()} exact>
        <CreateOrUpdateReflectionFieldDialog onSuccess={() => reflectionAction.run()} />
      </Route>
      <Route path={links.category.updateReflectionField()} exact>
        <CreateOrUpdateReflectionFieldDialog onSuccess={() => reflectionAction.run()} />
      </Route>

      <AlertDialog
        title="Xóa lĩnh vực"
        textContent="Bạn có muốn xoá dòng dữ liệu này?"
        open={!!reflectionFieldId}
        onClose={() => setReflectionFieldId(undefined)}
        onAgree={handleDeleteReflectionField}
      />
    </PageContentWithSearchBar>
  )
}

export default ListReflectionField
