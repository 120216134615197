import { useAsync } from "@corets/use-async"
import { useForm } from "@corets/use-form"
import { useState } from "react"
import { createMuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { FormMode } from "../../../oneportal/units/enums/FormMode"
import { getRecordDetail } from "../../services/api/records"
import { createCreateOrUpdateRecordForm } from "./createCreateOrUpdateRecordForm"
import { AttachFileType, RecordForm } from "./RecordForm"

export type CreateRecordProps = {
  recordId: number
}

export const UpdateRecord = (props: CreateRecordProps) => {
  const { recordId } = props
  const api = useApiClient()
  const alert = useAlert()
  const record = useAsync(() => {
    return getRecordDetail(api, recordId)
  }, [])

  const [form] = useForm(createCreateOrUpdateRecordForm(api, FormMode.Update, record.getResult()?.content), [
    JSON.stringify(record.getResult()?.content),
  ])
  const bind = createMuiFormBinder(form)

  const [attachFile, setAttachFile] = useState<AttachFileType | undefined>(undefined)

  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()
    if (res) {
      alert("Thành công", { variant: "success" })
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  return (
    <RecordForm
      bind={bind}
      form={form}
      onSubmit={handleSubmit}
      communeIds={record.getResult()?.content.projectCommuneIds}
      districtId={record.getResult()?.content.projectsDistristId}
      positions={record.getResult()?.content.positions}
      record={record.getResult()?.content}
      attachFile={attachFile}
      setAttachFile={setAttachFile}
    />
  )
}
