/* eslint-disable eqeqeq */
import { ObservableForm } from "@corets/form"
import { AutocompleteProps, FormControlProps, FormHelperTextProps } from "@mui/material"
import { FormHTMLAttributes, InputHTMLAttributes, SelectHTMLAttributes } from "react"

export type MuiFormBinder = {
  form: () => FormHTMLAttributes<HTMLFormElement>
  formControl: (field: string) => FormControlProps
  textField: (field: string, className?: string) => InputHTMLAttributes<HTMLInputElement>
  textFieldNumber: (field: string) => InputHTMLAttributes<HTMLInputElement>
  select: (field: string) => SelectHTMLAttributes<HTMLSelectElement>
  helperText: (field: string) => FormHelperTextProps
  datePicker: (field: string) => any
  textFieldDatePicker: (field: string) => InputHTMLAttributes<HTMLInputElement>
  textFieldEditor: (field: string) => any
  autocomplete: (field: string) => Partial<AutocompleteProps<any, any, any, any>>
}

export const createMuiFormBinder = (form: ObservableForm): MuiFormBinder => ({
  form: createFormBinder(form),
  formControl: createFormControlBinder(form),
  textField: createTextFieldBinder(form),
  helperText: createHelperTextBinder(form),
  textFieldNumber: createTextFieldNumberBinder(form),
  select: createSelectBinder(form),
  datePicker: createDatePickerBinder(form),
  textFieldDatePicker: createTextFieldDatePickerBinder(form),
  textFieldEditor: createTextFieldEditor(form),
  autocomplete: createAutocompleteBinder(form),
})

const createFormBinder = (form: ObservableForm) => (): FormHTMLAttributes<HTMLFormElement> => {
  return {
    onSubmit: (e) => {
      e.stopPropagation()
      e.preventDefault()

      form.submit()
    },
  }
}

const createFormControlBinder =
  (form: ObservableForm) =>
  (field: string): FormControlProps => {
    const errors = form.getErrorsAt(field)
    return {
      error: !!(errors && errors.length),
    }
  }

const createTextFieldBinder =
  (form: ObservableForm) =>
  (field: string, className?: string): InputHTMLAttributes<HTMLInputElement> => {
    const errors = form.getErrorsAt(field)

    return {
      name: field,
      value: form.getAt(field) ?? "",
      onChange: (event) => form.setAt(field, event.target.value),
      className: `form-control ${className} ${errors && "is-invalid"}`,
    }
  }

const createTextFieldNumberBinder =
  (form: ObservableForm) =>
  (field: string): InputHTMLAttributes<HTMLInputElement> => {
    const errors = form.getErrorsAt(field)

    return {
      name: field,
      value: form.getAt(field) ?? "",
      onChange: (event) => {
        const value = event.target.value
        if (!isNaN(value as unknown as number)) form.setAt(field, value)
      },
      className: `form-control ${errors && "is-invalid"}`,
    }
  }

const createSelectBinder =
  (form: ObservableForm) =>
  (field: string): SelectHTMLAttributes<HTMLSelectElement> => {
    const errors = form.getErrorsAt(field)
    return {
      name: field,
      value: form.getAt(field) ?? "",
      onChange: (e) => form.setAt(field, e.target.value),
      className: `custom-select ${errors && "is-invalid"}`,
    }
  }

const createHelperTextBinder =
  (form: ObservableForm) =>
  (field: string): FormHelperTextProps => {
    const errors = form.getErrorsAt(field)

    return { error: !!(errors && errors.length), children: form.getErrorsAt(field) }
  }

const createDatePickerBinder = (form: ObservableForm) => (field: string) => {
  return {
    value: form.getAt(field),
    onChange: (newValue) => form.setAt(field, newValue),
  }
}

const createTextFieldDatePickerBinder =
  (form: ObservableForm) =>
  (field: string): InputHTMLAttributes<HTMLInputElement> => {
    const errors = form.getErrorsAt(field)

    return {
      name: field,
      className: `form-control ${errors && "is-invalid"}`,
    }
  }

const createTextFieldEditor = (form: ObservableForm) => (field: string) => {
  return {
    value: form.getAt(field),
    onChange: (newValue) => form.setAt(field, newValue),
  }
}

const createAutocompleteBinder =
  (form: ObservableForm) =>
  (field: string): Partial<AutocompleteProps<any, any, any, any>> => {
    return {
      value: form.getAt(field) ?? null,
      onChange: (_, value) => form.setAt(field, value),
    }
  }
