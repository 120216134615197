import { createFormFromSchema, ObservableForm } from "@corets/form"
import { array, boolean, object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { createReflect, updateReflect } from "../../services/api/reflectTheScene"
import { ReflectRequest } from "../../units/types/ReflectRequest"
import { ReflectResponse } from "../../units/types/ReflectResponse"

const fileActions = {
  DELETE: "delete",
  INSERT: "insert",
  UPDATE: "update",
}

export const updateStatusReflectForm = (
  api: AxiosInstance,
  data?: ReflectResponse
): ObservableForm<ReflectRequest, boolean> => {
  const form = createFormFromSchema<ReflectRequest, boolean>(
    object<ReflectRequest>({
      id: string()
        .optional()
        .toDefault(data?.id?.toString() || ""),
      OrganizationPersonalName: string()
        .optional()
        .toDefault(data?.organizationPersonalName?.toString() || ""),
      PhoneNumber: string()
        .optional()
        .toDefault(data?.phoneNumber?.toString() || ""),
      Title: string()
        .optional()
        .toDefault(data?.title?.toString() || ""),
      Content: string()
        .optional()
        .toDefault(data?.content?.toString() || ""),
      DetectionPosition: string()
        .optional()
        .toDefault(data?.detectionPosition?.toString() || ""),
      Longitude: string().toDefault(data?.longitude?.toString() || ""),
      Latitude: string().toDefault(data?.latitude?.toString() || ""),
      ReflectionFieldId: string()
        .numeric()
        .optional()
        .toDefault(data?.reflectionFieldId?.toString() || ""),
      CategoriesId: string()
        .numeric()
        .optional()
        .toDefault(data?.categoriesId?.toString() || ""),
      ReflectionFormId: string()
        .numeric()
        .optional()
        .toDefault(data?.reflectionFormId?.toString() || ""),
      ReflectionLevelId: string()
        .numeric()
        .optional()
        .toDefault(data?.reflectionLevelId?.toString() || ""),
      ReflectionStatusId: string()
        .numeric()
        .optional()
        .toDefault(data?.reflectionStatusId?.toString() || "1"),
      SourceOfReflectionId: string()
        .numeric()
        .optional()
        .toDefault(data?.sourceOfReflectionId?.toString() || ""),
      ProcessingStepCurrentId: string()
        .numeric()
        .optional()
        .toDefault(data?.processingStepCurrentId?.toString() || "1"),
      ReflectionUnitId: string()
        .numeric()
        .optional()
        .toDefault(data?.reflectionProcessingUnitId?.toString() || ""),
      UserHandlerId: string()
        .optional()
        .toDefault(data?.userHandlerId?.toString() || ""),
      SourceDetails: string()
        .optional()
        .toDefault(data?.sourceDetails?.toString() || ""),
      Reason: string()
        .optional()
        .toDefault(data?.reason?.toString() || ""),
      files: array()
        .optional()
        .toDefault(data?.reflectionFilesViewDtos || []),
      IsDiscuss: boolean().optional().toDefault(data?.isDiscuss),
      IsPosted: boolean()
        .optional()
        .toDefault(data?.isPosted || false),
      IsPublic: boolean()
        .optional()
        .toDefault(data?.isPublic || false),
      IsDelete: boolean()
        .optional()
        .toDefault(data?.isDelete || false),
      IsCompleted: boolean()
        .optional()
        .toDefault(data?.isCompleted || false),
      IsUrgentHandling: boolean()
        .optional()
        .toDefault(data?.isUrgentHandling || false),
      ExpectedEndDateOfStep: string()
        .optional()
        .toDefault(data?.expectedEndDateOfStep || ""),
      deletedFiles: array().optional(),
    })
  ).handler(async (form) => {
    const datas = (await form.get()) as any

    const formData = new FormData()

    let fileIndex = 0
    for (let key in datas) {
      if (Array.isArray(datas[key])) {
        const arr = datas[key]?.filter((item) => item !== undefined)
        if (key === "files") {
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].fileId) {
              formData.append(`fileInfos[${fileIndex}].Action`, fileActions.INSERT)
              formData.append(`fileInfos[${fileIndex}].File`, arr[i])
              fileIndex++
            }
          }
        } else if (key === "deletedFiles") {
          for (let i = 0; i < arr.length; i++) {
            formData.append(`fileInfos[${fileIndex}].Action`, fileActions.DELETE)
            formData.append(`fileInfos[${fileIndex}].Id`, arr[i].fileId)
            formData.append(`fileInfos[${fileIndex}].url`, arr[i].filePath)
            fileIndex++
          }
        } else {
          for (let element of arr) {
            formData.append(key, element)
          }
        }
      } else {
        formData.append(key, datas[key])
      }
    }

    if (data?.id) {
      const res = await updateReflect(api, formData)
      if (res?.content?.status) return true
    } else {
      delete datas.id
      formData.delete("id")
      const res = await createReflect(api, formData)
      if (res?.content?.status) return true
    }
    return false
  })

  return form
}
