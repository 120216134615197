import { useForm } from "@corets/use-form"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../../config/links"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { createResetPasswordForm } from "./createResetPasswordForm"

export const ResetPasswordDialog = () => {
  const history = useHistory()
  const { userId } = useParams<{
    userId: string
  }>()
  const alert = useAlert()
  const api = useApiClient()

  const [form] = useForm(createResetPasswordForm(api, userId), [userId])
  const bind = createMuiFormBinder(form)

  const handleClose = () => history.push(links.system.listUser())

  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Thành công", { variant: "success" })
      handleClose()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  return (
    <WideDialog title="Reset password" onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-12">
            <label>
              Password <span>*</span>
            </label>
            <input type="password" {...bind.textField("password")} />
            <FormHelperText {...bind.helperText("password")} />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <button
              disabled={form.isSubmitting()}
              type="button"
              onClick={handleSubmit}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              Reset
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
