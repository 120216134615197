/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect } from "react"
import { useApiClient } from "../hooks/useApiClient"
import { JWTContext } from "./JWTContext"
import { setJWTCookies } from "../helpers/setJWTCookies"
import { getCookie, setCookie } from "../helpers/cookies"
import { CookieKeys } from "../units/enums/CookieKeys"
import { useHistory, useLocation } from "react-router"
import { removeJWTCookies } from "../helpers/removeJWTCookies"
import { refreshTokenApi, removeRefreshToken } from "../services/api/account"
import { links } from "../../../config/links"
import { getJWTCookies } from "../helpers/getJWTCookies"
import { useIdle } from "../hooks/useIdle"
import { REFRESH_TOKEN_INTERVAL, TIME_TO_IDLE } from "../units/constanst"

export type JWTProviderProps = {
  children: ReactNode
}

export const JWTProvider = (props: JWTProviderProps) => {
  const { children } = props

  const api = useApiClient()
  const history = useHistory()
  const { pathname } = useLocation()
  const isIdle = useIdle({
    timeToIdle: TIME_TO_IDLE * 1000,
    inactivityEvents: [],
    activityEvents: [
      "click",
      "mousemove",
      "keydown",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "focus",
    ],
  })

  const handleLogout = async () => {
    setCookie(CookieKeys.ReturnUrl, pathname)
    // Set cookies return url based on current path
    const refreshToken = getCookie(CookieKeys.RefreshToken)
    const deviceId = getCookie(CookieKeys.DeviceId)
    // Remove cookies
    removeJWTCookies()
    // Call API clear refresh token
    await removeRefreshToken(api, { refreshToken,deviceId })
    window.location.replace(links.oneportal.login());
  }

  const handleRefreshToken = async () => {
    let { returnUrl, accessToken, refreshToken, DeviceId } = getJWTCookies()
    const res = await refreshTokenApi(api, { returnUrl, accessToken, refreshToken, DeviceId })
    if (res && res.content && res.err == null) {
      const { refreshTokens, returnUrl, token } = res.content
      // Store cookies
      setJWTCookies(refreshTokens, returnUrl, token)
    } else {
      handleLogout()
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        // Check token if have access token mean logged in
        const accessToken = getCookie(CookieKeys.AccessToken)
        console.log("aT",accessToken);
        if (accessToken) {
          try {
            // First time load page will refresh token for checking still not expire login session
            if (pathname === links.oneportal.login()) {
              history.push(links.home.dashboard())
            }
          } catch (tokenErr) {
            handleLogout()
          }
        } else {
          // If not have access token
          handleLogout()
        }
      } catch (err) {
        // If have error
        handleLogout()
      }
    }

    // Set interval for refresh after specific time
    const refreshInterval = setInterval(() => {
      handleRefreshToken()
    }, REFRESH_TOKEN_INTERVAL * 1000)

    init()

    return () => {
      clearInterval(refreshInterval)
    }
  }, [])

  // Handle idle
  useEffect(() => {
    if (isIdle) {
      setCookie(CookieKeys.ReturnUrl, pathname)
      handleLogout()
    }
  }, [isIdle])

  return (
    <JWTContext.Provider value={{ logout: handleLogout, refresh: handleRefreshToken }}>{children}</JWTContext.Provider>
  )
}
