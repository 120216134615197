import styled from "styled-components"
import { ReactNode } from "react"
import { Table, TableContainer } from "@mui/material"
import clsx from "clsx"

export type TWrapperProps = {
  children?: ReactNode
  widths?: string[]
  tableClassName?: string
  tableContainerClassName?: string
}

export const TWrapper = (props: TWrapperProps) => {
  const { widths, tableClassName, children, tableContainerClassName } = props
  return (
    <CustomContainer
      id="scrollableTable"
      className={clsx("wrap-table table-responsive", tableContainerClassName && tableContainerClassName)}
    >
      <Table className={clsx("table table-striped", tableClassName && tableClassName)}>
        {widths && (
          <colgroup>
            {widths.map((width, index) => (
              <col key={index} width={width} />
            ))}
          </colgroup>
        )}

        {children}
      </Table>
    </CustomContainer>
  )
}

const CustomContainer = styled(TableContainer)`
  overflow-x: auto;
`
