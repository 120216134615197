import { AxiosInstance } from "axios"
import { AccessHistoryParams, AccessLogParams } from "../../units/types/AccessHistoryParams"

export const getListAccessHistory = async (api: AxiosInstance, params?: AccessHistoryParams): Promise<any> => {
  try {
    const res: any = await api.get("/api/admin/AccessHistory/GetAll", {
      params: params,
    })
    return res?.data
  } catch (error) {}
}

export const getListAccessLog = async (api: AxiosInstance, params?: AccessLogParams): Promise<any> => {
  try {
    const res: any = await api.get("/api/admin/ReflectTheScene/GetOperationLog", {
      params: params,
    })
    return res?.data
  } catch (error) {}
}
