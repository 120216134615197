import { useState } from "react"
import { createPortal } from "react-dom"
import { Box, Zoom } from "@mui/material"

interface ZoomableImageProps {
  src: string
  [key: string]: any
}

const ZoomableImage = ({ src, alt = "", ...props }: ZoomableImageProps) => {
  const [isZoom, setZoom] = useState(false)

  return (
    <>
      <img src={src} alt={alt} {...props} onClick={() => setZoom(true)} />
      {isZoom && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: '36px',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 500,
          }}
          onClick={() => setZoom(false)}
        >
          <Zoom in={isZoom}>
            <img
              src={src}
              style={{
                width: 'auto',
                maxWidth: 'calc(100vw - 72px)',
                maxHeight: 'calc(100vh - 72px)',
              }}
              alt={alt}
              // onClick={(e) => e.stopPropagation()}
            />
          </Zoom>
        </Box>
      )}
    </>
  )
}

export default ZoomableImage
