import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { createDistrict, updateDistrict } from "../../../services/api/district"
import { District } from "../../../units/types/district"
import { DistrictRequest } from "../../../units/types/districtRequest"

export const createCreateOrUpdateDistrictForm = (
  api: AxiosInstance,
  data?: District
): ObservableForm<DistrictRequest, boolean> => {
  const form = createFormFromSchema<DistrictRequest, boolean>(
    object<DistrictRequest>({
      id: string()
        .optional()
        .toDefault(data?.id?.toString() || ""),
      name: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.name || ""),
      provinceId: string().required(true, "Bắt buộc").toDefault(data?.provinceId?.toString()),
    })
  ).handler(async (form) => {
    const datas = await form.get()

    if (data) {
      const res = await updateDistrict(api, datas)
      if (res?.content?.status) return true
    } else {
      delete datas.id
      const res = await createDistrict(api, datas)
      if (res?.content?.status) return true
    }
    return false
  })

  return form
}
