/* eslint-disable react-hooks/exhaustive-deps */
import { ObservableForm } from "@corets/form"
import { useEffect, useState } from "react"
import { getAllInvestor, InvestorParams } from "../../category/services/api/investor"
import { MuiFormBinder } from "../helpers/createMuiFormBinder"
import { useApiClient } from "../hooks/useApiClient"
import { SelectAsyncValueType } from "../units/types/SelectAsyncValueType"
import { SelectAsync } from "./SelectAsync/SelectAsync"

export type InvestorSelectAsyncProps = {
  form: ObservableForm
  field: string
  bind: MuiFormBinder
  data?: SelectAsyncValueType
  onClickButton?: () => void
  labelButton?: string
  hiddenButton?: boolean
  labelDefaultOption?: string
  disabled?: boolean
}
export const InvestorSelectAsync = (props: InvestorSelectAsyncProps) => {
  const { form, field, bind, data, onClickButton, labelButton, hiddenButton, labelDefaultOption, disabled } = props
  const api = useApiClient()

  const [value, setValue] = useState<SelectAsyncValueType | undefined>(undefined)

  useEffect(() => {
    setValue(data)
  }, [JSON.stringify(data)])

  const loadOptions = async (search, _, { page }) => {
    const params: InvestorParams = {
      pageIndex: page,
      keyword: search,
      sorting: 'Name ASC'
    }

    const res = await getAllInvestor(api, params)

    return {
      options:
        res?.content?.items.map((item) => {
          return {
            value: item.id,
            label: item.name,
          }
        }) || [],
      hasMore: res?.content.hasNextPage,
      additional: {
        page: page + 1,
      },
    }
  }

  const handleChange = (data: SelectAsyncValueType) => {
    setValue(data)
    form.setAt(field, data.value.toString())
  }

  return (
    <SelectAsync
      onLoadOptions={loadOptions}
      onChange={handleChange}
      placeholder="Chọn chủ đầu tư"
      value={value}
      bind={bind}
      field={field}
      isError={form.getErrorsAt(field)}
      labelButton={labelButton}
      hiddenButton={hiddenButton}
      labelDefaultOption={labelDefaultOption}
      disabled={disabled}
      onClickButton={onClickButton}
    />
  )
}
