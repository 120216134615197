import { useState } from "react"
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography, IconButton } from "@mui/material"
import { dateVNFormat } from "../../../../oneportal/helpers/utils"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import { Map } from "@mui/icons-material"
import { isImage, isVideo, isDocument } from "../../../utils/file"
import ZoomableImage from "../../MediaView/ZoomableImage"
import ZoomableVideo from "../../MediaView/ZoomableVideo"

const BASE_API = process.env.REACT_APP_BASE_URL_API

interface ReflectInfoTabProps {
  reflect: ReflectResponse
}

const ReflectInfoTab = ({ reflect }: ReflectInfoTabProps) => {
  const { reflectionHandledViewDto: handle } = reflect

  return (
    <Box>
      <Typography>Thông tin:</Typography>
      <Table
        sx={{
          width: "100%",
          td: { border: "1px solid #eee", borderCollapse: "collapse" },
          marginBottom: 1,
          'td:first-child': { maxWidth: 250, fontWeight: 500 },
        }}
        size="small"
      >
        <TableBody>
          <TableRow>
            <TableCell>Mã phản ánh</TableCell>
            <TableCell>{reflect?.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tiêu đề</TableCell>
            <TableCell>{reflect?.title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nội dung phản ánh</TableCell>
            <TableCell>
              {<div className="ck-editor" dangerouslySetInnerHTML={{ __html: reflect?.content as string }} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nội dung xử lý</TableCell>
            <TableCell>
              {<div 
                style={{ fontWeight: 500 }} 
                className="ck-editor" 
                dangerouslySetInnerHTML={{ __html: handle?.reason as string }} 
              />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cá nhân tham gia xử lý</TableCell>
            <TableCell>
              {handle?.userHandlerName} - {handle?.reflectionUnitName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ngày Xử lý</TableCell>
            <TableCell>{dateVNFormat(handle?.realDate)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography>Tài liệu (hình ảnh) đính kèm:</Typography>
      <Box className="mt-4">
        <Grid container spacing={2}>
          {reflect?.reflectionFilesViewDtos
            ?.filter((item) => !isDocument(item?.fileName) && item?.processingStepId === 5)
            ?.map((item, i) => (
              <Grid md={4} key={i} item>
                {isImage(item?.fileName) && <ZoomableImage src={BASE_API + item.filePath} />}
                {isVideo(item?.fileName) && (
                  <ZoomableVideo src={BASE_API + item.filePath + "#t=0.1"} style={{ width: "100%" }} />
                )}
              </Grid>
            ))}
        </Grid>
        {reflect?.reflectionFilesViewDtos
          ?.filter((item) => isDocument(item?.fileName) && item?.processingStepId === 5)
          ?.map((item, i) => (
            <Box key={i} sx={{ mt: 1 }}>
              <p>
                Tài liệu:{" "}
                <a href={BASE_API + item.filePath} target="_blank">
                  {item?.fileName}
                </a>
              </p>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default ReflectInfoTab
