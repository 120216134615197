import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

export const CHART_COLORS = ["#2DCC70", "#FF646C"]
export type PieChartProps = {
  labels: string[]
  datas: number[]
  chartColor?: string[]
}
export const PieChart = (props: PieChartProps) => {
  const { datas, labels, chartColor } = props

  const dataChart = {
    labels,
    datasets: [
      {
        data: datas,
        backgroundColor: chartColor || CHART_COLORS,
        borderColor: chartColor || CHART_COLORS,
        borderWidth: 1,
        innerWidth: 600,
        innerHeight: 600,
      },
    ],
  }

  const options = { plugins: { legend: { display: true } } }

  return <Pie data={dataChart} options={options} style={{ width: "50%", height: "50%" }} />
}
