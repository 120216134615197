/* eslint-disable react-hooks/exhaustive-deps */
import { PageContent } from "../../../oneportal/components/PageContent"
import { CreateRecord } from "../../components/CreateOrUpdateRecord/CreateRecord"
import { useParams } from "react-router-dom"
import { UpdateRecord } from "../../components/CreateOrUpdateRecord/UpdateRecord"

const CreateOrUpdateRecordForm = () => {
  const { recordId } = useParams<{
    recordId: string
  }>()

  return (
    <PageContent
      pageTitle={`${recordId ? "Chỉnh sửa hồ sơ" : "Thêm hồ sơ"}`}
      sectionClassName="user-page"
      wrapClassName="user__form"
    >
      {recordId ? <UpdateRecord recordId={parseInt(recordId)} /> : <CreateRecord />}
    </PageContent>
  )
}

export default CreateOrUpdateRecordForm
