import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
} from "@mui/material"
import React, { useEffect } from "react"
import { CloseModalIcon } from "../../../../assets/icons"

import { useAction } from "@corets/use-action"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import { PagePagination } from "../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../../oneportal/components/Table/TBody"
import { TBodySkeleton } from "../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../oneportal/components/Table/THeadRow"
import { TWrapper } from "../../../oneportal/components/Table/TWrapper"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { getSimilarReflection } from "../../services/api/reflectTheScene"

export type SimilarReflectDialogProps = {
  onClose?: () => void
  open: boolean
  reflectId?: string | number
  onOpenProcessDialog?: (id: string | number) => void
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SimilarReflectDialog = ({ onClose, open, reflectId, onOpenProcessDialog }) => {
  const theme = useTheme()
  const api = useApiClient()

  const reflectAction = useAction(
    async () =>
      await getSimilarReflection(api, {
        ReflectionId: reflectId?.toString(),
        PageIndex: pagination.query.pageIndex || 0,
        PageSize: pagination.query.pageSize || 10,
      })
  )

  const pagination = usePagePagination({
    pageCount: reflectAction.getResult()?.content.pageCount,
    useQueryString: false,
  })

  const listSimilarReflect = reflectAction?.getResult()?.content?.items || []

  useEffect(() => {
    if (reflectId && open) {
      reflectAction.run()
    }
  }, [reflectId, pagination.query.pageIndex, pagination.query.pageSize, open])

  const handleOpenProcessDialog = (id: string | number) => () => {
    onOpenProcessDialog(id)
  }

  return (
    <Dialog fullScreen onClose={onClose} open={open} TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, color: "#fff" }}>
        Danh sách phản ánh tương tự
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseModalIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
          <THead>
            <THeadRow>
              <THeadCell>Nội dung phản ánh</THeadCell>
              <THeadCell>Độ tương tự</THeadCell>
              <THeadCell align="center">Thao tác</THeadCell>
            </THeadRow>
          </THead>
          <TBody>
            {!reflectAction.isRunning() &&
              listSimilarReflect?.map((item, i) => (
                <THeadRow key={i}>
                  <THeadCell>{item?.content}</THeadCell>
                  <THeadCell>{item?.similarity}</THeadCell>
                  <THeadCell align="center">
                    <Button variant="contained" onClick={handleOpenProcessDialog(item.reflectionId)}>
                      Xem chi tiết
                    </Button>
                  </THeadCell>
                </THeadRow>
              ))}
          </TBody>

          {reflectAction.isRunning() && <TBodySkeleton count={10} colSpan={11} />}
        </TWrapper>

        {listSimilarReflect.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <PagePagination
              pageSize={pagination.query.pageSize}
              pageIndex={pagination.query.pageIndex}
              pageCount={reflectAction.getResult()?.content.pageCount}
              {...pagination.bind()}
            />
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={onClose}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SimilarReflectDialog
