/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../../config/links"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { createCreateOrUpdateReflectionFieldForm } from "./createCreateOrUpdateReflectionFieldForm"
import { getReflectionFieldDetail } from "../../../services/api/reflection"

export type CreateOrUpdateReflectionFieldDialogProps = {
  onSuccess: () => void
}

export const CreateOrUpdateReflectionFieldDialog = (props: CreateOrUpdateReflectionFieldDialogProps) => {
  const { onSuccess } = props
  const api = useApiClient()
  const alert = useAlert()
  const history = useHistory()

  const { reflectionFieldId } = useParams<{
    reflectionFieldId: string
  }>()

  const reflectionFieldDetail = useAction(async () => await getReflectionFieldDetail(api, reflectionFieldId))

  const [form] = useForm(
    createCreateOrUpdateReflectionFieldForm(api, reflectionFieldDetail.getResult()?.content.result),
    [JSON.stringify(reflectionFieldDetail.getResult()?.content.result)]
  )
  const bind = createMuiFormBinder(form)

  useEffect(() => {
    reflectionFieldId && reflectionFieldDetail.run()
  }, [reflectionFieldId])

  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Thành công", { variant: "success" })
      handleClose()
      onSuccess()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const handleClose = () => history.push(links.category.reflectionFieldsManagement())

  return (
    <WideDialog title={reflectionFieldId ? "Chỉnh sửa lĩnh vực" : "Thêm lĩnh vực"} onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-12">
            <label>
              Tên lĩnh vực <span>*</span>
            </label>
            <input {...bind.textField("name")} />
            <FormHelperText {...bind.helperText("name")} />
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button
              disabled={form.isSubmitting() || !form.isChanged()}
              type="button"
              onClick={handleSubmit}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {reflectionFieldId ? "Lưu thay đổi" : "Thêm"}
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
