import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { Log } from "../../../units/types/Log"


export type RcordFile = {
  fileId: number
  fileTypeAttachmentId: number
}

export const createGetDetailLogForm = (
  api: AxiosInstance,
  log?: Log,
): ObservableForm<Log, boolean> => {
  const form = createFormFromSchema<Log, boolean>(
    object<Log>({
      id: string()
        .toDefault(log?.id?.toString() || "")
        .optional(),
        message: string()
        .toDefault(log?.message?.toString() || "")
        .required(true, "Bắt buộc"),
        level: string()
        .toDefault(log?.level?.toString() || "")
        .required(true, "Bắt buộc"),
        actionName: string()
        .toDefault(log?.actionName?.toString() || "")
        .required(true, "Bắt buộc"),
        raiseDate: string()
        .date("Ngày không đúng")
        .toDefault(log?.raiseDate || "")
        .required(true, "Bắt buộc"),
        sourceContext: string()
        .toDefault(log?.sourceContext || "")
        .required(true, "Bắt buộc"),
        requestPath: string()
        .toDefault(log?.requestPath?.toString() || "")
        .required(true, "Bắt buộc"),
        exception: string()
        .toDefault(log?.exception?.toString() || "")
        .required(true, "Bắt buộc")
    })
  ).handler(async (form) => {
    const datas = form.get()
    return false
  })

  return form
}
