import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { getCookie } from "../../helpers/cookies"
import { login } from "../../services/api/account"
import { CookieKeys } from "../../units/enums/CookieKeys"
import { ChunkedResponse } from "../../units/types/ChunkedResponse"
import { LoginResponse } from "../../units/types/LoginResponse"

export type LoginFormType = {
  email: string
  password: string
}

export const createLoginForm = (api: AxiosInstance): ObservableForm<LoginFormType, ChunkedResponse<LoginResponse>> => {
  const form = createFormFromSchema<LoginFormType>(
    object<LoginFormType>({
      email: string().required(true, "Bắt buộc"),
      // .email("Email không đúng định dạng"),
      password: string().required(true, "Bắt buộc"),
    })
  ).handler(async (form) => {
    const formData = await form.get()
    const cookieReturnUrl = getCookie(CookieKeys.ReturnUrl)
    return await login(api, { ...formData, rememberMe: true, returnUrl: cookieReturnUrl })
  })

  return form
}
