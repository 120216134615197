/* eslint-disable react-hooks/exhaustive-deps */
import "../../assets/style/home.css"
import "../../assets/style/dashboard.css"
import { PageTitle } from "../oneportal/components/PageTitle"
import { useApiClient } from "../oneportal/hooks/useApiClient"
import { useAsync } from "@corets/use-async"
import {
  getDashboardByReflectionStatus,
  getDashBoardOntimeOrLateField,
  getDashBoardReflectionOntimeOrLate,
  getDashBoardSatisfactionLevelByFields,
  getDashBoardSatisfactionLevelByProcessingUnit,
  getDashBoardSatisfactionLevelOverall,
  getDashBoardStatisticByDetectedPositionPeriod,
  getDashBoardStatisticByProcessingUnit,
  getDashBoardStatisticNewReflection,
  getDashBoardStatisticOntimeOrLateByDetectedPosition,
  getDashBoardStatisticOntimeOrLateByProcessingUnit,
  getDashBoardStatisticStatusByField,
  getSatisfactionLevelByCommuneDistrictProvince,
} from "./services/home"
import { PieChart } from "./components/PieChart"
import { VerticalBarChart } from "./components/VerticalBarChart"

const Dashboard = () => {
  const api = useApiClient()

  const pageData = useAsync(async () => {
    const [
      newReflection,
      byReflectionStatus,
      reflectionOntimeOrLate,
      statisticStatusByField,
      ontimeOrLateField,
      byProcessingUnit,
      ontimeOrLateProcessingUnit,
      satisfied,
      satisfactionLevelByFields,
      satisfactionLevelByProcessingUnit,
      satisfactionLevelByCommuneDistrictProvince,
      statisticByDetectedPositionPeriod,
      statisticOntimeOrLateByDetectedPosition,
    ] = await Promise.all([
      getDashBoardStatisticNewReflection(api),
      getDashboardByReflectionStatus(api),
      getDashBoardReflectionOntimeOrLate(api),
      getDashBoardStatisticStatusByField(api),
      getDashBoardOntimeOrLateField(api),
      getDashBoardStatisticByProcessingUnit(api),
      getDashBoardStatisticOntimeOrLateByProcessingUnit(api),
      getDashBoardSatisfactionLevelOverall(api),
      getDashBoardSatisfactionLevelByFields(api),
      getDashBoardSatisfactionLevelByProcessingUnit(api),
      getSatisfactionLevelByCommuneDistrictProvince(api),
      getDashBoardStatisticByDetectedPositionPeriod(api),
      getDashBoardStatisticOntimeOrLateByDetectedPosition(api),
    ])

    return {
      newReflection,
      byReflectionStatus,
      reflectionOntimeOrLate,
      statisticStatusByField,
      ontimeOrLateField,
      byProcessingUnit,
      ontimeOrLateProcessingUnit,
      satisfied,
      satisfactionLevelByFields,
      satisfactionLevelByProcessingUnit,
      satisfactionLevelByCommuneDistrictProvince,
      statisticByDetectedPositionPeriod,
      statisticOntimeOrLateByDetectedPosition,
    }
  }, [])

  return (
    <section className="page-content">
      <PageTitle title={"Trang chủ"} />
      <div className="row">
        <div className="col-6">
          <div className="card">
            <div className="stat-widget-two card-body">
              <div className="stat-content">
                <div className="stat-text">Phản ánh mới đang chờ tiếp nhận</div>
                {pageData.getResult()?.newReflection?.content && (
                  <div className="stat-digit">
                    {pageData.getResult()?.newReflection?.content?.newReflectTheScene} (
                    {pageData.getResult()?.newReflection?.content?.percentString})
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="stat-widget-two card-body">
              <div className="stat-content">
                <div className="stat-text">Mức độ hài lòng của người dân</div>
                {pageData.getResult()?.satisfied?.content && (
                  <div className="stat-digit">
                    {pageData.getResult()?.satisfied?.content?.totalSatisfied} (
                    {pageData.getResult()?.satisfied?.content?.satisfiedPercentString})
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Tổng số phản ánh</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-6 mb-5">
              <div style={{ width: "50%", margin: "auto" }}>
                <PieChart
                  datas={[
                    pageData.getResult()?.byReflectionStatus?.content.processed || 0,
                    pageData.getResult()?.byReflectionStatus?.content.processing || 0,
                  ]}
                  labels={["Đã xử lý", "Đang xử lý"]}
                  chartColor={["#2DCC70", "#7cb5ec"]}
                />
              </div>
            </div>
            <div className="col-6">
              <div style={{ width: "50%", margin: "auto" }}>
                <PieChart
                  datas={[
                    pageData.getResult()?.reflectionOntimeOrLate?.content.onTimeRef || 0,
                    pageData.getResult()?.reflectionOntimeOrLate?.content.lateRef || 0,
                  ]}
                  labels={["Đúng hạn", "Trễ hạn"]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Tổng số phản ánh theo từng lĩnh vực</h4>
        </div>
        <div className="card-body">
          <div className="row" style={{ marginBottom: "50px" }}>
            <VerticalBarChart
              labels={pageData.getResult()?.statisticStatusByField?.content.map((item) => item.name)}
              dataFirstColumn={pageData.getResult()?.statisticStatusByField?.content.map((item) => item.processed || 0)}
              dataSecondColumn={pageData
                .getResult()
                ?.statisticStatusByField?.content.map((item) => item.processing || 0)}
              legend={["Đã xử lý", "Đang xử lý"]}
              backgroundColor={["#90ed7d", "#7cb5ec"]}
            />
          </div>
          <div className="row">
            <VerticalBarChart
              labels={pageData.getResult()?.ontimeOrLateField?.content.map((item) => item.name)}
              dataFirstColumn={pageData.getResult()?.ontimeOrLateField?.content.map((item) => item.onTimeRef || 0)}
              dataSecondColumn={pageData.getResult()?.ontimeOrLateField?.content.map((item) => item.lateRef || 0)}
              legend={["Đúng hạn", "Trễ hạn"]}
              backgroundColor={["#2DCC70", "#FF646C"]}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Tổng số phản ánh theo cơ quan xử lý</h4>
        </div>
        <div className="card-body">
          <div className="row" style={{ marginBottom: "50px" }}>
            <VerticalBarChart
              labels={pageData.getResult()?.byProcessingUnit?.content.map((item) => item.name)}
              dataFirstColumn={pageData.getResult()?.byProcessingUnit?.content.map((item) => item.processed || 0)}
              dataSecondColumn={pageData.getResult()?.byProcessingUnit?.content.map((item) => item.processing || 0)}
              legend={["Đã xử lý", "Đang xử lý"]}
              backgroundColor={["#90ed7d", "#7cb5ec"]}
            />
          </div>
          <div className="row">
            <VerticalBarChart
              labels={pageData.getResult()?.ontimeOrLateProcessingUnit?.content.map((item) => item.name)}
              dataFirstColumn={pageData
                .getResult()
                ?.ontimeOrLateProcessingUnit?.content.map((item) => item.onTimeRef || 0)}
              dataSecondColumn={pageData
                .getResult()
                ?.ontimeOrLateProcessingUnit?.content.map((item) => item.lateRef || 0)}
              legend={["Đúng hạn", "Trễ hạn"]}
              backgroundColor={["#2DCC70", "#FF646C"]}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Mức độ hài lòng của người dân theo lĩnh vực</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <VerticalBarChart
              labels={pageData.getResult()?.satisfactionLevelByFields?.content.map((item) => item.reflectionFieldName)}
              dataFirstColumn={pageData.getResult()?.satisfactionLevelByFields?.content.map((item) => item.total || 0)}
              dataSecondColumn={pageData
                .getResult()
                ?.satisfactionLevelByFields?.content.map((item) => item.totalSatisfied || 0)}
              legend={["Tổng", "Hài lòng"]}
              backgroundColor={["#7cb5ec", "#90ed7d"]}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Mức độ hài lòng của người dân theo cơ quan xử lý</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <VerticalBarChart
              labels={pageData.getResult()?.satisfactionLevelByProcessingUnit?.content.map((item) => item.unitName)}
              dataFirstColumn={pageData
                .getResult()
                ?.satisfactionLevelByProcessingUnit?.content.map((item) => item.total || 0)}
              dataSecondColumn={pageData
                .getResult()
                ?.satisfactionLevelByProcessingUnit?.content.map((item) => item.totalSatisfied || 0)}
              legend={["Tổng", "Hài lòng"]}
              backgroundColor={["#7cb5ec", "#90ed7d"]}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Mức độ hài lòng của người dân theo quận/huyện, phường/xã</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <VerticalBarChart
              labels={pageData
                .getResult()
                ?.satisfactionLevelByCommuneDistrictProvince?.content.map(
                  (item) => `${item.commune.name}, ${item.district.name}`
                )}
              dataFirstColumn={pageData
                .getResult()
                ?.satisfactionLevelByCommuneDistrictProvince?.content.map((item) => item.statistics.total || 0)}
              dataSecondColumn={pageData
                .getResult()
                ?.satisfactionLevelByCommuneDistrictProvince?.content.map(
                  (item) => item.statistics.totalSatisfied || 0
                )}
              legend={["Tổng", "Hài lòng"]}
              backgroundColor={["#7cb5ec", "#90ed7d"]}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Tổng số phản ánh theo vị trí</h4>
        </div>
        <div className="card-body">
          <div className="row" style={{ marginBottom: "50px" }}>
            <VerticalBarChart
              labels={pageData
                .getResult()
                ?.statisticByDetectedPositionPeriod?.content.map(
                  (item) => `${item.commune.name}, ${item.district.name}`
                )}
              dataFirstColumn={pageData
                .getResult()
                ?.statisticByDetectedPositionPeriod?.content.map((item) => item.statistics.processing || 0)}
              dataSecondColumn={pageData
                .getResult()
                ?.statisticByDetectedPositionPeriod?.content.map((item) => item.statistics.processed || 0)}
              legend={["Đã xử lý", "Đang xử lý"]}
              backgroundColor={["#90ed7d", "#7cb5ec"]}
            />
          </div>
          <div className="row">
            <VerticalBarChart
              labels={pageData
                .getResult()
                ?.statisticOntimeOrLateByDetectedPosition?.content.map(
                  (item) => `${item.commune.name}, ${item.district.name}`
                )}
              dataFirstColumn={pageData
                .getResult()
                ?.statisticOntimeOrLateByDetectedPosition?.content.map((item) => item.statistics.onTimeRef || 0)}
              dataSecondColumn={pageData
                .getResult()
                ?.statisticOntimeOrLateByDetectedPosition?.content.map((item) => item.statistics.lateRef || 0)}
              legend={["Đúng hạn", "Trễ hạn"]}
              backgroundColor={["#2DCC70", "#FF646C"]}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Dashboard
