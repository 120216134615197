import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"

export type SearchReflectionSourceFormType = {
  keyword?: string
  address?: string
  phoneNumber?: string
}

export const createSearchReflectionSourceForm = (
  data?: SearchReflectionSourceFormType
): ObservableForm<SearchReflectionSourceFormType, SearchReflectionSourceFormType> => {
  const form = createFormFromSchema<SearchReflectionSourceFormType>(
    object<SearchReflectionSourceFormType>({
      keyword: string()
        .optional()
        .toDefault(data?.keyword || ""),
      address: string()
        .optional()
        .toDefault(data?.address || ""),
      phoneNumber: string()
        .optional()
        .toDefault(data?.phoneNumber || ""),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
