import { AxiosInstance } from "axios"
import { ChangePasswordRequest } from "../../units/types/ChangePasswordRequest"
import { ChunkedResponse } from "../../units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../units/types/ChunkedStatusResponse"
import { CurrentUser } from "../../units/types/CurrentUser"
import { LoginRequest } from "../../units/types/LoginRequest"
import { LoginResponse } from "../../units/types/LoginResponse"
import { RefreshTokenRequest } from "../../units/types/RefreshToken"
import { RefreshTokenResponse } from "../../units/types/RefreshTokenResponse"
import { RemoveRefreshTokenRequest } from "../../units/types/RemoveRefreshToken"
export const login = async (
  api: AxiosInstance,
  req: LoginRequest
): Promise<ChunkedResponse<LoginResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<LoginResponse>>("/api/admin/account/Login", req)
    return res.data
  } catch (err) {}
}

export const removeRefreshToken = async (
  api: AxiosInstance,
  req: RemoveRefreshTokenRequest 
  ): Promise<any> => {
  try {
    return await api.post<any>("/api/Account/RemoveRefreshToken", req)
  } catch (err) {}
}

export const refreshTokenApi = async (
  api: AxiosInstance,
  req: RefreshTokenRequest
): Promise<ChunkedResponse<RefreshTokenResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<RefreshTokenResponse>>("/api/Account/RefreshToken", req)
    return res.data
  } catch (err) {}
}

export const changePassword = async (
  api: AxiosInstance,
  req?: ChangePasswordRequest
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/Account/ChangePassword", req)
    return res.data
  } catch (err) {}
}

export const getUserAccountDetail = async (api: AxiosInstance): Promise<ChunkedResponse<CurrentUser> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<CurrentUser>>("/api/admin/account/GetUserAccountDetail")
    return res.data
  } catch (err) {}
}

export const updateUserAccount = async (
  api: AxiosInstance,
  data: FormData
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/account/UpdateUserAccount", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return res.data
  } catch (err) {}
}
