/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { useAsync } from "@corets/use-async"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../../config/links"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getCommuneDetail } from "../../../services/api/commune"
import { createCreateOrUpdateCommuneForm } from "./createCreateOrUpdateCommuneForm"
import { getLookupProvince, getLookupDistrict } from "../../../../oneportal/services/api/administrative"
import { Select } from "../../../../oneportal/components/Select"

export type CreateOrUpdateCommuneDialogProps = {
  onSuccess: () => void
}

export const CreateOrUpdateCommuneDialog = (props: CreateOrUpdateCommuneDialogProps) => {
  const { onSuccess } = props
  const api = useApiClient()
  const alert = useAlert()
  const history = useHistory()

  const { communeId } = useParams<{
    communeId: string
  }>()

  const communeDetail = useAction(async () => await getCommuneDetail(api, communeId))
  const provinceList = useAsync(async () => await getLookupProvince(api))

  const [form] = useForm(createCreateOrUpdateCommuneForm(api, communeDetail.getResult()?.content), [
    JSON.stringify(communeDetail.getResult()?.content),
  ])
  const districtList = useAsync(
    async () => form.getAt("provinceId") && (await getLookupDistrict(api, form.getAt("provinceId"))),
    [form.getAt("provinceId")]
  )
  const bind = createMuiFormBinder(form)

  useEffect(() => {
    communeId && communeDetail.run()
  }, [communeId])

  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Thành công", { variant: "success" })
      handleClose()
      onSuccess()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const handleClose = () => history.push(links.category.communes())

  return (
    <WideDialog title={communeId ? "Chỉnh sửa phường xã" : "Thêm phường xã"} onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-12">
            <label>
              Tỉnh thành <span>*</span>
            </label>
            <Select
              options={provinceList.getResult()?.content}
              bind={bind}
              field={"provinceId"}
              labelDefaultOption="Chọn tỉnh thành"
            />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>
              Quận huyện <span>*</span>
            </label>
            <Select
              options={districtList.getResult()?.content}
              bind={bind}
              field={"districtId"}
              labelDefaultOption="Chọn quận huyện"
            />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>
              Tên phường xã <span>*</span>
            </label>
            <input {...bind.textField("name")} />
            <FormHelperText {...bind.helperText("name")} />
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button
              disabled={form.isSubmitting() || !form.isChanged()}
              type="button"
              onClick={handleSubmit}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {communeId ? "Lưu thay đổi" : "Thêm"}
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
