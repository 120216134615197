import { useAction } from "@corets/use-action"
import { useAsync } from "@corets/use-async"
import { Box, Chip, Divider, Tooltip, Typography, Stack, IconButton } from "@mui/material"
import { orange, red, cyan } from "@mui/material/colors"
import { useEffect, useState } from "react"
import { PageContent } from "../../../oneportal/components/PageContent"
import { PagePagination } from "../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../../oneportal/components/Table/TBodyRow"
import { TBodySkeleton } from "../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../../oneportal/components/Table/TWrapper"
import { dateVNFormat } from "../../../oneportal/helpers/utils"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { useQuery } from "../../../oneportal/hooks/useQuery"
import HaveCommentForm from "../../components/SearchForm/HaveComment"

import { RemoveRedEye } from "@mui/icons-material"
import { styled } from "@mui/material/styles"
import {
  getListCategories,
  getListFields,
  getListReflectHasInteractive,
  getListStatus,
} from "../../services/api/reflectTheScene"
import { HEADER_HAVE_COMMENT_TABLE, REVIEWER_OPTIONS } from "../../units/constanst"
import { ReflectHasInteractiveParams } from "../../units/types/ReflectFilterParams"
import ReflectCommentDialog from "../../components/ReflectComment/ReflectCommentDialog"
import GuideDrawer from "../../components/GuideDrawer"
import ColumnSetting from "../../components/ColumnSetting"
import { TypographyTruncate } from "../../components/Typography"
import RowReflectItem from "../../components/ListTable/RowReflectItem"
import RowReflectHeader from "../../components/ListTable/RowReflectHeader"

const WaitingProcess = () => {
  const api = useApiClient()
  const [columnsToShow, setColumnsToShow] = useState(HEADER_HAVE_COMMENT_TABLE)
  const [reflectId, setReflectId] = useState<any>(null)
  const [openDetailReflect, setOpenDetailReflect] = useState(false)

  const reflectAction = useAction(async () => await getListReflectHasInteractive(api, getFilterParams()))
  const reflectList = reflectAction.getResult()?.content.items || []

  const pagination = usePagePagination({
    pageCount: reflectAction.getResult()?.content.pageCount,
  })

  const [query, , pathQuery] = useQuery<ReflectHasInteractiveParams>({
    IsAnswered: 0,
    CategoriesId: 0,
    Loading: false,
    Searching: "",
    ReflectionFieldId: 0,
    ReflectionStatusId: 0,
  })

  const fieldOptions = useAsync(async () => await getListFields(api, {}), [])?.getResult()?.content || []
  const statusOptions = useAsync(async () => await getListStatus(api, {}), [])?.getResult()?.content || []
  const categoryOptions = useAsync(async () => await getListCategories(api, {}), [])?.getResult()?.content || []

  const getFilterParams = () => {
    const IsAnswered = REVIEWER_OPTIONS.find((review) => review?.id === parseInt(query?.IsAnswered))
    const newParams = {
      ...query,
      ReflectionStatusId: query?.ReflectionStatusId || undefined,
      CategoriesId: query?.CategoriesId || undefined,
      ReflectionFieldId: query?.ReflectionFieldId || undefined,
      PageIndex: pagination.query.pageIndex || 0,
      PageSize: pagination.query.pageSize || 10,
      IsDelete: false,
      IsAnswered: IsAnswered?.key,
      Searching: query?.Searching || undefined,
    }
    return newParams
  }

  useEffect(() => {
    reflectAction.run()
  }, [JSON.stringify(getFilterParams())])

  const handleToggleDetailReflect = () => setOpenDetailReflect(!openDetailReflect)

  return (
    <PageContent pageTitle="Phản ánh có tương tác" sectionClassName="user-page" wrapClassName="user__form">
      <Box justifyContent="space-between" alignItems="center" sx={{ display: "flex", paddingBottom: "16px" }}>
        <Typography>Danh sách phản ánh</Typography>
        <Stack direction="row" spacing={2}>
          <ColumnSetting onApply={(columns) => setColumnsToShow(columns)} initColumns={HEADER_HAVE_COMMENT_TABLE} />
          <GuideDrawer />
        </Stack>
      </Box>

      <Divider light />

      <HaveCommentForm
        params={query}
        onSetParams={pathQuery}
        fieldOptions={fieldOptions}
        statusOptions={statusOptions}
        categoryOptions={categoryOptions}
      />

      <Typography variant="body2" sx={{ fontWeight: 600, pt: 1 }}>
        Tổng số <Chip size="small" label={reflectAction.getResult()?.content?.totalItemCount || 0} color="warning" />
      </Typography>

      <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
        {reflectAction.isRunning() && <TBodySkeleton count={10} colSpan={11} />}
        {!!reflectAction.getResult()?.content?.items?.length && (
          <>
            <THead>
              <RowReflectHeader columnsToShow={columnsToShow} />
            </THead>

            <TBody>
              {!reflectAction.isRunning() &&
                reflectList.map((item: any, index) => (
                  <RowReflectItem
                    reflect={item}
                    index={index}
                    columnsToShow={columnsToShow}
                    renderAction={
                      <IconButton
                        onClick={() => {
                          setReflectId(item?.id)
                          handleToggleDetailReflect()
                        }}
                      >
                        <RemoveRedEye className="cursor-pointer" />
                      </IconButton>
                    }
                    onClickId={() => {}}
                    hideShowInfoIcon
                  />
                ))}
            </TBody>
          </>
        )}
        {!reflectAction.isRunning() &&
          !reflectAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
      </TWrapper>

      {!!reflectAction.getResult()?.content.items.length && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageIndex={pagination.query.pageIndex}
            pageCount={reflectAction.getResult()?.content.pageCount}
            {...pagination.bind()}
          />
        </Box>
      )}

      <ReflectCommentDialog
        open={openDetailReflect}
        onClose={handleToggleDetailReflect}
        onSuccess={() => {
          reflectAction.run()
        }}
        reflectId={reflectId}
      />
    </PageContent>
  )
}

export default WaitingProcess
