/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { EditIcon, DeleteIcon } from "../../../assets/icons"
import { Moditor, WPlus } from "../../../assets/images"
import { links } from "../../../config/links"
import { AlertDialog } from "../../oneportal/components/Dialogs/AlertDialog"
import { PageContentWithSearchBar } from "../../oneportal/components/PageContentWithSearchbar"
import { PagePagination } from "../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../oneportal/components/Table/TBodyRow"
import { TBodySkeleton } from "../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../oneportal/components/Table/THead"
import { THeadCell } from "../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../oneportal/components/Table/TWrapper"
import { useTableSorter } from "../../oneportal/components/Table/useTableSorter"
import { useAlert } from "../../oneportal/hooks/useAlert"
import { useApiClient } from "../../oneportal/hooks/useApiClient"
import { useQuery } from "../../oneportal/hooks/useQuery"
import { PermissionDialog } from "../components/Permission/PermissionDialog"
import { CreateOrUpdateRoleDialog } from "../components/Role/CreateOrUpdateRoleDialog/CreateOrUpdateRoleDialog"
import { SearchRoleFormType } from "../components/Role/SaearchRoleForm/createSaearchRoleForm"
import { SearchRoleForm } from "../components/Role/SaearchRoleForm/SearchRoleForm"
import { deleteRole, getListRole, RoleParams } from "../services/api/role"

const ListRole = () => {
  const api = useApiClient()
  const history = useHistory()
  const sorter = useTableSorter()
  const alert = useAlert()

  const roleAction = useAction(() => getListRole(api, roleParams))
  const [roleId, setRoleId] = useState<string | undefined>(undefined)

  const pagination = usePagePagination({
    pageCount: roleAction.getResult()?.content.pageCount,
  })
  const [query, , pathQuery] = useQuery<RoleParams>({})

  const roleParams: RoleParams = {
    sortExpression: sorter.query.sort && `${sorter.query.sort} ${sorter.query.direction}`,
    pageIndex: pagination.query.pageIndex,
    pageSize: pagination.query.pageSize,
    name: query.name,
    code: query.code,
  }

  useEffect(() => {
    roleAction.run()
  }, [JSON.stringify(roleParams)])

  const handleSearcRole = (data: SearchRoleFormType) =>
    pathQuery({ ...data, pageSize: undefined, pageIndex: undefined })

  const handleDeleteRole = async () => {
    const res = await deleteRole(api, roleId as string)
    setRoleId(undefined)
    if (res?.content.status) {
      alert("Thành công", { variant: "success" })
      roleAction.run()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  return (
    <PageContentWithSearchBar
      pageTitle="Nhóm quyền"
      sectionClassName="qlds-page"
      searchbarClassName="list-user-form"
      searchBar={
        (!!roleAction.getResult()?.content.items.length || !!Object.keys(query).length) && (
          <SearchRoleForm onSubmit={handleSearcRole} defaultValue={query} />
        )
      }
    >
      <>
        <TWrapper
          widths={["10%", "30%", "30%", "30%"]}
          tableClassName="table__user"
          tableContainerClassName="wrap-table__user"
        >
          {roleAction.isRunning() && <TBodySkeleton count={10} colSpan={8} />}
          {!!roleAction.getResult()?.content.items.length && (
            <>
              <THead>
                <THeadRow>
                  <THeadCell>STT</THeadCell>
                  <THeadCell {...sorter.bind("name")}>Tên role</THeadCell>
                  <THeadCell {...sorter.bind("code")}>Code</THeadCell>
                  <THeadCell>Hành động</THeadCell>
                </THeadRow>
              </THead>
              <TBody>
                {!roleAction.isRunning() &&
                  roleAction
                    .getResult()
                    ?.content.items.filter((item) => !["ADMIN", "REGISTERUSER"].includes(item.code))
                    .map((item, index) => (
                      <TBodyRow key={item.id}>
                        <TBodyCell>
                          {(pagination.query.pageSize || 10) *
                            (pagination.query.pageIndex ? pagination.query.pageIndex - 1 : 0) +
                            index +
                            1}
                        </TBodyCell>
                        <TBodyCell>{item.name}</TBodyCell>
                        <TBodyCell>{item.code}</TBodyCell>
                        <TBodyCell>
                          <div className="action__user table-action d-flex align-items-center">
                            <button
                              type="button"
                              className="border-0 mr-3"
                              onClick={() => history.push(links.system.updateRole(item.id))}
                            >
                              <EditIcon />
                            </button>
                            <button
                              type="button"
                              className="border-0"
                              onClick={() => history.push(links.system.rolePermission(item.id))}
                            >
                              <img src={Moditor} alt="" className="icon" />
                            </button>
                            <button type="button" className="border-0" onClick={() => setRoleId(item.id)}>
                              <DeleteIcon className="icon" />
                            </button>
                          </div>
                        </TBodyCell>
                      </TBodyRow>
                    ))}
              </TBody>
            </>
          )}
        </TWrapper>
        {!roleAction.isRunning() && !roleAction.getResult()?.content.items.length && !Object.keys(query).length && (
          <TPlaceholder title="Không có dữ liệu">
            <button
              className="btn  btn-ct  btn-add btn-danger-ct  d-flex align-items-center"
              type="button"
              onClick={() => history.push(links.system.createRole())}
            >
              Thêm Role
              <img src={WPlus} alt="" />
            </button>
          </TPlaceholder>
        )}
        {!roleAction.isRunning() && !roleAction.getResult()?.content.items.length && !!Object.keys(query).length && (
          <TPlaceholder title="Không có dữ liệu trùng khớp" />
        )}
        {!!roleAction.getResult()?.content.items.length && (
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageCount={roleAction.getResult()?.content.pageCount}
            {...pagination.bind()}
            pageIndex={pagination.query.pageIndex}
          />
        )}
      </>
      <Route path={links.system.createRole()} exact>
        <CreateOrUpdateRoleDialog onSuccess={() => roleAction.run()} />
      </Route>
      <Route path={links.system.updateRole()} exact>
        <CreateOrUpdateRoleDialog onSuccess={() => roleAction.run()} />
      </Route>
      <Route path={links.system.rolePermission()} exact>
        <PermissionDialog />
      </Route>

      <AlertDialog
        title="Xóa role"
        textContent="Bạn có muốn xoá dòng dữ liệu này?"
        open={!!roleId}
        onClose={() => setRoleId(undefined)}
        onAgree={handleDeleteRole}
      />
    </PageContentWithSearchBar>
  )
}

export default ListRole
