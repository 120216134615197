import { Search } from "@mui/icons-material"
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { useState } from "react"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { LogFilterParams } from "../../../units/types/LogRequest"



interface ISearchForm {
  params?: LogFilterParams
  onSetParams: (params: any) => void
  levelOptions: Array<any>
}
const ALL_OPTIONS = { name: "Tất cả", id: "" }
const SearchForm = ({
  params,
  onSetParams,
  levelOptions,
}: ISearchForm) => {
  const [searchTerm, setSerchTerm] = useState(params?.Keyword || "")
  const api = useApiClient()

  const handleUpdateParam = (field: string, value: any) => {
    onSetParams({
      ...params,
      [field]: value,
      PageIndex: 0,
    })
  }

  const handleSelectChange = (field: string) => (e: any) => {
    onSetParams({
      ...params,
      [field]: e.target.value,
      PageIndex: 0,
    })
  }

  return (
    <form>
      <Grid container sx={{ py: "16px" }} spacing={2}>
        <Grid item md={6}>
          <TextField
            label="Tìm kiếm theo nội dung message"
            variant="standard"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSerchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleUpdateParam("Keyword", searchTerm)}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Level</InputLabel>
            <Select fullWidth value={params?.Level} onChange={handleSelectChange("Level")}>
              {[ALL_OPTIONS, ...levelOptions]?.map((action) => (
                <MenuItem key={action.id} value={action?.id}>
                  {action.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
              <MobileDatePicker
                label="Từ ngày"
                value={params?.startDate || null}
                onChange={(value) => onSetParams({ startDate: value })}
                renderInput={(params) => <TextField {...params} size="small" variant="standard" fullWidth />}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="Đến ngày"
              value={params?.endDate || null}
              onChange={(value) => onSetParams({ endDate: value })}
              renderInput={(params) => <TextField {...params} size="small" variant="standard" fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </form>
  )
}

export default SearchForm
