/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useEffect, useState } from "react"
import "../../../../assets/style/danhmuc.css"

import { SearchProcessingUnitForm } from "../../components/SearchProcessingUnitForm/SearchProcessingUnitForm"
import { SearchProcessingUniFormType } from "../../components/SearchProcessingUnitForm/createSearchProcessingUnitForm"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { useTableSorter } from "../../../oneportal/components/Table/useTableSorter"
import { PageContentWithSearchBar } from "../../../oneportal/components/PageContentWithSearchbar"
import { TWrapper } from "../../../oneportal/components/Table/TWrapper"
import { TBodySkeleton } from "../../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../../oneportal/components/Table/THead"
import { THeadRow } from "../../../oneportal/components/Table/THeadRow"
import { THeadCell } from "../../../oneportal/components/Table/THeadCell"
import { TBody } from "../../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../../oneportal/components/Table/TBodyRow"
import { TPlaceholder } from "../../../oneportal/components/Table/TPlaceholder"
import { PagePagination } from "../../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../../oneportal/components/PagePagination/usePagePagination"
import { useQuery } from "../../../oneportal/hooks/useQuery"
import { exportFile, getStatisticsByEvaluate, ReportParams } from "../../service/api/report"

const Evaluate = () => {
  const api = useApiClient()
  const sorter = useTableSorter()
  const [isExportExcel, setIsExportExcel] = useState(false)
  const [isExportPdf, setIsExportPdf] = useState(false)

  const categoryAction = useAction(async () => await getStatisticsByEvaluate(api, categoryParams))

  const pagination = usePagePagination({
    pageCount: categoryAction.getResult()?.content.pageCount,
  })
  const [query, , pathQuery] = useQuery<ReportParams>({})

  const categoryParams: ReportParams = {
    sorting: sorter.query.sort && `${sorter.query.sort} ${sorter.query.direction}`,
    pageIndex: pagination.query.pageIndex,
    pageSize: pagination.query.pageSize || 30,
    fromDate: query?.fromDate,
    toDate: query?.toDate,
  }

  useEffect(() => {
    categoryAction.run()
  }, [JSON.stringify(categoryParams)])

  const handleEvaluate = (data: SearchProcessingUniFormType) =>
    pathQuery({ ...data, pageSize: undefined, pageIndex: undefined })

  const handleExport = async (type: "excel" | "pdf") => {
    const isExportPdf = type === "pdf"
    const params = { ...categoryParams, isExportPdf }
    isExportPdf ? setIsExportPdf(true) : setIsExportExcel(true)

    const results = await exportFile("/api/admin/Statistics/ExportStatisticsByEvaluate", api, params)
    if (results) {
      setIsExportPdf(false)
      setIsExportExcel(false)
      const fileType = isExportPdf
        ? "application/pdf"
        : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const url = window.URL.createObjectURL(new Blob([results], { type: fileType }))

      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `Thongketheodanhgia.${isExportPdf ? "pdf" : "xlsx"}`)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    }
  }

  return (
    <PageContentWithSearchBar
      pageTitle="Thống kê theo đánh giá"
      sectionClassName="qlds-page"
      searchbarClassName="list-user-form"
      searchBar={
        (!!categoryAction.getResult()?.content.items.length || !!Object.keys(query).length) && (
          <SearchProcessingUnitForm
            onSubmit={handleEvaluate}
            defaultValue={query}
            isExportExcel={isExportExcel}
            isExportPdf={isExportPdf}
            onExport={handleExport}
          />
        )
      }
    >
      <>
        <TWrapper tableClassName="table__user" tableContainerClassName="wrap-table__cate">
          {categoryAction.isRunning() && <TBodySkeleton count={10} colSpan={8} />}
          {!!categoryAction.getResult()?.content.items.length && (
            <>
              <THead>
                <THeadRow>
                  <THeadCell>STT</THeadCell>
                  <THeadCell {...sorter.bind("reflectionFieldName")}>Đơn vị</THeadCell>
                  <THeadCell {...sorter.bind("reflectionTotalCount")}>Số phản ánh giải quyết</THeadCell>
                  <THeadCell {...sorter.bind("reflectionHasEvaluateCount")}>Số phản ánh có đánh giá</THeadCell>
                  <THeadCell {...sorter.bind("reflectionEvaluateSatisfiedCount")}>Hài lòng</THeadCell>
                  <THeadCell {...sorter.bind("reflectionEvaluateAcceptCount")}>Chấp nhận</THeadCell>
                  <THeadCell {...sorter.bind("reflectionEvaluateUnSatisfiedCount")}>Không hài lòng</THeadCell>
                </THeadRow>
              </THead>
              <TBody>
                {!categoryAction.isRunning() &&
                  categoryAction.getResult()?.content.items.map((item, index) => (
                    <TBodyRow key={item.reflectionFieldId}>
                      <TBodyCell>
                        {(pagination.query.pageSize || 10) *
                          (pagination.query.pageIndex ? pagination.query.pageIndex - 1 : 0) +
                          index +
                          1}
                      </TBodyCell>
                      <TBodyCell>{item.reflectionFieldName}</TBodyCell>
                      <TBodyCell>{item.reflectionTotalCount}</TBodyCell>
                      <TBodyCell>{item.reflectionHasEvaluateCount}</TBodyCell>
                      <TBodyCell>{item.reflectionEvaluateSatisfiedCount}</TBodyCell>
                      <TBodyCell>{item.reflectionEvaluateAcceptCount}</TBodyCell>
                      <TBodyCell>{item.reflectionEvaluateUnSatisfiedCount}</TBodyCell>
                    </TBodyRow>
                  ))}
              </TBody>
            </>
          )}
        </TWrapper>
        {!categoryAction.isRunning() &&
          !categoryAction.getResult()?.content.items.length &&
          !Object.keys(query).length && <TPlaceholder title="Không có dữ liệu"></TPlaceholder>}
        {!categoryAction.isRunning() &&
          !categoryAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
        {!!categoryAction.getResult()?.content.items.length && (
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageCount={categoryAction.getResult()?.content.pageCount}
            {...pagination.bind()}
            pageIndex={pagination.query.pageIndex}
          />
        )}
      </>
    </PageContentWithSearchBar>
  )
}

export default Evaluate
