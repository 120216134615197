import { ObservableForm } from "@corets/form"
import { ChangeEvent, RefObject } from "react"

export type UploadImageProps = {
  inputRef?: RefObject<HTMLInputElement>
  form: ObservableForm
  field: string
  setImagePreview: (data: string) => void
}
export const UploadImage = (props: UploadImageProps) => {
  const { form, inputRef, setImagePreview, field } = props

  const handleUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e?.target?.files?.length && e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string)
        form.setAt(field, file)
      }

      reader.readAsDataURL(file)
    }
  }

  return (
    <input
      className="fileInput"
      type="file"
      onChange={(e) => handleUploadFile(e)}
      ref={inputRef}
      hidden
      accept={"image/png, image/jpeg"}
      multiple={false}
    />
  )
}
