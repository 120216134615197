import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { Box, FormHelperText } from "@mui/material"
import { ISODate } from "../units/types/ISODate"
import dayjs, { Dayjs } from "dayjs"
import { formatISODate } from "../helpers/formatISODate"
import { MuiFormBinder } from "../helpers/createMuiFormBinder"

export type InputDatePickerProps = {
  value: ISODate | undefined
  onChange: (newDate: ISODate | undefined) => void
  bind: MuiFormBinder
  field: string
}

export const InputDatePicker = (props: InputDatePickerProps) => {
  const { value, onChange, bind, field } = props

  const date = !!value ? dayjs(value as string) : null

  const handleChange = (newDate: Dayjs | null) => {
    try {
      if (newDate) {
        onChange(formatISODate(newDate))
      } else {
        onChange(undefined)
      }
    } catch (err) {}
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={date}
        onChange={handleChange}
        inputFormat="dd/MM/yyyy"
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <input ref={inputRef} {...inputProps} {...bind.textFieldDatePicker(field)} placeholder="--/--/----" />
            {InputProps?.endAdornment}
          </Box>
        )}
      />
      <FormHelperText {...bind.helperText(field)} />
    </LocalizationProvider>
  )
}
