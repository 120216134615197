import { FormHelperText } from "@mui/material"
import clsx from "clsx"
import { AsyncPaginate } from "react-select-async-paginate"
import { components } from "react-select"

export const SelectAsync = (props) => {
  const {
    bind,
    field,
    onLoadOptions,
    value,
    onChange,
    placeholder = "Chọn",
    noOptionsMessage,
    isError,
    disabled = false,
    onClickButton,
    hiddenButton,
    labelButton,
  } = props

  const SingleValue = (props) =>
    components.SingleValue && (
      <components.SingleValue className="single-value" {...props}>
        {props.children}
      </components.SingleValue>
    )

  return (
    <>
      <div className="d-flex align-items-center">
        <AsyncPaginate
          isDisabled={disabled}
          className={clsx(isError && "invalid", "async-select")}
          value={value}
          loadOptions={onLoadOptions}
          onChange={onChange}
          placeholder={`--${placeholder}--`}
          debounceTimeout={500}
          additional={{
            page: 1,
          }}
          components={{ SingleValue }}
          noOptionsMessage={noOptionsMessage}
        />
        {onClickButton && !hiddenButton && (
          <button disabled={disabled} type="button" className="btn btn-ct btn-primary-ct" onClick={onClickButton}>
            {labelButton}
          </button>
        )}
      </div>
      <FormHelperText {...bind.helperText(field)} />
    </>
  )
}
