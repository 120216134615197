import { PageContent } from "../../components/PageContent"
import { createChangePasswordForm } from "./createChangePasswordForm"
import { createMuiFormBinder } from "../../helpers/createMuiFormBinder"
import { FormHelperText } from "@material-ui/core"
import { useForm } from "@corets/use-form"
import { useApiClient } from "../../hooks/useApiClient"
import { changePassword } from "../../services/api/account"
import { useAlert } from "../../hooks/useAlert"
import "../../../../assets/style/changePass.css"

const ChangePassword = () => {
  const api = useApiClient()
  const [form] = useForm(createChangePasswordForm())
  const bind = createMuiFormBinder(form)
  const alert = useAlert()

  const handleSubmitForm = async () => {
    const formValidate = await form.validate()
    if (formValidate) return

    const formData = await form.submit()
    const res = await changePassword(api, formData)
    if (res?.content) {
      alert("Thành công", { variant: "success" })
      form.clear()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  return (
    <PageContent pageTitle="Đặt lại mật khẩu" sectionClassName="change-pass__page" wrapClassName="change-pass__form">
      <form {...bind.form()}>
        <div className="form-group row align-items-center">
          <label className="col-sm-3">
            Mật khẩu hiện tại <span>*</span>
          </label>
          <div className="col-sm-9">
            <input type="password" {...bind.textField("currentPassword")} />
            <FormHelperText {...bind.helperText("currentPassword")} />
          </div>
        </div>
        <div className="form-group row align-items-center">
          <label className="col-sm-3">
            Mật khẩu mới <span>*</span>
          </label>
          <div className="col-sm-9">
            <input type="password" {...bind.textField("newPassword")} />
            <FormHelperText {...bind.helperText("newPassword")} />
          </div>
        </div>
        <div className="form-group row align-items-center">
          <label className="col-sm-3">
            Xác nhận mật khẩu <span>*</span>
          </label>
          <div className="col-sm-9">
            <input type="password" {...bind.textField("confirmNewPassword")} />
            <FormHelperText {...bind.helperText("confirmNewPassword")} />
          </div>
        </div>
        <div className="form__save d-flex align-items-center justify-content-center">
          <button className="btn btn-ct btn-default-ct" type="reset">
            Hủy
          </button>
          <button className="btn btn-ct btn-primary-ct" type="button" onClick={handleSubmitForm}>
            Lưu thay đổi
          </button>
        </div>
      </form>
    </PageContent>
  )
}
export default ChangePassword
