/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../../config/links"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getDistrictDetail } from "../../../services/api/district"
import { createCreateOrUpdateDistrictForm } from "./createCreateOrUpdateDistrictForm"
import { getLookupProvince } from "../../../../oneportal/services/api/administrative"
import { Select } from "../../../../oneportal/components/Select"

export type CreateOrUpdateDistrictDialogProps = {
  onSuccess: () => void
}

export const CreateOrUpdateDistrictDialog = (props: CreateOrUpdateDistrictDialogProps) => {
  const { onSuccess } = props
  const api = useApiClient()
  const alert = useAlert()
  const history = useHistory()

  const { districtId } = useParams<{
    districtId: string
  }>()

  const districtDetail = useAction(async () => await getDistrictDetail(api, districtId))
  const provinceList = useAction(async () => getLookupProvince(api))

  const [form] = useForm(createCreateOrUpdateDistrictForm(api, districtDetail.getResult()?.content), [
    JSON.stringify(districtDetail.getResult()?.content),
  ])
  const bind = createMuiFormBinder(form)

  useEffect(() => {
    districtId && districtDetail.run()
  }, [districtId])
  useEffect(() => {
    provinceList.run()
  }, [])

  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Thành công", { variant: "success" })
      handleClose()
      onSuccess()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const handleClose = () => history.push(links.category.distrists())

  return (
    <WideDialog title={districtId ? "Chỉnh sửa quận huyện" : "Thêm quận huyện"} onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-12">
            <label>
              Tỉnh thành <span>*</span>
            </label>
            <Select
              options={provinceList.getResult()?.content}
              bind={bind}
              field={"provinceId"}
              labelDefaultOption="Chọn tỉnh thành"
            />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>
              Tên quận huyện <span>*</span>
            </label>
            <input {...bind.textField("name")} />
            <FormHelperText {...bind.helperText("name")} />
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button
              disabled={form.isSubmitting() || !form.isChanged()}
              type="button"
              onClick={handleSubmit}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {districtId ? "Lưu thay đổi" : "Thêm"}
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
