import clsx from "clsx"
import { ReactNode } from "react"
import { PageTitle } from "./PageTitle"

export type PageContentProps = {
  children: ReactNode
  pageTitle: string
  sectionClassName?: string
  wrapClassName?: string
}

export const PageContent = (props: PageContentProps) => {
  const { children, sectionClassName, wrapClassName, pageTitle } = props
  return (
    <section className={clsx("page-content", sectionClassName)}>
      <PageTitle title={pageTitle} />
      <div className="page__title">
        <span>{pageTitle}</span>
      </div>
      <div className={clsx("wrap__content-page", wrapClassName)}>{children}</div>
    </section>
  )
}
