import { ReactNode } from "react"
import { TableRow } from "@material-ui/core"
import styled from "styled-components"

export type TBodyRowProps = {
  hover?: boolean
  children?: ReactNode
  onClick?: () => void
}

export const TBodyRow = (props: TBodyRowProps) => {
  const { hover = true, children, onClick } = props
  const clickable = !!onClick

  return (
    // passing boolean is currently broken in combination with TableRow and styled components....
    <CustomRow hover={hover} onClick={onClick} clickable={+clickable}>
      {children}
    </CustomRow>
  )
}

const CustomRow = styled(TableRow)<{ clickable: number }>`
  cursor: ${(p) => (p.clickable ? "pointer" : "auto")};
`
