import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"

export type SearchDistrictFormType = {
  keyword?: string
}

export const createSearchDistrictForm = (
  data?: SearchDistrictFormType
): ObservableForm<SearchDistrictFormType, SearchDistrictFormType> => {
  const form = createFormFromSchema<SearchDistrictFormType>(
    object<SearchDistrictFormType>({
      keyword: string()
        .optional()
        .toDefault(data?.keyword || ""),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
