/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect } from "react"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../../config/links"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { ErrorTypeEnum } from "../../../../oneportal/units/enums/ErrorTypeEnum"
import { ErrorType } from "../../../../oneportal/units/types/ErrorType"
import { getRoleDetail } from "../../../services/api/role"
import { createCreateOrUpdateRoleDialog } from "./createCreateOrUpdateRoleDialog"

export type CreateOrUpdateRoleDialogProps = {
  onSuccess: () => void
}

export const CreateOrUpdateRoleDialog = (props: CreateOrUpdateRoleDialogProps) => {
  const { onSuccess } = props
  const history = useHistory()
  const api = useApiClient()

  const { roleId } = useParams<{
    roleId: string
  }>()

  const roleDetail = useAction(async () => await getRoleDetail(api, roleId))

  const handleErrorCallBack = (error?: ErrorType) => {
    if (error?.errorType === ErrorTypeEnum.ExistsRoleCode) {
      alert("Đã có Role tồn tại với mã code này", { variant: "error" })
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const [form] = useForm(() => {
    return createCreateOrUpdateRoleDialog(api, roleDetail.getResult()?.content, handleErrorCallBack)
  }, [JSON.stringify(roleDetail.getResult()?.content)])

  const bind = createMuiFormBinder(form)
  const alert = useAlert()

  useEffect(() => {
    roleId && roleDetail.run()
  }, [roleId])

  const handleSubmit = async () => {
    const errors = await form.validate()
    if (errors) return

    const res = await form.submit()
    if (res) {
      alert("Thành công", { variant: "success" })
      handleClose()
      onSuccess()
    }
  }

  const handleClose = () => history.push(links.system.listRole())

  return (
    <WideDialog title={roleId ? "Chỉnh sửa role" : "Thêm mới role"} onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-12">
            <label>
              Tên role <span>*</span>
            </label>
            <input type="text" {...bind.textField("name")} />
            <FormHelperText {...bind.helperText("name")} />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>
              Code <span>*</span>
            </label>
            <input disabled={!!roleId} type="text" {...bind.textField("code")} />
            <FormHelperText {...bind.helperText("code")} />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <button
              type="button"
              disabled={form.isSubmitting() || !form.isChanged()}
              className="btn btn-ct btn-primary-ct loading"
              onClick={handleSubmit}
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {roleId ? "Lưu thay đổi" : "Thêm role"}
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
