import React, { Fragment, useCallback, useMemo, FunctionComponent, Dispatch } from "react"
import { useDropzone } from "react-dropzone"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  flexDirection: "column" as "column",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
}

const activeStyle = {
  borderColor: "#2196f3",
}

const acceptStyle = {
  borderColor: "#00e676",
}

const rejectStyle = {
  borderColor: "#ff1744",
}

export type DropZoneProps = {
  setFile: Dispatch<any>
  isMulti?: boolean
  accept?: string
}

export const DropZone: FunctionComponent<DropZoneProps> = (props) => {
  const { setFile, isMulti, accept } = props
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles)
    },
    [setFile]
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: isMulti,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <Fragment>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} accept={accept} />
        <p className="mb-2">Kéo và thả file vào đây, hoặc nhấp để chọn file</p>
        <CloudUploadIcon />
      </div>
    </Fragment>
  )
}
