import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { REGEX_PASSWORD } from "../../units/constanst"
import { ChangePasswordRequest } from "../../units/types/ChangePasswordRequest"

export type ChangePasswordFormType = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export const createChangePasswordForm = (): ObservableForm<ChangePasswordFormType, ChangePasswordRequest> => {
  const form = createFormFromSchema<ChangePasswordFormType, ChangePasswordRequest>(
    object<ChangePasswordFormType>({
      currentPassword: string().required(true, "Bắt buộc"),
      newPassword: string()
        .required(true, "Bắt buộc")
        .matches(REGEX_PASSWORD, "Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ít nhất 8 ký tự"),
      confirmNewPassword: string()
        .required(true, "Bắt buộc")
        .and((value) => {
          return value === form.getAt("newPassword") ? undefined : "Xác nhận mật khẩu không đúng"
        }),
    })
  ).handler(async (form) => {
    const { currentPassword, newPassword } = form.get()
    return { currentPassword, newPassword }
  })

  return form
}
