import { createFormFromSchema, ObservableForm } from "@corets/form"
import { array, number, object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { convertObjectToFormData } from "../../../oneportal/helpers/convertObjectToFormData"
import { formatISODate } from "../../../oneportal/helpers/formatISODate"
import { FormMode } from "../../../oneportal/units/enums/FormMode"
import { ISODate } from "../../../oneportal/units/types/ISODate"
import { createRecord, updateRecord } from "../../services/api/records"
import { Project } from "../../units/types/Project"
import { ProjectRecord } from "../../units/types/ProjectRecord"
import { Records } from "../../units/types/Records"

export type CreateRecordType = {
  id: number
  recordsTypeId: number
  project: ProjectRecord
  recordNum: number
  receiveTypeId: number
  receiveDate: ISODate
  receiveUserName: string
  pC07ReceiveId: number
  pC07ManageId: number
  datePredictReturn: ISODate
  dateRealReturn: ISODate
  result: boolean
  returnDocNumb: number
  // documentAnsTypeId: number
  recordsStatusId: number
  datePredictCheck: ISODate
  province?: string
  district?: string
  recordFile: RcordFile[]
  cancelReason: string
}

export type RcordFile = {
  fileId: number
  fileTypeAttachmentId: number
}

export const createCreateOrUpdateRecordForm = (
  api: AxiosInstance,
  mode: FormMode,
  recordData?: Records,
  projectData?: Project
): ObservableForm<CreateRecordType, boolean> => {
  const form = createFormFromSchema<CreateRecordType, boolean>(
    object<CreateRecordType>({
      id: string()
        .toDefault(recordData?.id?.toString() || "")
        .optional(),
      recordsTypeId: string()
        .toDefault(recordData?.recordsTypeId?.toString() || "")
        .required(true, "Bắt buộc"),
      recordNum: string()
        .toDefault(recordData?.recordNum?.toString() || "")
        .required(true, "Bắt buộc"),
      receiveTypeId: string()
        .toDefault(recordData?.receiveTypeId?.toString() || "")
        .required(true, "Bắt buộc"),
      receiveDate: string()
        .date("Ngày không đúng")
        .toDefault(formatISODate(recordData?.receiveDate) || "")
        .required(true, "Bắt buộc"),
      receiveUserName: string()
        .toDefault(recordData?.receiveUserName || "")
        .required(true, "Bắt buộc"),
      pC07ReceiveId: string()
        .toDefault(recordData?.pC07ReceiveId?.toString() || "")
        .required(true, "Bắt buộc"),
      pC07ManageId: string()
        .toDefault(recordData?.pC07ManageId?.toString() || "")
        .required(true, "Bắt buộc"),
      datePredictReturn: string()
        .date("Ngày không đúng")
        .toDefault(formatISODate(recordData?.datePredictReturn?.toString()) || "")
        .required(true, "Bắt buộc"),
      dateRealReturn: string()
        .date("Ngày không đúng")
        .optional()
        .toDefault(formatISODate(recordData?.dateRealReturn?.toString()) || ""),
      result: string()
        .optional()
        .toDefault(recordData?.result?.toString() || ""),
      returnDocNumb: string()
        .optional()
        .toDefault(recordData?.returnDocNumb?.toString() || ""),
      cancelReason: string()
        .optional()
        .toDefault(recordData?.cancelReason?.toString() || ""),
      datePredictCheck: string()
        .date("Ngày không đúng")
        .toDefault(formatISODate(recordData?.datePredictCheck?.toString()) || "")
        .optional(),
      project: object<ProjectRecord>({
        id: string()
          .toDefault(recordData?.projectId?.toString() || "")
          .optional(),
        name: string()
          .toDefault(recordData?.projectsName || projectData?.name || "")
          .required(true, "Bắt buộc"),
        investorId: string()
          .toDefault(recordData?.projectsInvestorId?.toString() || projectData?.investorId.toString() || "")
          .required(true, "Bắt buộc"),
        projectTypeId: string()
          .toDefault(recordData?.projectsProjectTypeId?.toString() || projectData?.projectTypeId.toString() || "")
          .required(true, "Bắt buộc"),
        projectGroupId: string()
          .toDefault(recordData?.projectsProjectGroupId?.toString() || projectData?.projectGroupId.toString() || "")
          .required(true, "Bắt buộc"),
        communeIds: array().optional(),
        projectStatusId: string()
          .optional()
          .toDefault(recordData?.projectsProjectStatusId?.toString() || projectData?.projectStatusId?.toString() || ""),
        address: string().optional().toDefault(recordData?.projectsAddress || projectData?.address?.toString() || ""),
      }).toDefault({}),
      recordsStatusId: string()
        .toDefault(recordData?.recordsStatusId?.toString() || "")
        .optional(),
      recordFile: array(
        object<RcordFile>({
          fileId: number(),
          fileTypeAttachmentId: number(),
        })
      ).optional(),
    })
  ).handler(async (form) => {
    const datas = form.get()

    delete datas.district
    delete datas.province

    let formData = new FormData()
    formData = convertObjectToFormData(datas, formData)
    // Object.keys(datas.project).forEach((item) => formData.append(`project.${item}`, datas.project[item]))
    // Object.keys(datas).forEach((item) => formData.append(item, datas[item]))
    // datas.recordFile.forEach((e) => !!e && formData.append("recordFile", JSON.stringify(e)))

    formData.delete("project")
    formData.delete("recordFile")

    if (mode === FormMode.Create) {
      formData.delete("project.id")
      formData.delete("project.projectStatusId")
      formData.delete("id")

      const res = await createRecord(api, formData)
      if (res) {
        return true
      }
    } else {
      const res = await updateRecord(api, formData)
      if (res) {
        return true
      }
    }
    return false
  })

  return form
}
