import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { AxiosInstance } from "axios"
import { ErrorType } from "../../../../oneportal/units/types/ErrorType"
import { Role } from "../../../../oneportal/units/types/Role"
import { createRole, updateRole } from "../../../services/api/role"
import { RoleRequest } from "../../../units/types/RoleRequest"

export const createCreateOrUpdateRoleDialog = (
  api: AxiosInstance,
  data?: Role,
  errorCallBack?: (error?: ErrorType) => void
): ObservableForm<RoleRequest, boolean> => {
  const form = createFormFromSchema<RoleRequest, boolean>(
    object<RoleRequest>({
      id: string()
        .optional()
        .toDefault(data?.id || ""),
      code: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.code || ""),
      name: string()
        .required(true, "Bắt buộc")
        .toDefault(data?.name || ""),
    })
  ).handler(async (form) => {
    const datas = await form.get()

    if (data) {
      const res = await updateRole(api, datas, errorCallBack)
      if (res?.content.status) return true
    } else {
      delete datas.id
      const res = await createRole(api, datas, errorCallBack)
      if (res?.content.status) return true
    }

    return false
  })

  return form
}
