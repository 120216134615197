/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { EditIcon, DeleteIcon } from "../../../assets/icons"
import { WPlus } from "../../../assets/images"
import { links } from "../../../config/links"
import { AlertDialog } from "../../oneportal/components/Dialogs/AlertDialog"
import { PageContentWithSearchBar } from "../../oneportal/components/PageContentWithSearchbar"
import { PagePagination } from "../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../oneportal/components/Table/TBodyRow"
import { TBodySkeleton } from "../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../oneportal/components/Table/THead"
import { THeadCell } from "../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../oneportal/components/Table/TWrapper"
import { useTableSorter } from "../../oneportal/components/Table/useTableSorter"
import { useAlert } from "../../oneportal/hooks/useAlert"
import { useApiClient } from "../../oneportal/hooks/useApiClient"
import { useQuery } from "../../oneportal/hooks/useQuery"
import { CreateOrUpdateDistrictDialog } from "../components/District/CreateOrUpdateDistrictDialog/CreateOrUpdateDistrictDialog"
import "../../../assets/style/danhmuc.css"
import { SearchDistrictFormType } from "../components/District/SearchDistrictForm/createSearchDistrictForm"
import { SearchDistrictForm } from "../components/District/SearchDistrictForm/SearchDistrictForm"

import { getAllDistrict, deleteDistrict, districtParams } from "../services/api/district"
import { dateVNFormat } from "../../oneportal/helpers/utils"

const ListDistrict = () => {
  const api = useApiClient()
  const history = useHistory()
  const sorter = useTableSorter()
  const alert = useAlert()

  const districtAction = useAction(async () => await getAllDistrict(api, districtParams))
  const [districtId, setDistrictId] = useState<number | undefined>(undefined)

  const pagination = usePagePagination({
    pageCount: districtAction.getResult()?.content.pageCount,
  })
  const [query, , pathQuery] = useQuery<districtParams>({})

  const districtParams: districtParams = {
    sortExpression: sorter.query.sort && `${sorter.query.sort} ${sorter.query.direction}`,
    pageIndex: pagination.query.pageIndex,
    pageSize: pagination.query.pageSize,
    name: query.keyword,
  }

  useEffect(() => {
    districtAction.run()
  }, [JSON.stringify(districtParams)])

  const handleSearchInvestor = (data: SearchDistrictFormType) =>
    pathQuery({ ...data, pageSize: undefined, pageIndex: undefined })

  const handleDeleteDistrict = async () => {
    const res = await deleteDistrict(api, districtId as number)
    setDistrictId(undefined)
    if (res?.content.status) {
      alert("Thành công", { variant: "success" })
      districtAction.run()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  return (
    <PageContentWithSearchBar
      pageTitle="Danh sách quận huyện"
      sectionClassName="qlds-page"
      searchbarClassName="list-user-form"
      searchBar={
        (!!districtAction.getResult()?.content.items.length || !!Object.keys(query).length) && (
          <SearchDistrictForm onSubmit={handleSearchInvestor} defaultValue={query} />
        )
      }
    >
      <>
        <TWrapper
          //widths={["10%", "30%", "30%", "30%"]}
          tableClassName="table__user"
          tableContainerClassName="wrap-table__cate"
        >
          {districtAction.isRunning() && <TBodySkeleton count={10} colSpan={8} />}
          {!!districtAction.getResult()?.content.items.length && (
            <>
              <THead>
                <THeadRow>
                  <THeadCell>STT</THeadCell>
                  <THeadCell {...sorter.bind("provinceName")}>Tỉnh thành</THeadCell>
                  <THeadCell {...sorter.bind("name")}>Tên quận, huyện</THeadCell>
                  <THeadCell {...sorter.bind("modifiedDate")}>Ngày sửa</THeadCell>
                  <THeadCell {...sorter.bind("modifiedBy")}>Người sửa</THeadCell>
                  <THeadCell>Hành động</THeadCell>
                </THeadRow>
              </THead>
              <TBody>
                {!districtAction.isRunning() &&
                  districtAction.getResult()?.content.items.map((item, index) => (
                    <TBodyRow key={item.id}>
                      <TBodyCell>
                        {(pagination.query.pageSize || 10) *
                          (pagination.query.pageIndex ? pagination.query.pageIndex - 1 : 0) +
                          index +
                          1}
                      </TBodyCell>
                      <TBodyCell>{item.provinceName}</TBodyCell>
                      <TBodyCell>{item.name}</TBodyCell>
                      <TBodyCell>{dateVNFormat(item.modifiedDate)}</TBodyCell>
                      <TBodyCell>{item.modifiedBy}</TBodyCell>
                      <TBodyCell>
                        <div className="action__user table-action d-flex align-items-center">
                          <button
                            type="button"
                            className="border-0 mr-3"
                            onClick={() => history.push(links.category.updateDistrict(item.id.toString()))}
                          >
                            <EditIcon />
                          </button>
                          <button type="button" className="border-0" onClick={() => setDistrictId(item.id)}>
                            <DeleteIcon className="icon" />
                          </button>
                        </div>
                      </TBodyCell>
                    </TBodyRow>
                  ))}
              </TBody>
            </>
          )}
        </TWrapper>
        {!districtAction.isRunning() &&
          !districtAction.getResult()?.content.items.length &&
          !Object.keys(query).length && (
            <TPlaceholder title="Không có dữ liệu">
              <button
                className="btn  btn-ct  btn-add btn-danger-ct  d-flex align-items-center"
                type="button"
                onClick={() => history.push(links.category.createDistrict())}
              >
                Thêm quận huyện
                <img src={WPlus} alt="" />
              </button>
            </TPlaceholder>
          )}
        {!districtAction.isRunning() &&
          !districtAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
        {!!districtAction.getResult()?.content.items.length && (
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageCount={districtAction.getResult()?.content.pageCount}
            {...pagination.bind()}
            pageIndex={pagination.query.pageIndex}
          />
        )}
      </>
      <Route path={links.category.createDistrict()} exact>
        <CreateOrUpdateDistrictDialog onSuccess={() => districtAction.run()} />
      </Route>
      <Route path={links.category.updateDistrict()} exact>
        <CreateOrUpdateDistrictDialog onSuccess={() => districtAction.run()} />
      </Route>

      <AlertDialog
        title="Xóa quận huyện"
        textContent="Bạn có muốn xoá dòng dữ liệu này?"
        open={!!districtId}
        onClose={() => setDistrictId(undefined)}
        onAgree={handleDeleteDistrict}
      />
    </PageContentWithSearchBar>
  )
}

export default ListDistrict
