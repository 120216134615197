/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { PageTitle } from "../../../oneportal/components/PageTitle"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { TBody } from "../../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../../oneportal/components/Table/TBodyRow"
import { THead } from "../../../oneportal/components/Table/THead"
import { THeadCell } from "../../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../../oneportal/components/Table/THeadRow"
import { TWrapper } from "../../../oneportal/components/Table/TWrapper"
import { headerQLHS } from "../../units/constanst"
import {
  getListRecord,
  deleteRecord,
  getLookupRecordStatus,
  updateRecordStatus,
  exportFile,
} from "../../services/api/record"
import { dateVNFormat } from "../../../oneportal/helpers/utils"
import { readImageFile } from "../../../oneportal/helpers/readImageFile"
import LastPageIcon from "../../../../assets/image/last-page-arrow.png"
import FirstPageIcon from "../../../../assets/image/first-page-arrow.png"
import BackIcon from "../../../../assets/image/back.png"
import NextIcon from "../../../../assets/image/next.png"
import { DeleteIcon, EditIcon } from "../../../../assets/icons"
//import { useTableSorter } from "../../../oneportal/components/Table/useTableSorter"
import DialogConfirm from "../../components/DialogConfirm/DialogConfirm"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import Box from "@mui/material/Box"
import { getLookupProvince } from "../../../oneportal/services/api/administrative"
import { getLookupProjectType } from "../../services/api/projectType"
import { getLookupRecordsType } from "../../services/api/recordTypes"
import { NavLink } from "react-router-dom"
import { links } from "../../../../config/links"
import { ChangeStatus } from "../../components/ChangeStatusDialog/ChangeStatusDialog"
import { CircularProgress } from "@mui/material"

export type ListConstructionProps = {
  isEdit?: boolean
  getRecordId?: (recordId: number) => void
}
const ListConstruction = (props: ListConstructionProps) => {
  const { isEdit, getRecordId } = props
  const api = useApiClient()
  const alert = useAlert()
  const history = useHistory()
  const [listProvince, setListProvince] = useState([] as any)
  const [listProjectType, setListProjectType] = useState([] as any)
  const [listRecordType, setListRecordType] = useState([] as any)
  const [listRecordStatus, setListRecordStatus] = useState([] as any)
  const [listRecord, setListRecord] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [open, setOpen] = useState(false)
  const [openConfirrm, setOpenConfirm] = useState(false)
  const [recordId, setRecordId] = useState()
  const [statusId, setStatus] = useState("")
  const [filter, setFilter] = useState({
    keyword: "",
    projectTypeId: "",
    provinceId: "",
    fromDate: "",
    toDate: "",
    recordsTypeId: "",
    recordsStatusId: "",
  })
  const [selectedId, setSelectedId] = useState()
  const [isExportingPdf, setIsExportingPdf] = useState(false)
  const [isExportingExcel, setIsExportingExcel] = useState(false)

  useEffect(() => {
    GetListAll("", "", "", "", "", "", "", "", pageIndex.toString(), pageSize.toString())
    getProvince()
    getProjectType()
    getRecordType()
    getRecordStatus()
  }, [])
  const getRecordType = async () => {
    try {
      const results = await getLookupRecordsType(api)
      if (results) setListRecordType(results.content)
    } catch (error) {}
  }
  const getProjectType = async () => {
    try {
      const results = await getLookupProjectType(api)
      if (results) {
        setListProjectType(results.content)
      }
    } catch (error) {}
  }
  const getRecordStatus = async () => {
    try {
      const results = await getLookupRecordStatus(api)
      if (results) setListRecordStatus(results.content)
    } catch (error) {}
  }
  const getProvince = async () => {
    try {
      const results = await getLookupProvince(api)
      if (results) {
        setListProvince(results.content)
      }
    } catch (error) {}
  }
  const GetListAll = async (
    keyword: string,
    projectTypeId: string,
    provinceId: string,
    fromDate: string,
    toDate: string,
    recordsTypeId: string,
    recordsStatusId: string,
    sort: string,
    pageIndex: string,
    pageSize: string
  ) => {
    try {
      const results = await getListRecord(api, {
        keyword,
        projectTypeId,
        provinceId,
        fromDate,
        toDate,
        recordsTypeId,
        recordsStatusId,
        sort,
        pageIndex,
        pageSize,
      })
      if (results) {
        setListRecord(results.content.items)
        setPageIndex(results.content.pageIndex)
        setTotalPage(results.content.pageCount)
      }
    } catch (error: any) {
      alert(error, { variant: "error" })
    }
  }

  const onChangePage = (e) => {
    GetListAll(
      filter.keyword,
      filter.projectTypeId,
      filter.provinceId,
      filter.fromDate,
      filter.toDate,
      filter.recordsTypeId,
      filter.recordsStatusId,
      "asc",
      pageIndex.toString(),
      e.target.value
    )
    setPageSize(e.target.value)
  }

  const gotoLastPage = (e) => {
    setPageIndex(totalPage)
    GetListAll(
      filter.keyword,
      filter.projectTypeId,
      filter.provinceId,
      filter.fromDate,
      filter.toDate,
      filter.recordsTypeId,
      filter.recordsStatusId,
      "asc",
      totalPage.toString(),
      pageSize.toString()
    )
  }
  const gotoFirstPage = (e) => {
    setPageIndex(1)
    GetListAll(
      filter.keyword,
      filter.projectTypeId,
      filter.provinceId,
      filter.fromDate,
      filter.toDate,
      filter.recordsTypeId,
      filter.recordsStatusId,
      "asc",
      "1",
      pageSize.toString()
    )
  }

  const gotoNextPage = (e) => {
    const next = pageIndex + 1
    if (pageIndex < totalPage) {
      setPageIndex(next)
      GetListAll(
        filter.keyword,
        filter.projectTypeId,
        filter.provinceId,
        filter.fromDate,
        filter.toDate,
        filter.recordsTypeId,
        filter.recordsStatusId,
        "asc",
        next.toString(),
        pageSize.toString()
      )
    }
  }

  const gotoPrePage = (e) => {
    if (pageIndex > 1) {
      const pre = pageIndex - 1
      setPageIndex(pre)
      GetListAll(
        filter.keyword,
        filter.projectTypeId,
        filter.provinceId,
        filter.fromDate,
        filter.toDate,
        filter.recordsTypeId,
        filter.recordsStatusId,
        "asc",
        pre.toString(),
        pageSize.toString()
      )
    }
  }

  const onChangeStatusByRow = async (e, id) => {
    setOpenConfirm(true)
    setRecordId(id)
    setStatus(e.target.value)
  }

  const updateStatus = async (id, data?: { cancelReason: ""; dateRealReturn: ""; result: "" }) => {
    try {
      const results = await updateRecordStatus(api, {
        id,
        statusId: statusId,
        cancelReason: data?.cancelReason,
        dateRealReturn: data?.dateRealReturn,
        result: data?.result,
      })
      if (results) {
        alert("Cập nhật thành công", { variant: "success" })
        setOpenConfirm(false)
        GetListAll(
          filter.keyword,
          filter.projectTypeId,
          filter.provinceId,
          filter.fromDate,
          filter.toDate,
          filter.recordsTypeId,
          filter.recordsStatusId,
          "",
          pageIndex.toString(),
          pageSize.toString()
        )
      }
    } catch (error) {
      GetListAll(
        filter.keyword,
        filter.projectTypeId,
        filter.provinceId,
        filter.fromDate,
        filter.toDate,
        filter.recordsTypeId,
        filter.recordsStatusId,
        "",
        pageIndex.toString(),
        pageSize.toString()
      )
    }
  }

  const onChangeSelectFile = (e) => {
    window.open(readImageFile(e.target.value))
  }

  const handleOpenConfirm = (e, id) => {
    setOpen(true)
    setRecordId(id)
  }
  const deleteSingleRecord = async (e, id) => {
    try {
      const result = await deleteRecord(api, id)
      if (result) {
        alert("Xoá thành công", { variant: "success" })
        setOpen(false)
        GetListAll(
          filter.keyword,
          filter.projectTypeId,
          filter.provinceId,
          filter.fromDate,
          filter.toDate,
          filter.recordsTypeId,
          filter.recordsStatusId,
          "",
          pageIndex.toString(),
          pageSize.toString()
        )
      }
    } catch (error: any) {
      alert(error, { variant: "error" })
      GetListAll(
        filter.keyword,
        filter.projectTypeId,
        filter.provinceId,
        filter.fromDate,
        filter.toDate,
        filter.recordsTypeId,
        filter.recordsStatusId,
        "",
        pageIndex.toString(),
        pageSize.toString()
      )
    }
  }

  const onChangefilter = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    })
  }

  const onChangeFromDate = (value) => {
    setFilter({
      ...filter,
      fromDate: value,
    })
  }

  const onChangeToDate = (value) => {
    setFilter({
      ...filter,
      toDate: value,
    })
  }

  const onClickSearch = () => {
    GetListAll(
      filter.keyword,
      filter.projectTypeId,
      filter.provinceId,
      filter.fromDate,
      filter.toDate,
      filter.recordsTypeId,
      filter.recordsStatusId,
      "",
      pageIndex.toString(),
      pageSize.toString()
    )
  }
  const onClickClearFilter = () => {
    setFilter({
      keyword: "",
      projectTypeId: "",
      provinceId: "",
      fromDate: "",
      toDate: "",
      recordsTypeId: "",
      recordsStatusId: "",
    })
    GetListAll("", "", "", "", "", "", "", "", pageIndex.toString(), pageSize.toString())
  }

  const handlerExportFile = async (type: string) => {
    const { keyword, provinceId, projectTypeId, fromDate, toDate, recordsTypeId, recordsStatusId } = filter
    type === "pdf" ? setIsExportingPdf(true) : setIsExportingExcel(true)
    const results = await exportFile(api, {
      fileExtention: type,
      keyword,
      ProjectId: projectTypeId,
      ProvinceId: provinceId,
      FromDate: fromDate,
      ToDate: toDate,
      RecordsTypeId: recordsTypeId,
      RecordsStatusId: recordsStatusId,
      pageIndex,
      pageSize,
    })
    if (results) {
      setIsExportingPdf(false)
      setIsExportingExcel(false)
      const fileType =
        type === "pdf"
          ? "application/pdf"
          : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const url = window.URL.createObjectURL(new Blob([results], { type: fileType }))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `Hoso.${type}`)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    }
  }

  const handlerConfirmChangeStatus = (_, id) => {
    setSelectedId(id)
    if (statusId === "4" || statusId === "3") {
      history.push(links.records.changeStatus())
      setOpenConfirm(false)
      return
    }
    updateStatus(id)
  }

  return (
    <>
      <Route path={links.records.changeStatus()} exact>
        <ChangeStatus
          onSuccess={(data) => updateStatus(selectedId, data)}
          onClose={() => history.push(links.records.records())}
          statusId={statusId}
        />
      </Route>
      <PageTitle title="Quản lý hồ sơ" />
      <section className="page-content qlds-page">
        <div className="page__title">
          <span>Danh sách hồ sơ</span>
        </div>
        <div className="wrap__content-page qlhs-form">
          <form>
            <div className="form-row">
              <div className="form-group col-12 col-md-6 col-lg-4">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Nhập tên công tình, tên chủ đầu tư hoặc số hiệu văn bản"
                  name="keyword"
                  onChange={onChangefilter}
                  value={filter.keyword}
                />
              </div>
              <div className="form-group col-12 col-md-6 col-lg-4">
                <select className="custom-select" onChange={onChangefilter} name="provinceId" value={filter.provinceId}>
                  <option selected value="">
                    Địa phương
                  </option>
                  {Array.isArray(listProvince) &&
                    listProvince.map((item: any) => <option value={item.id}>{item.name}</option>)}
                </select>
              </div>
              <div className="form-group col-12 col-md-6 col-lg-4">
                <select
                  className="custom-select"
                  name="recordsTypeId"
                  onChange={onChangefilter}
                  value={filter.recordsTypeId}
                >
                  <option selected value="">
                    Loại hồ sơ
                  </option>
                  {Array.isArray(listRecordType) &&
                    listRecordType.map((item: any) => <option value={item.id}>{item.name}</option>)}
                </select>
              </div>
              <div className="form-group col-12 col-md-6 col-lg-4">
                <select
                  className="custom-select"
                  name="projectTypeId"
                  onChange={onChangefilter}
                  value={filter.projectTypeId}
                >
                  <option selected value="">
                    Loại công trình
                  </option>
                  {Array.isArray(listProjectType) &&
                    listProjectType.map((item: any) => <option value={item.id}>{item.name}</option>)}
                </select>
              </div>
              <div className="form-group col-12 col-md-6 col-lg-4">
                <select
                  className="custom-select"
                  name="recordsStatusId"
                  onChange={onChangefilter}
                  value={filter.recordsStatusId}
                >
                  <option selected value="">
                    Trạng thái hồ sơ
                  </option>
                  {Array.isArray(listRecordStatus) &&
                    listRecordStatus.map((item) => <option value={item.id}>{item.name}</option>)}
                </select>
              </div>
              <div className="form-group col-12 col-md-6 col-lg-4 mb-0">
                <div className="form-row rowqlds-ct">
                  <div className="form-group col-lg-6">
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={filter.fromDate}
                        onChange={onChangeFromDate}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <input ref={inputRef} className="form-control" {...inputProps} placeholder="--/--/----" />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider> */}
                  </div>
                  <span className="line">-</span>
                  <div className="form-group col-lg-6">
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={filter.toDate}
                        onChange={onChangeToDate}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <input ref={inputRef} className="form-control" {...inputProps} placeholder="--/--/----" />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="form__save d-flex align-items-center justify-content-center">
              <div className="btn btn-ct btn-default-ct loading" onClick={onClickClearFilter}>
                Xóa
              </div>
              <div className="btn btn-ct btn-primary-ct loading" onClick={onClickSearch}>
                Tìm kiếm
              </div>
              {!isEdit && (
                <>
                  <div className="btn btn-ct btn-danger-ct loading" onClick={() => handlerExportFile("pdf")}>
                    {isExportingPdf && <CircularProgress size={20} color="inherit" />}
                    Export to PDF
                  </div>
                  <div className="btn btn-ct btn-success-ct loading" onClick={() => handlerExportFile("xlsx")}>
                    {isExportingExcel && <CircularProgress size={20} color="inherit" />}
                    Export to Excel
                  </div>
                </>
              )}
            </div>
          </form>
        </div>

        <TWrapper tableContainerClassName="qlds__list">
          <THead>
            <THeadRow>
              {headerQLHS.map((item: any) => (
                <THeadCell>{item.name}</THeadCell>
              ))}
            </THeadRow>
          </THead>

          <TBody>
            {Array.isArray(listRecord) && listRecord.length > 0 ? (
              listRecord.map((item: any, index) => (
                <>
                  <TBodyRow>
                    <TBodyCell align="center">{index + 1}</TBodyCell>
                    <TBodyCell align="center">{item.projectsName}</TBodyCell>
                    <TBodyCell align="center">{item.projectsInvestorName}</TBodyCell>
                    <TBodyCell align="center">{item.projectsAddress}</TBodyCell>
                    <TBodyCell align="center">{item.projectsProjectTypeName}</TBodyCell>
                    <TBodyCell align="center">{item.recordsTypeName}</TBodyCell>
                    <TBodyCell align="center">{item.receiveTypeName}</TBodyCell>
                    <TBodyCell align="center">{dateVNFormat(item.receiveDate)}</TBodyCell>
                    <TBodyCell align="center">{dateVNFormat(item.datePredictReturn)}</TBodyCell>
                    <TBodyCell align="center">{item.documentAnsTypeName}</TBodyCell>
                    <TBodyCell align="center">{item.documentAnsTypeId}</TBodyCell>
                    <TBodyCell align="center">{dateVNFormat(item.dateRealReturn)}</TBodyCell>
                    <TBodyCell align="center">
                      <select className="custom-select" onChange={onChangeSelectFile}>
                        <option value={""}>---Chọn xem file---</option>
                        {Array.isArray(item.recordsFiles) &&
                          item.recordsFiles.map((item: any, index) => (
                            <option value={item.path}>{item.fileTypeAttachmentName}</option>
                          ))}
                      </select>
                    </TBodyCell>
                    <TBodyCell align="center">
                      <select className="custom-select" onChange={(e) => onChangeStatusByRow(e, item.id)}>
                        {Array.isArray(listRecordStatus) &&
                          listRecordStatus.map((row) => {
                            if (item?.recordsStatusId?.toString() === row?.id?.toString())
                              return (
                                <option value={row.id} selected>
                                  {row.name}
                                </option>
                              )
                            return <option value={row.id}>{row.name}</option>
                          })}
                      </select>
                    </TBodyCell>
                    <TBodyCell align="center">
                      <div className="table-action d-flex align-items-center">
                        <NavLink to={links.records.editRecord(item.id.toString())}>
                          <EditIcon className="icon" />
                        </NavLink>

                        <DeleteIcon className="icon" onClick={(e) => handleOpenConfirm(e, item.id)} />

                        {getRecordId && <button onClick={() => getRecordId(item.id)}>Chon</button>}
                      </div>
                    </TBodyCell>
                  </TBodyRow>
                </>
              ))
            ) : (
              <TBodyRow>
                <TBodyCell colSpan={100}>
                  <p className="text-center"> Không có dữ liệu</p>
                </TBodyCell>
              </TBodyRow>
            )}
          </TBody>
        </TWrapper>
        {/* <PagePagination /> */}
        <div className="pagination">
          <p className="page-number">{`Trang ${pageIndex}/${totalPage}`}</p>
          <button className="first-page" onClick={gotoFirstPage}>
            <img src={FirstPageIcon} alt="" />
          </button>
          <button className="pre-page" onClick={gotoPrePage}>
            <img src={BackIcon} alt="" />
          </button>
          <select className="custom-select" onChange={onChangePage}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <button className="next-page" onClick={gotoNextPage}>
            <img src={NextIcon} alt="" />
          </button>
          <button className="last-page" onClick={gotoLastPage}>
            <img src={LastPageIcon} alt="" />
          </button>
        </div>
      </section>

      {recordId && (
        <DialogConfirm
          open={open}
          setOpen={setOpen}
          content={"Bạn có muốn xoá dòng dữ liệu này?"}
          //deleteRow={deleteSingleRecord}
          rowId={recordId}
          getList={GetListAll}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handleAccept={deleteSingleRecord}
        />
      )}

      {statusId && (
        <DialogConfirm
          open={openConfirrm}
          setOpen={setOpenConfirm}
          content={"Bạn có muốn cập nhật trạng thái của hồ sơ này?"}
          rowId={recordId}
          getList={GetListAll}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handleAccept={handlerConfirmChangeStatus}
        />
      )}
    </>
  )
}

export default ListConstruction
