import { createFormFromSchema, ObservableForm } from "@corets/form"
import { object, string } from "@corets/schema"
import { formatISODate } from "../../../oneportal/helpers/formatISODate"

export type changeStatusDoneFormType = {
  result?: string
  dateRealReturn?: string
}

export const changeStatusDoneForm = (
  data?: changeStatusDoneFormType
): ObservableForm<changeStatusDoneFormType, changeStatusDoneFormType> => {
  const form = createFormFromSchema<changeStatusDoneFormType>(
    object<changeStatusDoneFormType>({
      result: string()
        .toDefault(data?.result?.toString() || "")
        .required(true, "Bắt buộc"),
      dateRealReturn: string()
        .date("Ngày không đúng")
        .toDefault(formatISODate(data?.dateRealReturn) || "")
        .required(true, "Bắt buộc"),
    })
  ).handler(async (form) => {
    return form.get()
  })

  return form
}
