import { useForm } from "@corets/use-form"
import { Box, Button, FormControl, FormHelperText, Paper, Stack, TextField } from "@mui/material"
import { useState, useEffect } from "react"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { ReflectResponse } from "../../../units/types/ReflectResponse"
import { updateStatusReflectForm } from "../../CreateOrUpdateReflectDialog/updateStatusReflectForm"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"

interface ApproveTabProps {
  reflect: ReflectResponse
  onSuccess?: () => void
}

const ApproveTab = ({ reflect, onSuccess }: ApproveTabProps) => {
  const api = useApiClient()
  const alert = useAlert()

  const [form] = useForm(updateStatusReflectForm(api, reflect), [JSON.stringify(reflect)])

  const handleSubmit = async () => {
    if (!form.getAt("Reason")) {
      alert("Nội dung không được để trống", { variant: "error" })
      return
    }
    const errors = await form.validate()

    console.log("errors verify:>> ", errors)

    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Phê duyệt phản ánh thành công", { variant: "success" })
      onSuccess && onSuccess()
    } else {
      alert("Phê duyệt phản ánh thất bại", { variant: "error" })
      onSuccess && onSuccess()
    }
  }

  useEffect(() => {
    form.setAt("ProcessingStepCurrentId", reflect?.processingStepCurrentId)
    form.setAt("ReflectionStatusId", reflect?.reflectionStatusId)
    form.setAt("Reason", "Đồng ý kết quả")
  }, [reflect?.id])

  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="column" spacing={2}>
        <FormControl fullWidth>
          <label>
            Nội dung <span>*</span>
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={form.getAt("Reason")}
            onChange={(event, editor) => {
              const data = editor.getData()
              form.setAt("Reason", data)
            }}
          />
          <FormHelperText error={!!form.getErrorsAt("Reason")} sx={{ ml: 0 }}>
            Nội dung bắt buộc nhập
          </FormHelperText>
        </FormControl>
        <Box textAlign="center">
          <Button variant="contained" disabled={form.isSubmitting()} onClick={handleSubmit}>
            Thực hiện
          </Button>
        </Box>
      </Stack>
    </Paper>
  )
}

export default ApproveTab
