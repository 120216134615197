import { useHistory } from "react-router-dom"
import { links } from "../../../config/links"
import { WideDialog } from "../../oneportal/components/Dialogs/WideDialog"
import ListProject from "../pages/ListProject/ListProject"

export type ProjectListDialogProps = {
  getProjectDetail: (recordId: number) => void
}
export const ProjectListDialog = (props: ProjectListDialogProps) => {
  const { getProjectDetail } = props
  const history = useHistory()

  const handleClose = () => history.push(links.records.addRecord())

  const handleGetProjectId = (recordId: number) => {
    getProjectDetail(recordId)
    handleClose()
  }

  return (
    <WideDialog maxWidth="xl" title="Tìm kiếm công trình cũ" onClose={handleClose}>
      <ListProject isEdit getProjectId={handleGetProjectId} />
    </WideDialog>
  )
}
