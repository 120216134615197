import { AxiosInstance } from "axios"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedArrayResponse } from "../../../oneportal/units/types/ChunkedArrayResponse"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { ChunkedLookupResponse } from "../../../oneportal/units/types/ChunkedLookupResponse"
import { RecordsTypes } from "../../units/types/RecordsTypes"

export type RecordsTypeParams = ChunkedParams & {
  nanme?: number
}

export const getAllRecordsType = async (
  api: AxiosInstance,
  params?: RecordsTypeParams
): Promise<ChunkedPaginationResponse<RecordsTypes> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<RecordsTypes>>("/api/admin/RecordsType/GetAll", { params: req })
    return res.data
  } catch (err) {}
}

export const getLookupRecordsType = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/RecordsType/GetLookup")
    return res.data
  } catch (err) {}
}
