import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

export const TypographyTruncate = styled(Typography)(() => ({
  fontSize: 14,
  display: "-webkit-box",
  maxWidth: "300px",
  "-webkitLineClamp": "3",
  "-webkitBoxOrient": "vertical",
  overflow: "hidden",
}))
