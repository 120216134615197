import { createFormFromSchema, ObservableForm } from "@corets/form"
import { array, boolean, object, string, number } from "@corets/schema"
import { AxiosInstance } from "axios"
import { createReflect, updateReflect } from "../../../services/api/reflectTheScene"
import { createComment, updateComment } from "../../../services/api/comment"
import { CommentRequest } from "../../../units/types/CommentRequest"
import { CommentResponse } from "../../../units/types/CommentResponse"

const fileActions = {
  DELETE: "delete",
  INSERT: "insert",
  UPDATE: "update",
}

export const createCreateOrUpdateCommentForm = (
  api: AxiosInstance,
  data?: CommentResponse
): ObservableForm<CommentRequest, boolean> => {
  const form = createFormFromSchema<CommentRequest, boolean>(
    object<CommentRequest>({
      Id: string()
        .numeric()
        .optional()
        .toDefault(data?.id?.toString() || ""),
      Content: string()
        .required(true, "Nội dung không được để trống")
        .toDefault(data?.content?.toString() || ""),
      IsReply: boolean().optional().toDefault(!!data?.isReply),
      ReflectionId: string()
        .numeric()
        .required(true, "Mã phản ánh không được trống")
        .toDefault(data?.reflectionId?.toString() || ""),
      NewsId: string()
        .numeric()
        .optional()
        .toDefault(data?.newsId?.toString() || ""),
      ParentId: string()
        .numeric()
        .optional()
        .toDefault(data?.parentId?.toString() || ""),
      IsDelete: boolean()
        .optional()
        .toDefault(data?.isDelete || false),
      IsActive: boolean().optional().toDefault(data?.isActive),
      files: array()
        .optional()
        .toDefault(data?.commentFilesViewDtos || []),
      deletedFiles: array().optional(),
    })
  ).handler(async (form) => {
    const datas = (await form.get()) as any

    const formData = new FormData()

    for (let key in datas) {
      if (Array.isArray(datas[key])) {
        const arr = datas[key]?.filter((item) => item !== undefined)
        let fileIndex = 0
        if (key === "files") {
          for (let i = 0; i < arr.length; i++) {
            if (!arr[i].fileId) {
              formData.append(`fileInfos[${fileIndex}].Action`, fileActions.INSERT)
              formData.append(`fileInfos[${fileIndex}].File`, arr[i])
              fileIndex++
            }
          }
        } else if (key === "deletedFiles") {
          for (let i = 0; i < arr.length; i++) {
            formData.append(`fileInfos[${fileIndex}].Action`, fileActions.DELETE)
            formData.append(`fileInfos[${fileIndex}].Id`, arr[i].fileId)
            fileIndex++
          }
        } else {
          for (let element of arr) {
            formData.append(key, element)
          }
        }
      } else {
        formData.append(key, datas[key])
      }
    }

    if (datas?.Id) {
      const res = await updateComment(api, formData)
      if (res?.content?.status) return true
    } else {
      delete datas.id
      const res = await createComment(api, formData)
      if (res?.content?.status) return true
    }
    return false
  })

  return form
}
