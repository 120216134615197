import { Autocomplete, FormHelperText } from "@mui/material"
import clsx from "clsx"
import TextField from "@material-ui/core/TextField";

export type MUIAutocompleteProps<TValue = string> = {
  options: TValue[]
  getOptionLabel: (option) => string
  noOptionsText?: string
  onChange?: (event, value: unknown, reason) => void
  className?: string
  value?: string[]
  placeholder?: string
  isError?: string[]
  disabled?: boolean
}
export const MUIAutocompleteMulti = (props: MUIAutocompleteProps) => {
  const {
    options,
    getOptionLabel,
    onChange,
    placeholder = "Chọn",
    className,
    noOptionsText,
    isError,
    disabled = false,
    value,
  } = props
  
  return (
    <>
      <Autocomplete
        disabled={disabled}
        multiple  
        options={options}
        getOptionLabel={getOptionLabel}
        id="controlled"
        value={value}
        onChange={onChange}
        noOptionsText={noOptionsText}
        className={className}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={`--${placeholder}--`}
            className={clsx("form-control", isError && "is-invalid")}
          />
        )}
      />
      <FormHelperText error={!!(isError && isError.length)} children={isError} />
    </>
  )
}
