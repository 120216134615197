import { Autocomplete, FormHelperText } from "@mui/material"
import clsx from "clsx"

export type MUIAutocompleteProps<TValue = string> = {
  options: TValue[]
  getOptionLabel: (option) => string
  noOptionsText?: string
  onChange?: (event, value: unknown, reason) => void
  className?: string
  value?: TValue
  placeholder?: string
  isError?: string[]
  disabled?: boolean
}
export const MUIAutocomplete = (props: MUIAutocompleteProps) => {
  const {
    options,
    value,
    getOptionLabel,
    onChange,
    placeholder = "Chọn",
    className,
    noOptionsText,
    isError,
    disabled = false,
  } = props

  return (
    <>
      <Autocomplete
        disabled={disabled}
        options={options}
        getOptionLabel={getOptionLabel}
        id="controlled"
        value={value}
        onChange={onChange}
        noOptionsText={noOptionsText}
        className={className}
        isOptionEqualToValue={(option, value) => option === value}
        renderInput={(params) => {
          return (
            <div ref={params.InputProps.ref}>
              <input
                type="text"
                placeholder={`--${placeholder}--`}
                {...params.inputProps}
                className={clsx("form-control custom-select", isError && "is-invalid")}
              />
            </div>
          )
        }}
      />
      <FormHelperText error={!!(isError && isError.length)} children={isError} />
    </>
  )
}
