import { useEffect, useState } from "react"
import createActivityDetector from "activity-detector"

export type useIdleOptions = {
  timeToIdle: number
  inactivityEvents: any[]
  activityEvents: string[]
}

export const useIdle = (options: useIdleOptions) => {
  const [isIdle, setIsIdle] = useState(false)

  useEffect(() => {
    const activityDetector = createActivityDetector(options)
    activityDetector.on("idle", () => setIsIdle(true))
    activityDetector.on("active", () => setIsIdle(false))

    return () => activityDetector.stop()
  }, [options])

  return isIdle
}
