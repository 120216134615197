import { useAsync } from "@corets/use-async"
import { useForm } from "@corets/use-form"
import { useHistory } from "react-router"
import { InputDatePicker } from "../../../oneportal/components/DatePicker"
import { Select } from "../../../oneportal/components/Select"
import { createMuiFormBinder } from "../../../oneportal/helpers/createMuiFormBinder"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { getLookupProjectGroup } from "../../../records/services/api/projectGroup"
import { getLookupProjectType } from "../../../records/services/api/projectType"
import { getLookupRecordsType } from "../../../records/services/api/recordTypes"
import { createFilterReportForm } from "./createFilterReportForm"
import { ArrowLeft } from "../../../../assets/images"
import { exportFile } from "../../service/api/report"
import { useState } from "react"
import { CircularProgress } from "@mui/material"

export type FilterReportFormProps = {
  uri: string
}
export const FilterReportForm = (props: FilterReportFormProps) => {
  const { uri } = props
  const history = useHistory()
  const api = useApiClient()
  const [isExporting, setIsExporting] = useState(false)
  const [isPreviewing, setIsPreviewing] = useState(false)

  const [form] = useForm(createFilterReportForm(), [])
  const bind = createMuiFormBinder(form)
  const pageOptions = useAsync(async () => {
    const [recordTypes, projectGroup, projectType] = await Promise.all([
      getLookupRecordsType(api),
      getLookupProjectGroup(api),
      getLookupProjectType(api),
    ])

    return {
      recordTypes,
      projectGroup,
      projectType,
    }
  }, [])

  const handleSubmit = async (isPreview?: boolean) => {
    const validationErrors = await form.validate()
    if (validationErrors) return
    const data = await form.get()
    isPreview ? setIsPreviewing(true) : setIsExporting(true)
    const requestParams = {
      ...data,
      fileExtention: isPreview ? "pdf" : "xlsx",
    }

    const results = await exportFile(uri, api, requestParams)
    if (results) {
      setIsPreviewing(false)
      setIsExporting(false)
      const fileType = isPreview
        ? "application/pdf"
        : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const url = window.URL.createObjectURL(new Blob([results], { type: fileType }))
      if (isPreview) {
        window.open(url, "_blank")
        return
      }
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `Baocao.xlsx`)
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    }
  }

  return (
    <form action="">
      <div className="form-row">
        <div className="form-group col-12 col-lg-6">
          <label htmlFor="type">Loại dịch vụ công</label>
          <Select
            options={pageOptions.getResult()?.recordTypes?.content}
            bind={bind}
            field={"RecordTypeId"}
            labelDefaultOption="Chọn loại dịch vụ công"
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="date-from">Từ ngày</label>
              <InputDatePicker {...bind.datePicker("FromDate")} bind={bind} field="fromDate" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="date-to">Từ ngày</label>
              <InputDatePicker {...bind.datePicker("ToDate")} bind={bind} field="toDate" />
            </div>
          </div>
        </div>
        <div className="form-group col-12 col-lg-6">
          <div className="form-group">
            <label htmlFor="type-project">Loại công trình</label>
            <Select
              options={pageOptions.getResult()?.projectType?.content}
              bind={bind}
              field={"ProjectTypeId"}
              labelDefaultOption="Chọn loại công trình"
            />
          </div>
        </div>
        <div className="form-group col-12 col-lg-6">
          <div className="form-group">
            <label htmlFor="group-project">Nhóm dự án</label>
            <Select
              options={pageOptions.getResult()?.projectGroup?.content}
              bind={bind}
              field={"GroupProjectId"}
              labelDefaultOption="Chọn nhóm dự án"
            />
          </div>
        </div>
      </div>
      <div className="form__save">
        <button className="btn btn-ct btn-back-ct btn-back-bg" type="button" onClick={() => history.goBack()}>
          <img src={ArrowLeft} alt="" />
          Quay lại
        </button>
        <button className="btn btn-ct btn-default-ct" type="button" onClick={() => form.clear()}>
          Xoá
        </button>
        <button className="btn btn-ct btn-primary-ct loading" type="button" onClick={() => handleSubmit()}>
          {isExporting && <CircularProgress size={20} color="inherit" />}
          Xuất báo cáo
        </button>
        <button className="btn btn-ct btn-success-ct loading" type="button" onClick={() => handleSubmit(true)}>
          {isPreviewing && <CircularProgress size={20} color="inherit" />}
          Xem trước
        </button>
      </div>
    </form>
  )
}
