import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { Button, IconButton } from "@mui/material"
import { Add, AttachFile, Remove, Close } from "@mui/icons-material"
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tab } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import Slide from "@mui/material/Slide"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import React, { useEffect, useState } from "react"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { createCreateOrUpdateCommentForm } from "./CreateOrUpdateCommentForm"
import { deleteComment } from "../../../services/api/comment"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface ConfirmDialogProps {
  commentId?: any
  open: boolean
  handleClose: () => void
  onSuccess?: () => void
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const { commentId, open, handleClose, onSuccess } = props
  const theme = useTheme()
  const api = useApiClient()
  const alert = useAlert()

  const deleteCommentAction = useAction(async () => await deleteComment(api, commentId || 0))

  const handleSubmit = async () => {
    try {
      await deleteCommentAction.run()
      handleClose()
      onSuccess && onSuccess()
      alert("Xóa phải hồi thành công", { variant: "success" })
    } catch (error) {
      alert("Xóa phải hồi thất bại", { variant: "error" })
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, color: "#fff" }}>
        Xác nhận xóa phản hồi
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>Bạn có muốn xóa phản hồi này không?</DialogContent>
      <Divider />
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          Đóng
        </Button>
        <LoadingButton
          loading={deleteCommentAction.isRunning()}
          variant="contained"
          onClick={handleSubmit}
          color="error"
        >
          Xóa
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
