import { useState } from "react"
import { createPortal } from "react-dom"
import { Box, Zoom } from "@mui/material"
import { PlayArrow } from "@mui/icons-material"

interface ZoomableVideoProps {
  src: string
  [key: string]: any
}

const ZoomableVideo = ({ src, boxProps, ...props }: ZoomableVideoProps) => {
  const [isZoom, setZoom] = useState(false)

  return (
    <>
      <Box sx={{ position: "relative" }} {...boxProps}>
        <video src={src} {...props} onClick={() => setZoom(true)} />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: 40,
            height: 40,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            borderRadius: 9999,
          }}
          onClick={() => setZoom(true)}
        >
          <PlayArrow sx={{ fontSize: 36 }} />
        </Box>
      </Box>
      {isZoom && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: "36px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 500,
          }}
          onClick={() => setZoom(false)}
        >
          <Zoom in={isZoom}>
            <video
              src={src}
              controls
              style={{
                width: "auto",
                maxWidth: "calc(100vw - 72px)",
                maxHeight: "calc(100vh - 72px)",
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </Zoom>
        </Box>
      )}
    </>
  )
}

export default ZoomableVideo
