/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useForm } from "@corets/use-form"
import { useAsync } from "@corets/use-async"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router"
import { links } from "../../../../../config/links"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { createCreateOrUpdateCategoryForm } from "./createCreateOrUpdateCategoryForm"
import { getLookupReflectionFields } from "../../../../oneportal/services/api/administrative"
import { Select } from "../../../../oneportal/components/Select"
import { getCategoryDetail } from "../../../services/api/category"
import { UploadImage } from "../../../../oneportal/components/UploadImage"
import ChangeAvatarIcon from "../../../../../assets/image/change-avatar-blue.png"
import "../../../../../assets/style/userInfo.css"
import { readImageFile } from "../../../../oneportal/helpers/readImageFile"

export type CreateOrUpdateCategoryDialogProps = {
  onSuccess: () => void
}

export const CreateOrUpdateCategoryDialog = (props: CreateOrUpdateCategoryDialogProps) => {
  const { onSuccess } = props
  const api = useApiClient()
  const alert = useAlert()
  const history = useHistory()
  const inputRef = useRef<HTMLInputElement>(null)
  const [imagePreview, setImagePreview] = useState<string | undefined>()

  const { categoryId } = useParams<{
    categoryId: string
  }>()

  const categoryDetail = useAction(async () => await getCategoryDetail(api, categoryId))
  const reflectionOptions = useAsync(() => getLookupReflectionFields(api), [])

  const [form] = useForm(createCreateOrUpdateCategoryForm(api, categoryDetail.getResult()?.content), [
    JSON.stringify(categoryDetail.getResult()?.content),
  ])

  const bind = createMuiFormBinder(form)

  useEffect(() => {
    categoryId && categoryDetail.run()
  }, [categoryId])

  useEffect(() => {
    categoryDetail.getResult()?.content &&
      setImagePreview(readImageFile(categoryDetail.getResult()?.content?.categoryFilePath as string))
  }, [JSON.stringify(categoryDetail.getResult()?.content)])

  const handleSubmit = async () => {
    const errors = await form.validate()

    if (errors) return

    const res = await form.submit()

    if (res) {
      alert("Thành công", { variant: "success" })
      handleClose()
      onSuccess()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const handleClose = () => history.push(links.category.categoryManagement())

  return (
    <WideDialog title={categoryId ? "Chỉnh sửa lĩnh vực" : "Thêm lĩnh vực"} onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-12">
            <label>
              Tên chuyên mục <span>*</span>
            </label>
            <input {...bind.textField("name")} />
            <FormHelperText {...bind.helperText("name")} />
          </div>

          <div className="form-group col-12 col-md-12">
            <label>
              Lĩnh vưc <span>*</span>
            </label>
            <Select
              options={reflectionOptions.getResult()?.content}
              bind={bind}
              field="reflectionFieldId"
              labelDefaultOption="Chọn lĩnh vực"
            />
          </div>
          <div className="form-group col-12 col-md-12">
            <label>Ảnh đại diện</label>
            <div className="user__avatar">
              <div className="user__img">
                <img src={imagePreview} alt="" />
                <button type="button" className="change__avatar" onClick={() => inputRef?.current?.click()}>
                  <img src={ChangeAvatarIcon} alt="" />
                </button>
                <UploadImage inputRef={inputRef} form={form} setImagePreview={setImagePreview} field="file" />
              </div>
            </div>
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button
              disabled={form.isSubmitting() || !form.isChanged()}
              type="button"
              onClick={handleSubmit}
              className="btn btn-ct btn-primary-ct loading"
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {categoryId ? "Lưu thay đổi" : "Thêm"}
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
