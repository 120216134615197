import { ProjectInfo } from "./enums/ProjectInfo"

export const projectInfoOptions = [
  {
    label: "Công trình mới",
    value: ProjectInfo.New,
  },
  {
    label: "Công trình cũ",
    value: ProjectInfo.Old,
  },
]

export const resultOptions = [
  {
    label: "Đang xử lý",
    value: "",
  },
  {
    label: "Đạt",
    value: "true",
  },
  {
    label: "Không đạt",
    value: "false",
  },
]

export const headerQLHS = [
  { name: "STT" },
  { name: "Công trình" },
  { name: "Chủ đầu tư" },
  { name: "Địa điểm" },
  { name: "Loại công trình" },
  { name: "Loại hồ sơ" },
  { name: "Hình thức tiếp nhận" },
  { name: "Thời gian tiếp nhận" },
  { name: "Ngày hẹn trả kết quả" },
  { name: "Loại văn bản trả lời" },
  { name: "Số văn bản trả lời" },
  { name: "Ngày văn bản trả lời" },
  { name: "File đính kèm" },
  { name: "Trạng thái hồ sơ" },
  { name: "Hành động" },
]

export const headerQLCT = [
  { name: "STT" },
  { name: "Nhóm Công trình" },
  { name: "Tên Công trình" },
  { name: "Chủ đầu tư" },
  { name: "Địa điểm" },
  { name: "Loại công trình" },
  { name: "Trạng thái công trình" },
]

export const WordExtension = [".doc", ".docx"]
export const PptExtension = [".ppt", ".pptx"]
export const ExcelExtension = [".xls", ".xlsx", ".csv"]
export const ImageExtension = [".jpeg", ".jpg", ".png"]
