import { ObservableForm } from "@corets/form"
import { FormHelperText } from "@mui/material"
import clsx from "clsx"
import { useRef, useState } from "react"
import { DeleteIcon, DownLoadIcon } from "../../../../assets/icons"
import { AlertDialog } from "../../../oneportal/components/Dialogs/AlertDialog"
import { useAlert } from "../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../oneportal/hooks/useApiClient"
import { uploadFileStream } from "../../../oneportal/services/api/documentUpload"
import { UploadFile } from "../../../oneportal/components/UploadFile"
import { AttachFileType } from "./RecordForm"
import { readImageFile } from "../../../oneportal/helpers/readImageFile"

export type AttachFileProps = {
  form: ObservableForm
  showDownloadButton: boolean
  attachFile: AttachFileType | undefined
  setAttachFile: (data: AttachFileType | undefined) => void
  showRequiredTextForAttachFile: boolean
}
export const AttachFile = (props: AttachFileProps) => {
  const { form, showDownloadButton, attachFile, showRequiredTextForAttachFile, setAttachFile } = props
  const api = useApiClient()
  const alert = useAlert()
  const inputRef = useRef<HTMLInputElement>(null)

  const [choosedFile, setChoosedFile] = useState<{ fileIndex: number; fileTypeAttachmentId: number } | undefined>(
    undefined
  )
  const [fileId, setFileId] = useState<number | undefined>(undefined)

  const handleClickChooseFile = (fileIndex: number, fileTypeAttachmentId: number) => {
    setChoosedFile({ fileIndex, fileTypeAttachmentId })
    inputRef?.current?.click()
  }

  const handleUploadFile = async (file: File) => {
    const formData = new FormData()
    formData.append("file", file)
    const res = await uploadFileStream(api, formData)
    if (res) {
      const newAttachFile = attachFile!.content.map((item, index) => {
        if (index === choosedFile?.fileIndex) {
          return {
            ...item,
            fileId: res.content.content.fileId,
            fileName: file.name,
          }
        }
        return item
      })
      setAttachFile({ content: newAttachFile, err: null })
      form.setAt("recordFile", [
        ...newAttachFile
          .filter((item) => item.fileId)
          .map((result) => {
            return { fileId: result.fileId, fileTypeAttachmentId: result.id }
          }),
      ])
    } else {
      alert("Upload file thất bại", { variant: "error" })
    }
    setChoosedFile(undefined)
  }

  const handleDeleteFile = async () => {
    const newAttachFile = attachFile!.content.map((item, index) => {
      if (item.fileId === fileId) {
        delete item.fileName
        delete item.fileId
        return item
      }
      return item
    })
    setAttachFile({ content: newAttachFile, err: null })
    form.setAt(
      "recordFile",
      form.getAt("recordFile").filter((item) => item.fileId !== fileId)
    )
    handleCloseAlertDialog()
  }

  const handleCloseAlertDialog = () => setFileId(undefined)

  return (
    <>
      <AlertDialog
        title="Xóa file đính kèm"
        textContent="Bạn có muốn xóa file này không?"
        open={fileId === 0 || !!fileId}
        onClose={handleCloseAlertDialog}
        onAgree={handleDeleteFile}
      />

      <UploadFile onUploadFile={handleUploadFile} inputRef={inputRef} />
      {attachFile?.content.length ? (
        <section className="file__attach wrap-table">
          <div className="form-group">
            <label>File đính kèm</label>
          </div>
          <table className="table table-bordered table-responsive-lg">
            <tbody>
              {attachFile?.content.map((item, index) => (
                <tr
                  key={index}
                  className={clsx(
                    showRequiredTextForAttachFile && item.isRequired && !item.fileName && "border-error-tr"
                  )}
                >
                  <th scope="row">{index + 1}</th>
                  <td>
                    {item.name}
                    {item.isRequired && <span className="text-danger">*</span>}
                    {showRequiredTextForAttachFile && item.isRequired && !item.fileName && (
                      <div>
                        <FormHelperText error={true}>Bắt buộc</FormHelperText>
                      </div>
                    )}
                  </td>
                  <td>{item.fileName}</td>
                  {showDownloadButton && (
                    <td>
                      {item.fileId && item.path ? (
                        <div className="table-action d-flex align-items-center justify-content-center">
                          <a href={readImageFile(item.path)} className="edit" download target="_blank" rel="noreferrer">
                            <DownLoadIcon className="icon" />
                          </a>
                        </div>
                      ) : null}
                    </td>
                  )}
                  {item.fileId ? (
                    <>
                      <td>
                        <div className="table-action d-flex align-items-center justify-content-center">
                          <DeleteIcon className="icon" onClick={() => setFileId(item.fileId)} />
                        </div>
                      </td>
                      <td></td>
                    </>
                  ) : (
                    <>
                      <td></td>
                      <td>
                        <div className="table-input d-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn btn-ct btn-primary-ct"
                            onClick={() => handleClickChooseFile(index, item.id)}
                          >
                            Chọn file
                          </button>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      ) : null}
    </>
  )
}
