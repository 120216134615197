import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export type VerticalBarChartProps = {
  labels?: string[]
  dataFirstColumn?: number[]
  dataSecondColumn?: number[]
  legend: string[]
  backgroundColor: string[]
}
export const VerticalBarChart = (props: VerticalBarChartProps) => {
  const { labels, dataFirstColumn, dataSecondColumn, legend, backgroundColor } = props

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          font: {
            size: 20,
          },
        },
      },
    },
  }

  const data = {
    labels,
    datasets: [
      {
        label: legend[0],
        data: dataFirstColumn,
        backgroundColor: backgroundColor[0],
      },
      {
        label: legend[1],
        data: dataSecondColumn,
        backgroundColor: backgroundColor[1],
      },
    ],
  }

  return <Bar options={options} data={data} height={500} width={2000} />
}
