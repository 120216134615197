import { AxiosInstance } from "axios"
import { ChunkedParams } from "../../../oneportal/units/types/ChunkedParams"
import { createChunkedParams } from "../../../oneportal/helpers/createChunkedParams"
import { ChunkedPaginationResponse } from "../../../oneportal/units/types/ChunkedPaginationResponse"
import { Categories } from "../../units/types/categories"
import { ChunkedResponse } from "../../../oneportal/units/types/ChunkedResponse"
import { ChunkedStatusResponse } from "../../../oneportal/units/types/ChunkedStatusResponse"

export type categoryParams = ChunkedParams & {
  sortExpression?: string
  name?: string
  reflectionFieldId?: string
}

export const getAllCategories = async (
  api: AxiosInstance,
  params?: categoryParams
): Promise<ChunkedPaginationResponse<Categories> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<Categories>>("api/admin/Categories/GetList", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const deleteCategory = async (
  api: AxiosInstance,
  CategoryId: number
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.put<ChunkedResponse<ChunkedStatusResponse>>(`/api/admin/Categories/Delete?id=${CategoryId}`)

    return res.data
  } catch (err) {}
}

export const createCategory = async (
  api: AxiosInstance,
  req: FormData
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.post<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Categories/Create", req)
    return res.data
  } catch (err) {}
}

export const updateCategory = async (
  api: AxiosInstance,
  req: FormData
): Promise<ChunkedResponse<ChunkedStatusResponse> | undefined> => {
  try {
    const res = await api.put<ChunkedResponse<ChunkedStatusResponse>>("/api/admin/Categories/Update", req)
    return res.data
  } catch (err) {}
}

export const getCategoryDetail = async (
  api: AxiosInstance,
  CommuneId: string
): Promise<ChunkedResponse<Categories> | undefined> => {
  try {
    const res = await api.get<ChunkedResponse<Categories>>(`/api/admin/Categories/GetById?id=${CommuneId}`)
    return res.data
  } catch (err) {}
}
