/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useEffect, useState } from "react"
import { Route, useHistory } from "react-router-dom"
import { EditIcon, DeleteIcon } from "../../../assets/icons"
import { WPlus } from "../../../assets/images"
import { links } from "../../../config/links"
import { AlertDialog } from "../../oneportal/components/Dialogs/AlertDialog"
import { PageContentWithSearchBar } from "../../oneportal/components/PageContentWithSearchbar"
import { PagePagination } from "../../oneportal/components/PagePagination/PagePagination"
import { usePagePagination } from "../../oneportal/components/PagePagination/usePagePagination"
import { TBody } from "../../oneportal/components/Table/TBody"
import { TBodyCell } from "../../oneportal/components/Table/TBodyCell"
import { TBodyRow } from "../../oneportal/components/Table/TBodyRow"
import { TBodySkeleton } from "../../oneportal/components/Table/TBodySkeleton"
import { THead } from "../../oneportal/components/Table/THead"
import { THeadCell } from "../../oneportal/components/Table/THeadCell"
import { THeadRow } from "../../oneportal/components/Table/THeadRow"
import { TPlaceholder } from "../../oneportal/components/Table/TPlaceholder"
import { TWrapper } from "../../oneportal/components/Table/TWrapper"
import { useTableSorter } from "../../oneportal/components/Table/useTableSorter"
import { useAlert } from "../../oneportal/hooks/useAlert"
import { useApiClient } from "../../oneportal/hooks/useApiClient"
import { useQuery } from "../../oneportal/hooks/useQuery"
import { CreateOrUpdateCommuneDialog } from "../components/Commune/CreateOrUpdateCommuneDialog/CreateOrUpdateCommuneDialog"
import "../../../assets/style/danhmuc.css"
import { SearchCommuneFormType } from "../components/Commune/SearchCommuneForm/createSearchCommuneForm"
import { SearchCommuneForm } from "../components/Commune/SearchCommuneForm/SearchCommuneForm"

import { getAllCommune, deleteCommune, communeParams } from "../services/api/commune"
import { dateVNFormat } from "../../oneportal/helpers/utils"

const ListProvince = () => {
  const api = useApiClient()
  const history = useHistory()
  const sorter = useTableSorter()
  const alert = useAlert()

  const communeAction = useAction(async () => await getAllCommune(api, communeParams))
  const [communeId, setCommuneId] = useState<number | undefined>(undefined)

  const pagination = usePagePagination({
    pageCount: communeAction.getResult()?.content.pageCount,
  })
  const [query, , pathQuery] = useQuery<communeParams>({})

  const communeParams: communeParams = {
    sortExpression: sorter.query.sort && `${sorter.query.sort} ${sorter.query.direction}`,
    pageIndex: pagination.query.pageIndex,
    pageSize: pagination.query.pageSize,
    name: query.keyword,
    provinceId: query.provinceId,
    districtId: query.districtId,
  }

  useEffect(() => {
    communeAction.run()
  }, [JSON.stringify(communeParams)])

  const handleSearchCommune = (data: SearchCommuneFormType) =>
    pathQuery({ ...data, pageSize: undefined, pageIndex: undefined })

  const handleDeleteCommune = async () => {
    const res = await deleteCommune(api, communeId as number)
    setCommuneId(undefined)
    if (res?.content.status) {
      alert("Thành công", { variant: "success" })
      communeAction.run()
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  return (
    <PageContentWithSearchBar
      pageTitle="Danh sách phường xã"
      sectionClassName="qlds-page"
      searchbarClassName="list-user-form"
      searchBar={
        (!!communeAction.getResult()?.content.items.length || !!Object.keys(query).length) && (
          <SearchCommuneForm onSubmit={handleSearchCommune} defaultValue={query} />
        )
      }
    >
      <>
        <TWrapper
          //widths={["10%", "30%", "30%", "30%"]}
          tableClassName="table__user"
          tableContainerClassName="wrap-table__cate"
        >
          {communeAction.isRunning() && <TBodySkeleton count={10} colSpan={8} />}
          {!!communeAction.getResult()?.content.items.length && (
            <>
              <THead>
                <THeadRow>
                  <THeadCell>STT</THeadCell>
                  <THeadCell {...sorter.bind("provinceName")}>Tỉnh thành</THeadCell>
                  <THeadCell {...sorter.bind("districtName")}>Tên quận, huyện</THeadCell>
                  <THeadCell {...sorter.bind("name")}>Tên phường, xã</THeadCell>
                  <THeadCell {...sorter.bind("modifiedDate")}>Ngày sửa</THeadCell>
                  <THeadCell {...sorter.bind("modifiedBy")}>Người sửa</THeadCell>
                  <THeadCell>Hành động</THeadCell>
                </THeadRow>
              </THead>
              <TBody>
                {!communeAction.isRunning() &&
                  communeAction.getResult()?.content.items.map((item, index) => (
                    <TBodyRow key={item.id}>
                      <TBodyCell>
                        {(pagination.query.pageSize || 10) *
                          (pagination.query.pageIndex ? pagination.query.pageIndex - 1 : 0) +
                          index +
                          1}
                      </TBodyCell>
                      <TBodyCell>{item.provinceName}</TBodyCell>
                      <TBodyCell>{item.districtName}</TBodyCell>
                      <TBodyCell>{item.name}</TBodyCell>
                      <TBodyCell>{dateVNFormat(item.modifiedDate)}</TBodyCell>
                      <TBodyCell>{item.modifiedBy}</TBodyCell>
                      <TBodyCell>
                        <div className="action__user table-action d-flex align-items-center">
                          <button
                            type="button"
                            className="border-0 mr-3"
                            onClick={() => history.push(links.category.updateCommune(item.id.toString()))}
                          >
                            <EditIcon />
                          </button>
                          <button type="button" className="border-0" onClick={() => setCommuneId(item.id)}>
                            <DeleteIcon className="icon" />
                          </button>
                        </div>
                      </TBodyCell>
                    </TBodyRow>
                  ))}
              </TBody>
            </>
          )}
        </TWrapper>
        {!communeAction.isRunning() && !communeAction.getResult()?.content.items.length && !Object.keys(query).length && (
          <TPlaceholder title="Không có dữ liệu">
            <button
              className="btn  btn-ct  btn-add btn-danger-ct  d-flex align-items-center"
              type="button"
              onClick={() => history.push(links.category.createCommune())}
            >
              Thêm phường xã
              <img src={WPlus} alt="" />
            </button>
          </TPlaceholder>
        )}
        {!communeAction.isRunning() &&
          !communeAction.getResult()?.content.items.length &&
          !!Object.keys(query).length && <TPlaceholder title="Không có dữ liệu trùng khớp" />}
        {!!communeAction.getResult()?.content.items.length && (
          <PagePagination
            pageSize={pagination.query.pageSize}
            pageCount={communeAction.getResult()?.content.pageCount}
            {...pagination.bind()}
            pageIndex={pagination.query.pageIndex}
          />
        )}
      </>
      <Route path={links.category.createCommune()} exact>
        <CreateOrUpdateCommuneDialog onSuccess={() => communeAction.run()} />
      </Route>
      <Route path={links.category.updateCommune()} exact>
        <CreateOrUpdateCommuneDialog onSuccess={() => communeAction.run()} />
      </Route>

      <AlertDialog
        title="Xóa phường xã"
        textContent="Bạn có muốn xoá dòng dữ liệu này?"
        open={!!communeId}
        onClose={() => setCommuneId(undefined)}
        onAgree={handleDeleteCommune}
      />
    </PageContentWithSearchBar>
  )
}

export default ListProvince
