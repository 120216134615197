/* eslint-disable react-hooks/exhaustive-deps */
import { useAction } from "@corets/use-action"
import { useAsync } from "@corets/use-async"
import { useForm } from "@corets/use-form"
import { CircularProgress, FormHelperText } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { ChangeAvatarBlue } from "../../../../../assets/images"
import { WideDialog } from "../../../../oneportal/components/Dialogs/WideDialog"
import { DropZone } from "../../../../oneportal/components/DropZone/DropZone"
import { Select } from "../../../../oneportal/components/Select"
import { UploadImage } from "../../../../oneportal/components/UploadImage"
import { createMuiFormBinder } from "../../../../oneportal/helpers/createMuiFormBinder"
import { readImageFile } from "../../../../oneportal/helpers/readImageFile"
import { useAlert } from "../../../../oneportal/hooks/useAlert"
import { useApiClient } from "../../../../oneportal/hooks/useApiClient"
import { getLookupReflectionProcessingUnit } from "../../../../oneportal/services/api/administrative"
import { genderOptions } from "../../../../oneportal/units/constanst"
import { ErrorTypeEnum } from "../../../../oneportal/units/enums/ErrorTypeEnum"
import { ErrorType } from "../../../../oneportal/units/types/ErrorType"
import { getLookupRole } from "../../../services/api/role"
import { createUser, getUserDetail, updateUser } from "../../../services/api/user"
import { createCreateOrUpdateUserForm } from "./createCreateOrUpdateUserForm"
import { MUIAutocompleteMulti } from "../../../../oneportal/components/Autocomplete/MUIAutocompleteMulti"
import { isEmpty } from "lodash-es"

export type CreateOrUpdateUserDialogProps = {
  onSuccess: () => void
  setShowModalCreate: (open: boolean) => void
  selectedId: string
}

export const CreateOrUpdateUserDialog = (props: CreateOrUpdateUserDialogProps) => {
  const { onSuccess, setShowModalCreate, selectedId } = props
  const api = useApiClient()
  const [roleValues, setRoleValues] = useState<Array<string>>([""])
  const [isSubmitted, setIsSubmitted] = useState(false)

  const userDetail = useAction(async () => await getUserDetail(api, selectedId))
  const [imagePreview, setImagePreview] = useState<string | undefined>(undefined)

  const handleErrorCallBack = (error?: ErrorType) => {
    if (error?.errorType === ErrorTypeEnum.UserExisted) {
      alert("Email đã tồn tại", { variant: "error" })
    } else {
      alert("Thất bại", { variant: "error" })
    }
  }

  const [form] = useForm(() => {
    setImagePreview(readImageFile(userDetail.getResult()?.content?.avatar as string))
    setRoleValues(userDetail.getResult()?.content?.roles?.map((item) => item?.id) as string[])
    return createCreateOrUpdateUserForm(api, userDetail.getResult()?.content)
  }, [JSON.stringify(userDetail.getResult()?.content)])

  const bind = createMuiFormBinder(form)
  const alert = useAlert()

  const inputRef = useRef<HTMLInputElement>(null)

  const pageOptions = useAsync(async () => {
    const [roleOptions, reflectionUnitOptions] = await Promise.all([
      getLookupRole(api),
      getLookupReflectionProcessingUnit(api),
    ])

    return {
      roleOptions,
      reflectionUnitOptions,
    }
  }, [])

  useEffect(() => {
    selectedId && userDetail.run()
  }, [selectedId])

  useEffect(() => {
    setRoleValues([])
  }, [])

  useEffect(() => {
    form.setAt("roles", roleValues)
  }, [roleValues])

  const handleSubmit = async () => {
    setIsSubmitted(true)
    const errors = await form.validate()

    if (errors) return

    const datas = await form.get()
    const formData = new FormData()
    const roleList = pageOptions.getResult()?.roleOptions?.content

    Object.keys(datas).forEach((item) => datas[item] && formData.append(item, datas[item]))
    console.log(roleList)

    roleList &&
      datas.roles?.map((role, index) =>
        formData.append(
          `roles[${index}].code`,
          pageOptions.getResult()?.roleOptions?.content.find((item) => item.id?.toString() === role)?.name as string
        )
      )
    let isSuccess = false
    formData.delete("roles")

    if (selectedId) {
      const res = await updateUser(api, formData, handleErrorCallBack)
      if (res?.content.status) isSuccess = true
    } else {
      formData.delete("id")
      const res = await createUser(api, formData, handleErrorCallBack)
      if (res?.content.status) isSuccess = true
    }

    if (isSuccess) {
      alert("Thành công", { variant: "success" })
      handleClose()
      onSuccess()
    }
  }

  const onDrop = async (acceptedFiles) => {
    form.setAt("file", acceptedFiles[0])
    const file: Blob = new Blob(acceptedFiles)
    const reader = new FileReader()
    reader.onloadend = () => {
      setImagePreview(reader.result as string)
    }

    reader.readAsDataURL(file)
  }

  const handleClose = () => setShowModalCreate(false)

  const handleChangeRole = (event, value) => {
    setRoleValues(value || [])
    form.setAt("roles", value)
  }

  return (
    <WideDialog title={selectedId ? "Chỉnh sửa thông tin user" : "Thêm mới user"} onClose={handleClose}>
      <form>
        <div className="form-row">
          <div className="form-group col-12 col-md-6">
            <label>
              Họ tên <span>*</span>
            </label>
            <input type="text" {...bind.textField("fullName")} />
            <FormHelperText {...bind.helperText("fullName")} />
          </div>
          <div className="form-group col-12 col-md-6">
            <label>
              Giới tính <span>*</span>
            </label>
            <Select
              options={genderOptions.map((item) => {
                return { id: item.value, name: item.label }
              })}
              bind={bind}
              field={"sex"}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label>
              Số điện thoại <span>*</span>
            </label>
            <input type="text" pattern="[0-9]{9}" {...bind.textFieldNumber("phoneNumber")} />
            <FormHelperText {...bind.helperText("phoneNumber")} />
          </div>
          <div className="form-group col-12 col-md-6">
            <label>
              Email <span>*</span>
            </label>
            <input disabled={!!selectedId} type="text" {...bind.textField("email")} />
            <FormHelperText {...bind.helperText("email")} />
          </div>
          <div className="form-group col-12 col-md-6">
            <label>
              Quyền <span>*</span>
            </label>
            <MUIAutocompleteMulti
              {...bind.autocomplete("province")}
              options={pageOptions.getResult()?.roleOptions?.content.map((item) => item.id.toString()) || []}
              placeholder="Chọn quyền"
              noOptionsText="Không có lựa chọn"
              isError={isSubmitted && isEmpty(roleValues) ? ["Bắt buộc"] : undefined}
              getOptionLabel={(option) =>
                pageOptions.getResult()?.roleOptions?.content.find((item) => item.id.toString() === option)?.name || ""
              }
              onChange={handleChangeRole}
              value={roleValues}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label>
              Đơn vị công tác <span>*</span>
            </label>
            <Select
              options={pageOptions.getResult()?.reflectionUnitOptions?.content}
              bind={bind}
              field={"reflectionUnitId"}
              labelDefaultOption="Chọn đơn vị"
            />
          </div>
          <div className="modal-select-avatar mb-4 col-12">
            <h4>Avatar</h4>
            {!imagePreview ? (
              <DropZone setFile={onDrop} accept="image/png, image/jpeg" />
            ) : (
              <div className="img">
                <img src={imagePreview} alt="" />
                <button type="button" className="change__avatar" onClick={() => inputRef.current?.click()}>
                  <img src={ChangeAvatarBlue} alt="" />
                </button>
                <UploadImage inputRef={inputRef} form={form} setImagePreview={setImagePreview} field="file" />
              </div>
            )}
          </div>
          <div className="col-12 d-flex justify-content-center">
            <button
              type="button"
              disabled={form.isSubmitting() || !form.isChanged()}
              className="btn btn-ct btn-primary-ct loading"
              onClick={handleSubmit}
            >
              {form.isSubmitting() && <CircularProgress size={20} color="inherit" />}
              {selectedId ? "Lưu thay đổi" : "Thêm user"}
            </button>
          </div>
        </div>
      </form>
    </WideDialog>
  )
}
