import { AxiosInstance } from "axios"
import { createChunkedParams } from "../../helpers/createChunkedParams"
import { ChunkedArrayResponse } from "../../units/types/ChunkedArrayResponse"
import { ChunkedPaginationResponse } from "../../units/types/ChunkedPaginationResponse"
import { ChunkedParams } from "../../units/types/ChunkedParams"
import { ChunkedLookupResponse } from "../../units/types/ChunkedLookupResponse"
import { Commune } from "../../units/types/Commune"
import { District } from "../../units/types/District"
import { Province } from "../../units/types/Province"
import qs from "qs"

export type AdministrativeParams = ChunkedParams & {
  sortExpression?: number
  name?: string
}

export const getAllProvince = async (
  api: AxiosInstance,
  params?: AdministrativeParams
): Promise<ChunkedPaginationResponse<Province> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<Province>>("/api/admin/Administrative/GetListProvince", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getLookupProvince = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/Province/GetLookup")
    return res.data
  } catch (err) {}
}

export const getAllDistrict = async (
  api: AxiosInstance,
  params?: AdministrativeParams
): Promise<ChunkedPaginationResponse<District> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<District>>("/api/admin/Administrative/GetListDistrict", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getLookupDistrict = async (
  api: AxiosInstance,
  provinceId: number
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/District/GetLookupDistrict", {
      params: { provinceId },
    })
    return res.data
  } catch (err) {}
}

export const getLookupDistrictMulti = async (
  api: AxiosInstance,
  provinceIds: Array<number>
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>(
      "/api/admin/Administrative/GetLookupDistrictMultiProvince",
      {
        params: { provinceIds },
        paramsSerializer: (params) => {
          return qs.stringify(params)
        },
      }
    )
    return res.data
  } catch (err) {}
}

export const getAllCommune = async (
  api: AxiosInstance,
  params?: AdministrativeParams
): Promise<ChunkedPaginationResponse<Commune> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedPaginationResponse<Commune>>("/api/admin/Administrative/GetListCommune", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getLookupCommune = async (
  api: AxiosInstance,
  districtId: number
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>(
      "/api/admin/Administrative/GetLookupCommune",
      {
        params: { districtId },
      }
    )
    return res.data
  } catch (err) {}
}

export const getLookupCategory = async (
  api: AxiosInstance,
  params?: AdministrativeParams
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/Categories/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getLookupReflectionFields = async (
  api: AxiosInstance,
  params?: AdministrativeParams
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const req = createChunkedParams(params)
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>("/api/admin/ReflectionFields/GetLookup", {
      params: req,
    })
    return res.data
  } catch (err) {}
}

export const getLookupCommuneMulti = async (
  api: AxiosInstance,
  districtIds: Array<number>
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>(
      "/api/admin/Administrative/GetLookupCommuneMultiDistrict",
      {
        params: { districtIds },
        paramsSerializer: (params) => {
          return qs.stringify(params)
        },
      }
    )
    return res.data
  } catch (err) {}
}

export const getLookupReflectionProcessingUnit = async (
  api: AxiosInstance
): Promise<ChunkedArrayResponse<ChunkedLookupResponse> | undefined> => {
  try {
    const res = await api.get<ChunkedArrayResponse<ChunkedLookupResponse>>(
      "/api/admin/ReflectionProcessingUnit/GetLookup"
    )
    return res.data
  } catch (err) {}
}
